import { Country_State_city } from "./actionType"


const setCountry = (val) => {
    return {
        type: Country_State_city.SET_COUNTRY,
        payload: val
    }
}
const setState = (val) => {
    return {
        type: Country_State_city.SET_STATE,
        payload: val
    }
}
const setCity = (val) => {
    return {
        type: Country_State_city.SET_CITY,
        payload: val
    }
}
const resetCsc = () => {
    return {
        type: Country_State_city.RESET_CSC,
    }
}

// Native Address actions

const setNativeCountry = (val) => {
    return {
        type: Country_State_city.SET_NATIVE_COUNTRY,
        payload: val
    }
}
const setNativeState = (val) => {
    return {
        type: Country_State_city.SET_NATIVE_STATE,
        payload: val
    }
}
const setNativeCity = (val) => {
    return {
        type: Country_State_city.SET_NATIVE_CITY,
        payload: val
    }
}
const resetNativeCsc = () => {
    return {
        type: Country_State_city.RESET_NATIVE_CSC,
    }
}


export {
    setCountry, setState, setCity, resetCsc,
    setNativeCountry, setNativeState, setNativeCity, resetNativeCsc,
}