import React, { useEffect, useState } from 'react'
import '../DynamicdocumentFields.scss'
import Dropdown from '../../../../../CommanComponents/Dropdown/Dropdown'
import useDocumentDetails from '../../../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
import FormError from '../../../../../CommanComponents/Form/FormError'
const EmiratesIDBackSide = ({ emiratesIDFileds, handleInputChange, showError, regexError }) => {
    // const [regexError, setRegexError] = useState();

    const handleRegexValidation = (e) => {
        // let inputName = e.target.name;
        // if (inputName === 'Occupation' || inputName === 'Employer' || inputName === "Name_of_Spouse" || inputName === 'Issuing_Place') {
        //     if (!/^[a-zA-Z\s]*$/.test(e.key)) {
        //         e.preventDefault();
        //         setRegexError(inputName)
        //     } else { setRegexError('') }
        // } else if (inputName === 'Card_Number') {
        //     if (/^[a-zA-Z\s]*$/.test(e.key) && e.key !== "Backspace") {
        //         e.preventDefault();
        //         setRegexError(inputName)
        //     } else { setRegexError('') }
        // } else {
        //     setRegexError('')
        // }
    }


    return (
        <div className="DynamicdocumentFields-main-wrapper document-dyanmic-fields">
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Occupation*</label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.Occupation?.value}
                    type='text'
                    name='Occupation'
                    onChange={handleInputChange}
                    tabIndex={9}
                // onKeyUp={handleInputChange}
                // onKeyDown={(e) => handleRegexValidation(e)}
                />
                <FormError show={!emiratesIDFileds?.Occupation?.isValid && showError} error={'Please enter occupation.'} />
                {regexError === 'Occupation' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Employer* </label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.Employer?.value}
                    type='text'
                    name='Employer'
                    onChange={handleInputChange}
                    tabIndex={10}
                // onKeyUp={handleInputChange}
                // onKeyDown={(e) => handleRegexValidation(e)}
                />
                <FormError show={!emiratesIDFileds?.Employer?.isValid && showError} error={'Please enter employer'} />
                {regexError === 'Employer' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}>Issuing Place* </label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.Issuing_Place?.value}
                    type='text'
                    name='Issuing_Place'
                    onChange={handleInputChange}
                    tabIndex={11}
                // onKeyUp={handleInputChange}
                // onKeyDown={(e) => handleRegexValidation(e)}
                />
                <FormError show={!emiratesIDFileds?.Issuing_Place?.isValid && showError} error={'Please enter issuing place.'} />
                {regexError === 'Issuing_Place' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Card Number* </label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.Card_Number?.value}
                    type='text'
                    name='Card_Number'
                    onChange={handleInputChange}
                    tabIndex={12}
                // onKeyUp={handleInputChange}
                // onKeyDown={(e) => handleRegexValidation(e)}
                />
                <FormError show={!emiratesIDFileds?.Card_Number?.isValid && showError} error={'Please enter card number.'} />
                {regexError === 'Card_Number' && <FormError show={true} error={'Please enter only numeric character & space not allowed'} />}
            </div>
        </div>
    )
}

export default EmiratesIDBackSide