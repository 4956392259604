import React from "react"
import erroricon from "../../Assets/icons/error-icon.svg"
const FormError = ({ className, error, success, show = false }) => {
    return show === true && <div className={`errorInputMsg mt-5 secondary text-xxs ${className}`}>
        {
            success ? "" : <React.Fragment> <img src={erroricon} alt="erroricon" className="erroricon" />
                &nbsp;</React.Fragment>
        }
        {error}</div>
}

export default FormError