import React, { forwardRef } from "react";
import "./searchControl.scss";

const SearchControl = forwardRef(({ name, value, id, type, label, classNameWrappper, className, reset, ...props }, ref) => {

    return (
        <form className={`SearchControlbar ${classNameWrappper}`} onSubmit={(e) => e.preventDefault()}>
            <input type="search" required className={`form-control-search ${className}`} id={id} ref={ref} name={name} value={value} {...props} />
            <button className="close-icon" type="reset" onClick={reset}></button>
            <i className="search-icon"></i>
        </form>
    )
})
export default SearchControl;