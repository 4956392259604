import { FETCHING_LOADER_ACTIONS } from "../Actions/LoaderFetching/actionType";



const INITIAL_ERROR_STATES = {
    loading: false,
    message: null
}

const loaderFetching = (state = INITIAL_ERROR_STATES, { type, payload }) => {

    switch (type) {
        case FETCHING_LOADER_ACTIONS.LOADING_FETCH_SUCCESS:
            return {
                loading: true,
                message: payload,
            }
        case FETCHING_LOADER_ACTIONS.LOADING_FETCH_HIDE:
            return {
                loading: false,
                message: payload,
            }
        default:
            return state;
    }
}

export default loaderFetching;