// This make for OTP Fields UI and Functionality

import React, { useEffect, useMemo, useState } from 'react'
import useDownTimer from '../../../../../CustomHooks/useTimer';
import FormError from '../../../../../CommanComponents/Form/FormError';
import './otpBox.scss';
import { useSelector } from 'react-redux';
const OtpBox = ({ sendDataToParent, showError, resend, setShowError }) => {
    const forgotPassword = useSelector((state) => state.forgotPassword)
    const [loading, setLoading] = useState(false);
    const [resendButton, setResendButton] = useState(false);
    const [timer, setTimer] = useDownTimer();
    const [otp, setOtp] = useState("");
    const [OTPError, setOTPError] = useState(false)
    const [inputOtp1, setInputOtp1] = useState("");
    const [inputOtp2, setInputOtp2] = useState("");
    const [inputOtp3, setInputOtp3] = useState("");
    const [inputOtp4, setInputOtp4] = useState("");
    const [inputOtp5, setInputOtp5] = useState("");
    const [inputOtp6, setInputOtp6] = useState("");
    const [arrowsKeyState, setArrowKeyState] = useState(false);
    const textInput1 = React.createRef()
    const textInput2 = React.createRef()
    const textInput3 = React.createRef()
    const textInput4 = React.createRef()
    const textInput5 = React.createRef()
    const textInput6 = React.createRef()
    const [successMessage, setSuccessMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [symbolsArr] = useState(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9])

    const switchHandelChange = (key, elmnt) => {
        let value = elmnt.target.value
        // resetAll()
        setOTPError(false)
        switch (key) {
            case 0:
                console.log(key)

                setInputOtp1(value)
                onKeyUpChange(0, elmnt)
                break;
            case 1:
                setInputOtp2(value)
                onKeyUpChange(1, elmnt)
                console.log(key)

                break;
            case 2:
                setInputOtp3(value)
                onKeyUpChange(2, elmnt)
                console.log(key)

                break;
            case 3:
                setInputOtp4(value)
                onKeyUpChange(3, elmnt)
                console.log(key)

                break;
            case 4:
                setInputOtp5(value)
                onKeyUpChange(4, elmnt)
                console.log(key)

                break;
            case 5:
                setInputOtp6(value)
                onKeyUpChange(5, elmnt)
                console.log(key)

                break;
            default:
                break;
        }
    }
    const onKeyUpChange = (key, elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            switch (key) {
                case 0:
                    textInput1.current.focus()
                    break;
                case 1:
                    textInput1.current.focus()
                    break;
                case 2:
                    textInput2.current.focus()
                    break;
                case 3:
                    textInput3.current.focus()
                    break;
                case 4:
                    textInput4.current.focus()
                    break;
                case 5:
                    textInput5.current.focus()
                    break;
                default:
                    break;
            }
        } else if (elmnt.key === "ArrowRight") {
            // if (elmnt.target.value) {
            setArrowKeyState(!arrowsKeyState)
            switch (key) {
                case 0:
                    textInput2.current.focus()
                    break;
                case 1:
                    textInput3.current.focus()
                    break;
                case 2:
                    textInput4.current.focus()
                    break;
                case 3:
                    textInput5.current.focus()
                    break;
                case 4:
                    textInput6.current.focus()
                    break;
                case 5:
                    textInput6.current.focus()
                    break;

                default:
                    break;
            }
            // }
        }
        else if (elmnt.key === "ArrowLeft") {
            setArrowKeyState(!arrowsKeyState)
            switch (key) {
                case 0:
                    textInput1.current.focus()
                    break;
                case 1:
                    textInput1.current.focus()
                    break;
                case 2:
                    textInput2.current.focus()
                    break;
                case 3:
                    textInput3.current.focus()
                    break;
                case 4:
                    textInput4.current.focus()
                    break;
                case 5:
                    textInput5.current.focus()
                    break;

                default:
                    break;
            }

        }
        else {
            if (elmnt.target.value) {
                switch (key) {
                    case 0:
                        textInput2.current.focus()
                        break;
                    case 1:
                        textInput3.current.focus()
                        break;
                    case 2:
                        textInput4.current.focus()
                        break;
                    case 3:
                        textInput5.current.focus()
                        break;
                    case 4:
                        textInput6.current.focus()
                        break;
                    case 5:
                        textInput6.current.focus()
                        break;
                    default:
                        break;
                }
            }
        }
    }
    const handleMouseClick = (e) => {
        setArrowKeyState(!arrowsKeyState)
    }
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text/plain');
        console.log(pastedText)
        const pastedOtp = pastedText.split('').slice(0, 6);
        // setOtp(pastedOtp);
        // manually trigger onChange for the remaining input fields
        for (let i = 0; i < pastedOtp.length; i++) {
            let element = pastedOtp[i]
            switchHandelChange(i, { target: { value: element } });
        }
    };

    const resendFunc = () => {
        resend()
        setShowError(false)
        setLoading(true)
        setResendButton(true)
        if (true) {
            setLoading(false)
            setTimer('30')
        }
        setOtp("")
        setInputOtp1("")
        setInputOtp2("")
        setInputOtp3("")
        setInputOtp4("")
        setInputOtp5("")
        setInputOtp6("")
    }

    useMemo(() => {
        setTimer('30')
    }, [])
    const sendDataToParentHandler = () => {
        const array = [inputOtp1, inputOtp2, inputOtp3, inputOtp4, inputOtp5, inputOtp6,]
        const concatenatedStringArray = array.join('');
        const data = parseInt(concatenatedStringArray);
        sendDataToParent(data);
    };
    useEffect(() => {
        sendDataToParentHandler()
    }, [inputOtp1, inputOtp2, inputOtp3, inputOtp4, inputOtp5, inputOtp6,])
    console.log(showError, "Afsdfd")
    return (
        <div className="otp-wrapper">
            <p className="otp-label w-500 text-xs secondary">Enter OTP *</p>
            <div className="otp-input-wrap mt-5">
                <input ref={textInput1} className={`otp-input ${!OTPError ? "errorInput" : ""}`} type="text" id="first" tabIndex="1" maxLength="1" value={inputOtp1} onChange={(elmnt) => switchHandelChange(0, elmnt)}
                    onKeyUp={(elmnt) => onKeyUpChange(0, elmnt)}
                    onClick={handleMouseClick}
                    onKeyDown={(e) => symbolsArr.includes(e.key) || e.key === "Control" || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "v" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()}
                    onPaste={(e) => handlePaste(e)}
                    onKeyPress={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()}
                    autoFocus={true}
                    inputMode='numeric'
                />
                <input onClick={handleMouseClick} ref={textInput2} className={`otp-input ${!OTPError ? "errorInput" : ""}`} type="text" id="second" tabIndex="2" maxLength="1" value={inputOtp2} onChange={(elmnt) => switchHandelChange(1, elmnt)} onKeyUp={(elmnt) => onKeyUpChange(1, elmnt)} onKeyDown={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} onKeyPress={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} inputMode='numeric'
                />
                <input onClick={handleMouseClick} ref={textInput3} className={`otp-input ${!OTPError ? "errorInput" : ""}`} type="text" id="third" tabIndex="3" maxLength="1" value={inputOtp3} onChange={(elmnt) => switchHandelChange(2, elmnt)} onKeyUp={(elmnt) => onKeyUpChange(2, elmnt)} onKeyDown={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} onKeyPress={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} inputMode='numeric'
                />
                <input onClick={handleMouseClick} ref={textInput4} className={`otp-input ${!OTPError ? "errorInput" : ""}`} type="text" id="fourth" tabIndex="4" maxLength="1" value={inputOtp4} onChange={(elmnt) => switchHandelChange(3, elmnt)} onKeyUp={(elmnt) => onKeyUpChange(3, elmnt)} onKeyDown={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} onKeyPress={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} inputMode='numeric'
                />
                <input onClick={handleMouseClick} ref={textInput5} className={`otp-input ${!OTPError ? "errorInput" : ""}`} type="text" id="fifth" tabIndex="5" maxLength="1" value={inputOtp5} onChange={(elmnt) => switchHandelChange(4, elmnt)} onKeyUp={(elmnt) => onKeyUpChange(4, elmnt)} onKeyDown={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} onKeyPress={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} inputMode='numeric'
                />
                <input onClick={handleMouseClick} ref={textInput6} className={`otp-input ${!OTPError ? "errorInput" : ""}`} type="text" id="sixth" tabIndex="6" maxLength="1" value={inputOtp6} onChange={(elmnt) => switchHandelChange(5, elmnt)} onKeyUp={(elmnt) => onKeyUpChange(5, elmnt)} onKeyDown={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} onKeyPress={(e) => symbolsArr.includes(e.key) || e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" ? (elmnt) => switchHandelChange(0, elmnt) : e.preventDefault()} inputMode='numeric'
                />
            </div>
            <FormError
                show={showError}
                error={'Invalid Otp.'}
            />
            <p className='timer-otp mt-20 d-flex align-center justify-between flex-wrap'>Didn’t recieve OTP?
                <span>

                    {
                        loading ? <p type='button' className='btn-trasnparent' >Sending...</p> :
                            <button type='button' onClick={resendFunc} disabled={timer !== "0s"} className='transparent primary text-xxs w-500 '> {timer !== "0s" ? `Resend in ${timer}` : "Resend OTP"} </button>
                    }

                </span>
            </p>
        </div>
    )
}

export default OtpBox