import React, { useEffect } from 'react'
import Modals from '../../../CommanComponents/Modals'
import ModalsHeader from '../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../CommanComponents/Modals/ModalsBody'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton';
import { setClient, setEmployeeType } from '../../../store/Actions/EmployeeManagement'
import { setActiveTab } from '../../../store/Actions/PersonalDetails'
import DropdownSearch from '../../../CommanComponents/DropdownSearch/DropdownSearch'
import Auth from '../../../Classes/Auth'
import { getClientsList, getUsersClientsList } from '../../../store/Actions/Clients'
import './selectClientPop.scss'
const SelectClientPop = ({ reference, onClose }) => {
    const employeeManagement = useSelector((state) => state.employeeManagement)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const clients = useSelector(e => e.clients)
    const userId = Auth?.user()?.user_id // this is for added by user
    const userId_user = Auth?.user()?.user_id_user

    function saveBtnHandle(e) {
        e.preventDefault()
        navigate(`add-new-employee?client_id=${employeeManagement?.client?.id}`)
        dispatch(setEmployeeType('new'))
        dispatch(setActiveTab("Personal"))
        onClose()
    }


    const DropdownClientChanger = (val) => {
        dispatch(setClient(val))
    }

    useEffect(() => {
        if (Auth?.boolUserType() == 4) {
            dispatch(getUsersClientsList(userId_user, userId))
        } else {
            dispatch(getClientsList(userId))
        }
    }, [])


    const selectedClient = employeeManagement?.client

    console.log(selectedClient, "selectedClientselectedClient")

    return (
        <Modals
            ref={reference}
            ClosePopUp={onClose}
            Position="center"
            ModalsSize={'modal-xs'}
            ClassName={'select-client-modal-wrapper'}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={onClose}></i>
                    <p className='text-center m-auto'>Select Client</p>
                </h4>
            </ModalsHeader>
            <ModalBody className={'select-client-modal-body'}>
                <DropdownSearch
                    data={clients?.data.map((e) => e)}
                    defaultValue={selectedClient?.business_name}
                    className={'select-client-drop'}
                    clickHandle={(val) => DropdownClientChanger(val)}
                    ObjectKey={`business_name`}
                />


                <div className="save-btn-wrap d-flex mt-40 ">
                    <CommanButton
                        Text={'Add Employee'}
                        ClassName={'save-btn'}
                        onClick={saveBtnHandle}
                        disabled={selectedClient?.business_name == 'Select client*'}
                    />
                    <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={() => onClose()} />
                </div>
            </ModalBody >
        </Modals>
    )
}

export default SelectClientPop