import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dashboard from '../DashBoard'
import './support.scss'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import SearchControl from '../../CommanComponents/SearchControl/SearchControl'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
import { useOutsideClick } from 'rooks'
import data from './data.json'
import CreateTicket from './CreateTicket'
import useSupport from '../../CustomHooks/Support/useSupport'
import { useDispatch, useSelector } from 'react-redux'
import { dateFormat } from '../../CustomHooks/dateFormat'
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple'
import usePageListData from '../../CustomHooks/usePageListData'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import { setTicketSearch } from '../../store/Actions/Support'
import Auth from '../../Classes/Auth'
const Support = () => {
    const { ticketData, handleInputChange, inputFileRef, CreateTicketBtnHandle, openTicketPopup,
        closeTicketPopup, createTicketPopRef, showError, handleEditButton, actionType } = useSupport()

    const support = useSelector(e => e.support)

    const dispatch = useDispatch()
    const [searchVal, setSearchVal] = useState('')
    const ref = useRef(null)
    const [activeManage, setActiveManage] = useDetectOutsideClick(ref, false)
    const [pageNumber, setPageNumber] = useState(1)
    const userId = Auth?.user()?.user_id // this is for added by user

    // Debounced search function
    const debouncedSearch = useRef(null);

    useEffect(() => {
        debouncedSearch.current = debounce((value) => {
            dispatch(setTicketSearch(value, userId));
        }, 400);
    }, [dispatch]);

    const handleSearch = useCallback((e) => {
        const { value } = e.target;
        setSearchVal(value);
        debouncedSearch.current(value); // Call the debounced function
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    // const handleSearch = (e) => { 
    //     dispatch(employeeSearch(e.target.value))
    // }
    const handleResetSearch = () => {
        setSearchVal('')
    }
    // const handleSearch = (e) => {
    //     setSearchVal(e.target.value)
    // }
    // const handleResetSearch = () => {
    //     setSearchVal('')
    // }

    const handleManageBtn = (e, val) => {
        setActiveManage(activeManage === val ? -1 : val)
    }
    useOutsideClick(ref, () => {
        setActiveManage(-1)
    });
    const dataForCurrentPage = usePageListData(support?.data, pageNumber)
    console.log(ticketData, "ticketDataticketData")
    return (
        <>
            <div className="support-page-main-wrapper">
                <div className="top-bar-wrapper justify-between align-center">
                    <div className="heading-search-item justify-between align-center">
                        <SectionHeading Text={"Support"} />
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                        </div>

                        <div className="actions-wrappper d-flex justify-between align-center " >
                            <CommanButton onClick={() => openTicketPopup()} ClassName={"action-btn"} >
                                <p className='w-700'  > <span className='text-xs'>  + </span> Create Ticket</p>
                            </CommanButton>
                        </div>

                        {/* <div className="pagination-wrapper d-flex align-center justify-right">
                            <div className="pagination-item d-flex align-center">
                                <p className="text-xxs w-400 secondary mr-10 text-nowrap">
                                    {`1-${data?.length} of ${data?.length}`}
                                </p>
                                <div className="arrow-btn-wrap d-flex align-center">
                                    <button
                                        className={`arrow left transparent mr-10`}
                                    >
                                        <i className='icon'></i>
                                    </button>
                                    <button
                                        className={`arrow right transparent`}
                                    >   <i ></i></button>
                                </div>
                            </div>
                        </div> */}
                        <PaginationSimple
                            className={"document-pagenation"}
                            data={support?.data}
                            shows={support?.data?.length < 20 ? support?.data?.length : "20"}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />
                    </div>

                    <div className="support-table-wrapper mt-10">
                        <div className="table-wrap">
                            {dataForCurrentPage?.length ? <table className="table-item">
                                <thead className='table-head'>
                                    <tr className="table-row">
                                        <th className='errro-name'> Error Name </th>
                                        <th className='date'>Date</th>
                                        <th className='ticket-no'>Ticket No.</th>
                                        <th className='status'>Status</th>
                                        <th className='action'>Action</th>
                                    </tr>
                                </thead>

                                <tbody className='table-body'>
                                    {dataForCurrentPage?.map((val, key) => {
                                        return (
                                            <tr className="table-row" key={key}>
                                                <td className='name'> {val.error_name} </td>
                                                <td > {dateFormat(val?.date)} </td>
                                                <td > {val.ticket_no} </td>
                                                <td > {val.status} </td>
                                                <td >
                                                    <td onClick={(e) => e.stopPropagation()}>
                                                        <div className='dots-wrap d-flex justify-center align-center relative' onClick={(e) => { handleManageBtn(e, key) }} >
                                                            {/* <img src={dots} alt="" onClick={(e) => { handleManageBtn(e, key) }} /> */}
                                                            <i className='dots-icon' > </i>
                                                        </div>
                                                        {activeManage === key && <div className={`dropdown-wrap absolute active`} ref={ref}>
                                                            <div className="item d-flex align-center  pointer " onClick={() => handleEditButton(val?.ticket_no)} >
                                                                <i className='icons mr-5 edit '></i>
                                                                <p className="text-2xs w-500 secondary">Edit </p>
                                                            </div>
                                                            {/* <div className="item d-flex align-center  pointer"   >
                                                                <i className='icons mr-5 delete'></i>
                                                                <p className="text-2xs w-500 secondary">Delete</p>
                                                            </div> */}
                                                        </div>
                                                        }
                                                    </td>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> : <DataNotFound />}
                        </div>
                    </div>
                </div>
            </div>
            <CreateTicket
                reference={createTicketPopRef}
                onClose={closeTicketPopup}
                ticketData={ticketData}
                handleInputChange={handleInputChange}
                inputFileRef={inputFileRef}
                CreateTicketBtnHandle={CreateTicketBtnHandle}
                showError={showError}
                actionType={actionType}
            />
        </ >
    )
}

export default Support