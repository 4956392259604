import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRegisteredUserList } from '../../../store/Actions/AdminRegisteredUser'

const useRegisterUser = () => {
    // const adminRegisteredUser = useSelector((e => e.adminRegisteredUser))
    const adminRegisteredUser = useSelector((e => e.adminRegisteredUser))
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllRegisteredUserList())
    }, [])
    return { adminRegisteredUser }
}

export default useRegisterUser