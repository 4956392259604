import React from 'react'
import { AccessConsumer } from '../../../../../../../../Context/AccessContext';
import CheckboxInput from '../../../../../../../../CommanComponents/Form/CheckboxInput';
import { EmployeeDocumentAccepted, EmployeeDocumentAccepted2 } from '../../../../../../../../config';

const SelectEmpDocument = () => {
    return (
        <AccessConsumer>
            {(value) => {
                const { formData, documentUpload, handleInputChange, selectedEmployee, selectAllHandler, selectAll_docRef, selectAllDoc, selectAll } = value;
                const lastSelectedEmployee = formData?.employee?.selected_employees?.[formData?.employee?.selected_employees.length - 1];

                const isDocumentSelected = (document) => {
                    if (selectedEmployee) {
                        return (
                            selectedEmployee?.doc_type?.includes(document)
                        )
                    } else {
                        return (
                            lastSelectedEmployee?.doc_type?.includes(document)
                        )
                    }
                };
                console.log(selectedEmployee, "safwefehdfgasdf")
                return (
                    <>
                        <div className="SelectEmpDocument-wrapper">
                            <div className="employee-wrapper">
                                <p className="text-xs w-600 secondary mb-20 mt-15">Select document</p>
                                <ul className="employee-wrap">
                                    <li>
                                        <div className="input-custom-type mb-10">
                                            <CheckboxInput
                                                label={`Select All`}
                                                LabelClass={"secondary"}
                                                className={""}
                                                onChange={(e) => selectAllHandler(e)}
                                                name="select_all_doc"
                                                ref={selectAll_docRef}
                                                checked={selectAll.length > 0 ? formData?.employee?.selected_employees[0]?.doc_type.length > 1 : selectedEmployee?.doc_type.length > 1}
                                            />
                                        </div>
                                    </li>
                                    {EmployeeDocumentAccepted2?.map((val, key) => {
                                        return (
                                            <li>
                                                <div className="input-custom-type mb-10">
                                                    <CheckboxInput
                                                        label={val?.title}
                                                        LabelClass={val?.value == "passport" || val?.value == "emirates_id" ? "secondary" : "disable"}
                                                        className={""}
                                                        name="selected_document"
                                                        value={val?.value}
                                                        onChange={(e) => handleInputChange(e)}
                                                        disabled={val?.value !== "passport" && val?.value !== "emirates_id"}
                                                        checked={isDocumentSelected(val?.value)}
                                                    />
                                                </div>
                                            </li>
                                        )
                                    })}
                                    {/* {EmployeeDocumentAccepted?.map((val, key) => {
                                        return (
                                            <li>
                                                <div className="input-custom-type mb-10">
                                                    <CheckboxInput
                                                        label={val}
                                                        LabelClass={"secondary"}
                                                        className={""}
                                                        name="selected_document"
                                                        value={val}
                                                        onChange={(e) => handleInputChange(e)}
                                                        checked={isDocumentSelected(val)}
                                                    />
                                                </div>
                                            </li>
                                        )
                                    })} */}
                                </ul>
                            </div>
                        </div>
                    </>
                );
            }}

        </AccessConsumer >
    )
}

export default SelectEmpDocument