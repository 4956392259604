import DocumentUploadAction from "../Actions/DocumentUpload/actionType";

const init = {
    loading: false,
    success: false,
    error: false,
    data: [],
    docType: 'Add document*',
    document: null,
    aiAnalyzer: {}
}


const documentUpload = (state = init, { type, payload }) => {
    switch (type) {
        case DocumentUploadAction.DOC_UPLOAD_SUCCESS: return {
            loading: false,
            success: true,
            error: false
        }
        case DocumentUploadAction.DOC_SUCCESS_RESET: return {
            ...state,
            success: false,
        }
        case DocumentUploadAction.GET_UPLOADED_DOCS_SUCCESS: return {
            ...state,
            data: payload,
        }
        case DocumentUploadAction.UPLOADED_DOCS_RESET: return {
            ...state,
            data: [],
        }
        case DocumentUploadAction.GET_SINGLE_DOC: return {
            ...state,
            document: { ...state.document, ...payload }
        }
        case DocumentUploadAction.GET_AI_FRONTSIDE: return {
            ...state,
            aiAnalyzer: {
                ...state.aiAnalyzer,
                frontSide: { ...payload, success: true, },

            }
        }
        case DocumentUploadAction.RESET_AI_FRONTSIDE: return {
            ...state,
            aiAnalyzer: {
                ...state.aiAnalyzer,
                // frontSide: null,
                frontSide: { frontSide: {}, success: null, },
            }
        }
        case DocumentUploadAction.GET_AI_BACKSIDE: return {
            ...state,
            aiAnalyzer: {
                ...state.aiAnalyzer,
                backside: { ...payload, success: true, },
            }
        }
        case DocumentUploadAction.RESET_AI_BACKSIDE: return {
            ...state,
            aiAnalyzer: {
                ...state.aiAnalyzer,
                backside: { backside: {}, success: null, },
                // backside: null,
            }
        }
        case DocumentUploadAction.RESET_SINGLE_DOC: return {
            ...state,
            document: null
        }
        case DocumentUploadAction.DELETE_DOCUMENT_SUCCESS: return {
            ...state,
            deleted: true
        }
        case DocumentUploadAction.SET_UPDATE_DOCS: return {
            ...state,
            set_update: true
        }
        case DocumentUploadAction.RESET_UPDATE_DOCS: return {
            ...state,
            set_update: false,
            document: null
        }
        case DocumentUploadAction.SET_DOC_TYPE: return {
            ...state,
            docType: payload
        }
        default: return state
    }
}

export default documentUpload;