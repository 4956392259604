import React from 'react'
import './style.scss'
import Logo from '../../../Assets/icons/Logo.svg';
const RightSidebar = ({ children, ClassName }) => {

    return (
        <div className="right-sidebar-wrapper">
            <div className={`right-side-wrap ${ClassName}`}>
                <div className="children-container">
                    {children}
                </div>
                <div className="right-container relative">
                    <div className="bg-img ">
                        <div className="img-wrap ">
                            <div className="heading-wrapper pT-40 text-center">
                                <div className="logo-wrap mb-20 text-center">
                                    <img src={Logo} alt="" />
                                </div>
                                <p className='mt-50 text-xl w-500 white sub-head'>Your Business <br /> Documents Renewal</p>
                                <p className='fancy-font white mt-20'>Made Simple</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RightSidebar