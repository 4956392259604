import { Clients_Actions } from "../Actions/Clients/actionType"

const initState = {
    loading: false,
    success: false,
    error: false,
    delete: false,
    message: null,
    data: [],
    client_type: '',
    activeTab: 'Personal',
    fetch_type: '',
    single_client: {
        loading: false,
        data: [],
        error: false,
    }

}

const clients = (state = initState, { type, payload }) => {
    switch (type) {
        case Clients_Actions.SET_LOADING: return {
            ...state,
            loading: true
        }
        case Clients_Actions.SET_CLIENTS_LIST: return {
            ...state,
            loading: false,
            data: payload
        }
        case Clients_Actions.RESET_CLIENTS_LIST: return {
            ...state,
            data: []
        }
        case Clients_Actions.SET_CLIENT_TYPE: return {
            ...state,
            client_type: payload
        }
        case Clients_Actions.SET_CLIENT_ACTIVE_TAB: return {
            ...state,
            activeTab: payload
        }
        case Clients_Actions.CREATE_CLIENT_SUCCESS: return {
            ...state,
            success: true,
            message: null
        }
        case Clients_Actions.CREATE_CLIENT_ERROR: return {
            ...state,
            error: true,
            message: payload
        }
        case Clients_Actions.CREATE_CLIENT_ERROR_RESET: return {
            ...state,
            error: false,
            message: null
        }
        case Clients_Actions.CREATE_CLIENT_RESET: return {
            ...state,
            success: false
        }
        case Clients_Actions.SET_SINGLE_CLIENT_LOADING: return {
            ...state,
            single_client: {
                ...state.single_client,
                loading: true,
            }
        }
        case Clients_Actions.SET_SINGLE_CLIENT: return {
            ...state,
            single_client: {
                ...state.single_client,
                loading: false,
                data: payload
            }
        }
        case Clients_Actions.RESET_SINGLE_CLIENT: return {
            ...state,
            single_client: {
                ...state.single_client,
                data: []
            }
        }
        case Clients_Actions.SET_DELETE: return {
            ...state,
            delete: true
        }
        case Clients_Actions.RESET_DELETE: return {
            ...state,
            delete: false
        }
        case Clients_Actions.SET_FETCH_TYPE: return {
            ...state,
            fetch_type: payload
        }
        default: return state
    }
}

export default clients