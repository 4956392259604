import Request from "../../../Classes/Requests";

class personalDetails extends Request {
    constructor() {
        super()
        this.endpoint = {
            add_employee: super.url("/add_employee"),
            single_employee_detail: super.url("/employee_detail/"),
            edit_employee: super.url("/edit_employee/"),
            delete_employee: super.url("/delete_employee/"),
            get_employee_Login: super.url("/get_employee"), // employee  login myProfile
            // getData: super.url("/middleware/getbusiness?business=__BUSINESS_ID__&type=__TYPE__", "middleware"),
        }
    }
}
export default new personalDetails();
