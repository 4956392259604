import React, { useState } from 'react'
import Modals from '../Modals';
import ModalsHeader from '../Modals/ModalsHeader';
import ModalBody from '../Modals/ModalsBody';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";

const DocumentPrev = ({ onClose, reffrence, heading, children }) => {
    const previewClose = () => {
        onClose();
        setActiveSlideIndex(0);
    };

    const [activeSlideIndex, setActiveSlideIndex] = useState(0); // State to hold the active slide index

    // Function to handle slide change
    const handleSlideChange = (swiper) => {
        setActiveSlideIndex(swiper.activeIndex);
    };

    const swiperConfig = {
        className: "swiper-wrapper",
        spaceBetween: 0,
        centeredSlides: true,
        loop: false,
        speed: 1200,
        navigation: true,
        modules: [Navigation],
        onSlideChange: handleSlideChange // Attach the slide change handler

    };

    return (
        <Modals
            ref={reffrence}
            ClosePopUp={previewClose}
            // slide={"zoom"}
            Position="center"
            ModalsSize={'modal-l'}
            ClassName={'folder-modal-wrapper doc-preview-model'}
        // croseShow={true}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={previewClose}></i>
                    <p className='text-center m-auto'>
                        {heading ? heading : "Preview"}
                    </p>
                </h4>
            </ModalsHeader>
            <ModalBody >
                <Swiper {...swiperConfig}>
                    {children}
                </Swiper>


                {/* <div className="save-btn-wrap d-flex mt-40 ">
                    <CommanButton Text={'Save'} ClassName={'save-btn'} onClick={saveBtnHandle} />
                    <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={() => onClose()} />
                </div> */}
            </ModalBody >
        </Modals>
    )
}


export default DocumentPrev