import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import "./Form2.scss"
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/MM/yyyy');
const InputDatePicker = forwardRef(
  (
    { name, label, placeholder, id, onSelect, value, maxDate, disabled, className, includedDates, CustomIcon, ...props },
    ref
  ) => {
    const { user } = useSelector((state) => {
      return {
        user: state.user,
      };
    });
    const [focusLabel, setFocusLabel] = useState(false)

    // console.log(autoCorrectedDatePipe, "autoCorrectedDatePipe")
    return (
      <React.Fragment>
        <div className="form-group">
          <DatePicker
            className={`form-control ${className}`}
            name={name}
            onBlur={() => setFocusLabel(false)}
            id={id}
            onFocus={() => setFocusLabel(true)}
            selected={value ? new Date(value) : null}
            onChange={(date) => {
              onSelect(date);
            }}
            maxDate={maxDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            autoComplete="off"
            placeholderText={placeholder}
            includeDates={includedDates}
            disabled={disabled}
            customInput={
              <MaskedInput
                pipe={autoCorrectedDatePipe}
                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                keepCharPositions={false}
                guide={false}
              />
            }
            {...props}
          />

          <i className={CustomIcon ? CustomIcon : "calendarDates"}></i>

          <label className={`animLabel ${(label && value) || (focusLabel) ? "show" : "hide"}`} htmlFor={id}>
            {label}
          </label>
        </div>
      </React.Fragment>
    );
  }
);
export default InputDatePicker;

function foo(array) {
  return Object.entries(
    array.reduce((obj, item, index) => {
      if (typeof item === "string") {
        obj[(obj[index] = item.toUpperCase())] = index;
      }

      return obj;
    }, {})
  );
}

const bar = foo(["a", "b", "c"]);
