import React, { useCallback, useEffect, useState } from 'react'
import styles from "./messageToast.scss"
import { useDispatch, useSelector } from 'react-redux';
import { hideSuccessToast } from '../../store/Actions/SuccessMessagePopup';

const MessageToast = () => {

    const dispatch = useDispatch();
    const success = useSelector(state => state.successMessagePopup)

    const handleClose = useCallback(() => {
        dispatch(hideSuccessToast())
    }, [dispatch])

    useEffect(() => {
        if (success.showToast) {
            setTimeout(handleClose, 3500);
            // const timer = setTimeout(handleClose, 3500);
            // return () => clearTimeout(timer); // Clear the timer on unmount or when success changes
        }
    }, [success, handleClose])

    // const [success, setSuccess] = useState({
    //     toastType: "success",
    //     showToast: true
    // })

    // const handleClose = useCallback(() => {
    //     setSuccess({
    //         ...success,
    //         showToast: false
    //     })
    // }, [])

    // useEffect(() => {
    //     if (success.showToast) {
    //         setTimeout(handleClose, 3000)
    //     }
    // }, [success, handleClose]) 
    return (
        <div className={"messageToastContainer"}>
            {success.showToast &&
                <div className={`${"toastWrap"} `}>
                    <div className={"toastIcon"}>
                        {
                            success.toastType === 'success' ? <i className="iconSuccess msgIcon"></i>
                                : success.toastType === 'error' ? <i className="iconError msgIcon"></i>
                                    : success.toastType === 'warning' ? <i className="iconWarning msgIcon"></i> : ''
                        }

                    </div>
                    <div className={"toastParaWrap"}>
                        <p className={`${"toastParaText"} text-xs w-400`}> {success.message}</p>
                    </div>
                    <span className={"crossSpan"} onClick={handleClose}>
                        <i className={"iconCross"}></i>
                    </span>
                    <span className={`bottomBoder ${success.toastType === "success" ? "successBorder" : success.toastType === 'error' ? "errorBorder" : success.toastType === "warning" ? "warningBorder" : ''} `} ></span>
                </div>
            } </div>

    )
}

export default MessageToast