
import { showErrorToast, showSuccessToast } from "../SuccessMessagePopup";
import { Clients_Actions } from "./actionType"
import clientsReq from "./clientsReq";
import Auth from "../../../Classes/Auth";
const userId = Auth?.user()?.user_id // this is for added by user
const userId_user = Auth?.user()?.user_id_user
const getClientsList = (id) => {
    return async (dispatch) => {
        dispatch(
            { type: Clients_Actions.SET_LOADING },
        )
        clientsReq.get(`${clientsReq.endpoint.typing_client_users}/${id}`, (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: Clients_Actions.SET_CLIENTS_LIST, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}
const getUsersClientsList = (id, userId) => {
    return async (dispatch) => {
        dispatch(
            { type: Clients_Actions.SET_LOADING },
        )
        clientsReq.get(`${clientsReq.endpoint.typing_client_users}/${id}?user_id=${userId}`, (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: Clients_Actions.SET_CLIENTS_LIST, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}

const addClient = (data) => {
    return async (dispatch) => {
        clientsReq.post(clientsReq.endpoint.add_typing_client, data, (success) => {
            dispatch(
                { type: Clients_Actions.CREATE_CLIENT_SUCCESS },
            )
            dispatch(showSuccessToast('Client added successfully!'))
        }, (error) => {
            if (error?.response?.status == 400) {
                dispatch(
                    { type: Clients_Actions.CREATE_CLIENT_ERROR, payload: 'User with this email already exists. ' },
                )
            }
        }
        )
    }
}

const updateClient = (client_id, data, user_id) => {
    return async (dispatch) => {
        clientsReq.put(`${clientsReq.endpoint.update_typing_client}/${client_id}`, data, (success) => {
            dispatch(getSingleClient(user_id, client_id))
            dispatch(showSuccessToast('Client updated successfully!'))
        }, (error) => {
            if (error?.response?.status == 400) {
                dispatch(
                    { type: Clients_Actions.CREATE_CLIENT_ERROR, payload: 'User with this email already exists. ' },
                )
            }
        }
        )
    }
}




const getSingleClient = (user_id, client_id) => {
    return async (dispatch) => {
        dispatch(
            { type: Clients_Actions.SET_SINGLE_CLIENT_LOADING },
        )
        clientsReq.get(`${clientsReq.endpoint.client_view}/${user_id}/${client_id}`, (success) => {
            dispatch(
                { type: Clients_Actions.SET_SINGLE_CLIENT, payload: success?.data },
            )
        }, (error) => {
        }
        )
    }
}
const setDeleteClient = (client_id, user_id) => {
    return async (dispatch) => {
        clientsReq.delete(`${clientsReq.endpoint.delete_typing_client}/${client_id}`, (success) => {
            dispatch({ type: Clients_Actions.SET_DELETE })
            if (Auth?.boolUserType() == 4) {
                dispatch(getUsersClientsList(userId_user, userId))
            } else {
                dispatch(getClientsList(user_id))
            }
            dispatch(showSuccessToast('Deleted successfully!'))
        }, (error) => {
        }
        )
    }
}

const clientSearch = (userId, value) => {
    return async (dispatch) => {
        clientsReq.get(clientsReq.endpoint.search_clients.replace('__TC_ID__', userId).replace('__VALUE__', value), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: Clients_Actions.SET_CLIENTS_LIST, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}

const resettSingleClient = () => {
    return {
        type: Clients_Actions.RESET_SINGLE_CLIENT,
    }
}
const resetDeleteClient = () => {
    return {
        type: Clients_Actions.RESET_DELETE,
    }
}
const resetAddClient = () => {
    return {
        type: Clients_Actions.CREATE_CLIENT_RESET,
    }
}
const resetError = () => {
    return {
        type: Clients_Actions.CREATE_CLIENT_ERROR_RESET,
    }
}

const setClientType = (type) => {
    return {
        type: Clients_Actions.SET_CLIENT_TYPE,
        payload: type
    }
}


const setClientActiveTab = (payload) => {
    return ({
        type: Clients_Actions.SET_CLIENT_ACTIVE_TAB,
        payload: payload
    })
}

const resetClientSearch = (id) => {
    return async (dispatch) => {
        if (Auth?.boolUserType() == 4) {
            dispatch(getUsersClientsList(userId_user, userId))
        } else {
            dispatch(getClientsList(id))
        }
        // dispatch(getClientsList(id));
    }
}

const setFetchType = (payload) => {
    return ({
        type: Clients_Actions.SET_FETCH_TYPE,
        payload: payload
    })
}
const resetClientList = (payload) => {
    return ({
        type: Clients_Actions.RESET_CLIENTS_LIST
    })
}
export {
    getClientsList, setClientType, setClientActiveTab,
    getSingleClient, resettSingleClient, addClient, resetAddClient, updateClient,
    setDeleteClient, resetDeleteClient, clientSearch, resetClientSearch, resetError, setFetchType,
    getUsersClientsList, resetClientList
};