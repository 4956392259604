import React, { useEffect, useLayoutEffect, useState } from 'react'
import RightSidebar from '../../RightSideBar/RightSidebar'
import '../forgotPaswword.scss'
import backIcon from '../../../../Assets/icons/back-icon.svg'
import { Link, useNavigate } from "react-router-dom";
import OtpBox from './OtpBox/OtpBox';
import { useDispatch, useSelector } from 'react-redux';
import { VerifyOTP, resendOTP, resetVerifyOtp, verifyValidUser } from '../../../../store/Actions/ForgotPassword';
const SendOtp = () => {
    const forgotPassword = useSelector((state) => state.forgotPassword)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [inputData, setInputData] = useState()
    const [showError, setShowError] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');


    useEffect(() => {
        dispatch({ type: "HIDE_VALID_USER" })
    }, [])
    // Callback function to receive data from the child
    const handleChildData = (data) => {
        data && setInputData(data)
    };

    const continueBtnHandle = () => {
        // if (inputData?.toString().length === 6) {
        const otpData = {
            email: emailParam,
            otp_code: inputData
        }
        console.log(inputData, "ADFsdv")
        dispatch(VerifyOTP(otpData))

        // } else {
        //     setShowError(true)
        // }
    }
    const sendOtpHandler = () => {
        if (emailParam) {
            const data = {
                email: emailParam,
            }
            dispatch(resendOTP(data))

        }
    }

    useEffect(() => {
        if (forgotPassword.verifyOtp?.success) {
            navigate(`/forgot-password/confirm-new-password?email=${emailParam}`)
        } else {
            setShowError(true)
        }
    }, [forgotPassword.verifyOtp?.success])

    useEffect(() => {
        dispatch(resetVerifyOtp())
    }, [])
    useEffect(() => {
        if (forgotPassword.verifyOtp?.error) {
            setShowError(true)
        } else {
            setShowError(true)
        }
    }, [forgotPassword.verifyOtp])
    return (
        <div className="forgot-wrapper">
            <RightSidebar>
                <div className="form-container">
                    <button className="back-wrap mb-40 transparent" onClick={() => navigate('/forgot-password')}>
                        <img src={backIcon} alt="" />
                    </button>
                    <div className="form-wrap">
                        <div className="form">
                            <h3 className="heading text-md w-500 primary text-center">Welcome</h3>
                            <p className="sub-heading w-400 text-center secondary">{'Please check your phone number or email'}</p>
                            <div className="form-control mt-40">
                                {<OtpBox sendDataToParent={handleChildData} showError={showError && forgotPassword.verifyOtp?.error} setShowError={setShowError} data={forgotPassword} resend={sendOtpHandler} />}
                            </div>
                            {
                                <button type='button' className="login-button pT-10 pb-10 mt-30 w-400 text-s transparent white" onClick={() => continueBtnHandle()}>
                                    Continue
                                </button>}
                            <p className='signup-btn text-center mt-30 text-xs w-400'>Have a Account? <Link className='pointer w-600' to={'/login'} > Login</Link></p>
                        </div>
                    </div>
                </div>
            </RightSidebar >
        </div >
    )
}

export default SendOtp