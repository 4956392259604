import React, { forwardRef } from "react";

const RadioInput = forwardRef(
    ({ name, id, type, checked, label, className, LabelClass, ...props }, ref) => {
        return (
            <label className={LabelClass}>
                <input
                    type="radio"
                    className={className}
                    id={id}
                    ref={ref}
                    checked={checked}
                    name={name}
                    {...props}
                />
                <span>{label}</span>
            </label>
        );
    }
);
export default RadioInput;
