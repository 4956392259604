import React, { useState } from 'react'
import Modals from '../../../../../../CommanComponents/Modals'
import ModalsHeader from '../../../../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../../../../CommanComponents/Modals/ModalsBody'
import './accessModal.scss';
import CommanButton from '../../../../../../CommanComponents/CommanButton/CommanButton';
import Dropdown from '../../../../../../CommanComponents/Dropdown/Dropdown';
import FormError from '../../../../../../CommanComponents/Form/FormError';
import DataNotFound from '../../../../../../CommanComponents/DataNotFound/DataNotFound';
import AccessStep1 from './AccessMultiSteps/AccessStep1';
import { AccessProvider } from '../../../../../../Context/AccessContext';
import { useSelector } from 'react-redux';
import { useEmployeesManagement } from '../../../../../../CustomHooks/EmployeesManagement/useEmployeesManagement';
import AccessStep2 from './AccessMultiSteps/AccessStep2';
import ModalsFooter from '../../../../../../CommanComponents/Modals/ModalsFooter';
import useAccessModal from '../../../../../../CustomHooks/AccessManagement/useAccessModal';
import useWindowDimensions from '../../../../../../CustomHooks/useWindowDimensions';
const AccessModal = ({ reference, onClose, accessManagement, giveAccessBtn,
    accessBOEmail, acessBOError, handleInputBOchange,
    currentStep,
    setCurrentStep,
    accessOf,
    setAccessOf,
    formData,
    setFormData,
    nextStep,
    previousStep,
    handleInputChange,
    saveBtnHandler,
    selectAllHandler,
    employeeManagement,
    documentUpload,
    showDocs,
    searchValModal,
    handleSearchModal,
    handleResetSearchModal,
    selectAll,
    handleSelectedDocs,
    selectedEmployee,
    updateId, selectAllEmpRef, selectAll_docRef, selectAllDoc

}) => {

    const closeHandler = () => {
        onClose()
        setCurrentStep(1)
        setFormData(
            {
                employee: {},
                email: "",
                permission: ""
            }
        )
    }
    // get window height & width
    const { width } = useWindowDimensions();
    console.log(accessManagement?.view_access?.data, "ASdfsdfgsdgv")
    return (
        <Modals
            ref={reference}
            ClosePopUp={closeHandler}
            slide={"zoom"}
            Position="center"
            ModalsSize={width >= 1200 ? 'modal-l' : 'modal-xl'}
            ClassName={'access-modal-wrapper'}
        >
            <ModalsHeader ClassName={'heading'}>
                <h4 className='w-500 text-s d-flex align-center'  >
                    {/* <i className="back-btn pointer mr-10" onClick={onClose}></i> */}
                    <p className='text-center m-auto'>Account access</p>
                </h4>
            </ModalsHeader>
            <ModalBody className={!accessManagement?.data?.access_list?.length && 'access-modal-body-wrap'} >
                <AccessProvider value={{
                    nextStep,
                    previousStep,
                    formData,
                    handleInputChange,
                    accessOf, setAccessOf, width, selectAllHandler,
                    employeeManagement, documentUpload, showDocs, searchValModal, handleSearchModal, handleResetSearchModal,
                    selectAll,
                    handleSelectedDocs, selectedEmployee, selectAllEmpRef, selectAll_docRef, selectAllDoc
                }}>

                    {currentStep === 1 &&
                        <AccessStep1
                            accessManagement={accessManagement}
                            giveAccessBtn={giveAccessBtn}
                            accessBOEmail={accessBOEmail}
                            handleInputBOchange={handleInputBOchange}
                            acessBOError={acessBOError}
                            nextStep={nextStep}
                            closeHandler={closeHandler}
                        />
                    }
                    {currentStep === 2 && <AccessStep2 />}

                </AccessProvider>
            </ModalBody >
            <ModalsFooter>
                <div className="navigation d-flex justify-right">
                    {console.log(accessManagement, "accessManagementaccessManagementqsds")}
                    {currentStep !== 1 && !updateId && <CommanButton
                        Text={'Back'}
                        cancel={true}
                        ClassName={'access-btn mr-20'}
                        type={"button"}
                        onClick={currentStep == 1 ? closeHandler : previousStep}
                    />}
                    {currentStep !== 1 && <CommanButton
                        Text={accessManagement?.loading ? 'Sending...' : "Save"}
                        ClassName={'access-btn'}
                        type={"button"}
                        onClick={currentStep == 1 ? nextStep : saveBtnHandler}
                    />}
                </div>
            </ModalsFooter>
        </Modals>
    )
}

export default AccessModal