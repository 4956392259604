import React from 'react'
import "./loaderFetching.scss"
import { useDispatch, useSelector } from 'react-redux';
const LoaderFetching = () => {
    const dispatch = useDispatch();
    const loaderFetching = useSelector(state => state.loaderFetching)
    console.log(loaderFetching, "loaderFetchingloaderFetching")
    return (
        loaderFetching?.loading && <div class="background-loader">
            <div class="loader">
                <span class="spinner spinner1"></span>
                <span class="spinner spinner2"></span>
                <span class="spinner spinner3"></span>
                <br />
                <span class="loader-text">{loaderFetching?.message?.length ? loaderFetching?.message : 'Loading...'}</span>
            </div>
        </div>
    )
}

export default LoaderFetching