import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setUserClientList } from '../../store/Actions/User'
import Auth from '../../Classes/Auth'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const useUserDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = Auth?.user()?.user_id // this is for added by user
    // const { users_id } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [breadcrumb, setBreadcrumb] = useState(null);
    const users_id = searchParams.get('users_id');
    const user_name = searchParams.get('user_name');

    const editBtnHandler = (id) => {
        navigate(`/clients-management/id=${id}`)
    }


    useEffect(() => {
        // if (users_id.includes("id=")) {
        // const id = users_id?.includes("id=") && users_id?.replace("id=", "")
        dispatch(setUserClientList(users_id, userId))
        setBreadcrumb(user_name)
        // }
    }, [])

    return { editBtnHandler, breadcrumb, setBreadcrumb }
}

export default useUserDetails