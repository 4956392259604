// import { State, } from "country-state-city";
// import Select from "react-select";
// import './Csc2.scss'
// import { useDispatch, useSelector } from "react-redux";
// import { setCity, setState } from "../../store/Actions/CountryStateCity";
// import { useEffect, useState } from "react";

// const StateSelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
//     const dispatch = useDispatch()
//     const [modifiedStates, setModifiedStates] = useState([]);
//     const { country, states, city } = useSelector((state) => {
//         return ({
//             country: state.countryStateCity.country,
//             states: state.countryStateCity.states,
//             city: state.countryStateCity.city
//         })
//     })
//     const onChangeHandle = (item) => {
//         dispatch(setState(item))
//         dispatch(setCity(''))
//     }

//     console.log(Defaultvalue, "saxacsvf")
//     // useEffect(() => {
//     //     // { Defaultvalue !== false && dispatch(setState({ "name": Defaultvalue })); }
//     //     // { Defaultvalue !== false && dispatch(setState(State?.getStatesOfCountry(country?.isoCode).filter(state => { return state.name === Defaultvalue })[0])); }
//     //     console.log(Defaultvalue, "sdafdsfg 222222222")
//     //     { Defaultvalue !== false && dispatch(setState(State?.getStatesOfCountry(country?.isoCode).filter(state => { return state.name === Defaultvalue })[0])); }

//     // }, [Defaultvalue])


//     // useEffect(() => {
//     //     if (country?.isoCode) {
//     //         // Fetch and modify states
//     //         let fetchedStates = State.getStatesOfCountry(country.isoCode);
//     //         fetchedStates = fetchedStates.map(state => {
//     //             if (state.name === 'Abu Dhabi Emirate') {
//     //                 state.name = 'Abu Dhabi';
//     //             }
//     //             // Add more conditions as needed
//     //             return state;
//     //         });
//     //         setModifiedStates(fetchedStates);
//     //     }
//     // }, [Defaultvalue, country?.isoCode, dispatch]);

//     useEffect(() => {
//         console.log(Defaultvalue, "sdafdsfg 222222222");
//         if (Defaultvalue !== false) {
//             const state = State?.getStatesOfCountry(country?.isoCode).find(state => {
//                 console.log(state.name, Defaultvalue, "state.name")
//                 switch (state.name) {
//                     case 'Abu Dhabi Emirate': if (Defaultvalue === 'Abu Dhabi') {
//                         return state.name = "Abu Dhabi"
//                     }
//                     case 'Ajman Emirate': if (Defaultvalue === 'Ajman') {
//                         return state.name = "Ajman"
//                     }
//                     case 'Sharjah Emirate': if (Defaultvalue === 'Sharjah')
//                         return state.name = "Sharjah"
//                     default:
//                         return state.name === Defaultvalue;
//                 }
//             });
//             // console.log(state, State?.getStatesOfCountry(country?.isoCode), Defaultvalue, "statestate")
//             if (state) {
//                 dispatch(setState(state));
//             }
//         }
//     }, [Defaultvalue, country?.isoCode, dispatch]);


//     useEffect(() => {
//         if (country?.isoCode) {
//             // Fetch and modify states
//             let fetchedStates = State.getStatesOfCountry(country.isoCode);
//             fetchedStates = fetchedStates.map(state => {
//                 switch (state.name) {
//                     case 'Abu Dhabi Emirate':
//                         return { ...state, name: 'Abu Dhabi' };
//                     case 'Ajman Emirate':
//                         return { ...state, name: 'Ajman' };
//                     case 'Sharjah Emirate':
//                         return { ...state, name: 'Sharjah' };
//                     default:
//                         return state;
//                 }
//             });
//             setModifiedStates(fetchedStates);
//         }
//     }, [Defaultvalue, country?.isoCode, dispatch])

//     console.log(State?.getStatesOfCountry(country?.isoCode), "sdgsdgg3tyy4564")
//     return (
//         <Select
//             // options={State?.getStatesOfCountry(country?.isoCode)}
//             options={modifiedStates}
//             getOptionLabel={(options) => {
//                 return options["name"];
//             }}
//             getOptionValue={(options) => {
//                 return options["name"];
//             }}
//             value={states}
//             onChange={(item) => {
//                 onChangeHandle(item)
//             }}
//             placeholder={placeholder ? placeholder : 'State *'}
//             className={ClassName}
//         />

//     )
// }

// export default StateSelector

import { State } from "country-state-city";
import Select from "react-select";
import './Csc2.scss';
import { useDispatch, useSelector } from "react-redux";
import { setCity, setState } from "../../store/Actions/CountryStateCity";
import { useEffect, useState } from "react";

const StateSelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
    const dispatch = useDispatch();
    const [modifiedStates, setModifiedStates] = useState([]);
    const { country, states, city } = useSelector((state) => ({
        country: state.countryStateCity.country,
        states: state.countryStateCity.states,
        city: state.countryStateCity.city
    }));

    const onChangeHandle = (item) => {
        dispatch(setState(modifyStateName(item)));
        dispatch(setCity(''));
    };

    const modifyStateName = (state) => {
        if (!state) return state;
        switch (state.name) {
            case 'Abu Dhabi Emirate':
                return { ...state, name: 'Abu Dhabi' };
            case 'Ajman Emirate':
                return { ...state, name: 'Ajman' };
            case 'Sharjah Emirate':
                return { ...state, name: 'Sharjah' };
            default:
                return state;
        }
    };

    useEffect(() => {
        if (country?.isoCode) {
            // Fetch and modify states
            let fetchedStates = State.getStatesOfCountry(country.isoCode);
            fetchedStates = fetchedStates.map(modifyStateName);
            setModifiedStates(fetchedStates);
        }
    }, [country?.isoCode]);

    useEffect(() => {
        if (Defaultvalue !== false && country?.isoCode) {
            const state = State.getStatesOfCountry(country.isoCode).find(state => {
                return modifyStateName(state).name === Defaultvalue;
            });
            if (state) {
                dispatch(setState(state));
            }
        }
    }, [Defaultvalue, country?.isoCode, dispatch]);

    return (
        <>
            <Select
                options={modifiedStates}
                getOptionLabel={(options) => options.name}
                getOptionValue={(options) => options.isoCode}
                value={states ? modifyStateName(states) : null}
                onChange={onChangeHandle}
                placeholder={placeholder ? placeholder : 'State *'}
                className={ClassName}
            />
        </>
    );
};

export default StateSelector;
