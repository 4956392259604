import { SUCCESS_MSG_POPUP } from "./actionType";

export const showSuccessToast = (successmessage) => {
    return dispatch => {
        dispatch({
            type: SUCCESS_MSG_POPUP.SUCCESS_TOAST,
            payload: successmessage
        })
    }
}

export const showErrorToast = (successmessage) => {
    return dispatch => {
        dispatch({
            type: SUCCESS_MSG_POPUP.ERROR_TOAST,
            payload: successmessage
        })
    }
}

export const showWarningToast = (successmessage) => {
    return dispatch => {
        dispatch({
            type: SUCCESS_MSG_POPUP.WARNING_TOAST,
            payload: successmessage
        })
    }
}

export const hideSuccessToast = () => {
    return dispatch => {
        dispatch({
            type: SUCCESS_MSG_POPUP.HIDE_TOAST,
            payload: ""
        })
    }
}