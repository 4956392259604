import { User_Details } from '../Actions/Auth/actionType'

const init = {
    userType: '',
    offer_type: '',
    signUp: {
        data: {},
        loading: false,
        success: false,
        error: false,
    },
    exist_user: {
        data: {},
        loading: false,
        success: false,
        error: false,
    },

    emailVerify: {
        loading: false,
        success: false,
        error: false,
    },
    login: {
        data: {},
        loading: false,
        success: false,
        error: false,
    },
    message: ''
}


const auth = (state = init, action) => {
    switch (action.type) {
        case User_Details.SET_USER_TYPE: return {
            ...state,
            userType: action.payload?.user_type,
            offer_type: action.payload?.user_Subscription_type,
        }
        case User_Details.LOGIN_SUCCESS: return {
            ...state,
            login: {
                ...state.login,
                success: true
            }
        }
        case User_Details.LOGIN_RESET: return {
            ...state,
            login: {
                ...state.login,
                success: false
            }
        }
        case User_Details.SET_SIGNUP_SUCCESS: return {
            ...state,
            signUp: {
                ...state.signUp,
                success: true
            }
        }
        case User_Details.SET_SIGNUP_HIDE: return {
            ...state,
            signUp: {
                ...state.signUp,
                success: false
            }
        }
        case User_Details.SET_CHECK_EXIST_SUCCESS: return {
            ...state,
            exist_user: {
                ...state.exist_user,
                success: true
            }
        }
        case User_Details.SET_CHECK_EXIST_ERROR: return {
            ...state,
            exist_user: {
                ...state.exist_user,
                success: false,
                error: true
            },
            message: action?.payload
        }
        case User_Details.RESET_SET_CHECK_EXIST: return {
            ...state,
            exist_user: {
                ...state.exist_user,
                loading: false,
                success: false,
                error: false,
            },
            message: ''
        }
        case User_Details.SET_MESSAGE: return {
            ...state,
            message: action.payload
        }

        case User_Details.SET_MESSAGE_HIDE: return {
            ...state,
            message: ''
        }

        case User_Details.SET_SEND_EMAIL_LOADING: return {
            ...state,
            emailVerify: {
                ...state.emailVerify,
                loading: true
            }
        }
        case User_Details.SET_SEND_EMAIL_SUCCESS: return {
            ...state,
            emailVerify: {
                ...state.emailVerify,
                loading: false,
                success: true
            }
        }
        case User_Details.RESET_SEND_EMAIL_SUCCESS: return {
            ...state,
            emailVerify: {
                ...state.emailVerify,
                loading: false,
                success: false
            }
        }
        case User_Details.SET_VERIFY_EMAIL_SUCCESS: return {
            ...state,
            emailVerify: {
                ...state.emailVerify,
                success: true,
                message: action?.payload
            }
        }
        case User_Details.SET_VERIFY_EMAIL_ERROR: return {
            ...state,
            emailVerify: {
                ...state.emailVerify,
                error: true,
                message: action?.payload
            }
        }
        default: return state
    }
}

export default auth;