
import { showSuccessToast } from '../SuccessMessagePopup';
import AddUserAction from './actionType';
import addUserReq from './addUserReq';

const setAddUser = (data) => {
    return async (dispatch) => {
        dispatch({ type: AddUserAction.SET_ADD_USER_LOADING })
        addUserReq.post(addUserReq.endpoint.add_user, data, (success) => {
            dispatch({ type: AddUserAction.SET_ADD_USER_SUCCESS })
            dispatch(showSuccessToast("User created successfully"))
            dispatch(setUserList(data?.added_by_user_id))
        },
            (error) => {
                if (error?.response?.status == 409) {
                    dispatch({ type: AddUserAction?.SET_ADD_USER_ERROR })
                }
            }
        )
    }
}

const setUserList = (user_id) => {
    return async (dispatch) => {
        addUserReq.get(addUserReq.endpoint.list_users.replace("__USER_ID__", user_id), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch({ type: AddUserAction.SET_USER_LIST_SUCCESS, payload: payload })

        },
            (error) => {

            }
        )
    }
}
const setUserViewForUpdate = (id) => {
    return async (dispatch) => {
        addUserReq.get(`${addUserReq.endpoint.view_user}/${id}`, (success) => {
            dispatch({ type: AddUserAction.SET_USER_SINGLE_USER_VIEW, payload: success.data })
        },
            (error) => {

            }
        )
    }
}

const setUserDelete = (id, user_id) => {
    return async (dispatch) => {
        addUserReq.delete(`${addUserReq.endpoint.delete_user}/${id}`, (success) => {
            dispatch(setUserList(user_id))
            dispatch(showSuccessToast("Delete user successfully."))
        },
            (error) => {

            }
        )
    }
}

const setUserUpdate = (id, data) => {
    return async (dispatch) => {
        addUserReq.put(`${addUserReq.endpoint.update_user}/${id}`, data, (success) => {
            dispatch({ type: AddUserAction.SET_ADD_USER_SUCCESS, payload: success.data })
            dispatch(setUserList(data?.added_by_user_id))
            dispatch(showSuccessToast("Update user succefully."))
        },
            (error) => {

            }
        )
    }
}


const setUserClientList = (id, user_id) => {
    return async (dispatch) => {
        dispatch({ type: AddUserAction.SET_USER_CLIENT_LIST_LOADING })
        addUserReq.get(addUserReq.endpoint.list_client_by_user_id.replace("__ID__", id).replace("__USER_ID__", user_id), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch({ type: AddUserAction.SET_USER_CLIENT_LIST_SUCCESS, payload: payload })
        },
            (error) => {

            }
        )
    }
}




const resetAddUser = () => {
    return { type: AddUserAction.SET_ADD_USER_SUCCESS_RESET }
}
const resetUserUpdate = () => {
    return { type: AddUserAction.SET_USER_UPDATE_RESET }
}
const resetUserError = () => {
    return { type: AddUserAction.SET_ADD_USER_ERROR_RESET }
}
const resetUserClientList = () => {
    return { type: AddUserAction.SET_USER_CLIENT_LIST_RESET }
}

export {
    setAddUser, resetAddUser, setUserList, setUserViewForUpdate,
    setUserDelete, setUserUpdate, resetUserUpdate, resetUserError, setUserClientList,
    resetUserClientList
}