import React from 'react'

const useHandleDownload = (ref, fileName) => {
    const imageUrl = ref.current.src;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';  // Security best practice
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default useHandleDownload