import React from 'react';
import { AccessConsumer } from '../../../../../../../Context/AccessContext';
import CheckboxInput from '../../../../../../../CommanComponents/Form/CheckboxInput';
import "./accessMultiSteps.scss"
import RadioInput from '../../../../../../../CommanComponents/Form/RadioInput';
import SelectAccessOf from './AccessStep2Components/SelectAccessOf';
import SelectEmployees from './AccessStep2Components/SelectEmployees';
import SelectEmpDocument from './AccessStep2Components/SelectEmpDocument';
const AccessStep2 = () => {
    let numbers = Array.from({ length: 10 }, (_, i) => i);
    return (
        <AccessConsumer>
            {(value) => {
                const { formData, } = value;
                return (
                    <>
                        <div className='access-step2-wrapper'>
                            <SelectAccessOf />
                            <div className="elements-wrapper">
                                <p className="text-xs w-600 secondary headings mb-15">Actions</p>
                                <div className="Actions-wrapper">
                                    {value?.accessOf == "Employees" && <>
                                        <div className="first-col-wrap">
                                            <SelectEmployees />
                                        </div>
                                        <div className="second-col-wrap">
                                            {/* {value?.showDocs && <SelectEmpDocument />} */}
                                            {formData?.employee?.selected_employees?.length > 0 && <SelectEmpDocument />}
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </>
                );
            }}

        </AccessConsumer >
    );
};

export default AccessStep2;
