export const User_Details = {
    SET_USER_TYPE: "SET_USER_TYPE",
    SET_SIGNUP_SUCCESS: "SET_SIGNUP_SUCCESS",
    SET_SIGNUP_HIDE: "SET_SIGNUP_HIDE",
    SET_MESSAGE: "SET_MESSAGE",
    SET_MESSAGE_HIDE: "SET_MESSAGE_HIDE",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_RESET: "LOGIN_RESET",

    SET_SEND_EMAIL_LOADING: "SET_SEND_EMAIL_LOADING",
    SET_SEND_EMAIL_SUCCESS: "SET_SEND_EMAIL_SUCCESS",
    RESET_SEND_EMAIL_SUCCESS: "RESET_SEND_EMAIL_SUCCESS",

    SET_VERIFY_EMAIL_SUCCESS: "SET_VERIFY_EMAIL_SUCCESS",
    SET_VERIFY_EMAIL_ERROR: "SET_VERIFY_EMAIL_ERROR",

    SET_CHECK_EXIST_LOADING: "SET_CHECK_EXIST_LOADING",
    SET_CHECK_EXIST_SUCCESS: "SET_CHECK_EXIST_SUCCESS",
    SET_CHECK_EXIST_ERROR: "SET_CHECK_EXIST_ERROR",
    RESET_SET_CHECK_EXIST: "SET_CHECK_EXIST",
};