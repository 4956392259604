import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboard } from '../../store/Actions/AllEmployees'
import Auth from '../../Classes/Auth'

const useAllEmployees = () => {
    const allEmployees = useSelector((e => e.allEmployees))
    const dispatch = useDispatch()
    const userId = Auth?.user()?.user_id // this is for added by user
    console.log(allEmployees, "allEmployeesallEmployees");

    useEffect(() => {
        dispatch(getDashboard(userId))
    }, [])

    return { allEmployees }
}

export default useAllEmployees