import React, { useEffect, useState } from 'react'
import "./reminders.scss"
import data from './data.json'
import { useDispatch, useSelector } from 'react-redux';
import useReminders from '../../../CustomHooks/EmployeePersonalDetails/useReminders';
import { useTodayDate } from '../../../CustomHooks/useTodayDate';
import { dateFormat } from '../../../CustomHooks/dateFormat';
import { setDocType } from '../../../store/Actions/DocumentUpload';
import DataNotFound from '../../../CommanComponents/DataNotFound/DataNotFound';
const Reminders = ({ handleActiveTab }) => {
    const dispatch = useDispatch()
    const employeeManagement = useSelector((state) => state.employeeManagement)
    const personalDetails = useSelector((state) => state.personalDetails)
    const { reminders, handleInputChange, date, todayDate } = useReminders();
    useEffect(() => {
        dispatch(setDocType('Add document*'));
    }, [])

    const calculateDaysDifference = (expiryDate) => {
        const expiry = new Date(expiryDate);
        const today = new Date(todayDate);
        const timeDifference = expiry.getTime() - today.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);
        return dayDifference;
    };
    return (
        <div className="reminder-main-wrapper">
            {/* <div className="reminder-top-wrap d-flex justify-between mb-15 align-center">
                <p className="employee-name secondary text-xs w-700 ">
                    {employeeManagement.employee_type !== 'new' && personalDetails?.single_employee?.data?.first_name + ' ' + personalDetails?.single_employee?.data?.last_name}
                </p>
            </div> */}
            {reminders?.data.length ?
                <div className="reminder-content-wrapper">
                    <div className="table-wrap">
                        <table className="table-item">
                            <thead className='table-head'>
                                <tr className="table-row">
                                    <th >Document </th>
                                    <th >Expiry Date</th>
                                    <th >{`Reminder 1(60 days from expiry)`}</th>
                                    <th >Reminder 2</th>
                                    <th >{`Reminder 3(48 hrs from expiry)`}</th>
                                </tr>
                            </thead>

                            <tbody className='table-body'>
                                {reminders?.data?.map((val, key) => {
                                    const daysDifference = calculateDaysDifference(val.Date_of_Expiry);
                                    return (
                                        <tr className="table-row" key={key}  >
                                            <td  > {val.Document_type} </td>
                                            <td>  {dateFormat(val.Date_of_Expiry)}
                                            </td>
                                            <td className='reminder1' >
                                                <button className='transparent'>
                                                    {/* {dateFormat(val?.Reminder_1)} */}
                                                    {daysDifference < 60 ? "-" : dateFormat(val?.Reminder_1)}

                                                    {console.log(daysDifference, "sdaffv")}
                                                </button>
                                            </td>
                                            <td className='reminder2 '>
                                                <button className='transparent '>
                                                    {/* Prior 30 Days */}
                                                    <div className={`dob-wrap d-flex align-center details-InputClassName`}>
                                                        <p className={`dob_para d-flex align-center ${'details-InputClassName'} ${date ? 'active' : ""}`}>{val?.Reminder_2 ? dateFormat(val?.Reminder_2) : date ? dateFormat(date) : "DD-MM-YYYY"}</p>
                                                        <input
                                                            className={'details-InputClassName'}
                                                            type="date"
                                                            min={todayDate}
                                                            max={val?.Date_of_Expiry}
                                                            value={val?.Reminder_2 ? val?.Reminder_2 : date}
                                                            name='Issuing_Date'
                                                            onChange={(e) => handleInputChange(e, val?.Document_type)} // Pass document type here
                                                            onKeyUp={(e) => handleInputChange(e, val?.Document_type)} // Pass document type here
                                                        />
                                                    </div>
                                                    {/* <i className="arrow-icon"> </i> */}
                                                </button>

                                            </td>
                                            <td className='reminder3'>
                                                <button className='transparent'>
                                                    {/* {dateFormat(val?.Reminder_3)} */}
                                                    {daysDifference < 2 ? "-" : dateFormat(val?.Reminder_3)}
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </div> :

                <DataNotFound />}
        </div>
    )
}

export default Reminders