import SupportAction from "../Actions/Support/actionType"



const initState = {
    loading: false,
    error: false,
    data: [],
    success: false,
    update: {
        loading: false,
        error: false,
        success: false,
        data: [],
    }
}

const support = (state = initState, { type, payload }) => {
    switch (type) {
        case SupportAction.SET_CREATE_TICKET_LOADING: return {
            ...state,
            loading: true,
        }
        case SupportAction.SET_CREATE_TICKET_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
        }
        case SupportAction.RESET_CREATE_TICKET_SUCCESS: return {
            ...state,
            loading: false,
            error: false,
            success: false,
        }
        case SupportAction.SET_TICKET_LIST_SUCCESS: return {
            ...state,
            data: payload,
        }
        case SupportAction.SET_TICKET_VIEW_SUCCESS: return {
            ...state,
            update: {
                ...state.update,
                success: true,
                data: payload
            }
        }
        case SupportAction.RESET_TICKET_VIEW_SUCCESS: return {
            ...state,
            update: {
                ...state.update,
                success: false,
                data: []
            }
        }
        default: return state
    }
}

export default support