import React, { forwardRef, useEffect } from 'react'
import './addUserPop.scss'
import Modals from '../../../CommanComponents/Modals'
import ModalHeader from '../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../CommanComponents/Modals/ModalsBody'
import FormError from '../../../CommanComponents/Form/FormError'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton'
import PhoneInput from 'react-phone-input-2'
import Dropdown from '../../../CommanComponents/Dropdown/Dropdown'
import MultiSelectorDropdown from '../../../CommanComponents/MultiSelectorDropdown/MultiSelectorDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getClientsList } from '../../../store/Actions/Clients'
import Auth from '../../../Classes/Auth'
const AddUserPop = ({ onClose, addUserData, showError, handleInputChange, handlePhoneInput, addUserSaveBtn, handleSelectDropdown, updateID, regexError }, ref) => {
    const clients = useSelector(e => e.clients)
    const user = useSelector((e) => e.user)
    const desiredIds = addUserData?.clients?.value;
    return (
        <Modals
            ref={ref}
            ClosePopUp={onClose}
            slide={"zoom"}
            Position="center"
            ModalsSize={'modal-s'}
            ClassName={'AddUserPop-modal-wrapper'}
            croseShow={true}
        >
            <ModalHeader ClassName={'heading'}>
                <h4 className='w-500 text-s d-flex align-center '>
                    {/* <i className="back-btn pointer mr-10" onClick={onClose}></i> */}
                    <p className='text-center m-auto'>Add User</p>
                </h4>
            </ModalHeader>
            <ModalBody className={"AddUserPop-modal-body"}>
                <form className="add-user-form" onSubmit={addUserSaveBtn}>
                    {/* <div className="form-field mb-20">
                        <label htmlFor="" className='mb-10 d-block text-xs secondary' > ID </label>
                        <input type="text" disabled value="EMP-BLUE-001" />
                    </div> */}
                    <div className="form-field mb-20 ">
                        <label htmlFor="" className='mb-10 d-block text-xs secondary' > Full Name* </label>
                        <input
                            type="text"
                            name="full_name"
                            placeholder='Type Full Name'
                            value={addUserData?.full_name.value}
                            onChange={handleInputChange}
                        // onKeyUp={handleInputChange}
                        />
                        <FormError show={!addUserData?.full_name?.isValid && showError || regexError == "full_name"} error={regexError == "full_name" ? "Please enter only alphabetical character." : 'Please enter username'} />
                    </div>

                    <div className="multi-field-wrap mb-20">
                        <div className="form-field ">
                            <label htmlFor="" className='mb-10 d-block text-xs secondary' > Email* </label>
                            <input
                                type="text"
                                name="email"
                                placeholder='Type E-mail'
                                value={addUserData?.email.value}
                                onChange={handleInputChange}
                                onKeyUp={handleInputChange}
                            />
                            <FormError show={!addUserData?.email?.isValid && showError || user?.error} error={user?.error ? "User with this email already exists." : 'Please enter valid email.'} />
                        </div>
                        <div className="form-field ">
                            <label htmlFor="" className='mb-10 d-block text-xs secondary' > Mobile*</label>
                            <PhoneInput
                                countryCodeEditable={false}
                                containerClass="custom-form-container"
                                inputClass="custom-form-input"
                                specialLabel
                                value={`${addUserData?.phone_no?.country_code} ${addUserData?.phone_no?.value}`}
                                onChange={(value, formattedValue, nameValue) => {
                                    handlePhoneInput(value, formattedValue, nameValue);
                                }}
                                onKeyUp={(value, formattedValue, nameValue) => {
                                    handlePhoneInput(value, formattedValue, nameValue);
                                }}
                                onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                preserveOrder={['onlyCountries', 'preferredCountries']}
                                inputProps={{
                                    name: "phone_no",
                                    autoFocus: false,
                                    placeholder: "Phone Number *",
                                }}
                                enableSearch
                                name="mobileNo"
                                searchPlaceholder="Search Country"
                                disableSearchIcon
                            />
                            <FormError show={!addUserData?.phone_no?.isValid && showError}
                                error={
                                    addUserData?.phone_no?.value?.length < 9 && addUserData?.phone_no?.value?.length > 0
                                        ? 'Please enter correct phone number.'
                                        : 'Please enter phone number.'
                                }
                            />

                        </div>
                    </div>
                    <div className="multi-field-wrap mb-20">
                        <div className="form-field ">
                            <label htmlFor="" className='mb-10 d-block text-xs secondary' > Type*</label>
                            <Dropdown
                                key={addUserData?.type?.value}
                                ClassName={'user-select-dropdown'}
                                padding={'12.5px 16px'}
                                borderRadius={'8px'}
                                textAlign={'left'}
                                borderWidth='1px'
                                data={['Full Time', 'Manager', 'Temporary']}
                                defaultValue={addUserData?.type?.value}
                                clickHandle={(val) => handleSelectDropdown(val, 'type')}
                            />

                            <FormError show={!addUserData?.type?.isValid && showError} error={'Please select type.'} />
                        </div>
                        <div className="form-field ">
                            <label htmlFor="" className='mb-10 d-block text-xs secondary' > Assign Client </label>
                            <MultiSelectorDropdown
                                // data={['guddu', 'ranjan', '3', '4',]}
                                data={clients?.data.map((e) => e)}
                                ObjectKey={`business_name`}
                                className={'client-selector'}
                                // defaultValue={["Select Client"]}
                                // defaultValue={desiredIds?.length > 0 ? desiredIds?.map(id => clients?.data?.find(client => client?.id === id)?.business_name).join(', ') : ["Select Client"]}
                                defaultValue={["Select Client"]}
                                defaultSelectedVal={
                                    // clients?.data.filter(obj => desiredIds?.includes(obj.id))
                                    desiredIds
                                }
                                clickHandle={(val) => handleSelectDropdown(val, 'clients')}
                            // key={clients?.data.map((e) => e) || desiredIds}
                            />

                            {/* <FormError show={true} error={'Please select client.'} /> */}
                        </div>
                    </div>
                    <div className="multi-field-wrap mb-20">
                        <div className="form-field status-wrap d-flex align-center">
                            <label htmlFor="" className='d-block text-xs secondary' > Status* </label>
                            <div className="radio-wrap d-flex align-center">
                                <label htmlFor="Active" className='d-block text-xs base' > Active </label>
                                <input type="radio" name="status"
                                    key={addUserData?.status?.value}
                                    defaultChecked={addUserData?.status?.value == "1" ? true : false}
                                    id='Active'
                                    value={'1'}
                                    onChange={handleInputChange}
                                    onKeyUp={handleInputChange}
                                />
                            </div>
                            <div className="radio-wrap d-flex align-center">
                                <label htmlFor="Inactive" className='d-block text-xs base' > Inactive </label>
                                <input type="radio" name="status" id='Inactive' defaultChecked={addUserData?.status?.value == "0" ? true : false}
                                    key={addUserData?.status?.value}
                                    value={'0'}
                                    onChange={handleInputChange}
                                    onKeyUp={handleInputChange}

                                />
                            </div>
                        </div>
                        <div className="form-field ">
                            <CommanButton
                                type='submit'
                                Text={user?.loading ? 'Creating...' : 'Create'}
                                ClassName={"create-user-btn text-xxs"}
                                fill={true}
                            />
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modals>
    )
}

export default forwardRef(AddUserPop)