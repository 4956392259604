import React, { useEffect } from 'react'
import RightSidebar from '../RightSideBar/RightSidebar'
import './verifyEmailSuccess.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { verifyEmail } from '../../../store/Actions/Auth';
import { useSelector } from 'react-redux';
const VerifyEmailSuccess = () => {
    const auth = useSelector(e => e.auth)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        const url = window.location.href;
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const token = urlParams.get('token');
        const uidb64 = urlParams.get('uidb64');
        // window.onload = function () {
        if (uidb64 && token) {
            dispatch(verifyEmail(uidb64, token))
        }
        // };
    }, [dispatch]);

    // Option 2: Use window.onload event
    window.onload = function () {
        console.log("mukesg");
    };
    console.log(auth?.emailVerify?.message, "sqfwegf")
    return (
        <div className="login-wrapper">
            <RightSidebar>
                <div className="form-container">
                    <div className="form-wrap">
                        <div className="form"  >
                            <h3 className="heading text-md w-500 primary text-center">
                                {/* {auth?.emailVerify.success ? "Email verified successfully"
                                    : auth?.emailVerify.error ? "Link has been expired" : ''} */}
                                {auth?.emailVerify.message}
                            </h3>
                            {/* <p className="sub-heading w-400 text-center secondary"> Please enter your detail</p> */}
                            <div className="form-control mt-40">

                            </div>

                            {auth?.emailVerify.success ?
                                <button type='submit' className="login-button pT-10 pb-10 mt-30 w-400 text-s transparent white"
                                    onClick={() => navigate("/login")}
                                >
                                    Login
                                </button>
                                : auth?.emailVerify.error ?
                                    " " :
                                    ''}
                        </div>
                    </div>
                </div>
            </RightSidebar>
        </div>
    )
}

export default VerifyEmailSuccess