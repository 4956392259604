import React from 'react'
import './sectionHeading.scss'
import Auth from '../../Classes/Auth'

const SectionHeading = ({ children, ClassName, Text }) => {
    return (
        <h3 className={`section-heading primary text-sm w-500 ${ClassName}
                         ${Auth.userType() === "Business Owner" ? "business-owner" :
                Auth.userType() === "Typing Center" || Auth.userType() === "Typing Center User" ? "typing-center"
                    :
                    Auth.userType() === "Individual" ? "individual" : ''}
                        `}>
            {children ? children : Text}
        </h3>
    )
}

export default SectionHeading