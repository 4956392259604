import React, { useState } from 'react'
import './stepperBar.scss'
import { useSelector } from 'react-redux'
const StepperBar = ({ StepsAmount }) => {
    const TotalSteps = Array.from({ length: StepsAmount }, (_, i) => i + 1)
    // const [activeStep, setActiveStep] = useState(1)
    const { activeStep } = useSelector((state) => {
        return ({
            activeStep: state.stepperBar.activeStep
        })
    })
    // console.log(activeStep)
    return (
        <div className="StepperBar-progressBar">
            {TotalSteps.map((val, key) => {
                return (
                    <div className={`wrapper
                     ${activeStep === val ? "active"
                            : activeStep > val ? "completed"
                                : ""} `}>
                        <div className="circle" data-count={val}></div>
                    </div>
                )
            })}
            {/* <div class="wrapper ">
                <div class="circle" data-count={2}></div>
            </div> */}
        </div>


    )
}

export default StepperBar