export const Employee_Management_Actions = {
    SET_EMPLOYEE_TYPE: "SET_EMPLOYEE_TYPE",
    GET_ALL_EMPLOYEE: "GET_ALL_EMPLOYEE",
    RESET_ALL_EMPLOYEE: "RESET_ALL_EMPLOYEE",
    SET_LOADING: "SET_LOADING",

    SET_SEARCH: "SET_SEARCH",
    RESET_SEARCH: "RESET_SEARCH",

    SET_CLIENT: "SET_CLIENT",

    SET_BULK_UPLOAD_LOADING: "SET_BULK_UPLOAD_LOADING",
    SET_BULK_UPLOAD_SUCCESS: "SET_BULK_UPLOAD_SUCCESS",
    SHOW_BULK_UPLOAD_ERROR: "SHOW_BULK_UPLOAD_ERROR",
    RESET_BULK_UPLOAD: "RESET_BULK_UPLOAD",
    RESET_BULK_UPLOAD_ERROR: "RESET_BULK_UPLOAD_ERROR",
};