import React, { useCallback, useEffect, useMemo, useState } from 'react'
import RightSidebar from '../RightSideBar/RightSidebar'
import './forgotPaswword.scss'
import backIcon from '../../../Assets/icons/back-icon.svg'
import { Link, useNavigate } from "react-router-dom";
import ValidationFile from '../Login/ValidationFile';
import FormError from '../../../CommanComponents/Form/FormError';
import { useDispatch, useSelector } from 'react-redux';
import { hideErrorMsg, verifyValidUser } from '../../../store/Actions/ForgotPassword';
const ForgotPassword = () => {
    const dispatch = useDispatch()
    const forgotPassword = useSelector((state) => state.forgotPassword)
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [email, setEmail] = useState({
        email: {
            value: "",
            isValid: false,
        },
        validation: false,
    });
    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "email":
                return ValidationFile.validEmail(inputValue);
            default:
                return false;
        }
    };
    const isFormValid = () => {
        return email.email.isValid ? true : false;
    };

    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...email,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setEmail(data);
        if (inputName === "email") {
            dispatch({ type: "SET_MESSAGE", payload: "" })
        }
    }
    const sendOtpHandler = () => {
        if (email.validation) {
            const data = {
                email: email.email.value,
            }
            dispatch(verifyValidUser(data))

        } else {
            setShowError(true);
        }
    }
    useEffect(() => {
        if (forgotPassword.isValid) {
            navigate(`/forgot-password/verify-otp?email=${email?.email?.value}`)
        }
    }, [forgotPassword.isValid])

    useEffect(() => {
        dispatch(hideErrorMsg())
    }, [])
    return (
        <div className="forgot-wrapper">
            <RightSidebar>
                <div className="form-container">
                    <button className="back-wrap mb-40 transparent" onClick={() => navigate('/login')}>
                        <img src={backIcon} alt="" />
                    </button>
                    <div className="form-wrap">
                        <div className="form">
                            <h3 className="heading text-md w-500 primary text-center">Forgot Password</h3>
                            <p className="sub-heading w-400 text-center secondary"> Please enter your detail </p>
                            <div className="form-control mt-40">
                                <form>
                                    <div className="form-field ">
                                        <input type="text" name="email" className='forgot-pass-input' placeholder='Enter your email address*'
                                            value={email?.email?.value}
                                            onChange={handleInput}
                                            onKeyUp={handleInput}
                                        />
                                        <i className="icon user-icon"></i>
                                    </div>
                                    {forgotPassword.message ?
                                        <FormError show={forgotPassword?.message && true} error={forgotPassword?.message} /> :
                                        <FormError show={!email.email.isValid && showError} error={'Please enter valid email'} />
                                    }
                                </form>
                            </div>
                            <button type='button' className="login-button pT-10 pb-10 mt-30 w-400 text-s transparent white" onClick={() => sendOtpHandler()}>
                                {!forgotPassword?.loading ? " Send OTP" : "Loading..."}
                            </button>
                            <p className='signup-btn text-center mt-30 text-xs w-400'>Have an Account? <Link className='pointer w-600' to={'/login'} > Login</Link></p>
                        </div>
                    </div>
                </div>
            </RightSidebar>
        </div>
    )
}

export default ForgotPassword