import React, { useEffect, useState } from "react";
import './cardsStyle.scss'
function ProgressBarCard({ progress, className }) {
  const [offset, setOffset] = useState(null)
  const radius = 40;
  const strokeWidth = 3;
  const circumference = 2 * Math.PI * radius;
  // const offset = circumference - (progress / 100) * circumference;
  useEffect(() => {
    setOffset((circumference - (progress / 100) * circumference) + 10)
  })
  // const offset = (circumference - (progress / 100) * circumference) + 10       ;
  console.log(circumference, offset, strokeWidth, "SDAfsgf")

  return (
    <div className={`bg-primary progressbar-wrap d-flex ${className}`} >
      <div className="heading-wrap">
        <p className="text-xs w-500 white mb-10 mt-10">Drive Storage</p>
        <p className="text-xs w-300 white storage"> 5GB / 10GB</p>
      </div>
      <div className="circle-wrap" >
        <svg width={radius * 2} height={radius * 2}>
          <circle
            stroke="#fff"
            fill="none"
            strokeWidth={strokeWidth}
            r={radius - strokeWidth / 2}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="#FCBF22"
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDasharray={circumference + " " + circumference}
            style={{
              strokeDashoffset: offset,
              rotate: '-90deg',
              translate: `0px ${radius * 2}px`
            }}
            r={radius - strokeWidth / 2}
            cx={radius}
            cy={radius}
          // transform="rotate(-90 ) translate(-100 0)"   
          />
          <text
            x={radius}
            y={radius}
            fontSize="14"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
          >
            {progress}%
          </text>
        </svg>

        {/* <svg width="100" height="100" viewBox="0 0 100 100">
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stop-color="#2ed8a7" />
            <stop offset="100%" stop-color="#a6ceff" />
          </linearGradient>
          <circle stroke-linecap="round" cx="50" cy="50" r="48" stroke="url(#linear)" stroke-width="4" fill="none" stroke-dasharray="315" stroke-dashoffset="100" stroke-mitterlimit="0" transform="rotate(-90 ) translate(-100 0)" />
          <circle
            stroke="#fff"
            fill="none"
            strokeWidth={strokeWidth}
            r={radius - strokeWidth / 2}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="#FCBF22"
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset: offset }}
            r={radius - strokeWidth / 2}
            cx={radius}
            cy={radius}
          />
          <text
            x={radius}
            y={radius}
            fontSize="14"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
          >
            {progress}%
          </text>
        </svg> */}
      </div>
    </div>
  );
}

export default ProgressBarCard;
