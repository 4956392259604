import React, { forwardRef, useEffect, useState } from "react";
import "./tabs.scss";
// import Tabs from "../ImageUploader/Tabs/Tabs";

const Tabs = forwardRef(({ disableBtns = [], tabs, TabWrapperClass, TabButtonClass, ActiveTabClass, defaultValue, activeTabHandle }, ref) => {
    const [activeTab, setActiveTab] = useState(defaultValue ? defaultValue : "");


    useEffect(() => {
        // Get the tabIndex from the URL query parameters
        const params = new URLSearchParams(window.location.search);
        const tabIndex = params.get("tabIndex");
        if (tabIndex) {
            setActiveTab(tabIndex.replace(/"/g, "")); // Remove quotes if present
            activeTabHandle(tabIndex.replace(/"/g, ""));
        }
    }, []); // Empty dependency array to run only on component mount


    const handleActive = (title) => {
        setActiveTab(title)
        activeTabHandle(title)
        // Update the URL with the new query parameter
        const params = new URLSearchParams(window.location.search);
        params.set("tabIndex", `"${title}"`);
        window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
    }

    return (
        <React.Fragment>
            <div className={`tab-container ${TabWrapperClass ? TabWrapperClass : ''}`}>
                {tabs.map((title, index) => (
                    <button key={index} disabled={disableBtns.includes(title)}
                        className={
                            `${TabButtonClass ? TabButtonClass : "tab-btn"}
                             ${(activeTab === title) &&
                            (ActiveTabClass ? ActiveTabClass : 'active-tab-btn')}`
                        }
                        onClick={() => handleActive(title, index)}>
                        {title}
                    </button>
                ))}
            </div>
        </React.Fragment>
    );
})
Tabs.displayName = "Tabs"
export default Tabs