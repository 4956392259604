import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backIcon from '../../../../Assets/icons/back-icon.svg'
import './completeProfile.scss'
import { Country, State, City } from 'country-state-city';
import CSC from '../../../../CommanComponents/CSC/CSC'
import Dropdown from '../../../../CommanComponents/Dropdown/Dropdown'
import RightSidebar from '../../RightSideBar/RightSidebar';
import ScrollToTop from '../../../../CustomHooks/useScrolltoTop';
import Auth from '../../../../Classes/Auth'
import StepperBar from '../../../../CommanComponents/StepperBar/StepperBar';
import { useDispatch, useSelector } from 'react-redux';
import CountrySelector from '../../../../CommanComponents/CSC2/CountrySelector';
import StateSelector from '../../../../CommanComponents/CSC2/StateSelector';
import CitySelector from '../../../../CommanComponents/CSC2/CitySelector';
import ValidationFile from '../../Login/ValidationFile';
import FormError from '../../../../CommanComponents/Form/FormError';
import { resetCheckUserExists, resetSendEmailVerify, signupBusinessOwner, signupIndividual } from '../../../../store/Actions/Auth';
import { hideSuccessToast } from '../../../../store/Actions/SuccessMessagePopup';
import Validation from '../../../../Classes/Validation';
import { use18YearsAgoDate, useTodayDate } from '../../../../CustomHooks/useTodayDate';
import { dateFormat } from '../../../../CustomHooks/dateFormat';
import EmailSent from '../EmailSent';
import RegexValid from '../../../../Classes/RegexValid';
import PhoneInput from 'react-phone-input-2';
import InputDateTimePicker from '../../../../CommanComponents/Form/InputDateTimePicker';
import InputDatePicker from '../../../../CommanComponents/Form/InputDatePicker';
import moment from 'moment';
import Storage from '../../../../Classes/Storage';
import { user_offer_type } from '../../../../Constant/auth';
const CompleteProfile = ({ setCompleteProfile, singleData, emailSent, setEmailSent }) => {
    const { country, states, city, signUp, message, emailVerify } = useSelector((state) => {
        return ({
            country: state.countryStateCity.country,
            states: state.countryStateCity.states,
            city: state.countryStateCity.city,
            signUp: state.auth.signUp,
            message: state.auth.message,
            emailVerify: state.auth.emailVerify
        })
    })
    const countryStateCity = useSelector((state) => state.countryStateCity)
    const auth = useSelector((e) => e.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const phoneInputRef = useRef(null);
    const [showError, setShowError] = useState(false);
    const [regexError, setRegexError] = useState();
    const todayDate = useTodayDate()
    const validRegex = new Validation()
    const validReg = new RegexValid()
    const [showMobileInput, setShowMobileInput] = useState(false);
    const [skipProfile, setSkipProfile] = useState(false);
    const Years18AgoDate = use18YearsAgoDate()
    const [businessOwnerProfile, setBusinessOwnerProfile] = useState({
        business_licence_no: {
            value: "",
            isValid: false,
        },
        emirates_ID: {
            value: "",
            isValid: true,
        },
        // nationality: {
        //     value: "",
        //     isValid: true,
        // },
        _phone: {
            value: "",
            country_code: "+971",
            isValid: false,
        },
        // text_ID: {
        //     value: "",
        //     isValid: false,
        // },
        address: {
            value: "",
            isValid: false,
        },

        zip_code: {
            value: "",
            isValid: false,
        },
        validation: false,
    });

    const [individualProfile, setIndividualProfile] = useState({
        first_name: {
            value: "",
            isValid: false,
        },
        last_name: {
            value: "",
            isValid: false,
        },
        emirates_id: {
            value: "",
            isValid: false,
        },
        nationality: {
            value: "",
            isValid: true,
        },
        dob: {
            value: "",
            isValid: false,
        },

        gender: {
            value: "Gender*",
            isValid: false,
        },
        address: {
            value: "",
            isValid: false,
        },
        country: {
            value: "",
            isValid: false,
        },
        state: {
            value: "",
            isValid: false,
        },
        city: {
            value: "",
            isValid: false,
        },
        zip_code: {
            value: "",
            isValid: false,
        },
        validation: false,
    });

    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "business_licence_no":
                return ValidationFile.validEmpty(inputValue);

            // case "emirates_ID":
            // return ValidationFile.validEmpty(inputValue);

            // case "nationality": return ValidationFile.validEmpty(inputValue);

            case "_phone":
                return ValidationFile.validPhoneNumber(inputValue);
            // var pattern = new RegExp(/[0-9]{9,}/)
            // if (pattern.test(inputValue)) {
            //     return true
            // }
            // else {
            //     return false
            // }

            // case "text_ID":
            //     return ValidationFile.validEmpty(inputValue);

            case "address":
                return ValidationFile.validEmpty(inputValue);

            case "country": return ValidationFile.validEmpty(inputValue);
            case "state": return ValidationFile.validEmpty(inputValue);
            case "city": return ValidationFile.validEmpty(inputValue);

            case "zip_code":
                return ValidationFile.validEmpty(inputValue);

            default:
                return false;
        }
    };

    const validationConfirmIndividual = (inputValue, inputName) => {
        switch (inputName) {
            case "first_name":
                return ValidationFile.validEmpty(inputValue);

            case "last_name":
                return ValidationFile.validEmpty(inputValue);

            case "emirates_id": return ValidationFile.validEmpty(inputValue);

            // case "nationality":
            //     return ValidationFile.validEmpty(inputValue);

            case "dob":
                return ValidationFile.validEmpty(inputValue);

            case "gender":
                return inputValue !== "gender*"

            case "address":
                return ValidationFile.validEmpty(inputValue);

            case "country": return ValidationFile.validEmpty(inputValue);
            case "state": return ValidationFile.validEmpty(inputValue);
            case "city": return ValidationFile.validEmpty(inputValue);

            case "zip_code":
                return ValidationFile.validEmpty(inputValue);

            default:
                return false;
        }
    };

    const isFormValid = () => {
        return (
            businessOwnerProfile.business_licence_no.isValid &&
                // businessOwnerProfile.emirates_ID.isValid &&
                // businessOwnerProfile.nationality.isValid &&
                businessOwnerProfile._phone.isValid &&
                businessOwnerProfile.address.isValid &&
                businessOwnerProfile.country.isValid &&
                businessOwnerProfile.state.isValid &&
                businessOwnerProfile.city.isValid &&
                businessOwnerProfile.zip_code.isValid ? true : false)
    };
    const isFormValidIndividual = () => {
        return (
            // individualProfile.first_name.isValid &&
            //     individualProfile.last_name.isValid &&
            // individualProfile.emirates_id.isValid &&
            // individualProfile.nationality.isValid &&
            individualProfile.dob.isValid &&
                individualProfile.gender.isValid &&
                individualProfile.address.isValid &&
                individualProfile.country.isValid &&
                individualProfile.state.isValid &&
                individualProfile.city.isValid &&
                individualProfile.zip_code.isValid
                ? true : false)
    };


    const handleInput = (e, type) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (type === "businessOwnerProfile") {
            let data = {
                ...businessOwnerProfile,
                [inputName]: {
                    value: inputValue,
                    isValid: validationConfirm(inputValue, inputName),
                },
                validation: isFormValid(),
            };
            // setBusinessOwnerProfile(data);


            switch (inputName) {
                case "nationality":
                    if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
                        return setBusinessOwnerProfile(data), setRegexError('');
                    } else {
                        return setRegexError(inputName);
                    }
                case "emirates_ID":
                case "Emirates_ID":
                    if (validReg.acceptNumsForCard(inputValue) || inputValue === '') {
                        return setBusinessOwnerProfile(data), setRegexError('');
                    } else {
                        return setRegexError(inputName);
                    }
                case "zip_code":
                    if (validReg.acceptNums(inputValue) || inputValue === '') {
                        return setBusinessOwnerProfile(data), setRegexError('');
                    } else {
                        return setRegexError(inputName);
                    }
                default:
                    return setBusinessOwnerProfile(data);
            }




        } else if (type === "individualProfile") {
            if (inputName == "first_name" || inputName == "last_name") {
                // Capitalize the first letter of the input value
                inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
            }
            let data = {
                ...individualProfile,
                [inputName]: {
                    value: inputValue,
                    isValid: validationConfirmIndividual(inputValue, inputName),
                },
                validation: isFormValidIndividual(),
            };
            // setIndividualProfile(data);

            switch (inputName) {
                case "first_name":
                case "last_name":
                case "nationality":
                    if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
                        return setIndividualProfile(data), setRegexError('');
                    } else {
                        return setRegexError(inputName);
                    }
                case "emirates_id":
                case "Emirates_ID":
                    if (validReg.acceptNumsForCard(inputValue) || inputValue === '') {
                        return setIndividualProfile(data), setRegexError('');
                    } else {
                        return setRegexError(inputName);
                    }
                case "zip_code":
                    if (validReg.acceptNums(inputValue) || inputValue === '') {
                        return setIndividualProfile(data), setRegexError('');
                    } else {
                        return setRegexError(inputName);
                    }
                default:
                    return setIndividualProfile(data);
            }


        };
    }
    const handleGenderChange = (val) => {
        let setGender = {
            ...individualProfile,
            "gender": {
                value: val,
                isValid: validationConfirmIndividual(val, "gender"),
            }
        }
        setIndividualProfile(setGender)
    }


    const handleBirthdate = (date) => {
        console.log(date, "SAdsdafdsc")
        let DateInfo = {
            ...individualProfile,
            dob: {
                value: date,
                isValid: !validRegex.isEmpty(date),
            },
            validation: isFormValidIndividual(),
        };
        setIndividualProfile(DateInfo);
        console.log(date, "Asdfdfgfv")
    }

    // effect for get the value of countryStateCity  
    useEffect(() => {
        let setAddress = {
            ...individualProfile,
            "country": {
                value: countryStateCity?.country?.name,
                isValid: validationConfirmIndividual(countryStateCity?.country?.name, "country"),
            },
            "state": {
                value: countryStateCity?.states?.name,
                isValid: validationConfirmIndividual(countryStateCity?.states?.name, "state"),
            },
            "city": {
                value: countryStateCity?.city?.name,
                isValid: validationConfirmIndividual(countryStateCity?.city?.name, "city"),
            },
        }
        setIndividualProfile(setAddress)
    }, [countryStateCity])

    // effect for get the value of countryStateCity  
    useEffect(() => {
        let setAddress = {
            ...businessOwnerProfile,
            "country": {
                value: countryStateCity?.country?.name,
                isValid: validationConfirm(countryStateCity?.country?.name, "country"),
            },
            "state": {
                value: countryStateCity?.states?.name,
                isValid: validationConfirm(countryStateCity?.states?.name, "state"),
            },
            "city": {
                value: countryStateCity?.city?.name,
                isValid: validationConfirm(countryStateCity?.city?.name, "city"),
            },
        }
        setBusinessOwnerProfile(setAddress)
    }, [countryStateCity])

    function formatDateDatePicker(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    //   const inputDate = 'Fri May 10 2024 00:00:00 GMT+0530 (India Standard Time)';
    //   const formattedDate = formatDateDatePicker(inputDate);

    // console.log(formattedDate); // Output: 2024-05-10

    const handleRegisterBtn = (e, skip) => {
        e.preventDefault()
        console.log(skip, "Sddfv")
        // dispatch({ type: "stepperReset" })
        if (!skip) {
            setSkipProfile(true)
            if (isFormValid() || isFormValidIndividual()) {
                // setEmailSent(true)
                if (Auth.userType() === "Individual") {
                    const data = {
                        ...singleData,
                        "additional_informations": {
                            "first_name": singleData?.first_name,
                            "last_name": singleData?.last_name,
                            // "Emirates_ID": individualProfile.emirates_id.value,
                            "Nationality": individualProfile.nationality.value,
                            "dob": formatDateDatePicker(individualProfile.dob.value),
                            "gender": individualProfile.gender.value,
                            "address": individualProfile.address.value,
                            "postal_code": individualProfile.zip_code.value,
                            "country": country?.name,
                            "state": states?.name,
                            "city": city?.name,
                            "subscription_type": Storage.getString(user_offer_type)
                        }
                    }
                    // console.log(formatDateDatePicker(data?.additional_informations?.dob), "sadsaf")
                    dispatch(signupIndividual(data))

                    if (signUp.success) {
                        navigate('/login')
                        console.log(signUp.success, "signUp.success")
                    } else {
                        console.log(signUp.success, 'something went wrong')
                    }

                } else
                    if (Auth.userType() === "Business Owner") {
                        const data = {
                            ...singleData,
                            additional_information: {
                                business_licence_no: businessOwnerProfile.business_licence_no.value,
                                Emirates_ID: businessOwnerProfile.emirates_ID.value,
                                // Nationality: businessOwnerProfile.nationality.value,
                                // text_id: businessOwnerProfile.text_ID.value,
                                address: businessOwnerProfile.address.value,
                                postal_code: businessOwnerProfile.zip_code.value,
                                country: country?.name,
                                state: states?.name,
                                city: city?.name,
                                subscription_type: Storage.getString(user_offer_type)
                            },
                        }
                        dispatch(signupBusinessOwner(data))
                        if (signUp.success) {
                            navigate('/login')
                        } else {
                        }
                    }

            } else {
                setShowError(true);
            }
        }
        else {
            setSkipProfile(false)
            if (Auth?.boolUserType() == 1) {
                dispatch(signupBusinessOwner({ ...singleData, additional_information: {} }))
            } else if (Auth?.boolUserType() == 3) {
                dispatch(signupIndividual({
                    ...singleData, additional_informations:
                    {
                        "first_name": singleData?.first_name,
                        "last_name": singleData?.last_name,
                    }
                }))
            }
        }

        // for skip------------->
        // if (skip) {

        // }

    }

    useEffect(() => {
        if (message) {
            setCompleteProfile(false);
            dispatch({ type: "stepperReset" })
        }
        // dispatch(hideSuccessToast())
    }, [message])


    const handleRegexValidation = (e) => {
        let inputName = e.target.name;
        const inputValue = e?.clipboardData?.getData('text');
        console.log(inputValue, "inputValueinputValue")
        if (inputName === 'nationality' || inputName === 'first_name' || inputName === 'last_name') {
            if (!validRegex.alphaOnly(e.key)) {
                e.preventDefault();
                setRegexError(inputName)
            }
        } else if (inputName === 'zip_code') {
            if (e.key !== "Backspace" && !/^[0-9\b]+$/.test(e.key)) {
                e.preventDefault();
                setRegexError(inputName)
            }
        } else if (inputName === 'emirates_id' || inputName === 'emirates_ID') {
            let regex = /^[0-9\s]*$/;
            if (!regex.test(e.key) && e.key.length === 1 && e.key !== '-') {
                setRegexError(inputName);
                e.preventDefault(); // Prevent further processing
            }
        }


        else {
            setRegexError('')
        }
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         setRegexError('')
    //     }, 1500);
    // }, [regexError])


    useEffect(() => {
        if (emailVerify?.success) {
            setEmailSent(true)
            dispatch({ type: "stepperReset" })
            dispatch(resetSendEmailVerify())
        }

    }, [emailVerify?.success])


    // HAndle owner phone nummbder
    const handlePhoneInput = (inputValue, countryDetail, nameValue) => {
        let dialCode = countryDetail.dialCode;
        let mobileNumber = inputValue.replace(dialCode, "");
        let mobileData = {
            ...businessOwnerProfile,
            "_phone": {
                value: mobileNumber,
                country_code: dialCode,
                isValid: validationConfirm(mobileNumber, "_phone"),
            }
        }
        setBusinessOwnerProfile(mobileData)
    }

    const handlePhoneFocus = () => {
        setShowMobileInput(true)

    }


    useEffect(() => {
        dispatch(resetCheckUserExists())
    }, [])

    // useEffect(() => {
    //     if (phoneInputRef?.current) {
    //         phoneInputRef?.current?.numberInputRef?.focus();
    //     }
    // }, [showMobileInput, handlePhoneFocus])
    const handleBackBtn = () => {
        // localStorage.setItem('profileData', JSON.stringify(Auth.userType() === "Individual" ? {
        //     ...individualProfile,
        //     //  country: country?.name,
        //     // state: states?.name,
        //     // city: city?.name,
        //     "country": {
        //         value: countryStateCity?.country?.name,
        //         isValid: validationConfirm(countryStateCity?.country?.name, "country"),
        //     },
        //     "state": {
        //         value: countryStateCity?.states?.name,
        //         isValid: validationConfirm(countryStateCity?.states?.name, "state"),
        //     },
        //     "city": {
        //         value: countryStateCity?.city?.name,
        //         isValid: validationConfirm(countryStateCity?.city?.name, "city"),
        //     },
        // } : {
        //     ...businessOwnerProfile,
        //     //  country: country?.name,
        //     // state: states?.name,
        //     // city: city?.name,
        //     "country": {
        //         value: countryStateCity?.country?.name,
        //         isValid: validationConfirm(countryStateCity?.country?.name, "country"),
        //     },
        //     "state": {
        //         value: countryStateCity?.states?.name,
        //         isValid: validationConfirm(countryStateCity?.states?.name, "state"),
        //     },
        //     "city": {
        //         value: countryStateCity?.city?.name,
        //         isValid: validationConfirm(countryStateCity?.city?.name, "city"),
        //     },
        // }));
        setCompleteProfile(false)
    }
    // In CompleteProfile.jsx or wherever you need to restore:
    // const storedProfileData = localStorage.getItem('profileData');
    // useEffect(() => {
    //     if (storedProfileData) {
    //         if (Auth.userType() == "Individual") {
    //             setIndividualProfile(JSON.parse(storedProfileData));
    //             // localStorage.removeItem('profileData');
    //         } else {
    //             setBusinessOwnerProfile(JSON.parse(storedProfileData));
    //             // localStorage.removeItem('profileData');
    //         }
    //         // localStorage.removeItem('profileData'); // Remove the stored data after setting profiles
    //         // Optionally, clear the stored data once restored
    //     }
    // }, [singleData]);
    // console.log(businessOwnerProfile, "adf555egfdgcsfds")
    return (
        <>
            <ScrollToTop />
            <div className="complete-profile-wrapper">
                <RightSidebar ClassName={'complete-profile-rightBar'}>
                    {!emailSent ? <>
                        <div className="stepper-wrapper mb-10 mt-3">
                            <StepperBar StepsAmount={2} />
                        </div>
                        <div className="form-container">
                            <button className="back-wrap mb-40 transparent" onClick={() => { handleBackBtn(); dispatch({ type: "stepperReset" }) }}>
                                <img src={backIcon} alt="" />
                            </button>
                            <div className="form-wrap">
                                <form className="form" onSubmit={handleRegisterBtn}>
                                    <h3 className="heading text-md w-500 primary text-center"> {Auth?.boolUserType() == 1 ? "Add Business" : "Complete Profile"} </h3>
                                    <div className="form-control mt-40">
                                        {
                                            Auth.userType() === "Business Owner" &&
                                            <>
                                                <div className="form-field ">
                                                    <input type="text" name="business_licence_no" id="" placeholder='Trade License No*'
                                                        value={businessOwnerProfile.business_licence_no.value}
                                                        onChange={(e) => handleInput(e, "businessOwnerProfile")}
                                                        onKeyUp={(e) => handleInput(e, "businessOwnerProfile")}
                                                        maxLength={20}
                                                    />
                                                    <FormError show={!businessOwnerProfile.business_licence_no.isValid && showError} error={'Please enter trade license no.'} />
                                                </div>
                                                <div className="form-field ">
                                                    <input type="text" name="emirates_ID" id="" placeholder='Emirates ID of Owner'
                                                        value={businessOwnerProfile.emirates_ID.value}
                                                        onChange={(e) => handleInput(e, "businessOwnerProfile")}
                                                        // onKeyUp={(e) => handleInput(e, "businessOwnerProfile")}
                                                        maxLength={18}
                                                    // onKeyDown={(e) => handleRegexValidation(e)}
                                                    // onPaste={handleRegexValidation}
                                                    />
                                                    {/* <FormError show={!businessOwnerProfile.emirates_ID.value && showError} error={'Please enter emirates id'} /> */}
                                                    {/* <FormError show={!businessOwnerProfile?.emirates_ID?.isValid && showError || regexError === 'emirates_ID'}
                                                        error={regexError === 'emirates_ID' ? 'Please enter only numeric character without space.' : 'Please enter emirates id.'} /> */}
                                                    <FormError show={regexError === 'emirates_ID'}
                                                        error={regexError === 'emirates_ID' && 'Please enter only numeric character without space.'} />
                                                </div>
                                                {/* <div className="form-field ">
                                                    <input type="text" name="nationality" id="" placeholder='Nationality *'
                                                        value={businessOwnerProfile.nationality.value}
                                                        onChange={(e) => handleInput(e, "businessOwnerProfile")} 
                                                    />
                                                    <FormError show={!businessOwnerProfile.nationality.isValid && showError || regexError === 'nationality'} error={regexError === 'nationality' ? 'Please enter only alphabetical character.' : 'Please enter nationality'} />
                                                   
                                                </div> */}
                                                {/* <div className="form-field ">
                                                    <input type="text" name="text_ID" id="" placeholder='text ID *'
                                                        value={businessOwnerProfile.text_ID.value}
                                                        onChange={(e) => handleInput(e, "businessOwnerProfile")}
                                                        onKeyUp={(e) => handleInput(e, "businessOwnerProfile")}
                                                    />
                                                    <FormError show={!businessOwnerProfile.text_ID.isValid && showError} error={'Please enter text id'} />
                                                </div> */}
                                                {!showMobileInput &&
                                                    <div className='form-field '>
                                                        <input className='signup-input-filed' type="text" placeholder='Owner Mobile Number*'
                                                            onFocus={() => handlePhoneFocus()}
                                                        />
                                                        <FormError show={!businessOwnerProfile._phone.isValid && showError} error={'Please enter phone number.'} />
                                                    </div>
                                                }
                                                {console.log(businessOwnerProfile._phone.value.length, "SDfsfgfbvdfshregfv")}
                                                {showMobileInput &&
                                                    <div className='form-field '>

                                                        <PhoneInput
                                                            ref={phoneInputRef}
                                                            countryCodeEditable={false}
                                                            containerClass="custom-form-container"
                                                            inputClass="custom-form-input owner-phone-numb"
                                                            specialLabel
                                                            value={`${businessOwnerProfile._phone.country_code} ${businessOwnerProfile._phone.value}`}
                                                            onChange={(value, formattedValue, nameValue) => {
                                                                handlePhoneInput(value, formattedValue, nameValue);
                                                            }}
                                                            onKeyUp={(value, formattedValue, nameValue) => {
                                                                handlePhoneInput(value, formattedValue, nameValue);
                                                            }}
                                                            onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                                            preserveOrder={['onlyCountries', 'preferredCountries']}
                                                            inputProps={{
                                                                name: "_phone",
                                                                autoFocus: false,
                                                                placeholder: "Owner Mobile Number*",
                                                            }}
                                                            enableSearch
                                                            name="mobileNo"
                                                            searchPlaceholder="Search Country"
                                                            disableSearchIcon
                                                        // onBlur={() => {
                                                        //     businessOwnerProfile._phone.value == 0 && setShowMobileInput(false)
                                                        // }}
                                                        />
                                                        <FormError show={!businessOwnerProfile._phone.isValid && showError}
                                                            error={
                                                                businessOwnerProfile._phone.value.length < 9 && businessOwnerProfile._phone.value.length > 0
                                                                    ? 'Please enter correct phone number.'
                                                                    : 'Please enter phone number.'
                                                            }
                                                        />
                                                    </div>
                                                }
                                                <div className="form-field">
                                                    <input type="text" name="address" id="" placeholder='Address *'
                                                        value={businessOwnerProfile.address.value}
                                                        onChange={(e) => handleInput(e, "businessOwnerProfile")}
                                                        onKeyUp={(e) => handleInput(e, "businessOwnerProfile")} />
                                                    <FormError show={!businessOwnerProfile.address.isValid && showError} error={'Please enter address'} />
                                                </div>
                                                <div className="form-field address-wrap">
                                                    <div className="other-address-detail">
                                                        <div className="form-field">
                                                            <CountrySelector />
                                                            <FormError show={!businessOwnerProfile?.country?.isValid && showError} error={'Please select country.'} />
                                                        </div>
                                                        <div className="form-field  ">
                                                            <StateSelector />
                                                            <FormError show={!businessOwnerProfile?.state?.isValid && showError} error={'Please select state.'} />
                                                        </div>
                                                        <div className="form-field  ">
                                                            <CitySelector />
                                                            <FormError show={!businessOwnerProfile?.city?.isValid && showError} error={'Please select city.'} />
                                                        </div>
                                                        <div className="form-field  ">
                                                            <input type="text" name="zip_code" id="" placeholder='Zip code'
                                                                value={businessOwnerProfile.zip_code.value}
                                                                onChange={(e) => handleInput(e, "businessOwnerProfile")}
                                                                // onKeyUp={(e) => handleInput(e, "businessOwnerProfile")}
                                                                // onKeyDown={(e) => handleRegexValidation(e)}
                                                                maxLength={6}
                                                            />
                                                            <FormError
                                                                show={!businessOwnerProfile?.zip_code?.isValid && showError || regexError === "zip_code"}
                                                                error={regexError === "zip_code" ? 'Please enter only numeric value.' : 'Please enter zip code.'}
                                                            />
                                                            {/* {regexError === "zip_code" && <FormError show={true} error={'Please enter only numeric value.'} />} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {
                                            Auth.userType() === "Individual" &&
                                            <>
                                                {/* <div className="form-field ">
                                                    <input type="text" name="first_name" id="" placeholder='First Name *'
                                                        value={individualProfile.first_name.value}
                                                        onChange={(e) => handleInput(e, "individualProfile")}
                                                    />
                                                    <FormError show={!individualProfile.first_name.isValid && showError || regexError === "first_name"} error={regexError === "first_name" ? 'Please enter only alphabetical character.' : 'Please enter first name'} />

                                                </div>
                                                <div className="form-field ">
                                                    <input type="text" name="last_name" id="" placeholder='Last Name *'
                                                        value={individualProfile.last_name.value}
                                                        onChange={(e) => handleInput(e, "individualProfile")}
                                                    />
                                                    <FormError show={!individualProfile.last_name.isValid && showError || regexError === "last_name"} error={regexError === "last_name" ? 'Please enter only alphabetical character.' : 'Please enter last name'} />
                                                </div> */}
                                                {/* <div className="form-field ">
                                                    <input type="text" name="emirates_id" id="" placeholder='Emirates ID *'
                                                        value={individualProfile.emirates_id.value}
                                                        onChange={(e) => handleInput(e, "individualProfile")}
                                                        maxLength={18}
                                                    />
                                                    <FormError show={!individualProfile?.emirates_id?.isValid && showError || regexError === 'emirates_id'}
                                                        error={regexError === 'emirates_id' ? 'Please enter only numeric character without space.' : 'Please enter emirates id.'} />
                                                </div> */}
                                                {/* <div className="form-field ">
                                                    <input type="text" name="nationality" id="" placeholder='Nationality*'
                                                        value={individualProfile.nationality.value}
                                                        onChange={(e) => handleInput(e, "individualProfile")}
                                                    />
                                                    <FormError show={!individualProfile.nationality.value && showError || regexError === "nationality"} error={regexError === "nationality" ? 'Please enter only alphabetical character.' : 'Please enter nationality'} />
                                                </div> */}
                                                <div className="form-field dob-main-wrappper ">
                                                    {/* <div className={`dob-gender-wrap`}> */}
                                                    {/* <div className="dob-wrap d-flex align-center">
                                                            <label htmlFor="individual_dob" className='bob_para text-ss w-400 secondary'>{individualProfile.dob.value ? dateFormat(individualProfile.dob.value) : "Date of birth*"}</label>
                                                            <input type="date" name="dob" id="individual_dob"
                                                                max={todayDate}
                                                                value={individualProfile.dob.value}
                                                                onChange={(e) => handleInput(e, "individualProfile")}
                                                                onKeyUp={(e) => handleInput(e, "individualProfile")}
                                                            />
                                                        </div> */}
                                                    <div className="datePickerWrap">

                                                        <InputDatePicker
                                                            name="dob"
                                                            type="date"
                                                            // maxDate={moment().toDate()}
                                                            maxDate={Years18AgoDate}
                                                            dateFormat="dd-MM-yyyy"
                                                            onSelect={(e) => {
                                                                // handleBirthdate(e);
                                                                handleBirthdate(e, "individualProfile")
                                                            }}
                                                            // label="Date of Birth"
                                                            placeholder="Date of birth (dd-mm-yyyy)"
                                                            value={individualProfile.dob.value}
                                                            className={'date-picker-auth'}
                                                        // onKeyDown={(e) => e.preventDefault()}
                                                        />

                                                    </div>
                                                    <FormError show={!individualProfile.dob.isValid && showError} error={'Please enter date of birth'} />
                                                </div>
                                                <div className="form-field">
                                                    <Dropdown
                                                        ClassName={'gender-dropdown'}
                                                        padding={'12.5px 16px'}
                                                        borderRadius={'8px'}
                                                        textAlign={'left'}
                                                        data={['Male', 'Female']}
                                                        defaultValue={individualProfile.gender.value}
                                                        fontSize={"18px"}
                                                        btnClassName={'btn'}
                                                        borderWidth={'1px'}
                                                        clickHandle={(val) => handleGenderChange(val)}
                                                    />
                                                    <FormError show={individualProfile.gender.value === "Gender*" && showError} error={'Please enter gender'} />
                                                </div>
                                                <div className="form-field address-wrap ">
                                                    <input type="text" name="address" id="" placeholder='Address*'
                                                        value={individualProfile.address.value}
                                                        onChange={(e) => handleInput(e, "individualProfile")}
                                                        onKeyUp={(e) => handleInput(e, "individualProfile")}
                                                    />
                                                    <FormError show={!individualProfile.address.isValid && showError} error={'Please enter address'} />
                                                </div>
                                                <div className="form-field address-wrap">
                                                    <div className="other-address-detail">
                                                        <div className="form-field">
                                                            <CountrySelector placeholder={"Country*"} />
                                                            <FormError show={!individualProfile?.country?.isValid && showError} error={'Please select country.'} />
                                                        </div>
                                                        <div className="form-field  ">
                                                            <StateSelector placeholder={"State*"} />
                                                            <FormError show={!individualProfile?.state?.isValid && showError} error={'Please select state.'} />
                                                        </div>
                                                        <div className="form-field  ">
                                                            <CitySelector placeholder={"City*"} />
                                                            <FormError show={!individualProfile?.city?.isValid && showError} error={'Please select city.'} />
                                                        </div>
                                                        <div className="form-field  ">
                                                            <input type="text" name="zip_code" id="" placeholder='Zip code*'
                                                                value={individualProfile.zip_code.value}
                                                                onChange={(e) => handleInput(e, "individualProfile")}
                                                                // onKeyUp={(e) => handleInput(e, "individualProfile")}
                                                                // onKeyDown={(e) => handleRegexValidation(e)}
                                                                maxLength={6}
                                                            />
                                                            <FormError
                                                                show={!individualProfile?.zip_code?.isValid && showError || regexError === "zip_code"}
                                                                error={regexError === "zip_code" ? 'Please enter only numeric value.' : 'Please enter zip code.'}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        }

                                    </div>
                                    <button type='submit' className="login-button pT-10 pb-10 w-400 text-s transparent white">
                                        {emailVerify?.loading && skipProfile ? "Sending email..." : "Save"}
                                    </button>
                                </form>
                            </div>
                            <button className='primary transparent text-xs w-500 underline mx-20'
                                onClick={(e) => handleRegisterBtn(e, "skip")}
                            >
                                {emailVerify?.loading && !skipProfile ? "Sending email..." : "Skip for now"}
                            </button>
                        </div>
                    </>
                        : <EmailSent />
                    }
                </RightSidebar>
            </div>
        </>
    )
}

export default CompleteProfile