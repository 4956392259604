import Request from "../../../Classes/Requests";

class authRequest extends Request {
    constructor() {
        super()
        this.endpoint = {
            signup_typing_center: super.url("/typing_center_register"),
            user_login: super.url("/user_login"),
            business_user_register: super.url("/business_user_register"),
            individual_user_register: super.url("/individual_user_register"),
            sendEmailVerification: super.url("/send-email-verification"),
            verify_email: super.url("/verify_email?uidb64=__U_IDB__&token=__Token__"),
            check_user_exists: super.url("/check_user_exists"),

            // getData: super.url("/middleware/getbusiness?business=__BUSINESS_ID__&type=__TYPE__", "middleware"),
        }
    }
}
export default new authRequest();
