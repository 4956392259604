import { useEffect, useRef, useState } from "react"
import ValidationFile from "../../Classes/ValidationFile";
import Auth from "../../Classes/Auth";
import { getClientsList, getUsersClientsList } from "../../store/Actions/Clients";
import { resetAddUser, resetUserClientList, resetUserError, resetUserUpdate, setAddUser, setUserDelete, setUserList, setUserUpdate, setUserViewForUpdate } from "../../store/Actions/User";
import { useDispatch, useSelector } from "react-redux";
import RegexValid from "../../Classes/RegexValid";

const useUsers = () => {
    const dispatch = useDispatch()
    const user = useSelector((e) => e.user)
    const validReg = new RegexValid()
    const addUserRef = useRef()
    const timeoutRef = useRef(null) // Ref to store timeout ID
    const userId = Auth?.user()?.user_id // this is for added by user
    const userId_user = Auth?.user()?.user_id_user
    const [showError, setShowError] = useState(false)
    const [regexError, setRegexError] = useState('')
    const [updateID, setUpdateID] = useState(false)
    const [addUserData, setAddUserData] = useState({
        full_name: {
            value: "",
            isValid: false,
        },
        email: {
            value: "",
            isValid: false,
        },
        phone_no: {
            value: "",
            country_code: "+971",
            isValid: false,
        },
        type: {
            value: "Select Type",
            isValid: false,
        },
        clients: {
            value: '',
            isValid: true,
        },
        status: {
            value: "1",
            isValid: true,
        },
        validation: false,
    })



    // check input vafildation
    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "full_name":
                return ValidationFile.validEmpty(inputValue);

            case "email":
                return ValidationFile.validEmail(inputValue);

            case "phone_no":
                return ValidationFile.validPhoneNumber(inputValue);
            // var pattern = new RegExp(/[0-9]{10,}/)
            // if (pattern.test(inputValue)) {
            //     return true
            // }
            // else {
            //     return false
            // }

            case "type":
                return ValidationFile.validEmpty(inputValue);

            case "clients":
                return true;

            case "status":
                return true;

            default:
                return false;
        }
    };

    // check form is validation 
    const isFormValid = () => {
        return (
            addUserData.full_name.isValid &&
                addUserData.email.isValid &&
                addUserData.phone_no.isValid &&
                addUserData.type.isValid &&
                addUserData.clients.isValid &&
                addUserData.status.isValid
                ? true : false
        )
    };

    // handle input fileds
    const handleInputChange = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...addUserData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        // setAddUserData(data);

        if (inputName == 'email') {
            dispatch(resetUserError())
        }

        switch (inputName) {
            case "full_name":
                if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
                    return setAddUserData(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }

            default:
                return setAddUserData(data);
        }

    };

    // handle phone input
    const handlePhoneInput = (inputValue, countryDetail, nameValue) => {
        let dialCode = countryDetail.dialCode;
        let mobileNumber = inputValue.replace(dialCode, "");
        let mobileData = {
            ...addUserData,
            "phone_no": {
                value: mobileNumber,
                country_code: dialCode,
                isValid: validationConfirm(mobileNumber, "phone_no"),
            }
        }
        setAddUserData(mobileData)
    }

    const handleSelectDropdown = (val, name) => {
        let data = {
            ...addUserData,
            [name]: {
                value: val,
                isValid: validationConfirm(val, name),
            },
            validation: isFormValid(),
        }
        setAddUserData(data)
    }

    // Save Btn handler
    const addUserSaveBtn = (e) => {
        e.preventDefault()

        const clientsId = addUserData?.clients?.value ? addUserData?.clients?.value?.map((e) => { return e !== null && e?.id ? e?.id : e }).filter(id => id !== undefined) : []

        const data = {
            "full_name": addUserData?.full_name?.value,
            "email": addUserData?.email?.value,
            "phone_no": `${addUserData.phone_no.country_code} ${addUserData.phone_no.value}`,
            "type": addUserData?.type?.value,
            "is_active": addUserData?.status?.value,
            // "assign_client": Array.isArray(clientsId) ? clientsId.join(',') : "",
            "assign_client": clientsId,
            "added_by_user_id": userId
        }

        if (isFormValid()) {
            if (updateID == false) {
                dispatch(setAddUser(data))
            } else {
                dispatch(setUserUpdate(updateID, data))
            }

        } else {
            setShowError(true);
        }
    }

    const resetAddUSerData = () => {
        setAddUserData({
            full_name: {
                value: "",
                isValid: false,
            },
            email: {
                value: "",
                isValid: false,
            },
            phone_no: {
                value: "+971",
                country_code: "",
                isValid: false,
            },
            type: {
                value: "Select Type",
                isValid: false,
            },
            clients: {
                value: '',
                isValid: true,
            },
            status: {
                value: "1",
                isValid: true,
            },
            validation: false,
        })
    }

    const AddUserClickHanlder = () => {
        addUserRef?.current?.open()
        resetAddUSerData()
        setShowError(false);
    }
    const AddUserClose = () => {
        addUserRef?.current?.close()
        resetAddUSerData()
        setUpdateID(false)
        dispatch(resetAddUser())
    }


    const editBtnHanlder = (id) => {
        setUpdateID(id)
        AddUserClickHanlder()
        dispatch(setUserViewForUpdate(id))

    }
    useEffect(() => {
        if (user?.update_user) {
            const update_user = user?.update_user
            const mobileNo = update_user?.phone_no?.split(" ")
            setAddUserData({
                full_name: {
                    value: update_user?.full_name,
                    isValid: true,
                },
                email: {
                    value: update_user?.email,
                    isValid: true,
                },
                phone_no: {
                    value: mobileNo ? mobileNo[1] : '',
                    country_code: mobileNo ? mobileNo[0] : '',
                    isValid: true,
                },
                type: {
                    value: update_user?.type,
                    isValid: true,
                },
                clients: {
                    value: update_user?.assign_client,
                    isValid: true,
                },
                status: {
                    value: update_user?.is_active ? 1 : 0,
                    isValid: true,
                },
                validation: true,
            })
        }
    }, [user?.update_user])

    const deleteUserHandler = (id) => {
        dispatch(setUserDelete(id, userId))
    }

    useEffect(() => {
        if (Auth?.boolUserType() == 4) {
            dispatch(getUsersClientsList(userId_user, userId))
        } else {
            dispatch(getClientsList(userId))
        }
        dispatch(setUserList(userId))
        dispatch(resetUserClientList())
    }, [])

    useEffect(() => {
        if (user.success == true) {
            AddUserClose()
            dispatch(resetAddUser())
            dispatch(resetUserUpdate())
        }
    }, [user])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setRegexError('')
    //     }, 2000);
    // }, [regexError])
    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            setRegexError('')
        }, 2000);
    }, [regexError])

    return {
        addUserData, handleInputChange, handlePhoneInput, addUserSaveBtn,
        showError, handleSelectDropdown, addUserRef, AddUserClose, AddUserClickHanlder,
        editBtnHanlder, deleteUserHandler, updateID, regexError
    }
}

export default useUsers