import React, { useEffect, useRef, useState } from 'react';
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick';
import './multiSelectorDropdown.scss';

const MultiSelectorDropdown = ({ defaultValue, btnClassName, className, key, clickHandle, data = [], disableList = [], ObjectKey = false, defaultSelectedVal = [], ...props }) => {
    const ref = useRef(null);
    const [activeDrop, setActiveDrop] = useDetectOutsideClick(ref, false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValuesObject, setSelectedValuesObject] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [dataList, setDataList] = useState(data);
    const [mounted, setMounted] = useState(false); // State to track component mount

    const toggleSelection = (value, completeValObj) => {
        const updatedSelection = [...selectedValues];
        const updatedSelectionObj = [...selectedValuesObject];
        const index = updatedSelection.indexOf(value);
        if (index === -1) {
            updatedSelection.push(value);
            updatedSelectionObj.push(completeValObj);

        } else {
            updatedSelection.splice(index, 1);
            updatedSelectionObj.splice(index, 1);
        }

        setSelectedValues(updatedSelection);
        setSelectedValuesObject(updatedSelectionObj)
        clickHandle && clickHandle(ObjectKey !== false ? updatedSelectionObj : updatedSelection);
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        const filteredData = data.filter(item => {
            if (typeof item === 'string') {
                return item.toLowerCase().includes(e.target.value.toLowerCase());
            } else if (ObjectKey && typeof item === 'object' && item !== null) {
                return item[ObjectKey].toString().toLowerCase().includes(e.target.value.toLowerCase());
            }
            return false;
        });

        setDataList(filteredData);
    };

    useEffect(() => {
        Array.isArray(data) && typeof data[0] === 'object' && data[0] !== null ?
            setDataList(data.map((e) => e)) : setDataList(data);
    }, [data]);

    useEffect(() => {
        if (ObjectKey !== false) {
            // setSelectedValues(selectedValuesObject)
        }
    }, [selectedValues])

    // useEffect(() => {
    //     // Add default selected values to the state when component mounts
    //     const data = defaultSelectedVal?.length > 0 ? defaultSelectedVal?.map(id => dataList?.find(client => client?.id === id)?.business_name) : ''
    //     const dataObj = defaultSelectedVal?.length > 0 ? defaultSelectedVal?.map(id => dataList?.find(client => client?.id === id)) : ''
    //     if (activeDrop == true) {
    //         setSelectedValues(data);
    //         setSelectedValuesObject(dataObj)

    //     }

    // }, []);
    useEffect(() => {
        // Add default selected values to the state when component mounts and dropdown is active
        if (mounted && activeDrop) {
            const data = defaultSelectedVal?.length > 0 ? defaultSelectedVal?.map(id => dataList?.find(client => client?.id === id)?.business_name) : '';
            const dataObj = defaultSelectedVal?.length > 0 ? defaultSelectedVal?.map(id => dataList?.find(client => client?.id === id)) : '';
            setSelectedValues(data);
            setSelectedValuesObject(dataObj);
            setMounted(false);
        }
    }, [mounted, activeDrop]);

    useEffect(() => {
        // Set mounted to true when the component mounts
        setMounted(true);
    }, []);



    return (
        <div className={`multi-select-Searchdropdown-wrapper ${className} `} ref={ref} key={key}>
            <button className={`drop-btn ${btnClassName} ${selectedValues.length > 0 ? 'selected' : ''}`} onClick={() => setActiveDrop(!activeDrop)} type='button'>
                {selectedValues.length && selectedValues?.filter(value => value !== undefined).length > 0 ? selectedValues.filter(value => value !== undefined).join(', ') : defaultValue ? defaultValue : 'Select *'}
                <i className={`${activeDrop ? 'active-icon' : ''} icon-arrow`}></i>
            </button>
            {activeDrop &&
                <div className='search-list-main-wrapper'>
                    <div className="search-input-wrap">
                        <input type="search" name="" id="" className='search-input'
                            value={searchValue}
                            onChange={(e) => handleSearch(e)}
                            onKeyDown={(e) => handleSearch(e)}
                        />
                    </div>
                    <ul className='list-wrap'>
                        {
                            dataList.map((val, key) => (
                                <li className={`${disableList.includes(val) && 'disabled'} ${selectedValues.includes(val) && "active"} `}
                                    key={key} onClick={() => toggleSelection(ObjectKey !== false ? val[ObjectKey] : val, val)}>
                                    {/* {ObjectKey !== false ? val[ObjectKey] : val} */}
                                    {console.log(selectedValues, "selectedValues.includes(val)")}
                                    <div className='item-wrap'>
                                        <input
                                            type="checkbox"
                                            checked={selectedValues.includes(ObjectKey !== false ? val[ObjectKey] : val)}
                                            // checked={defaultSelectedVal.includes(val.id)}
                                            // checked={selectedValues.includes(ObjectKey !== false ? val[ObjectKey] : val) && defaultSelectedVal.includes(val.id)}
                                            onChange={() => toggleSelection(ObjectKey !== false ? val[ObjectKey] : val, val)}
                                        />
                                        {ObjectKey !== false ? val[ObjectKey] : val}
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            }
        </div>
    );
};

export default MultiSelectorDropdown;
