import React from 'react'
import './notificationcards.scss'
import { useNavigate } from 'react-router-dom'
import DataNotFound from '../../../../CommanComponents/DataNotFound/DataNotFound'
import { dateFormat } from '../../../../CustomHooks/dateFormat'
import { setActiveTab } from '../../../../store/Actions/PersonalDetails'
import { useDispatch } from 'react-redux'
import useDocumentDetails from '../../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
// import data from './data.json'
const NotificationCard = ({ data }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { DocuemntEditBtnHandler } = useDocumentDetails()
  function handleRenew(id, is_seen, employee_id, client_id, docType) {
    // if (!is_seen) {
    //   const formData = new FormData();
    //   formData.append('id', id);
    //   formData.append('is_seen', 1);
    //   dispatch(setSeenNoty(userId, formData))
    // }
    navigate(`/employees-management/id=${employee_id}?client_id=${client_id}`)
    dispatch(setActiveTab("Documents"))
    DocuemntEditBtnHandler(employee_id, docType)
  }
  return (
    <div className='dashboard-notification-wrap'>
      <div className="heading-wrap text-xs w-500 white bg-secondary">
        Notifications
      </div>
      <div className=" table-wrap">
        <table className="table-item">
          <thead className='table-head'>
            <tr className="table-row">
              <th> Purpose </th>
              <th>User Name</th>
              <th >Expiry Date</th>
              <th >Action</th>
            </tr>
          </thead>

          <tbody className='table-body'>
            {data?.length ? data?.map((val, key) => {
              return (
                <tr className="table-row" key={key}  >
                  <td  > {val.Purpose}  </td>
                  <td> {val.first_name + ' ' + val.last_name} </td>
                  <td > {dateFormat(val.Date_of_Expiry)} </td>
                  <td  >
                    <button
                      className='button bg-primary border-0 white btn w-400 text-xxs'
                      onClick={() => handleRenew(val?.id, val?.is_seen, val?.employee_id, val?.client_id, val?.Document_type)}
                    >Renew</button>
                  </td>
                </tr>
              )
            }) :
              <tr className="table-row"  >
                <td colSpan={4} >
                  <DataNotFound className={"mt-10"} />
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      {data?.length > 0 &&
        <button className=' see-more w-500 text-xxs secondary mt-15' onClick={() => navigate('/notifications')} >See More</button>
      }
    </div>
  )
}

export default NotificationCard