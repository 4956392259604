import React, { useState, memo, useRef, useCallback, useLayoutEffect, useEffect } from 'react'
import "./personalDetails.scss";
import dummyUserImg from '../../../Assets/images/black-user-img.png'
import data from './data.json'
import CSC from '../../../CommanComponents/CSC/CSC';
import Dropdown from '../../../CommanComponents/Dropdown/Dropdown';
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MobileInput from '../../../CommanComponents/MobileInput/MobileInput';
import CountrySelector from '../../../CommanComponents/CSC2/CountrySelector';
import StateSelector from '../../../CommanComponents/CSC2/StateSelector';
import CitySelector from '../../../CommanComponents/CSC2/CitySelector';
import useEmployeePersonalDetails from '../../../CustomHooks/EmployeePersonalDetails/useEmployeePersonalDetails';
import PhoneInput from 'react-phone-input-2';
import FormError from '../../../CommanComponents/Form/FormError';
import { deleteEmployee, resetDelete } from '../../../store/Actions/PersonalDetails';
import { useDispatch } from 'react-redux';
import DeleteConfirmPop from '../../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop';
import NativeCountrySelector from '../../../CommanComponents/CSC2/NativeCsc2/NativeCountrySelector';
import NativeStateSelector from '../../../CommanComponents/CSC2/NativeCsc2/NativeStateSelector';
import NativeCitySelector from '../../../CommanComponents/CSC2/NativeCsc2/NativeCitySelector';
import Validation from '../../../Classes/Validation';
import { useTodayDate, use18YearsAgoDate } from '../../../CustomHooks/useTodayDate';
import ImageViewer from '../../../CommanComponents/ImageViewer/ImageViewer';
import { dateFormat } from '../../../CustomHooks/dateFormat';
import { setDocType } from '../../../store/Actions/DocumentUpload';
import Auth from '../../../Classes/Auth';
import InputDatePicker from '../../../CommanComponents/Form/InputDatePicker';
import moment from 'moment';
import RadioInput from '../../../CommanComponents/Form/RadioInput';
const PersonalDetails = ({ handleActiveTab, activeEditLocal, setActiveEditLocal, }) => {
    const dispatch = useDispatch()

    const imageRef = useRef();
    const { employee_ID } = useParams()
    const employeeName = employee_ID.includes("id=") ? employee_ID.replace("id=", "") : ""
    const navigate = useNavigate()
    const { activeEdit, editBtnHandler, setActiveEdit, handlePersonalDetailsBtn,
        dobValue, setDobValue, type, setType, joiningDate, setJoiningDate,
        employeeData, handleInput, handleGenderChange, handlePhoneInput, showError,
        formRef, resetEmployeeData, personalDetails, singleEmployee, employeeManagement, arrowBtnHandler,
        arrowBtn, regexErrorPersonal, handleDateInput, inputFile
    } = useEmployeePersonalDetails();


    const validRegex = new Validation()
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    // const [regexErrorPersonal, setregexErrorPersonal] = useState();
    const todayDate = useTodayDate()
    const Years18AgoDate = use18YearsAgoDate()

    function onChangeFile(e, ref) {
        const file = e.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
        if (file?.type.includes("image")) {
            // if (file.size <= maxSize) {
            if (true) {
                if (file) {
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        ref.current.src = e.target.result
                    };

                    reader.readAsDataURL(file);
                }
            } else {
                alert('File size exceeds the limit of 2 MB');
                e.target.value = '';
            }
        } else {
            alert('Accept only .jpg, .jpeg, .png , .webp files.');
            e.target.value = '';
        }
    }


    // DELETE EMPLOYEE HERE
    const deleteHandler = useCallback((id) => {
        dispatch(deleteEmployee(id))
    }, [personalDetails?.delete])

    useLayoutEffect(() => {
        if (personalDetails?.delete) {
            navigate(-1)
            dispatch(resetDelete())
        }
    }, [personalDetails?.delete])

    const deleteClickHandler = (id) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }

    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }

    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeletePop(false);
        deleteHandler(deleteId)
    }


    const handleRegexValidation = (e) => {
        // let inputName = e.target.name;
        // if (inputName === 'f_name' || inputName === 'l_name') {
        //     if (!validRegex.alphaOnly(e.key)) {
        //         e.preventDefault();
        //         setregexErrorPersonal(inputName)
        //     }
        // } else if (inputName === 'current_zip_code' || inputName === 'native_zip_code') {
        //     if (e.key !== "Backspace" && !/^[0-9\b]+$/.test(e.key)) {
        //         e.preventDefault();
        //         setregexErrorPersonal(inputName)
        //     }
        // } else {
        //     setregexErrorPersonal('')
        // }
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setregexErrorPersonal('')
    //     }, 2000);
    // }, [regexErrorPersonal])

    useEffect(() => {
        dispatch(setDocType('Add document*'));
    }, [])


    console.log(employeeData, "asdfsdv")


    return (
        <div className="personal-details-main-wrapper">
            {/* <div className="employee-top-wrapper d-flex justify-between mb-15 align-center"> */}
            {/* <p className="employee-name secondary text-xs w-700 ">
                    {
                        (employeeManagement.employee_type === 'old' || type === 'old') && singleEmployee?.first_name ? (
                            singleEmployee.last_name ? `${singleEmployee.first_name} ${singleEmployee.last_name}` : "Employee Full Name"
                        ) : null
                    }


                </p> */}
            {/* <div className="employee-top-actions-wrap d-flex ">
                    {(employeeManagement.employee_type === 'old' || type === 'old') ?
                        <>
                            <button className="icons-wrap transparent d-flex align-center justify-center" onClick={editBtnHandler}> <i className="icons edit-icon"></i></button>
                            <button className="icons-wrap transparent d-flex align-center justify-center" onClick={() => deleteClickHandler(singleEmployee?.employee_id)} > <i className="icons delete-icon"></i></button>
                            <button className={`icons-wrap transparent d-flex align-center justify-center`} disabled={arrowBtn?.prev} onClick={() => arrowBtnHandler('prev')} > <i className="icons left-icon"></i></button>
                            <button className={`icons-wrap transparent d-flex align-center justify-center `} disabled={arrowBtn?.next} onClick={() => arrowBtnHandler('next')}> <i className="icons right-icon"></i></button>
                        </> : ""
                    }
                    <button onClick={() => navigate('/employees-management')} className="icons-wrap transparent d-flex align-center justify-center"> <i className="icons cross-icon"></i></button>
                </div> */}
            {/* </div> */}
            <div className="employee-basic-details-wrapper d-flex justify-between ">
                <div className="employee-img-wrap">
                    <ImageViewer object={singleEmployee?.employee_image} defaultImage={dummyUserImg} ref={imageRef} alt="Profile-Pic" className='user-img' />
                    <div className="pencile-wrap d-flex justify-center align-center" onClick={() => inputFile.current.click()}>
                        <i className='pencile-icon'></i>
                        <input id="myInput"
                            type="file"
                            ref={inputFile}
                            style={{ display: 'none' }}
                            onChange={(e) => onChangeFile(e, imageRef)}
                            accept='image/*'
                        />
                    </div>
                </div>
                <div className="employee-basic-details">
                    <div className="basic-details-wrap">
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                First Name
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : singleEmployee?.first_name}
                            </p>
                        </div>
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Last Name
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : singleEmployee?.last_name}
                            </p>
                        </div>
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Gender
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : singleEmployee?.gender}
                            </p>
                        </div>
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Date of birth
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : dateFormat(singleEmployee?.date_of_birth)}
                            </p>
                        </div>

                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                E-mail
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : singleEmployee?.e_mail}
                            </p>
                        </div>


                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Phone no
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : '+' + singleEmployee?.mobile}
                            </p>
                        </div>
                        <div className="basic-details-item address-details">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Address
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {employeeManagement.employee_type === 'new' || type === 'new' ? "-" :
                                    `${singleEmployee?.current_address ? singleEmployee?.current_address + ',' : ''}${singleEmployee?.current_city ? singleEmployee?.current_city + ',' : ''}${singleEmployee?.current_state ? singleEmployee?.current_state + ',' : ''}${singleEmployee?.current_country ? singleEmployee?.current_country + ',' : ''}${singleEmployee?.current_zip_code ? singleEmployee?.current_zip_code : ''}`}
                            </p>
                        </div>
                        <div className='basic-details-item extra-div'></div>
                        {
                            Auth?.boolUserType() !== 3 && <>
                                <div className="basic-details-item">
                                    <p className="details-title secondary text-xxs w-600 mb-3">
                                        Joining Date
                                    </p>
                                    <p className="details-desc secondary text-xxs w-400">
                                        {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : dateFormat(singleEmployee?.date_of_joining)}
                                    </p>
                                </div>

                                <div className="basic-details-item">
                                    <p className="details-title secondary text-xxs w-600 mb-3">
                                        Staffing Status
                                    </p>
                                    <p className="details-desc secondary text-xxs w-400">
                                        {employeeManagement.employee_type === 'new' || type === 'new' ? "-" : singleEmployee?.status ? "Active" : "Inactive"}
                                    </p>
                                </div>
                            </>
                        }
                        {console.log(singleEmployee?.status, "qsfsagfsfdgv")}
                    </div>
                </div>
            </div>
            {
                activeEditLocal &&
                <div className="employee-details-edit-wrapper mt-15">
                    <div className="form-wrapper">
                        <div className="form"  >
                            <div className="form-control">
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > First Name* </label>
                                    <input type="text"
                                        name='f_name'
                                        value={employeeData?.f_name?.value}
                                        onChange={handleInput}
                                    // onKeyUp={handleInput}
                                    // onKeyDown={(e) => handleRegexValidation(e)}
                                    />
                                    <FormError show={!employeeData?.f_name?.isValid && showError || regexErrorPersonal === 'f_name'} error={regexErrorPersonal === 'f_name' ? "Please enter only alphabetical character." : 'Please enter first name.'} />

                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Last Name* </label>
                                    <input type="text"
                                        name='l_name'
                                        value={employeeData?.l_name?.value}
                                        onChange={handleInput}
                                    // onKeyUp={handleInput}
                                    // onKeyDown={(e) => handleRegexValidation(e)}
                                    />
                                    <FormError show={!employeeData?.l_name?.isValid && showError || regexErrorPersonal === 'l_name'} error={regexErrorPersonal === 'l_name' ? "Please enter only alphabetical character." : 'Please enter last name.'} />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > Date of Birth* </label>
                                    {/* <div className="dob-wrap d-flex align-center">
                                        <div className={`bob_para text-xs w-400 ${employeeData?.dob?.value ? "base" : "secondary"} `}>{employeeData?.dob?.value ? dateFormat(employeeData?.dob?.value) : ""}</div>
                                        <input type="date" name="dob"
                                            max={Years18AgoDate}
                                            value={employeeData?.dob?.value}
                                            onChange={handleInput}
                                            onKeyUp={handleInput}
                                        />
                                    </div> */}
                                    <div className="datePickerWrap">
                                        <InputDatePicker
                                            name="dob"
                                            type="date"
                                            maxDate={Years18AgoDate}
                                            dateFormat="dd-MM-yyyy"
                                            onSelect={(e) => {
                                                handleDateInput(e, 'dob')
                                            }}
                                            placeholder="DD-MM-YYYY"
                                            value={employeeData?.dob?.value}
                                            className={'date-picker-auth'}
                                        />
                                    </div>
                                    <FormError show={!employeeData?.dob?.isValid && showError} error={'Please select date of birth.'} />
                                </div>
                                <div className="form-field dob-main-wrappper ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > Gender* </label>
                                    <div className={`dob-gender-wrap`}>
                                        <Dropdown
                                            key={employeeData?.gender?.value}
                                            ClassName={'gender-dropdown'}
                                            padding={'12.5px 16px'}
                                            borderRadius={'8px'}
                                            textAlign={'left'}
                                            data={['Male', 'Female']}
                                            // defaultValue={'Gender '}
                                            defaultValue={employeeData.gender.value}
                                            clickHandle={(val) => handleGenderChange(val)}
                                        />
                                    </div>
                                    <FormError show={!employeeData?.gender?.isValid && showError} error={'Please select gender.'} />
                                </div>
                                {Auth?.boolUserType() !== 3 && <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > Date of Joining* </label>
                                    {/* <div className="dob-wrap d-flex align-center">
                                        <div className={`bob_para text-xs w-400 ${employeeData?.date_of_joining?.value ? "base" : "secondary"} `}>{employeeData?.date_of_joining?.value ? dateFormat(employeeData?.date_of_joining?.value) : " "}</div>
                                        <input type="date" name="date_of_joining"
                                            // onChange={(e) => setJoiningDate(e.target.value)}
                                            // min="2018-01-01" max="2018-12-31"
                                            max={todayDate}
                                            value={employeeData?.date_of_joining?.value}
                                            onChange={handleInput}
                                            onKeyUp={handleInput}
                                        />
                                    </div> */}
                                    <div className="datePickerWrap">
                                        <InputDatePicker
                                            name="date_of_joining"
                                            type="date"
                                            // maxDate={Years18AgoDate}
                                            maxDate={moment().toDate()}
                                            dateFormat="dd-MM-yyyy"
                                            onSelect={(e) => {
                                                handleDateInput(e, 'date_of_joining')
                                            }}
                                            placeholder="DD-MM-YYYY"
                                            value={employeeData?.date_of_joining?.value}
                                            className={'date-picker-auth'}
                                        />
                                    </div>
                                    <FormError show={!employeeData?.date_of_joining?.isValid && showError} error={'Please select date of joining.'} />
                                </div>}

                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > E-mail* </label>
                                    <input type="text"
                                        name='email'
                                        value={employeeData?.email?.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                        disabled={Auth?.boolUserType() == 3}

                                    />
                                    <FormError show={!employeeData?.email?.isValid && showError || personalDetails?.employee_add?.error_msg?.length > 0}
                                        error={!employeeData?.email?.isValid && showError ? 'Please enter valid email.' : personalDetails?.employee_add?.error_msg} />

                                </div>
                                <div className="form-field ">
                                    <div className="mobile-no-wrap">
                                        <label htmlFor="" className='mb-3 d-block text-xxs'  > Phone no* </label>
                                        {/* <MobileInput /> */}
                                        <PhoneInput
                                            countryCodeEditable={false}
                                            containerClass="custom-form-container"
                                            inputClass="custom-form-input"
                                            specialLabel
                                            value={`${employeeData._phone.country_code} ${employeeData._phone.value}`}
                                            onChange={(value, formattedValue, nameValue) => {
                                                handlePhoneInput(value, formattedValue, nameValue);
                                            }}
                                            onKeyUp={(value, formattedValue, nameValue) => {
                                                handlePhoneInput(value, formattedValue, nameValue);
                                            }}
                                            onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                            preserveOrder={['onlyCountries', 'preferredCountries']}
                                            inputProps={{
                                                name: "_phone",
                                                autoFocus: false,
                                                placeholder: "Phone Number *",
                                            }}
                                            enableSearch
                                            name="mobileNo"
                                            searchPlaceholder="Search Country"
                                            disableSearchIcon
                                        />
                                        <FormError show={!employeeData?._phone?.isValid && showError}
                                            error={
                                                employeeData?._phone?.value?.length < 9 && employeeData?._phone?.value?.length > 0
                                                    ? 'Please enter correct phone number.'
                                                    : 'Please enter phone number.'
                                            }
                                        />
                                    </div>
                                </div>

                                {
                                    Auth?.boolUserType() === 1 &&
                                    <div className="form-field status-wrap d-flex align-center">
                                        <p htmlFor="" className='d-block text-xs secondary' > Status* </p>
                                        <div className="radio-wrap d-flex align-center">
                                            <RadioInput
                                                name="status"
                                                id='Active'
                                                value={"active"}
                                                className={"mr-5"}
                                                label={"Active"}
                                                LabelClass={"text-xs base"}
                                                defaultChecked={employeeData?.status?.value == "active" ? true : false}
                                                onChange={handleInput}
                                                key={employeeData?.status?.value == "active" ? true : false}
                                            />
                                            {console.log(employeeData?.status?.value, "employeeData?.status?.value")}
                                        </div>
                                        <div className="radio-wrap d-flex align-center">

                                            <RadioInput
                                                name="status"
                                                id='Inactive'
                                                value={"inactive"}
                                                className={"mr-5"}
                                                label={"Inactive"}
                                                LabelClass={"text-xs base"}
                                                defaultChecked={employeeData?.status?.value === "inactive" ? true : false}
                                                onChange={handleInput}
                                                key={employeeData?.status?.value === "inactive" ? true : false}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                            {Auth?.boolUserType() !== 3 && <div className="form-control mt-20">
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Native Address</label>
                                    <input type="text"
                                        name='native_address'
                                        value={employeeData?.native_address?.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                    />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Native country</label>
                                    <NativeCountrySelector
                                        //  Defaultvalue={singleEmployee?.native_country} 
                                        Defaultvalue={singleEmployee?.native_country}
                                    />
                                </div>
                                <div className="form-field  ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Native state </label>
                                    <NativeStateSelector Defaultvalue={singleEmployee?.native_state} />
                                </div>
                                <div className="form-field  ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Native city </label>
                                    <NativeCitySelector Defaultvalue={singleEmployee?.native_city !== "null" && singleEmployee?.native_city} />
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Native zip code</label>
                                    <input type="text"
                                        name='native_zip_code'
                                        value={employeeData?.native_zip_code?.value}
                                        onChange={handleInput}
                                        // onKeyUp={handleInput}
                                        // onKeyDown={(e) => handleRegexValidation(e)}
                                        maxLength={6}
                                    />
                                    {regexErrorPersonal === "native_zip_code" && <FormError show={true} error={'Please enter only numeric value without space.'} />}
                                </div>

                            </div>}
                            <div className="form-control mt-20">
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Current Address *</label>
                                    <input type="text"
                                        name='current_address'
                                        value={employeeData?.current_address?.value ? employeeData?.current_address?.value : ''}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                    />
                                    <FormError show={!employeeData?.current_address?.isValid && showError} error={'Please enter current address.'} />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Current country*</label>
                                    <CountrySelector Defaultvalue={singleEmployee?.current_country} />
                                    <FormError show={!employeeData?.current_country?.isValid && showError} error={'Please enter current country.'} />
                                </div>
                                <div className="form-field  ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Current state*</label>
                                    {/* <StateSelector Defaultvalue={singleEmployee?.current_state} /> */}
                                    <StateSelector Defaultvalue={singleEmployee?.current_state} />
                                    {console.log(singleEmployee?.current_state, "sdafdsfg 11111")}
                                    <FormError show={!employeeData?.current_state?.isValid && showError} error={'Please enter current state.'} />
                                </div>
                                <div className="form-field  ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Current city*</label>
                                    <CitySelector Defaultvalue={singleEmployee?.current_city} />
                                    <FormError show={!employeeData?.current_city?.isValid && showError} error={'Please enter current city.'} />
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Zip code *</label>
                                    <input type="text"
                                        name='current_zip_code'
                                        value={employeeData?.current_zip_code?.value}
                                        onChange={handleInput}
                                        // onKeyUp={handleInput}
                                        // onKeyDown={(e) => handleRegexValidation(e)}
                                        maxLength={6}
                                    />

                                    <FormError show={regexErrorPersonal === "current_zip_code" || !employeeData?.current_zip_code?.isValid && showError}
                                        error={regexErrorPersonal === "current_zip_code" ? "Please enter only numeric character without space." : !employeeData?.current_zip_code?.isValid && showError ? 'It should not be less than 5 & more than 6 digits.' : ''}
                                    />
                                    {/* {regexErrorPersonal === "current_zip_code" && <FormError show={true} error={'It should not be less than 5 & more than 6 digits.'} />} */}

                                </div>
                            </div>
                            <div className="save-btn-wrap d-flex mt-40 ">
                                <CommanButton Text={'Save'} ClassName={'save-btn'} onClick={(e) => handlePersonalDetailsBtn(e, inputFile?.current?.files[0])} />
                                <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={() => setActiveEditLocal(e => !e)} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handleDeleteBtn()}
            />}
        </div>
    )
}

export default memo(PersonalDetails)