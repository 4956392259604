import { AccessManagement_Actions } from "../Actions/AccessManagement/actionType"
const initState = {
    loading: false,
    success: false,
    error: false,
    data: [],
    view_access: {
        data: [],
        loading: false,
        success: false,
        error: false,
    },
    updateAccess: {
        data: [],
        loading: false,
        success: false,
        error: false,
    }
}

const accessManagement = (state = initState, { type, payload }) => {
    switch (type) {
        case AccessManagement_Actions.SET_GRANT_ACCESS_LOADING: return {
            ...state,
            loading: true
        }
        case AccessManagement_Actions.SET_GRANT_ACCESS_SUCCESS: return {
            ...state,
            loading: false,
            success: true
        }
        case AccessManagement_Actions.RESET_GRANT_ACCESS_SUCCESS: return {
            ...state,
            loading: false,
            success: false,
            error: false,

        }
        case AccessManagement_Actions.SET_GRANT_ACCESS_ERROR: return {
            ...state,
            loading: false,
            error: true,
            message: payload
        }
        case AccessManagement_Actions.RESET_GRANT_ACCESS_ERROR: return {
            ...state,
            error: false,
            message: ''
        }
        case AccessManagement_Actions.SET_LIST_ACCESS_USERS_LOADING: return {
            ...state,
            loading: true
        }
        case AccessManagement_Actions.SET_LIST_ACCESS_USERS_SUCCESS: return {
            ...state,
            loading: false,
            data: payload
        }
        case AccessManagement_Actions.GET_ACCESS_DETAILS_SUCCESS: return {
            ...state,
            view_access: {
                ...state?.view_access,
                success: true,
                data: payload,
            }
        }
        case AccessManagement_Actions.RESET_ACCESS_DETAILS_SUCCESS: return {
            ...state,
            view_access: {
                ...state?.view_access,
                success: true,
                data: [],
            }
        }
        case AccessManagement_Actions.UPDATE_ACCESS_DETAILS_SUCCESS: return {
            ...state,
            updateAccess: {
                ...state?.updateAccess,
                success: true,
            }
        }
        case AccessManagement_Actions.UPDATE_ACCESS_DETAILS_RESET: return {
            ...state,
            updateAccess: {
                ...state?.updateAccess,
                success: false,
            }
        }
        default: return state
    }
}

export default accessManagement