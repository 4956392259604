import React, { useState } from 'react'
import Dashboard from '../DashBoard'
import './faq.scss'
import data from './data.json'
import Auth from '../../Classes/Auth'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
const FAQ = () => {
    const [activeFaq, setActiveFaq] = useState(0)
    const onclickHandle = (val) => {
        setActiveFaq(val === activeFaq ? -1 : val)
    }
    return (
        <>
            <div className="main-faq-container">
                <SectionHeading ClassName={'mb-20'}>
                    FAQ
                </SectionHeading>
                {data.map((val, key) => {
                    return (
                        <div className={`faq_item-wrap ${activeFaq === key ? "active" : ''}`} key={key}>
                            <div onClick={() => onclickHandle(key)} className="faq-heading-wrap d-flex pointer align-center justify-between" >
                                <h5 className="faq-heading">
                                    {val.title}
                                </h5>
                                <i className="icon-toggle"></i>
                            </div>
                            <div div className={`faq-content-wrap ${activeFaq === key ? "active" : ''}`} >
                                {val.desc1 && <p>{val.desc1}</p>}
                                {val.desc2 && <p>{val.desc2}</p>}
                                {val.desc3 && <p>{val.desc3}</p>}
                                {val.List1 && <li className='mt-10'>{val.List1}</li>}
                                {val.List2 && <li>{val.List2}</li>}
                                {val.List3 && <li>{val.List3}</li>}
                            </div>

                        </div>
                    )
                })}
            </div>
        </ >
    )
}

export default FAQ