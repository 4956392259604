import React, { useEffect, useRef, useState } from 'react'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import './dropdownSearch.scss'
const DropdownSearch = ({ defaultValue, btnClassName, className, key, clickHandle, data, disableList = [], ObjectKey = false, ...props }) => {
    const ref = useRef(null);
    const [activeDrop, setActiveDrop] = useDetectOutsideClick(ref, false)
    const [value, setValue] = useState(defaultValue ? defaultValue : 'Select *')
    const [searchValue, setSearchValue] = useState('')
    const [dataList, setDataList] = useState(
        data
    )

    const listHandle = (val) => {
        ObjectKey !== false ? setValue(val[ObjectKey]) : setValue(val)
        setActiveDrop((e) => !e)
        clickHandle && clickHandle(val)
        setSearchValue('')
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        // const filteredData = data.filter(item => item.toString().toLowerCase().includes(e.target.value.toLowerCase()));
        const filteredData = data.filter(item => {
            if (typeof item === 'string') {
                return item.toLowerCase().includes(e.target.value.toLowerCase());
            } else if (ObjectKey && typeof item === 'object' && item !== null) {
                return item[ObjectKey]?.toString()?.toLowerCase()?.includes(e.target.value.toLowerCase());
            }
            return false;
        });

        setDataList(filteredData);
    }

    useEffect(() => {
        Array.isArray(data) && typeof data[0] === 'object' && data[0] !== null ?
            setDataList(data.map((e) => e)) : setDataList(data)
    }, [data])


    // Check if data is an array of objects
    // if (Array.isArray(data) && typeof data[0] === 'object' && data[0] !== null) {
    //     console.log(Array.isArray(data) && typeof data[0] === 'object' && data[0] !== null ? data.map((e) => e) : '', "Data is an array of objects:", data);
    // } else {
    //     console.log(Array.isArray(data) && typeof data[0] === 'object' && data[0] !== null ? data.map((e) => e) : '', "Data is not an array of objects:", data);
    // }

    return (
        <div className={`select-Searchdropdown-wrapper ${className} `} ref={ref} key={key}>
            <button className={`drop-btn ${btnClassName} ${defaultValue ? defaultValue === value ? 'secondary' : 'base' : ''}`} onClick={() => setActiveDrop(!activeDrop)} type='button'>
                {value}
                <i className={`${activeDrop ? 'active-icon' : ''} icon-arrow`}></i>
            </button>
            {activeDrop &&
                <div className='search-list-main-wrapper'>
                    <div className="search-input-wrap">
                        <input type="search" name="" id="" className='search-input'
                            value={searchValue}
                            onChange={(e) => handleSearch(e)}
                            onKeyDown={(e) => handleSearch(e)}
                        />
                    </div>
                    <ul className='list-wrap'>
                        {
                            dataList.map((val, key) => {
                                return (
                                    <li className={`${disableList.includes(val) && 'disabled'} ${value == val && "active"} `}
                                        key={key} onClick={() => { listHandle(val); }}>
                                        {
                                            ObjectKey !== false ? val[ObjectKey] : val
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

export default DropdownSearch