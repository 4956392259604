import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const usePDFViewer = () => {
    const [file, setFile] = useState(null);
    const [pageImage, setPageImage] = useState(null);
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
    };

    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const pdfData = new Uint8Array(reader.result);
                pdfjs.getDocument({ data: pdfData }).promise.then((pdf) => {
                    pdf.getPage(1).then((page) => {
                        const viewport = page.getViewport({ scale: 1.0 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };

                        page.render(renderContext).promise.then(() => {
                            const imageData = canvas.toDataURL('image/jpeg');
                            setPageImage(imageData);
                        });
                    });
                });
            };
            reader.readAsArrayBuffer(file);
        }
    }, [file]);

    return null
    // const onDocumentLoadSuccess = ({ numPages }) => {
    // setNumPages(numPages);
    // };

    // return (
    //     <div>
    //         <h2>Upload Document</h2>
    //         <input type="file" onChange={handleFileChange} accept=".pdf" />

    //         {file && (
    //             <div>
    //                 <h3>Preview:</h3>
    //                 {pageImage && <img src={pageImage}
    //                     alt="Document Preview"
    //                     style={{ maxWidth: '100%' }}
    //                 />}
    //                 {/* <Document file={file} onLoadSuccess={onDocumentLoadSuccess}> */}
    //                 {/* <Page pageNumber={pageNumber} /> */}
    //                 {/* </Document> */}
    //                 {/* <p>Page {pageNumber} of {numPages}</p> */}
    //             </div>
    //         )}
    //     </div>
    // );
};

export default usePDFViewer;
