import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ValidationFile from '../../Classes/ValidationFile'
import { useDispatch, useSelector } from 'react-redux'
import { createEmployee, createEmployeeReset, deleteEmployee, getSingleEmployee, hideError, resetDelete, setActiveTab, updateEmployee, updateSingleEmployeeReset } from '../../store/Actions/PersonalDetails'
import { resetCsc, setCountry } from '../../store/Actions/CountryStateCity'
import Auth from '../../Classes/Auth'
import Validation from '../../Classes/Validation'
import RegexValid from '../../Classes/RegexValid'
import { formatDateDatePicker } from '../dateFormat'
import { compressFile } from '../../HelperFunction/FileCompressor'

const useEmployeePersonalDetails = () => {
    const userId = Auth?.user()?.user_id // this is for added by user
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const formRef = useRef(null)
    const countryStateCity = useSelector((state) => state.countryStateCity)
    const personalDetails = useSelector((state) => state.personalDetails)
    const employeeManagement = useSelector((state) => state.employeeManagement)
    const [dobValue, setDobValue] = useState(null)
    const [joiningDate, setJoiningDate] = useState(null)
    const [activeEdit, setActiveEdit] = useState(false)
    const [type, setType] = useState('')
    const { employee_ID } = useParams()
    const location = useLocation();
    const [showError, setShowError] = useState(false);
    const validation = new Validation()
    const singleEmployee = personalDetails?.single_employee?.data
    const [currentID, setCurrentID] = useState(null)
    const [client_id, setClient_id] = useState(null)
    const [regexErrorPersonal, setRegexErrorPersonal] = useState('')
    const validReg = new RegexValid()
    const inputFile = useRef(null)
    const [arrowBtn, setArrowBtn] = useState({
        prev: false,
        next: false
    })
    // Add employee state
    const [employeeData, setEmployeeData] = useState({
        f_name: {
            value: "",
            isValid: false,
        },
        l_name: {
            value: "",
            isValid: false,
        },
        dob: {
            value: "",
            isValid: false,
        },
        gender: {
            value: "Gender *",
            isValid: false,
        },
        date_of_joining: {
            value: "",
            isValid: false,
        },
        email: {
            value: "",
            isValid: false,
        },
        _phone: {
            value: "",
            country_code: "+971",
            isValid: false,
        },
        native_address: {
            value: "",
            isValid: true,
        },
        native_country: {
            value: "",
            isValid: true,
        },
        native_state: {
            value: "",
            isValid: true,
        },
        native_city: {
            value: "",
            isValid: true,
        },
        native_zip_code: {
            value: "",
            isValid: true,
        },
        current_address: {
            value: "",
            isValid: false,
        },
        current_country: {
            value: "",
            isValid: false,
        },
        current_state: {
            value: "",
            isValid: false,
        },
        current_city: {
            value: "",
            isValid: false,
        },
        current_zip_code: {
            value: "",
            isValid: false,
        },
        employee_image: {
            value: "",
            isValid: true
        },
        status: {
            value: "active",
            isValid: true
        },
        validation: false,
    });

    const searchParams = new URLSearchParams(location.search);
    const client_id_param = searchParams.get('client_id')

    useEffect(() => {
        if (employee_ID.includes("id=") || employeeManagement?.employee_type == 'old') {
            setType("old")
            const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
            client_id_param !== "undefined" && dispatch(getSingleEmployee(data))
            setCurrentID(data)

        } else {
            setType("new")
            setActiveEdit(true)
        }
        if (personalDetails?.update) {
            const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
            client_id_param !== "undefined" && dispatch(getSingleEmployee(data))
        }

        const searchParams = new URLSearchParams(location.search);
        setClient_id(searchParams.get('client_id'))

    }, [employeeManagement?.employee_type, employee_ID, personalDetails?.update, currentID])

    // useEffect(() => {
    //     if (personalDetails?.update) {
    //         const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
    //         dispatch(getSingleEmployee(data))
    //     }
    // }, [personalDetails?.update])

    // useEffect(() => {
    //     if (employee_ID.includes("id=")) {
    //         setType("old")
    //         const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
    //         dispatch(getSingleEmployee(data))

    //     } else {
    //         setType("new")
    //         setActiveEdit(true)
    //     }
    // }, [employee_ID])


    const editBtnHandler = () => {
        setActiveEdit(e => !e)
    }

    // check input vafildation

    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "f_name": return ValidationFile.validEmpty(inputValue);
            case "l_name": return ValidationFile.validEmpty(inputValue);
            case "dob": return ValidationFile.validEmpty(inputValue);
            case "gender": return inputValue !== "Gender *"
            case "date_of_joining": return ValidationFile.validEmpty(inputValue);
            case "email": return ValidationFile.validEmail(inputValue);
            case "_phone":
                return ValidationFile.validPhoneNumber(inputValue);
            // var pattern = new RegExp(/[0-9]{10,}/)
            //     if (pattern.test(inputValue)) {
            //         return true
            //     }
            //     else {
            //         return false
            //     }
            case "native_address": return true;
            case "native_country": return true;
            case "native_state": return true;
            case "native_city": return true;
            case "native_zip_code": return true;
            case "current_address": return ValidationFile.validEmpty(inputValue);
            case "current_country": return ValidationFile.validEmpty(inputValue);
            case "current_state": return ValidationFile.validEmpty(inputValue);
            case "current_city": return ValidationFile.validEmpty(inputValue);
            // case "current_zip_code": return ValidationFile.validEmpty(inputValue);
            case "current_zip_code": return validation.minLength(inputValue, 5)

            default:
                return false;
        }
    };

    // check form is validation 
    const isFormValid = () => {
        return (
            employeeData?.f_name?.isValid &&
                employeeData?.l_name?.isValid &&
                employeeData?.dob?.isValid &&
                employeeData?.gender?.isValid &&
                employeeData?.date_of_joining?.isValid &&
                employeeData?.email?.isValid &&
                employeeData?._phone?.isValid &&
                employeeData?.native_address?.isValid &&
                employeeData?.native_country?.isValid &&
                employeeData?.native_state?.isValid &&
                employeeData?.native_city?.isValid &&
                employeeData?.native_zip_code?.isValid &&
                employeeData?.current_address?.isValid &&
                employeeData?.current_country?.isValid &&
                employeeData?.current_state?.isValid &&
                employeeData?.current_city?.isValid &&
                employeeData?.current_zip_code?.isValid ? true : false
        )
    };
    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName == "f_name" || inputName == "l_name") {
            // Capitalize the first letter of the input value
            inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        }
        let data = {
            ...employeeData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        // setEmployeeData(data);

        switch (inputName) {
            case "f_name":
            case "l_name":
                if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
                    return setEmployeeData(data), setRegexErrorPersonal('');
                } else {
                    return setRegexErrorPersonal(inputName);
                }
            case "current_zip_code":
            case "native_zip_code":
                if (validReg.acceptNums(inputValue) || inputValue === '') {
                    return setEmployeeData(data), setRegexErrorPersonal('');
                } else {
                    return setRegexErrorPersonal(inputName);
                }
            default:
                return setEmployeeData(data);
        }

    }

    const handleDateInput = (date, inputName) => {
        let DateInfo = {
            ...employeeData,
            [inputName]: {
                value: date,
                isValid: date ? true : false,
            },
            validation: isFormValid(),
        };
        setEmployeeData(DateInfo);
        // console.log(date, "Asdfdfgfv")
    }


    // gender change handler
    const handleGenderChange = (val) => {
        let setGender = {
            ...employeeData,
            "gender": {
                value: val,
                isValid: validationConfirm(val, "gender"),
            },
            validation: isFormValid(),
        }
        setEmployeeData(setGender)
    }

    // handle phone input
    const handlePhoneInput = (inputValue, countryDetail, nameValue) => {
        let dialCode = countryDetail.dialCode;
        let mobileNumber = inputValue.replace(dialCode, "");
        let mobileData = {
            ...employeeData,
            "_phone": {
                value: mobileNumber,
                country_code: dialCode,
                isValid: validationConfirm(mobileNumber, "_phone"),
                // isValid: true,
            },
            validation: isFormValid(),
        }
        setEmployeeData(mobileData)
    }
    // effect for get the value of countryStateCity  
    useEffect(() => {
        let setAddress = {
            ...employeeData,
            "native_country": {
                value: countryStateCity?.native_address?.n_country?.name,
                isValid: validationConfirm(countryStateCity?.country?.name, "native_country"),
            },
            "native_state": {
                value: countryStateCity?.native_address?.n_states?.name,
                isValid: validationConfirm(countryStateCity?.states?.name, "native_state"),
            },
            "native_city": {
                value: countryStateCity?.native_address?.n_city?.name,
                isValid: validationConfirm(countryStateCity?.city?.name, "native_city"),
            },
            "current_country": {
                value: countryStateCity?.country?.name,
                isValid: validationConfirm(countryStateCity?.country?.name, "current_country"),
            },
            "current_state": {
                value: countryStateCity?.states?.name,
                isValid: validationConfirm(countryStateCity?.states?.name, "current_state"),
            },
            "current_city": {
                value: countryStateCity?.city?.name,
                isValid: validationConfirm(countryStateCity?.city?.name, "current_city"),
            },
        }
        setEmployeeData(setAddress)
    }, [countryStateCity])


    // SUbmit Btn Handler
    const handlePersonalDetailsBtn = async (e, imgData) => {
        console.log(employeeData, 'line no255')
        e.preventDefault()

        // Create a FormData object
        const formData = new FormData();
        const data = {
            "first_name": employeeData.f_name.value,
            "last_name": employeeData.l_name.value,
            "date_of_birth": formatDateDatePicker(employeeData.dob.value),
            "gender": employeeData.gender.value,
            "date_of_joining": formatDateDatePicker(employeeData.date_of_joining.value),
            "e_mail": employeeData.email.value,
            "mobile": `${employeeData._phone.country_code} ${employeeData._phone.value}`,
            "native_address": employeeData.native_address.value,
            "native_country": employeeData.native_country.value,
            "native_state": employeeData.native_state.value,
            "native_city": employeeData.native_city.value,
            "native_zip_code": employeeData.native_zip_code.value,
            "current_address": employeeData.current_address.value,
            "current_country": employeeData.current_country.value,
            "current_state": employeeData.current_state.value,
            "current_city": employeeData.current_city.value,
            "current_zip_code": employeeData.current_zip_code.value,
            "added_by_user_id": userId,
            "status": employeeData.status.value,
            // "client_id": employeeManagement?.client?.id
        }


        // Append employee data to the FormData object using the spread operator
        Object.entries(data).forEach(([key, value]) => {
            if (key === "date_of_joining" && Auth?.boolUserType() == 3) {
                return;
            }
            formData.append(key, value);
        });

        // Append employee image to the FormData object
        // imgData && formData.append("employee_image", imgData ? imgData : ""); // Assuming imgData is the image data (file object or base64 encoded string)

        if (imgData) {
            try {
                const compressedFile = await compressFile(imgData); // Wait for the compressed file
                formData.append("employee_image", compressedFile, imgData.name); // Append the compressed file 
            } catch (error) {
                console.error("Error compressing file:", error);
            }
        }





        (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) && formData.append("client_id", client_id); // Assuming imgData is the image data (file object or base64 encoded string)


        // console.log(employeeManagement.employee_type, 'erwr', type, 'wfwdfefewf')
        if (isFormValid()) {
            // if (employeeManagement.employee_type == 'new' && type == 'new') {
            if (type == 'new') {
                dispatch(createEmployee(formData))
            } else {
                dispatch(updateEmployee(singleEmployee.employee_id, formData))
            }
        } else {
            setShowError(true);
        }
    }

    //function for  reset form
    const resetEmployeeData = () => {
        setEmployeeData({
            f_name: {
                value: "",
                isValid: false,
            },
            l_name: {
                value: "",
                isValid: false,
            },
            dob: {
                value: "",
                isValid: false,
            },
            gender: {
                value: "Gender *",
                isValid: false,
            },
            date_of_joining: {
                value: "",
                isValid: false,
            },
            email: {
                value: "",
                isValid: false,
            },
            _phone: {
                value: "+971",
                country_code: "",
                isValid: false,
            },
            native_address: {
                value: "",
                isValid: true,
            },
            native_country: {
                value: "",
                isValid: true,
            },
            native_state: {
                value: "",
                isValid: true,
            },
            native_city: {
                value: "",
                isValid: true,
            },
            native_zip_code: {
                value: "",
                isValid: true,
            },
            current_address: {
                value: "",
                isValid: false,
            },
            current_country: {
                value: "",
                isValid: false,
            },
            current_state: {
                value: "",
                isValid: false,
            },
            current_city: {
                value: "",
                isValid: false,
            },
            current_zip_code: {
                value: "",
                isValid: false,
            },
            status: {
                value: "active",
                isValid: true
            },
            validation: false,
        })
    }

    //Effect for  reset form , hide error , 
    useLayoutEffect(() => {
        if (personalDetails?.employee_add?.success) {
            resetEmployeeData()
            setShowError(false);
            dispatch(hideError())
            dispatch(resetCsc())
            // navigate to new employee
            navigate('/employees-management')
            dispatch(createEmployeeReset())
        }
        dispatch(updateSingleEmployeeReset())

        if (personalDetails?.update) {
            window.scrollTo(0, 0);
        }

    }, [personalDetails?.employee_add?.success, personalDetails?.update,])

    useEffect(() => {
        dispatch(hideError())
    }, [])
    //Effect for fetch existing employee details in input form
    useEffect(() => {
        if (employee_ID.includes("id=")) {
            const mobileNo = singleEmployee?.mobile?.split(" ")
            setEmployeeData(
                {
                    f_name: {
                        value: singleEmployee?.first_name,
                        isValid: singleEmployee?.first_name ? true : false,
                    },
                    l_name: {
                        value: singleEmployee?.last_name,
                        isValid: singleEmployee?.last_name ? true : false,
                    },
                    dob: {
                        value: singleEmployee?.date_of_birth,
                        isValid: singleEmployee?.date_of_birth ? true : false,
                    },
                    gender: {
                        value: singleEmployee?.gender,
                        isValid: singleEmployee?.gender ? true : false,
                    },
                    date_of_joining: {
                        value: singleEmployee?.date_of_joining,
                        isValid: true,
                    },
                    email: {
                        value: singleEmployee?.e_mail,
                        isValid: singleEmployee?.e_mail ? true : false,
                    },
                    _phone: {
                        value: mobileNo ? mobileNo[1] : '',
                        country_code: mobileNo ? mobileNo[0] : '',
                        isValid: true,
                    },
                    native_address: {
                        value: singleEmployee?.native_address != null ? singleEmployee?.native_address : '',
                        isValid: true
                    },
                    native_country: {
                        value: singleEmployee?.native_country,
                        isValid: true,
                    },
                    native_state: {
                        value: singleEmployee?.native_state,
                        isValid: true,
                    },
                    native_city: {
                        value: singleEmployee?.native_city,
                        isValid: true,
                    },
                    native_zip_code: {
                        value: singleEmployee?.native_zip_code != null ? singleEmployee?.native_zip_code : '',
                        isValid: true,
                    },
                    current_address: {
                        value: singleEmployee?.current_address,
                        isValid: singleEmployee?.current_address ? true : false,
                    },
                    current_country: {
                        value: singleEmployee?.current_country,
                        isValid: singleEmployee?.current_country ? true : false,
                    },
                    current_state: {
                        value: singleEmployee?.current_state,
                        isValid: singleEmployee?.current_state ? true : false,
                    },
                    current_city: {
                        value: singleEmployee?.current_city,
                        isValid: singleEmployee?.current_state ? true : false,
                    },
                    current_zip_code: {
                        value: singleEmployee?.current_zip_code,
                        isValid: singleEmployee?.current_zip_code ? true : false,
                    },
                    status: {
                        value: singleEmployee?.status,
                        isValid: true
                    },
                    validation: true,
                }
            )
        }
    }, [singleEmployee])

    console.log(employeeData, "sdfdsgdfg")

    // useEffect(() => {
    //     if (personalDetails?.update) {
    //         const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
    //         dispatch(getSingleEmployee(data))
    //     }
    // }, [personalDetails?.update])


    // Preview AND NEXT  Btn handler
    const currentEmployee = employeeManagement?.data?.find(employee => employee?.employee_id == currentID);
    const index = employeeManagement.data.findIndex(employee => employee?.employee_id === currentEmployee?.employee_id);
    const arrowBtnHandler = (direction) => {
        if (currentEmployee) {
            console.log("Desired Employee:", currentEmployee);

            // Find the index of the currentEmployee
            console.log(index, "fgdg")
            if (direction === "prev") {
                if (index > 0) {
                    const previousEmployee = employeeManagement.data[index - 1].employee_id;
                    navigate(`/employees-management/id=${previousEmployee}`)
                } else {
                    console.log("No previous employee found.");
                }
            } else if (direction === "next") {
                if (index < employeeManagement.data.length - 1) {
                    const nextEmployee = employeeManagement.data[index + 1].employee_id;
                    navigate(`/employees-management/id=${nextEmployee}`)
                } else {
                    console.log("No next employee found.");
                }
            }
        }
    }

    useLayoutEffect(() => {
        if (index == 0) {
            setArrowBtn({ next: false, prev: true })
        } else if (index < employeeManagement.data.length - 1) {
            setArrowBtn({ prev: false, next: false })
        }
        else if (index == employeeManagement.data.length - 1) {
            setArrowBtn({ prev: false, next: true })
        }
    }, [currentEmployee])


    useEffect(() => {
        if (employeeManagement.employee_type == 'new') {
            resetEmployeeData()
        }
    }, [employeeManagement.employee_type])

    console.log(countryStateCity, "sdafdsfg")
    return {
        activeEdit, editBtnHandler, setActiveEdit, handlePersonalDetailsBtn,
        dobValue, setDobValue, type, setType, joiningDate, setJoiningDate,
        employeeData, handleInput, handleGenderChange, handlePhoneInput, showError,
        formRef, resetEmployeeData, personalDetails, singleEmployee, employeeManagement, arrowBtnHandler,
        arrowBtn, regexErrorPersonal, handleDateInput, inputFile
    }
}


export default useEmployeePersonalDetails 
