import { Country_State_city } from '../Actions/CountryStateCity/actionType'
const initState = {
    country: '',
    states: '',
    city: '',
    native_address: {
        n_country: '',
        n_states: '',
        n_city: '',
    },
}

const countryStateCity = (state = initState, action) => {
    switch (action.type) {
        case Country_State_city.SET_COUNTRY: return {
            ...state,
            country: action.payload
        }
        case Country_State_city.SET_STATE: return {
            ...state,
            states: action.payload
        }
        case Country_State_city.SET_CITY: return {
            ...state,
            city: action.payload
        }
        case Country_State_city.RESET_CSC: return {
            ...state,
            country: '',
            states: '',
            city: ''
        }
        // native reducer
        case Country_State_city.SET_NATIVE_COUNTRY: return {
            ...state,
            native_address: {
                ...state?.native_address,
                n_country: action.payload
            }
        }
        case Country_State_city.SET_NATIVE_STATE: return {
            ...state,
            native_address: {
                ...state?.native_address,
                n_states: action.payload
            }

        }
        case Country_State_city.SET_NATIVE_CITY: return {
            ...state,
            native_address: {
                ...state?.native_address,
                n_city: action.payload
            }
        }
        case Country_State_city.RESET_NATIVE_CSC: return {
            ...state,
            native_address: {
                n_country: '',
                n_states: '',
                n_city: '',
            }
        }

        default: return state
    }
}

export default countryStateCity