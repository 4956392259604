import RemindersAction from "../Actions/Reminders/actionType"

const initState = {
    data: [],
}

const reminders = (state = initState, { type, payload }) => {
    switch (type) {
        case RemindersAction.REMINDER_LIST_SET: return {
            ...state,
            data: payload
        }
        case RemindersAction.REMINDER_LIST_RESET: return {
            ...state,
            data: []
        }
        default: return state
    }
}

export default reminders