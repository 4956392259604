import PersonalDetailsAction from "../Actions/PersonalDetails/actionType"

const initState = {
    employee_add: {
        success: false,
        error_msg: ""
    },
    single_employee: {
        data: []
    },
    update: false,
    delete: false,
    activeTab: 'Personal'
}

const personalDetails = (state = initState, action) => {
    switch (action.type) {
        case PersonalDetailsAction.CREATE_EMPLOYEE: return {
            ...state,
            employee_add: {
                ...state.employee_add,
                success: true
            }
        }
        case PersonalDetailsAction.CREATE_EMPLOYEE_RESET: return {
            ...state,
            employee_add: {
                ...state.employee_add,
                success: false
            }
        }
        case PersonalDetailsAction.SET_ERROR: return {
            ...state,
            employee_add: {
                ...state.employee_add,
                success: false,
                error_msg: action.payload
            }
        }
        case PersonalDetailsAction.HIDE_ERROR: return {
            ...state,
            employee_add: {
                ...state.employee_add,
                error_msg: ""
            }
        }
        case PersonalDetailsAction.GET_SINGLE_EMPLOYEE: return {
            ...state,
            single_employee: {
                data: action.payload
            }
        }
        case PersonalDetailsAction.SET_SINGLE_EMPLOYEE_RESET: return {
            ...state,
            single_employee: {
                data: []
            }
        }
        case PersonalDetailsAction.UPDATE_SINGLE_EMPLOYEE: return {
            ...state,
            update: true
        }
        case PersonalDetailsAction.UPDATE_SINGLE_EMPLOYEE_RESET: return {
            ...state,
            update: false
        }
        case PersonalDetailsAction.DELETE_EMPLOYEE_SUCCESS: return {
            ...state,
            delete: true
        }
        case PersonalDetailsAction.RESET_DELETE: return {
            ...state,
            delete: false
        }
        case PersonalDetailsAction.SET_ACTIVE_TAB: return {
            ...state,
            activeTab: action.payload
        }

        default: return state
    }
}

export default personalDetails