import React, { useRef, useState } from 'react'
import SectionHeading from '../../../CommanComponents/SectionHeading/SectionHeading'
import SearchControl from '../../../CommanComponents/SearchControl/SearchControl'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton'
import PaginationSimple from '../../../CommanComponents/PaginationSimple/PaginationSimple'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useDetectOutsideClick from '../../../CustomHooks/useDetectOutsideClick'
import './userDetail.scss';
import usePageListData from '../../../CustomHooks/usePageListData'
import useUserDetails from '../../../CustomHooks/Users/useUserDetails'
import { clientSearch, setFetchType } from '../../../store/Actions/Clients'
import Auth from '../../../Classes/Auth'
import useClients from '../../../CustomHooks/Clients/useClients'
import DataNotFound from '../../../CommanComponents/DataNotFound/DataNotFound'

const UserDetail = () => {
    const user = useSelector((e) => e.user)
    const ref = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeManage, setActiveManage] = useDetectOutsideClick(ref, false)
    const [searchVal, setSearchVal] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const userId = Auth?.user()?.user_id // this is for added by user 
    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }
    const handleResetSearch = () => {
        setSearchVal('')
    }
    const handleManageBtn = (e, val) => {
        e.stopPropagation()
        setActiveManage(activeManage === val ? -1 : val)
    }


    const EmployeeClickHandle = (val) => {
        navigate(`/clients-management`)
        // dispatch(clientSearch(userId, val))
        dispatch(setFetchType(val))
    }
    const dataForCurrentPage = usePageListData(user?.user_client_list?.data, pageNumber)

    const deleteClickHandler = (id) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }

    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }

    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeletePop(false);
        deleteUserHandler(deleteId)
    }

    const { editBtnHandler, breadcrumb, setBreadcrumb } = useUserDetails()

    const deleteUserHandler = () => {

    }
    return (
        <>
            <div className="employees-management-wrapper user-details-wrapper">
                <div className="employees-management-wrapper">
                    <div className="heading-search-item justify-between align-center">
                        <SectionHeading ClassName={"d-flex align-center justify-center"}>
                            <p onClick={() => navigate('/user')}>User Details  {`> ${breadcrumb}`}</p>
                        </SectionHeading>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                        </div>

                        <PaginationSimple
                            className={"document-pagenation"}
                            data={user?.user_client_list?.data}
                            shows={user?.user_client_list?.data?.length < 20 ? user?.user_client_list?.data?.length : "20"}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />
                    </div>
                    <div className="employees-management-table-wrapper mt-10">
                        {dataForCurrentPage?.length ?
                            <div className="table-wrap">
                                <table className="table-item">
                                    <thead className='table-head'>
                                        <tr className="table-row">
                                            <th className=''>Client ID</th>
                                            <th className=''>Client Name</th>
                                            <th className=''>Email</th>
                                            <th className=''>Employee</th>
                                            <th className=''>Action</th>
                                        </tr>
                                    </thead>


                                    <tbody className='table-body'>
                                        {dataForCurrentPage?.map((val, key) => {
                                            return (
                                                <tr className="table-row" key={key} onClick={() => EmployeeClickHandle(val.business_name)} >
                                                    <td > {val?.id}  </td>
                                                    <td > {val?.business_name} </td>
                                                    <td > {val?.email} </td>
                                                    <td> {val?.employee_count} </td>
                                                    <td onClick={(e) => e.stopPropagation()}>
                                                        <div className='dots-wrap d-flex justify-center align-center relative' onClick={(e) => { handleManageBtn(e, key) }} >
                                                            <i className='dots-icon' > </i>
                                                        </div>
                                                        {<div className={`dropdown-wrap absolute ${activeManage === key && 'active'}`} ref={ref}>
                                                            {/* <div className="item d-flex align-center mb-10 pointer "  >
                                                                <i className='icons mr-5 eye '></i>
                                                                <p className="text-2xs w-500 secondary">View</p>
                                                            </div> */}
                                                            <div className="item d-flex align-center pointer mb-10"
                                                                onClick={() => editBtnHandler(val?.id)}
                                                            >
                                                                <i className='icons mr-5 edit'></i>
                                                                <p className="text-2xs w-500 secondary" >Edit</p>
                                                            </div>
                                                            <div className="item d-flex align-center pointer"
                                                                onClick={() => deleteClickHandler(val?.id)}
                                                            >
                                                                <i className='icons mr-5 delete'></i>
                                                                <p className="text-2xs w-500 secondary"

                                                                >Delete</p>
                                                            </div>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                            :
                            <DataNotFound />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDetail