import Request from "../../../Classes/Requests";

class documentUpload extends Request {
    constructor() {
        super()
        this.endpoint = {
            passport_document: super.url("/passport_document"),
            upload_employee_document: super.url("/upload_employee_document"),
            passport_document_view: super.url("/passport_document_view"),
            uploaded_document_delete: super.url("/uploaded_document_delete"),
            edit_employee_passport_document: super.url("/edit_employee_passport_document"),
            passport_fields_delete: super.url("/passport_fields_delete"),
            delete_employee_passport_document: super.url("/delete_employee_passport_document"),
            emiratesID_document: super.url("/emiratesID_document"),
            list_of_all_documents: super.url("/list_of_all_documents"),
            EmiratesID_view: super.url("/EmiratesID_view"),
            EmiratesID_fields_delete: super.url("/EmiratesID_fields_delete"),
            edit_employee_emiratesID_document: super.url("/edit_employee_emiratesID_document"),
            delete_employee_EmiratesID_document: super.url("/delete_employee_EmiratesID_document"),
            extract_passport_info: super.url("/extract-passport-info"),
            get_raw_data: super.url("/get_raw_data"),

        }
    }
}
export default new documentUpload();
