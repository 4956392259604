import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeeList, resetEmployeeList, bulkEmployeeUpload, resetBulkEmployee, resetBulkEmployeeERROR, sendEmployeeCredentials, sendUpdateDocumentEmail } from '../../store/Actions/EmployeeManagement'
import { resetSingleEmployee } from '../../store/Actions/PersonalDetails'
import { resetUplodedDocs } from '../../store/Actions/DocumentUpload'
import { resetReminderList } from '../../store/Actions/Reminders'
import Auth from '../../Classes/Auth'

const useEmployeesManagement = () => {
    const dispatch = useDispatch()
    const employeeManagement = useSelector((state) => state.employeeManagement)
    const personalDetails = useSelector((state) => state.personalDetails)
    const [forceUpdate, setForceUpdate] = useState(false);
    const selectedClient = employeeManagement?.client
    const buldUploadRef = useRef()
    const inputBulkRef = useRef()
    const [bulkFile, setBulkFile] = useState(null)

    const getAllEmployees = () => {
        if (Auth?.boolUserType() == 1 || Auth?.boolUserType() == 3) {
            return dispatch(getEmployeeList(selectedClient?.id))
        } else {
            return selectedClient?.id ? dispatch(getEmployeeList(selectedClient?.id)) : false
        }
    }

    useEffect(() => {
        getAllEmployees()
    }, [personalDetails?.delete, dispatch, forceUpdate, employeeManagement?.bulk_upload])

    useEffect(() => {
        dispatch(resetSingleEmployee())
        dispatch(resetUplodedDocs())
        dispatch(resetReminderList())
        dispatch(resetEmployeeList())
    }, [])

    const openEmployeeBulkPop = () => {
        buldUploadRef.current.open()
        setBulkFile(null);
        // inputBulkRef?.current?.value = ''
    }
    const closeEmployeeBulkPop = () => {
        buldUploadRef.current.close()
        dispatch(resetBulkEmployeeERROR())
    }
    const handleBulkInput = (e) => {
        const selectedFile = e.target.files[0]
        if (selectedFile?.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setBulkFile(selectedFile)
        } else {
            alert('Please upload excel file only.');
        }
    }

    const handleBulkUpload = () => {
        const formData = new FormData();
        formData.append('file', bulkFile);
        dispatch(bulkEmployeeUpload(formData))
    }
    const crossBtnHandler = () => {
        setBulkFile(null)
        dispatch(resetBulkEmployeeERROR())
    }

    const sendCredentials = (employee_id) => {
        dispatch(sendEmployeeCredentials({ "employee_id": employee_id }))
    }
    const updateDocument = (email) => {
        dispatch(sendUpdateDocumentEmail({ "e_mail": email }))
    }

    useEffect(() => {
        if (employeeManagement?.bulk_upload?.success) {
            closeEmployeeBulkPop()
            dispatch(resetBulkEmployee())
        }
    }, [employeeManagement])
    console.log(employeeManagement?.bulk_upload, "axcsdfgfbvc")
    return [employeeManagement, personalDetails, forceUpdate, setForceUpdate, buldUploadRef, openEmployeeBulkPop,
        closeEmployeeBulkPop, handleBulkInput, inputBulkRef,
        bulkFile, handleBulkUpload, setBulkFile, crossBtnHandler, sendCredentials, updateDocument]
}

export { useEmployeesManagement }