import React from 'react'
import './style.scss'
import Logo from '../../../Assets/icons/Logo.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MessageToast from '../../../CommanComponents/MessageToast/MessageToast';
import { setUserType } from '../../../store/Actions/Auth';
import Modals from '../../../CommanComponents/Modals';
import ModalBody from '../../../CommanComponents/Modals/ModalsBody';
const IndustryType = ({ reference, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  // useSeletorExp
  // const userType = useSelector((state) => {
  //   return ({
  //     userType: state.auth.userType
  //   })
  // })
  // console.log(userType)

  const clickHandle = (val) => {
    dispatch(setUserType(val))
    navigate('/create-account')
  }


  return (
    <>
      <Modals
        ref={reference}
        ClosePopUp={onClose}
        slide={"zoom"}
        Position="center"
        ModalsSize={'modal-xs'}
        ClassName={'industry-modal-wrapper'}
      >
        {/* <ModalBody> */}
        <div className="industry-type-wrapper">
          <div className="industry-type-wrap">
            <div className="industry-box">
              <div className="logo-wrap mb-20 text-center">
                <img src={Logo} alt="" />
              </div>
              <p className="subheading text-center text-xs w-500"> Please Select Your Profile</p>
              <div className="industry-item mt-50">
                <button className='select-industry-btn text-ss w-400 white bs' onClick={() => clickHandle("1")}>I am a Business Owner</button>
                <button className='select-industry-btn text-ss w-400 white tc' onClick={() => clickHandle("2")}>I am a Typing / Business Center</button>
                <button className='select-industry-btn text-ss w-400 white ind' onClick={() => clickHandle("3")}>I am an Individual</button>
              </div>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modals>
    </>
  )
}

export default IndustryType