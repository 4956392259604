const SupportAction = {
    SET_CREATE_TICKET_LOADING: "SET_CREATE_TICKET_LOADING",
    SET_CREATE_TICKET_SUCCESS: "SET_CREATE_TICKET_SUCCESS",
    RESET_CREATE_TICKET_SUCCESS: "RESET_CREATE_TICKET_SUCCESS",

    SET_TICKET_LIST_SUCCESS: "SET_TICKET_LIST_SUCCESS",

    SET_TICKET_VIEW_SUCCESS: "SET_TICKET_VIEW_SUCCESS",
    RESET_TICKET_VIEW_SUCCESS: "RESET_TICKET_VIEW_SUCCESS",



}

export default SupportAction