import Cookies from "../../../Classes/Cookies";
import Storage from "../../../Classes/Storage";
import { userType } from "../../../Constant/auth";
import { setCommonError } from "../CommonError";
import { showErrorToast, showSuccessToast } from "../SuccessMessagePopup";
import { Employee_Management_Actions } from "./actionType"
import employeeManagementReq from "./employeeManagementReq";


const getEmployeeList = (id) => {
    return async (dispatch) => {
        dispatch({ type: Employee_Management_Actions.SET_LOADING, })
        employeeManagementReq.get(employeeManagementReq.endpoint.employee_list.replace('__CLIENT_ID__', id), (success) => {
            let payload;
            if (Array.isArray(success.data)) {
                payload = success.data?.map((item, index, array) => {
                    return array[array.length - 1 - index];
                });
            } else if (typeof success.data === 'object') {
                payload = [];
            } else {
                throw new Error("Invalid response format");
            }

            dispatch(
                { type: Employee_Management_Actions.GET_ALL_EMPLOYEE, payload: payload },
            )
        }, (error) => {
            dispatch(setCommonError(error?.message))
            console.log(error, "guddu this is updated line no 12")
        }
        )
    }
}


const setEmployeeType = (type) => {
    return {
        type: Employee_Management_Actions.SET_EMPLOYEE_TYPE,
        payload: type
    }
}
const employeeSearch = (userId, value) => {
    return async (dispatch) => {
        employeeManagementReq.get(employeeManagementReq.endpoint.employee_search.replace('__USER_ID__', userId).replace('__VALUE__', value), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: Employee_Management_Actions.SET_SEARCH, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}


const bulkEmployeeUpload = (data) => {
    return async (dispatch) => {
        dispatch({ type: Employee_Management_Actions.SET_BULK_UPLOAD_LOADING })
        employeeManagementReq.post(employeeManagementReq.endpoint.employee_bulk_upload, data, (success) => {
            dispatch(showSuccessToast('Employees added successfully!'))
            dispatch({ type: Employee_Management_Actions.SET_BULK_UPLOAD_SUCCESS })
        }, (error) => {
            // if (error?.response?.status == 409) {
            // dispatch({ type: Employee_Management_Actions.SHOW_BULK_UPLOAD_ERROR, payload: error.response.data.message ? error.response.data.message : error.response.data.e_mail ? error.response.data.e_mail : error.response.data.error })
            dispatch({ type: Employee_Management_Actions.SHOW_BULK_UPLOAD_ERROR, payload: error.response.data })
            // }
        }
        )
    }
}


const sendEmployeeCredentials = (data) => {
    return async (dispatch) => {
        employeeManagementReq.post(employeeManagementReq.endpoint.send_employee_credentials, data, (success) => {
            dispatch(showSuccessToast(success?.data?.success))
        }, (error) => {
            dispatch(showErrorToast(error?.response?.data?.error))
        }

        )
    }
}
const sendUpdateDocumentEmail = (data) => {
    return async (dispatch) => {
        employeeManagementReq.post(employeeManagementReq.endpoint.sendnotificationemployee, data, (success) => {
            dispatch(showSuccessToast(success?.data?.success))
        }, (error) => {
            dispatch(showErrorToast(error?.response?.data?.error))
        }

        )
    }
}

const resetEmployeeSearch = (id) => {
    return async (dispatch) => {
        // Call getEmployeeList() here
        dispatch(getEmployeeList(id));
    }
}

const setClient = (val) => {
    return {
        type: Employee_Management_Actions.SET_CLIENT,
        payload: val
    }
}
const resetEmployeeList = (val) => {
    return { type: Employee_Management_Actions.RESET_ALL_EMPLOYEE }
}
const resetBulkEmployee = (val) => {
    return { type: Employee_Management_Actions.RESET_BULK_UPLOAD }
}
const resetBulkEmployeeERROR = (val) => {
    return { type: Employee_Management_Actions.RESET_BULK_UPLOAD_ERROR }
}

export {
    getEmployeeList, setEmployeeType, employeeSearch,
    resetEmployeeSearch, setClient, resetEmployeeList, bulkEmployeeUpload,
    resetBulkEmployee, resetBulkEmployeeERROR, sendEmployeeCredentials, sendUpdateDocumentEmail
};