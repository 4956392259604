import React, { useContext, useRef, useState } from 'react'
import './rightBarPannel.scss'
import Auth from '../../../Classes/Auth'
const RightBarPannel = ({ children, toggelBtn, burgerBtn, setBurgerBtn }) => {
    return (
        <div className={`rightBarPannel-mian-wrapper ${toggelBtn ? 'toggel-active' : ''}`}>
            <div className="righbar-panel-wrap">
                <>
                    {/* <TopActionButtons /> */}
                </>
                {children}
            </div>

        </div>
    )
}

export default RightBarPannel