import React from 'react'
import Modals from '../../../CommanComponents/Modals'
import ModalBody from '../../../CommanComponents/Modals/ModalsBody'
import useForceToChangePass from '../../../CustomHooks/ForceToChangePass/useForceToChangePass'
import logo from '../../../Assets/icons/Logo.svg'
import "./forceToChangePass.scss"
import useOtherSettings from '../../../CustomHooks/Settings/useOtherSettings'
import FormError from '../../../CommanComponents/Form/FormError'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton'
import { useNavigate } from 'react-router-dom'
const ForceToChangePass = () => {
    const navigate = useNavigate()
    const { forceToChangePopRef, logoutBtnHandler } = useForceToChangePass()

    const { newPasswordData, handleInput, clickHandle, eyeBtn, eyeBtn1,
        showError, passCondition, re_passCondition, forgotPass, setForgotPass, setEyeBtn1, setEyeBtn } = useOtherSettings();

    return (
        <Modals
            ref={forceToChangePopRef}
            // ClosePopUp={closeForceChangePass}
            Position="center"
            ModalsSize={'modal-xs'}
            ClassName={'force-password-wrapper'}
        >
            <ModalBody className={'force-password-modal-body'}>
                <div className='logo-wrapper d-flex align-center'>
                    <img src={logo} alt="SnapIT" className='logo-item' />
                </div>
                <div className="update-password-wrapper mt-30">
                    <div>
                        <h3 className="text-sm w-600 secondary">Update your password</h3>
                        <p className='text-xs w-500 secondary mt-15'>You need to update your password because this is the first time you are signing in, or because your password has expired.</p>
                    </div>
                    <form className="forgotPass-after-collapse mt-20 mb-10  " onSubmit={clickHandle}>
                        <div className="forgot-fields-wrap">
                            <div className="form-field ">
                                <div className="relative input-wrap">
                                    <input
                                        type={eyeBtn ? 'password' : 'text'}
                                        className='pass-input'
                                        placeholder='New Password *'
                                        name="new_password1"
                                        value={newPasswordData?.new_password1?.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                    />
                                    <button className="eye-btn" type='button' onClick={() => setEyeBtn((e) => !e)}>
                                        <i className={`${eyeBtn ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                    </button>
                                </div>
                                {
                                    (passCondition || !newPasswordData?.new_password1?.isValid && showError) &&
                                    <div className='secondary text-xxs mt-10'>
                                        <p className={`danger mb-3`}> Password Condition :</p>
                                        <p> At least 8 characters long</p>
                                        <p> Include both lowercase and uppercase character</p>
                                        <p> One number or symbol</p>
                                    </div>
                                }
                            </div>
                            <div className="form-field mt-15">
                                <div className='relative input-wrap'>
                                    <input
                                        type={eyeBtn1 ? 'password' : 'text'}
                                        className='pass-input'
                                        placeholder='Confirm New Password *'
                                        name="new_password2"
                                        value={newPasswordData?.new_password2?.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}

                                    />
                                    <button className="eye-btn" type='button' onClick={() => setEyeBtn1((e) => !e)}>
                                        <i className={`${eyeBtn1 ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                    </button>
                                </div>
                                <FormError show={!newPasswordData?.new_password2?.isValid && showError} error={re_passCondition ? "Password not matched." : 'Please re-enter password'} />
                            </div>
                        </div>
                        <div className="force-btn-wrap d-flex mt-25">
                            <CommanButton Text={'Save'} ClassName={'save-btn mr-20'} type={"submit"} />
                            <CommanButton Text={'Logout'} cancel={true} type={'button'} onClick={() => logoutBtnHandler()} />
                        </div>
                    </form>
                </div>
            </ModalBody >
        </Modals>
    )
}

export default ForceToChangePass