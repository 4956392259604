import React, { useEffect, useState } from 'react'
import '../DynamicdocumentFields.scss'
import Dropdown from '../../../../../CommanComponents/Dropdown/Dropdown'
import useDocumentDetails from '../../../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
import FormError from '../../../../../CommanComponents/Form/FormError'
import { use18YearsAgoDate, useTodayDate, useTomorrowDate } from '../../../../../CustomHooks/useTodayDate'
import { dateFormat } from '../../../../../CustomHooks/dateFormat'
import RegexValid from '../../../../../Classes/RegexValid'
import InputDatePicker from '../../../../../CommanComponents/Form/InputDatePicker'
import moment from 'moment'
const EmiratesIDFrontSide = ({ emiratesIDFileds, setEmiratesIDFileds, handleInputChange, ondynamicDropdownChange, showError, regexError, handleDateInput }) => {

    const todayDate = useTodayDate()
    const tomorrowDate = useTomorrowDate()
    const Years18AgoDate = use18YearsAgoDate()
    // const [regexError, setRegexError] = useState();
    const validReg = new RegexValid()
    const handleRegexValidation = (e) => {
        // let inputName = e.target.name;
        // const inputValue = e?.clipboardData?.getData('text');
        // if (inputName === 'Name' || inputName === 'Nationality' || inputName === 'Signature') {
        //     if (!/^[a-zA-Z\s]*$/.test(e.key) || !/^[a-zA-Z\s]*$/.test(inputValue)) {
        //         e.preventDefault();
        //         setRegexError(inputName)
        //     } else {
        //         setRegexError('')
        //     }
        // } else if (inputName === 'ID_Number') {
        //     if (validReg.acceptNum(e.key) ||
        //         e.key == '-' ||
        //         e.key == "Backspace" ||
        //         e.ctrlKey ||
        //         validReg.acceptNum(inputValue)) {
        //         setRegexError('')
        //     } else {
        //         e.preventDefault();
        //         setRegexError(inputName)
        //     }
        // } else {
        //     setRegexError('')
        // }
    }

    return (
        <div className="DynamicdocumentFields-main-wrapper document-dyanmic-fields">
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> ID Number*</label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.ID_Number?.value}
                    type='text'
                    name='ID_Number'
                    onChange={handleInputChange}
                    maxLength={18}
                    tabIndex={1}
                />
                <FormError show={!emiratesIDFileds?.ID_Number?.isValid && showError || regexError === 'ID_Number'} error={regexError === 'ID_Number' ? 'Please enter only numeric character.' : 'Please enter id number'} />

            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Name* </label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.Name?.value}
                    type='text'
                    name='Name'
                    onChange={handleInputChange}
                    tabIndex={2}
                />
                <FormError show={regexError === 'Name' || !emiratesIDFileds?.Name?.isValid && showError}
                    error={
                        regexError === 'Name' ? 'Please enter only alphabetical character.' :
                            'Please enter name.'
                    } />
                {/* {regexError === 'Name' && <FormError show={true} error={'Please enter only alphabetical character.'} />}*/}

            </div>
            <div className={`form-field ${'details-InputClassName'}`}>
                <div>
                    <label htmlFor="" className={`mb-3 d-block text-xxs`}  >Date of Birth*</label>
                    {/* <div className={`dob-wrap d-flex align-center ${'details-InputClassName'}`}>
                        <div className={`dob_para text-xs w-400 secondary d-flex align-center ${'details-InputClassName'} ${emiratesIDFileds?.Date_of_Birth?.value ? 'active' : ""}`}>{emiratesIDFileds?.Date_of_Birth?.value ? dateFormat(emiratesIDFileds?.Date_of_Birth?.value) : "DD-MM-YYYY"}</div>
                        <input
                            className={'details-InputClassName'}
                            type="date"
                            max={todayDate}
                            value={emiratesIDFileds?.Date_of_Birth?.value}
                            name='Date_of_Birth'
                            onChange={handleInputChange}
                            onKeyUp={handleInputChange}
                        />
                    </div> */}
                    <div className="datePickerWrap">
                        <InputDatePicker
                            name="dob"
                            type="date"
                            maxDate={Years18AgoDate}
                            dateFormat="dd-MM-yyyy"
                            onSelect={(e) => {
                                handleDateInput(e, "Date_of_Birth", emiratesIDFileds, setEmiratesIDFileds)
                            }}
                            placeholder="DD-MM-YYYY"
                            value={emiratesIDFileds?.Date_of_Birth?.value}
                            className={'date-picker-auth'}
                            tabIndex={3}
                        />
                    </div>
                    <FormError show={!emiratesIDFileds?.Date_of_Birth?.isValid && showError} error={'Please enter date of birth'} />
                </div>
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Nationality*</label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.Nationality?.value}
                    type='text'
                    name='Nationality'
                    onChange={handleInputChange}
                    tabIndex={4}
                />
                <FormError show={!emiratesIDFileds?.Nationality?.isValid && showError || regexError === 'Nationality'} error={regexError === 'Nationality' ? "Please enter only alphabetical character." : 'Please enter nationality.'} />
            </div>
            <div className={`form-field ${'details-InputClassName'}`}>
                <div>
                    <label htmlFor="" className={`mb-3 d-block text-xxs`}> Issue Date*</label>
                    {/* <div className={`dob-wrap d-flex align-center ${'details-InputClassName'}`}>
                        <div className={`dob_para text-xs w-400 secondary d-flex align-center ${'details-InputClassName'} ${emiratesIDFileds?.Issuing_Date?.value ? 'active' : ""}`}>{emiratesIDFileds?.Issuing_Date?.value ? dateFormat(emiratesIDFileds?.Issuing_Date?.value) : "DD-MM-YYYY"}</div>
                        <input
                            className={'details-InputClassName'}
                            type="date"
                            max={todayDate}
                            value={emiratesIDFileds?.Issuing_Date?.value}
                            name='Issuing_Date'
                            onChange={handleInputChange}
                            onKeyUp={handleInputChange}
                        />
                    </div> */}
                    <div className="datePickerWrap">
                        <InputDatePicker
                            name="dob"
                            type="date"
                            maxDate={moment().toDate()}
                            dateFormat="dd-MM-yyyy"
                            onSelect={(e) => {
                                handleDateInput(e, "Issuing_Date", emiratesIDFileds, setEmiratesIDFileds)
                            }}
                            placeholder="DD-MM-YYYY"
                            value={emiratesIDFileds?.Issuing_Date?.value}
                            className={'date-picker-auth'}
                            tabIndex={5}
                        />
                    </div>
                    <FormError show={!emiratesIDFileds?.Issuing_Date?.isValid && showError} error={'Please enter  issue date.'} />
                </div>
            </div>
            <div className={`form-field ${'details-InputClassName'}`}>
                <div>
                    <label htmlFor="" className={`mb-3 d-block text-xxs`}> Expiry Date* </label>
                    {/* <div className={`dob-wrap d-flex align-center ${'details-InputClassName'}`}>
                        <div className={`dob_para text-xs w-400 secondary d-flex align-center ${'details-InputClassName'} ${emiratesIDFileds?.Date_of_Expiry?.value ? 'active' : ""}`}>{emiratesIDFileds?.Date_of_Expiry?.value ? dateFormat(emiratesIDFileds?.Date_of_Expiry?.value) : "DD-MM-YYYY"}</div>
                        <input
                            className={'details-InputClassName'}
                            type="date"
                            min={tomorrowDate}
                            value={emiratesIDFileds?.Date_of_Expiry?.value}
                            name='Date_of_Expiry'
                            onChange={handleInputChange}
                            onKeyUp={handleInputChange}
                        />
                    </div> */}
                    <div className="datePickerWrap">
                        <InputDatePicker
                            name="dob"
                            type="date"
                            minDate={tomorrowDate}
                            dateFormat="dd-MM-yyyy"
                            onSelect={(e) => {
                                handleDateInput(e, "Date_of_Expiry", emiratesIDFileds, setEmiratesIDFileds)
                            }}
                            placeholder="DD-MM-YYYY"
                            value={emiratesIDFileds?.Date_of_Expiry?.value}
                            className={'date-picker-auth'}
                            tabIndex={6}
                        />
                    </div>
                    <FormError show={!emiratesIDFileds?.Date_of_Expiry?.isValid && showError} error={'Please enter date of expiry.'} />
                </div>
            </div>
            <div className="form-field dob-main-wrappper ">
                <label htmlFor="" className={`mb-3 d-block text-xxs `}> Gender*</label>
                <div className={`dob-gender-wrap`}>
                    <Dropdown
                        key={emiratesIDFileds?.Gender?.value}
                        ClassName={`gender-dropdown  `}
                        btnClassName={'details-InputClassName'}
                        padding={'12.5px 16px'}
                        borderRadius={'8px'}
                        textAlign={'left'}
                        data={['Male', 'Female']}
                        defaultValue={emiratesIDFileds?.Gender?.value}
                        clickHandle={(e) => ondynamicDropdownChange('Gender', e, setEmiratesIDFileds)}
                        tabIndex={7}
                    />
                </div>
                <FormError show={!emiratesIDFileds?.Gender?.isValid && showError} error={'Please select gender.'} />
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Signature*</label>
                <input
                    className='details-InputClassName'
                    value={emiratesIDFileds?.Signature?.value}
                    type='text'
                    name='Signature'
                    onChange={handleInputChange}
                    tabIndex={8}
                />
                {/* <FormError show={!emiratesIDFileds?.Signature?.isValid && showError}  /> */}
                <FormError show={!emiratesIDFileds?.Signature?.isValid && showError || regexError === 'Signature'} error={regexError === 'Signature' ? "Please enter only alphabetical character." : 'Please enter signature.'} />
                {/* {regexError === 'Signature' && <FormError show={true} error={'Please enter only alphabetical character.'} />}*/}
            </div>
        </div>
    )
}

export default EmiratesIDFrontSide