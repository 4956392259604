import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "./notificationsPop.scss"
import Storage from '../../../../Classes/Storage';
import { notificationTrigger, setInviteNotification, setNotifications, setSeenNoty } from '../../../../store/Actions/Notifications';
import Auth from '../../../../Classes/Auth';
import { noty_count } from '../../../../Constant/auth';
import { dateFormat } from '../../../../CustomHooks/dateFormat';
import { Link, useNavigate } from 'react-router-dom';
import { noticationTiggerTime } from '../../../../config';
import Tabs from '../../../../CommanComponents/Tabs/Tabs';
import useNotifications from '../../../../CustomHooks/Notifications/useNotifications';
import { setCompanyDocumentsNotifications, triggerCompanyDocumentsNotification } from '../../../../store/Actions/CompanyDocuments';
import { setActiveTab } from '../../../../store/Actions/PersonalDetails';
import useDocumentDetails from '../../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails';

const NotificationsPop = ({ setOpenNotification }) => {
    const notifications = useSelector((state) => state.notifications)
    const companyDocuments = useSelector((e) => e.companyDocuments)
    const { DocuemntEditBtnHandler } = useDocumentDetails()
    // const { onMsgClickHandler } = useNotifications()
    const dispatch = useDispatch()
    const localNoty = Storage.getBool(noty_count)
    const userId = Auth?.user()?.user_id
    const [seenCount, setSeenCount] = useState(Storage.alive(noty_count) ? localNoty : 0);
    const [activeNotyTab, setActiveNotyTab] = useState('Reminder')
    const naviagte = useNavigate()
    function onNotyClick(id, is_seen, employee_id, client_id, docType) {
        // if (!is_seen) {
        //     const formData = new FormData();
        //     formData.append('id', id);
        //     formData.append('is_seen', 1);
        //     dispatch(setSeenNoty(userId, formData))
        // }
        // onMsgClickHandler(id, is_seen, employee_id, client_id, docType)

        if (!is_seen) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('is_seen', 1);
            dispatch(setSeenNoty(userId, formData))
        }
        naviagte(`/employees-management/id=${employee_id}?client_id=${client_id}`)
        dispatch(setActiveTab("Documents"))
        DocuemntEditBtnHandler(employee_id, docType)



        setOpenNotification(e => !e)
    }

    // Effect to update the count when reminders change
    useEffect(() => {
        let count = 0;
        if (notifications?.data?.length) {
            notifications?.data?.forEach(val => {
                if (val.is_seen === false) {
                    count++;
                    // console.log(val.is_seen, "dsfsfg")
                }
            });
            // Update the state with the count
            Storage.setBool(noty_count, count)
            setSeenCount(count);
        } else {
            setSeenCount(count);
        }

    }, [notifications?.data, noty_count]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(notificationTrigger(userId))
            dispatch(setNotifications(userId))
            dispatch(setInviteNotification(userId))
            dispatch(triggerCompanyDocumentsNotification(userId))
            dispatch(setCompanyDocumentsNotifications(userId))
        }, noticationTiggerTime);

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        if (activeNotyTab == "Reminder") {
            dispatch(notificationTrigger(userId))
            dispatch(setNotifications(userId))
            dispatch(triggerCompanyDocumentsNotification(userId))
            dispatch(setCompanyDocumentsNotifications(userId))
        } else if (activeNotyTab == "Invite") {
            dispatch(setInviteNotification(userId))
        }
    }, [activeNotyTab])

    return (
        <div className="topbar-notification-wrapper absolute d-flex align-center justify-center" >
            <div className="notification-popup-wrap ">
                {/* invitaion msgs */}
                <div className="invitaion-item-wrap">
                    <p className="text-xs secondary w-600 mb-5 heading">Notifications</p>
                    {Auth?.boolUserType() == 2 && <div className="notyTab-main-wrapper">
                        <Tabs
                            tabs={['Reminder', 'Invite']}
                            defaultValue={activeNotyTab}
                            activeTabHandle={(e) => setActiveNotyTab(e)}
                            TabButtonClass={"text-xxs w-400"}
                            ActiveTabClass={"active"}
                            TabWrapperClass={"notyPop-tabs-wrapper"}
                        />
                    </div>}

                    {activeNotyTab === "Reminder" ?
                        <>
                            {
                                notifications?.data?.length ? notifications?.data?.map((val, key) => {
                                    return (
                                        <div className="invitation-item d-flex pointer" key={key}
                                            //  onClick={() => onMsgClickHandlerSeenHandler(val?.id, val?.is_seen)}
                                            onClick={() => onNotyClick(val?.id, val?.is_seen, val?.employee_id, val?.client_id, val?.document_type)}

                                        >
                                            <div className="icon-wrap d-flex align-center justify-center">
                                                <i className="profile-icon icons"></i>
                                            </div>
                                            <div className="message-wrap">
                                                <p className={`w-400 text-2xs text-left base ${!val?.is_seen && "w-600"}`}>

                                                    Hi {val?.client_name} , Your Employee  {val?.first_name + " " + val?.last_name} document  {val?.document_type} is about to expire on {dateFormat(val.date_of_expiry)}.
                                                    {/* Employee Document Renewal - Hi <Person First Name>, Renew your employee documents before <Expiry Date>

                                    Business Document Renewal - Hi <Person First Name>, Renew your Business documents before <Expiry Date>. 


                                    Employee Document Expiry - Hi <Person First Name>, Your Employee documents <Folder / File> is about to expire on <Expiry Date>. 

                                    Business Document Expiry - Hi <Person First Name>, Your Business document <Folder / File> is about to expire on <Expiry Date>. */}
                                                </p>

                                            </div>
                                        </div>
                                    )
                                }) : ""
                                // :
                                // <>
                                //     <div className="invitation-item not-found-wrap "  >
                                //         <div className="message-wrap">
                                //             <p className="w-400 text-2xs text-center base">
                                //                 No record found!
                                //             </p>
                                //         </div>
                                //     </div>
                                // </>
                            }
                            {
                                companyDocuments?.notification?.data?.map((val, key) => {
                                    return (
                                        <div className="invitation-item d-flex pointer" key={key}
                                        >
                                            <div className="icon-wrap d-flex align-center justify-center">
                                                <i className="profile-icon icons"></i>
                                            </div>
                                            <div className="message-wrap">
                                                <p className={`w-400 text-2xs text-left base ${!val?.is_seen && "w-600"}`}>
                                                    {
                                                        val?.reminder_message.replace("CompaniesFolder", "Companies Folder").replace(/object \(\d+\)/, '').replace("CompaniesFile", "Companies File")
                                                    }
                                                </p>

                                            </div>
                                        </div>
                                    )
                                })
                                // :

                            }
                            {notifications?.data?.length === 0 && companyDocuments?.notification?.data?.length === 0 && <>
                                <div className="invitation-item not-found-wrap "  >
                                    <div className="message-wrap">
                                        <p className="w-400 text-2xs text-center base">
                                            No record found!
                                        </p>
                                    </div>
                                </div>
                            </>}
                        </>
                        :
                        <>
                            {notifications?.data?.notifications?.length ? notifications?.data?.notifications?.map((val, key) => {
                                return (
                                    <div className="invitation-item d-flex pointer" key={key}>
                                        <div className="icon-wrap d-flex align-center justify-center">
                                            <i className="profile-icon icons"></i>
                                        </div>
                                        <div className="message-wrap">
                                            <p className={`w-400 text-2xs text-left base`}>
                                                You have a new Invite Request: {val?.user}, {dateFormat(val?.date_of_invitation)}
                                            </p>

                                        </div>
                                    </div>
                                )
                            })
                                :
                                <>
                                    <div className="invitation-item not-found-wrap "  >
                                        <div className="message-wrap">
                                            <p className="w-400 text-2xs text-center base">
                                                No record found!
                                            </p>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
                {((notifications?.data?.length > 0) || (notifications?.data?.notifications?.length > 0)) && <div className="show-all-wrap">
                    <Link to={'/notifications'} className="text-2xs w-500 primary text-center" onClick={() => setOpenNotification(e => !e)}>Show all</Link>
                </div>}
                {/* <div className="show-all-wrap">
                    <Link to={'/notifications'} className="text-2xs w-500 primary text-center" onClick={() => setOpenNotification(e => !e)}>Show all</Link>
                </div> */}
            </div>
        </div>
    )
}

export default NotificationsPop