import AllEmployeesActions from "../Actions/AllEmployees/actionType"

const initState = {
    loading: false,
    data: [],
    error: false
}

const allEmployees = (state = initState, { type, payload }) => {
    switch (type) {
        case AllEmployeesActions.SET_DASHBOARD_LOADING: return {
            ...state,
            loading: true,
        }
        case AllEmployeesActions.SET_DASHBOARD_LOADED: return {
            ...state,
            loading: false,
            data: payload
        }
        default: return state
    }
}

export default allEmployees