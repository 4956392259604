const FoldersAction = {
    CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
    CREATE_FOLDER_ERROR: "CREATE_FOLDER_ERROR",
    CREATE_FOLDER_ERROR_RESET: "CREATE_FOLDER_ERROR_RESET",
    CREATE_FOLDER_RESET: "CREATE_FOLDER_RESET",

    FOLDER_LIST_LOADING: "FOLDER_LIST_LOADING",
    FOLDER_LIST_LOADED: "FOLDER_LIST_LOADED",
    FOLDER_LIST_RESET: "FOLDER_LIST_RESET",

    DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
    DELETE_FILES_SUCCESS: "DELETE_FILES_SUCCESS",

    RENAME_FOLDER_SUCCESS: "RENAME_FOLDER_SUCCESS",
    RENAME_FOLDER_RESET: "RENAME_FOLDER_RESET",

    FOLDER_SEARCH_SUCCESS: "FOLDER_SEARCH_SUCCESS",
    FOLDER_SEARCH_RESET: "FOLDER_SEARCH_RESET",

    FOLDER_DETAIL_SET: "FOLDER_DETAIL_SET",
    FOLDER_DETAIL_RESET: "FOLDER_DETAIL_RESET",

    SET_BREADCRUMB: "SET_BREADCRUMB",

}

export default FoldersAction