import React from 'react'
import './commanButton.scss'
const CommanButton = ({ children, Text, ClassName, align, cancel = false, fill, ...props }) => {
    return (
        <button className={`comman-btn d-flex align-center w-500 primary ${fill && 'fill'} ${ClassName} ${align ? align : 'justify-center'}
        ${cancel && "cancel"}
        `}
            {...props}
        >
            {Text ? Text : children}
        </button>
    )
}

export default CommanButton