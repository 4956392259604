import React from "react";
import { string } from "prop-types";
import { Link } from "react-router-dom";

function BreadcrumbItem({ title, to, disabled, ...props }) {
    if (to === "/") {
        return <React.Fragment><li ><Link to={"/"}>{title}</Link></li></React.Fragment>
    } else {
        if (disabled) {
            return <React.Fragment> <li  {...props}  >{title}</li></React.Fragment>
        }
        else {
            return <React.Fragment><li  ><Link to={to} {...props}>{title}</Link></li></React.Fragment>
        }
    }
}

BreadcrumbItem.propTypes = {
    title: string.isRequired,
    to: string.isRequired
}

export default BreadcrumbItem