const initState = {
    activeStep: 1
}
const stepperBar = (state = initState, action) => {
    switch (action.type) {
        case "stepperInc": return { ...state, activeStep: state.activeStep + 1 }
        case "stepperDec": return { ...state, activeStep: state.activeStep - 1 }
        case "stepperReset": return { ...state, activeStep: 1 }
        default: return state
    }
}

export default stepperBar