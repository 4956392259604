
import Auth from '../../../Classes/Auth'
import AllEmployeesActions from './actionType'
import allEmployeesReq from './allEmployeesReq'

const getDashboard = (userId) => {
    return async (dispatch) => {
        dispatch({ type: AllEmployeesActions.SET_DASHBOARD_LOADING })
        allEmployeesReq.get(allEmployeesReq.endpoint.dashboard_details.replace("USER_ID", userId), (success) => {
            dispatch({ type: AllEmployeesActions.SET_DASHBOARD_LOADED, payload: success.data })
        },
            (error) => {

            }
        )
    }
}



export { getDashboard }