export const dateFormat = (value) => {
    // Original date string
    var dateString = value ? value : "";

    // Split the string into year, month, and day parts
    var formattedDate;
    if (dateString) {

        var parts = dateString?.split("-");

        // Create a new Date object with year, month (minus 1 because months are zero-based), and day
        var date = new Date(parts[0], parts[1] - 1, parts[2]);

        // Format the date to "dd-mm-yyyy" format
        formattedDate = (date.getDate() < 10 ? '0' : '') + date.getDate() + "-" + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + "-" + date.getFullYear();
    } else {
        formattedDate = false
    }

    return formattedDate;
}


export function formatDateDatePicker(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


export const dateFormatYYYY_MM_DD = (value) => {
    // Original date string
    var dateString = value ? value : "";

    // Split the string into day, month, and year parts
    var formattedDate;
    if (dateString) {
        var parts = dateString.split("/");

        // Create a new Date object with year, month (minus 1 because months are zero-based), and day
        var date = new Date(parts[2], parts[1] - 1, parts[0]);

        // Format the date to "dd-mm-yyyy" format
        formattedDate =
            date.getFullYear() + "-" +
            ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + "-" +
            (date.getDate() < 10 ? '0' : '') + date.getDate();
    } else {
        formattedDate = false;
    }

    return formattedDate;
}
