import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton'
const EmailSent = () => {
    return (
        <div className="login-wrapper">
            <div className="form-container">
                <div className="form-wrap">
                    <div className="form"  >
                        <h3 className="heading text-md w-500 primary text-center">
                            Email sent
                        </h3>
                        <p className="sub-heading w-400 text-center secondary">
                            Please check your email to verify your account
                        </p>
                        <CommanButton className="login-button pT-10 pb-10 w-400 text-s transparent white mt-25">
                            <Link to="/login" className='white'>Login</Link>
                        </CommanButton>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default EmailSent