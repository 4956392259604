import React, { useState } from 'react'
import '../accessMultiSteps.scss'
import Tabs from '../../../../../../../../CommanComponents/Tabs/Tabs'
import { AccessConsumer } from '../../../../../../../../Context/AccessContext'
const SelectAccessOf = () => {
    // const [accessOf, setAccessOf] = useState("Employees")
    return (
        <AccessConsumer>
            {(value) => {
                return (
                    <div className="SelectAccessOf-wrapper">
                        <p className="text-xs w-600 secondary headings mb-15">Select type</p>
                        <div className="send-access-to-wrapper">
                            <Tabs
                                tabs={['Employees']}
                                defaultValue={value?.accessOf}
                                activeTabHandle={(e) => value?.setAccessOf(e)}
                                TabButtonClass={"default-access-btn text-xs w-500 text-left secondary"}
                                ActiveTabClass={"active"}
                                TabWrapperClass={"accessOf-tabs-wrapper"}
                            />
                        </div>
                    </div>
                );
            }}

        </AccessConsumer >
    )
}

export default SelectAccessOf