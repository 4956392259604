import React from 'react'
import './cardsStyle.scss'
const RecCards = ({ heading, val, className }) => {
    return (
        <>
            <div className={`rec-card-main-wrapper ${className}`}>
                <div className="heading-wrap text-xs w-500 white">
                    {heading}
                </div>
                <div className="content-wrap">
                    <p className="content text-md w-600 secondary">{val ? val : 0}</p>
                </div>
            </div>
        </>
    )
}

export default RecCards