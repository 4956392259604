import { FETCHING_LOADER_ACTIONS } from "./actionType"



export const showLoadingFetch = (payload) => {
    return dispatch => {
        dispatch({
            type: FETCHING_LOADER_ACTIONS.LOADING_FETCH_SUCCESS,
            payload: payload
        })
    }
}

export const hideLoadingFetch = (payload) => {
    return dispatch => {
        dispatch({
            type: FETCHING_LOADER_ACTIONS.LOADING_FETCH_HIDE,
            payload: payload
        })
    }
}
