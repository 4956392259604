const PersonalDetailsAction = {
    CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
    CREATE_EMPLOYEE_RESET: "CREATE_EMPLOYEE_RESET",

    GET_SINGLE_EMPLOYEE: "GET_SINGLE_EMPLOYEE",
    SET_SINGLE_EMPLOYEE_RESET: "SET_SINGLE_EMPLOYEE_RESET",

    UPDATE_SINGLE_EMPLOYEE: "UPDATE_SINGLE_EMPLOYEE",
    UPDATE_SINGLE_EMPLOYEE_RESET: "UPDATE_SINGLE_EMPLOYEE_RESET",

    DELETE_EMPLOYEE_SUCCESS: "DELETE_EMPLOYEE_SUCCESS",
    RESET_DELETE: "RESET_DELETE",

    SET_ERROR: "SET_ERROR",
    HIDE_ERROR: "HIDE_ERROR",

    SET_ACTIVE_TAB: "SET_ACTIVE_TAB"
}

export default PersonalDetailsAction