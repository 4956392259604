import moment from "moment";

export const useTodayDate = () => {
    // Get today's date
    const today = new Date();

    // Extract year, month, and day
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = today.getDate();

    // Format the date as needed
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

    return formattedDate
}

export const useYesterdayDate = () => {
    var today = new Date();

    // Subtract one day
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Format the date as ISO string (YYYY-MM-DD)
    var yesterdayISO = yesterday.toISOString().split('T')[0];
    return yesterdayISO
}
export const useTomorrowDate = () => {
    var today = new Date();

    // Subtract one day
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() + 1);

    // Format the date as ISO string (YYYY-MM-DD)
    var yesterdayISO = yesterday.toISOString().split('T')[0];
    return yesterdayISO
}

export const use18YearsAgoDate = () => {
    // Get today's date
    const today = new Date();

    // Subtract 18 years from today's date
    const yearsAgo18 = new Date(today);
    yearsAgo18.setFullYear(today.getFullYear() - 18);

    // Extract year, month, and day
    const year = yearsAgo18.getFullYear();
    const month = yearsAgo18.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = yearsAgo18.getDate();

    // Format the date as needed (YYYY-MM-DD)
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

    return formattedDate;
}


export const farFutureDate = moment().add(1000, 'years').toDate(); // 100 years into the future
