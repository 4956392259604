import React from 'react'
import DataNotFound from '../../../CommanComponents/DataNotFound/DataNotFound'
import "./registeredUser.scss";
import useRegisterUser from '../../../CustomHooks/AdminUser/RegisterUser/useRegisterUser';
import SectionHeading from '../../../CommanComponents/SectionHeading/SectionHeading';
import { dateFormat } from '../../../CustomHooks/dateFormat';

const RegisteredUser = () => {
    const { adminRegisteredUser } = useRegisterUser()
    return (
        <div className='admin-RegisteredUser-main-wrapper'>
            <div className="heading-search-item justify-between align-center">
                <SectionHeading>
                    Register Users List
                </SectionHeading>
            </div>
            {!adminRegisteredUser?.loading ?
                <div className="employees-management-table-wrapper mt-10">
                    {adminRegisteredUser?.data?.data?.length ? <div className="table-wrap">
                        <table className="table-item">
                            <thead className='table-head'>
                                <tr className="table-row">
                                    <th >User Type  </th>
                                    <th >Registration Date  </th>
                                    <th >Business Name </th>
                                    <th >Contact Person Name</th>
                                    <th >Email</th>
                                    <th className='text-nowrap'>Phone No</th>
                                    <th >Full Name</th>
                                    <th >First Name</th>
                                    <th >Last Name</th>
                                    <th >Emirates ID</th>
                                    <th >Nationality</th>
                                    <th >Text ID</th>
                                    <th >DOB</th>
                                    <th >Gender</th>
                                    <th >Subscription Type</th>
                                    <th >Subscription Expire</th>
                                    <th >Subscription Date</th>
                                    <th >Address</th>
                                    <th >Country</th>
                                    <th >State</th>
                                    <th >City</th>
                                    <th >Postal Code</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                {adminRegisteredUser?.data?.data?.map((val, key) => {
                                    let user_type;
                                    if (val?.user_type == "1") {
                                        user_type = "Business Owner"
                                    } else if (val?.user_type == "2") {
                                        user_type = "Typing Center"
                                    } else if (val?.user_type == "3") {
                                        user_type = "Individual"
                                    } else if (val?.user_type == "4") {
                                        user_type = "Typing Center User"
                                    }
                                    else if (val?.user_type == "5") {
                                        user_type = "Employee User"
                                    }

                                    console.log(user_type, "vsdfbd")
                                    return (
                                        <tr className="table-row" key={key}>
                                            <td className='text-nowrap'> {user_type} </td>
                                            <td className='text-nowrap'> {dateFormat(val?.registration_date)} </td>
                                            <td className='text-nowrap'> {val.business_name ? val.business_name : "NA"} </td>
                                            <td className='text-nowrap'> {val.contact_person_name ? val.contact_person_name : "NA"} </td>
                                            <td className='text-nowrap'> {val.email ? val.email : "NA"} </td>
                                            <td className='text-nowrap'> {val.phone_no ? val.phone_no : "NA"} </td>
                                            <td className='text-nowrap'> {val.full_name ? val.full_name : "NA"} </td>
                                            <td> {val.first_name ? val.first_name : "NA"} </td>
                                            <td> {val.last_name ? val.last_name : "NA"} </td>
                                            <td> {val.Emirates_ID ? val.Emirates_ID : "NA"} </td>
                                            <td> {val.Nationality ? val.Nationality : "NA"} </td>
                                            <td> {val.text_id ? val.text_id : "NA"} </td>
                                            <td> {val.dob ? val.dob : "NA"} </td>
                                            <td> {val.gender ? val.gender : "NA"} </td>
                                            <td className='text-nowrap'> {val.subscription_type ? val.subscription_type : "NA"} </td>
                                            <td> {(val.subscription_expire ? val.subscription_expire : "NA")} </td>
                                            <td> {dateFormat(val.subscription_date ? val.subscription_date : "NA")} </td>
                                            <td> {val.address ? val.address : "NA"} </td>
                                            <td className='text-nowrap'> {val.country ? val.country : "NA"} </td>
                                            <td className='text-nowrap'> {val.state ? val.state : "NA"} </td>
                                            <td className='text-nowrap'> {val.city ? val.city : "NA"} </td>
                                            <td> {val.postal_code ? val.postal_code : "NA"} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div> : <DataNotFound className={"mt-20"} />}
                </div>
                : <DataNotFound className={"mt-20"} text={"Loading..."} />
            }
        </div>
    )
}

export default RegisteredUser