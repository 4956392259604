import { Country } from "country-state-city";
import Select from "react-select";
import './Csc2.scss'
import { useDispatch, useSelector } from "react-redux";
import { resetCsc, setCity, setCountry, setState } from "../../store/Actions/CountryStateCity";
import { useEffect } from "react";
const CountrySelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
    const dispatch = useDispatch()
    const { country } = useSelector((state) => {
        return ({
            country: state.countryStateCity.country
        })
    })
    const onChangeHandle = (item) => {
        dispatch(setCountry(item));
        dispatch(setState(''))
        dispatch(setCity(''))
    }
    useEffect(() => {
        dispatch(resetCsc());
    }, [])

    useEffect(() => {
        // { Defaultvalue !== false && dispatch(setCountry({ "name": Defaultvalue })); }
        { Defaultvalue !== false && dispatch(setCountry(Country.getAllCountries().filter(country => { return country.name === Defaultvalue })[0])); }
    }, [Defaultvalue])



    // Filter Gulf countries
    const gulfCountries = Country.getAllCountries().filter(country => {
        return (
            country.name === "Bahrain" ||
            country.name === "Kuwait" ||
            country.name === "Oman" ||
            country.name === "Qatar" ||
            country.name === "Saudi Arabia" ||
            country.name === "United Arab Emirates"
        );
    });
    return (
        <Select
            // options={Country.getAllCountries()}
            options={gulfCountries}
            getOptionLabel={(options) => {
                return options["name"];
            }}
            getOptionValue={(options) => {
                return options["name"];
            }}
            value={country}
            // value={{
            //     "name": "guddu", 
            // }}
            onChange={(item) => {
                onChangeHandle(item)

            }}
            placeholder={placeholder ? placeholder : 'Country *'}
            className={ClassName}
        />
    )
}

export default CountrySelector