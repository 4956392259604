import { City } from "country-state-city";
import Select from "react-select";
import './Csc2.scss'
import { useDispatch, useSelector } from "react-redux";
import { setCity } from "../../store/Actions/CountryStateCity";
import { useEffect } from "react";
const CitySelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
    const dispatch = useDispatch();
    const { states, city } = useSelector((state) => {
        return ({
            states: state.countryStateCity.states,
            city: state.countryStateCity.city
        })
    })
    useEffect(() => {
        // { Defaultvalue !== false && dispatch(setCity({ "name": Defaultvalue })); }
        if (Defaultvalue !== false) {
            const city = City?.getCitiesOfState(states?.countryCode, states?.isoCode).filter(city => { return city.name === Defaultvalue })[0];
            console.log(city, "citycity")
            if (city) {
                dispatch(setCity(city));
            }
        }
    }, [Defaultvalue, states?.countryCode, states?.isoCode])
    return (
        <Select
            options={City.getCitiesOfState(
                states?.countryCode,
                states?.isoCode
            )}
            getOptionLabel={(options) => {
                return options["name"];
            }}
            getOptionValue={(options) => {
                return options["name"];
            }}
            value={city}
            onChange={(item) => {
                dispatch(setCity(item));
            }}
            placeholder={placeholder ? placeholder : 'City *'}
            className={ClassName}
        />
    )
}

export default CitySelector