import React from 'react'
import './mySubscription.scss'
import { useSelector } from 'react-redux'
import { dateFormat } from '../../../CustomHooks/dateFormat'
const MySubscription = () => {
    const settings = useSelector(e => e.settings)
    console.log(settings?.personal?.data, "ADfsdbfdgb")
    return (
        <div className="MySubscription-settings-main-wrapper">
            <div className="MySubscription-setting-wrap">
                <div className="MySubscription-password-wrap">
                    {/* <h3 className="heading-para text-ss w-600 mb-3 d-flex align-center justify-between">
                        Change Password
                    </h3> */}
                    <div className="MySubscription-before-collapse ">
                        <div className="">
                            <p className="text-xxs w-600 secondary description-para"> Subscription type </p>
                            <p className="text-xxs w-400 secondary description-para mt-5"> {settings?.personal?.data?.subscription_type ? settings?.personal?.data?.subscription_type : "N/A"} </p>
                        </div>
                        <div className="">
                            <p className="text-xxs w-600 secondary description-para"> Expire </p>
                            <p className="text-xxs w-400 secondary description-para mt-5"> {settings?.personal?.data?.subscription_expire ? settings?.personal?.data?.subscription_expire : "N/A"} </p>
                        </div>
                        <div className="">
                            <p className="text-xxs w-600 secondary description-para"> Date of subscription </p>
                            <p className="text-xxs w-400 secondary description-para mt-5"> {settings?.personal?.data?.subscription_date ? dateFormat(settings?.personal?.data?.subscription_date) : "N/A"} </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MySubscription