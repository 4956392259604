import React from 'react'
import { AccessConsumer } from '../../../../../../../../Context/AccessContext';
import CheckboxInput from '../../../../../../../../CommanComponents/Form/CheckboxInput';
import SearchControl from '../../../../../../../../CommanComponents/SearchControl/SearchControl'
const SelectEmployees = () => {
    let numbers = Array.from({ length: 50 }, (_, i) => i);
    return (
        <AccessConsumer>
            {(value) => {
                const { selectAllHandler, employeeManagement, handleInputChange, searchValModal,
                    handleSearchModal,
                    handleResetSearchModal, formData, selectAll, handleSelectedDocs, selectedEmployee, selectAllEmpRef } = value

                const lastSelectedEmployee = !selectAllEmpRef?.current?.checked ? formData?.employee?.selected_employees?.[formData?.employee?.selected_employees.length - 1] : null;
                // console.log(formData?.employee?.selected_employees?.map((e) => e.employee_id), "sdavsgsfd")

                // Helper function to check if an employee is selected 
                const transformedArray = formData?.employee?.selected_employees?.map((item, index) => {
                    return item.emp_id
                });

                console.log(employeeManagement?.data?.length === formData?.employee?.selected_employees?.length, "sdfdsghhg 111111111111111111111111111");
                return (
                    <div className="SelectEmployees-wrapper">
                        <div className="employee-wrapper">
                            <ul className="employee-wrap">
                                <div className='access-employee-search'>
                                    <SearchControl
                                        value={searchValModal}
                                        placeholder={'Search...'}
                                        onChange={handleSearchModal}
                                        reset={() => handleResetSearchModal()}
                                        classNameWrappper={'access-employees-search mb-15'}
                                        className={"access-employees-search-input"}

                                    />

                                    {/* ------- Pagination add ------ */}
                                </div>
                                {!searchValModal.length && <li>
                                    <div className="input-custom-type mb-10">
                                        <CheckboxInput
                                            label={`Select All`}
                                            LabelClass={"secondary"}
                                            className={""}
                                            onChange={(e) => selectAllHandler(e)}
                                            name="select_all_emp"
                                            ref={selectAllEmpRef}
                                            checked={employeeManagement?.data?.length === formData?.employee?.selected_employees?.length}
                                        />
                                    </div>
                                </li>}
                                {employeeManagement?.data?.map((val, key) => {
                                    return (
                                        <li>
                                            <div className={`input-custom-type select-employee-checkbox-wrap 
                                                ${selectedEmployee !== null ?
                                                    selectedEmployee?.emp_id == val?.employee_id && 'active' : lastSelectedEmployee?.emp_id == val?.employee_id ? "active" : ''

                                                }
                                                `}>
                                                <CheckboxInput
                                                    // label={val.first_name + ' ' + val.last_name}
                                                    // LabelClass={"secondary"}
                                                    className={"selected_employees"}
                                                    name="selected_employees"
                                                    value={val?.employee_id}
                                                    onChange={(e) => handleInputChange(e)}
                                                    // checked={transformedArray?.map(Number)?.includes(val.employee_id)}
                                                    checked={transformedArray?.includes(val.employee_id)}
                                                />
                                                <p className='secondary cursor'
                                                    onClick={(e) => { return !selectAll.length ? handleSelectedDocs(val.employee_id) : null }}
                                                >
                                                    {val.first_name + ' ' + val.last_name}
                                                </p>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                );
            }}

        </AccessConsumer >
    )
}

export default SelectEmployees