import Request from "../../../Classes/Requests";

class forgotRequest extends Request {
    constructor() {
        super()
        this.endpoint = {
            verify_user: super.url("/sending_ResetEmail_with_otp"),
            verify_user_with_otp: super.url("/verify_user_with_otp"),
            user_changes_password: super.url("/user_changes_password"),
        }
    }
}
export default new forgotRequest();