// Function to download the file
export const fileDownloaderHelperFunc = (fileURL, type = 'view') => {
    console.log(fileURL, "Asfsadfsaf")
    // const link = document.createElement('a');
    // link.href = fileURL;
    // link.target = '_blank';  // Open in a new tab
    // link.rel = 'noopener noreferrer';  // Security best practice
    // link.download = fileURL.split('/').pop(); // Get the file name from the URL
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);


    // Fetch the file and then download it
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    fetch(proxyUrl + fileURL)
        .then(response => response.blob())
        .then(blob => {

            if (type !== 'view') {
                const downloadLink = document.createElement('a');
                const url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = fileURL.split('/').pop(); // Get the file name from the URL
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                // Revoke the object URL to free up memory
                URL.revokeObjectURL(url);
            }


            // Open the file in a new tab
            if (type == "view") {
                const openLink = document.createElement('a');
                openLink.href = fileURL;
                openLink.target = '_blank';
                openLink.rel = 'noopener noreferrer'; // Security best practice
                document.body.appendChild(openLink);
                openLink.click();
                document.body.removeChild(openLink);
            }
        })
        .catch(error => console.error('Error fetching the file:', error));
};