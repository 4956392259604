import Auth from "./Auth";

class Api {
  constructor() {
    this.apiConfig = {
      mode: "productiondev", // productiondev // production // development
      development: {
        protocol: "http", // http or https
        host: "localhost",
        port: 8000,
        prefix: "",
        verticaltype: "snapit",
      },
      production: {
        protocol: "http", // http or https 
        host: "101.53.149.193:8000",
        port: "",
        dynamicHost: "snapit",
        prefix: "",
        verticaltype: "snapit",
      },
      productiondev: {
        protocol: "http", // http or https 
        host: "101.53.149.193:8002",
        port: "",
        dynamicHost: "snapit",
        prefix: "",
        verticaltype: "snapit",
      },
    };
  }

  /**
   * @type {*} : return
   * @param : null,
   */
  getBaseUrl(dynamicUrl) {

    return this.getApiBaseUrl(dynamicUrl);
  }

  /**
   * @type : return
   * @developmentUrl method returns api development URL
   */

  developmentUrl() {
    const developmentDetail = this.apiConfig.development;
    let developmentUrl =
      developmentDetail.protocol + "://" + developmentDetail.host;
    developmentUrl +=
      developmentDetail.port > 0 ? ":" + developmentDetail.port + "/" : "/";
    developmentUrl += developmentDetail.prefix
      ? developmentDetail.prefix + "/"
      : "";
    return developmentUrl;
  }

  getApiBaseUrl(dynamicUrl) {
    const developmentDetail = this.apiConfig[this.apiConfig.mode];
    let developmentUrl
    if (dynamicUrl) {
      developmentUrl =
        developmentDetail.protocol + "://" + dynamicUrl + developmentDetail.dynamicHost;
    } else {
      developmentUrl =
        developmentDetail.protocol + "://" + developmentDetail.host;
    }
    developmentUrl +=
      developmentDetail.port > 0 ? ":" + developmentDetail.port + "/" : "/";
    developmentUrl += developmentDetail.prefix
      ? developmentDetail.prefix + "/"
      : "";

    return developmentUrl;
  }

  /**
   * @type : return, It will return complete api url along with passed @extendedUrl. If @extendedUrl will not passed then only base url will be return
   * @param {*} extendedUrl : optional params
   */
  url(extendedUrl = null, dynamicUrl) {

    if (extendedUrl != null) {
      extendedUrl =
        extendedUrl.substring(0, 1) === "/"
          ? extendedUrl.substring(1)
          : extendedUrl;
    }
    return extendedUrl
      ? `${this.getBaseUrl(dynamicUrl)}${extendedUrl}`
      : this.getBaseUrl(dynamicUrl);
  }

  /**
   * @type : return
   * @param: null
   * @description : header
   */
  getApiheader() {
    // const { rbac, rbacid } = Auth.rbacToken();
    return {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
      type: Auth.boolUserType()
      // token: Auth.token(),
      // hash: Auth.hash(),
      // rbac: rbac,
      // rbacid: rbacid,
      // verticaltype: this.apiConfig[this.apiConfig.mode].verticaltype,
      // "Content-Security-Policy": "upgrade-insecure-requests"

    };
  }


  getAuthToken() {
    return Auth.token();
  }

  getNonLoginApiHeader() {
    return {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${Auth.token()}`,
      // type: Auth.boolUserType()
      // 'Content-Type': 'multipart/form-data', // Include boundary as required by FormData
      // 'Accept': 'application/json', // Additional header
    };
  }
}

export default Api;
