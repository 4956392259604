import React, { useRef, useState } from 'react'
import dots from '../../../Assets/icons/dots-vertical.svg'
import useDocumentDetails from '../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
import useDetectOutsideClick from '../../../CustomHooks/useDetectOutsideClick'
import DeleteConfirmPop from '../../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import { dateFormat } from '../../../CustomHooks/dateFormat'
import DataNotFound from '../../../CommanComponents/DataNotFound/DataNotFound'
import Auth from '../../../Classes/Auth'
const UploadedDocsList = ({ editHandlerFunc }) => {
    const { activeDoc, DynamicInputValues, setDynamicInputValues, documentSaveBtn,
        employeeManagement, onChangeFile, handleDelete, HandleDownload, handleInputChange,
        ondynamicDropdownChange, handleDocumentChnage, inputFile,
        inputFile2,
        imageRef2,
        fileName,
        fileName2, setFileName,
        setFileName2, EmployeeDocumentsList, imageRef, documentUpload, deleteHandler, DocuemntEditBtnHandler } = useDocumentDetails()

    const actionRef = useRef()
    const [activeManage, setActiveManage] = useDetectOutsideClick(actionRef, false)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteData, setDeleteData] = useState({
        employee_id: '',
        Document_type: ''
    })
    const handleManageBtn = (e, val) => {
        setActiveManage(prev => val === prev ? -1 : val)
    }

    const CancleDelete = () => {
        setDeletePop(false);
    }
    const handlePopDeleteBtn = () => {
        setDeletePop(false);
        deleteHandler(deleteData?.employee_id, deleteData?.Document_type)
    }
    return (
        documentUpload?.data?.length ?
            <div className="uploaded-doc-wrapper">
                <div className="table-wrap">
                    <table className="table-item">
                        <thead className='table-head'>
                            <tr className="table-row">
                                <th >Document </th>
                                <th >Expiry Date</th>
                                {/* <th >Reminder 1</th>
                                <th >Reminder 2</th> */}
                                <th >Action </th>
                            </tr>
                        </thead>

                        <tbody className='table-body'>
                            {documentUpload?.data?.map((val, key) => {
                                return (
                                    <tr className="table-row" onClick={(e) => editHandlerFunc(val?.employee_id, val?.Document_type)}   >
                                        <td  > {val?.Document_type} </td>
                                        <td> {dateFormat(val?.Date_of_Expiry)} </td>
                                        {/* <td className='' >
                                            <button className='transparent'>
                                        {val?.Given_name}
                                    </button>
                                        </td>
                                        <td className=' '>
                                            <button className='transparent '>
                                        {val?.Given_name}
                                    </button>

                                        </td> */}
                                        <td className='dots-wrapper' onClick={(e) => e.stopPropagation()}>
                                            <div className='dots-wrap relative' onClick={(e) => handleManageBtn(e, key)} >
                                                <img src={dots} alt="" />
                                                {activeManage === key && <div className="dropdown-wrap absolute" ref={actionRef}>
                                                    {Auth?.boolUserType() !== 5 &&
                                                        <div className="item d-flex align-center mb-10 pointer" onClick={() => { return setDeleteData({ employee_id: val?.employee_id, Document_type: val?.Document_type }), setDeletePop(true) }}>
                                                            <i className='icons mr-5 delete'></i>
                                                            <p className="text-2xs w-500 secondary" >Delete</p>
                                                        </div>
                                                    }
                                                    <div className="item d-flex align-center pointer" onClick={(e) => editHandlerFunc(val?.employee_id, val?.Document_type)}>
                                                        <i className='icons mr-5 edit'></i>
                                                        <p className="text-2xs w-500 secondary" >Edit</p>
                                                    </div>
                                                </div>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {deletePop &&
                    <DeleteConfirmPop
                        handleCancelVar={CancleDelete}
                        deleteVarHandler={() => handlePopDeleteBtn()}
                    />}
            </div>
            : <DataNotFound />
    )
}

export default UploadedDocsList