import Auth from "../../../Classes/Auth";
import { hideLoadingFetch, showLoadingFetch } from "../LoaderFetching";
import { showSuccessToast } from "../SuccessMessagePopup";
import PersonalDetailsAction from "./actionType";
import personalDetails from './personalDetailsReq'
const createEmployee = (data) => {
    return async (dispatch) => {
        dispatch(showLoadingFetch())
        personalDetails.post(personalDetails.endpoint.add_employee, data, (success) => {
            dispatch({ type: PersonalDetailsAction.CREATE_EMPLOYEE },)
            dispatch(showSuccessToast('Employee added successfully!'))
            dispatch(hideLoadingFetch())
        }, (error) => {
            if (error?.response?.status == 409) {
                dispatch({ type: PersonalDetailsAction.SET_ERROR, payload: error.response.data.message })
            }
            dispatch(hideLoadingFetch())
            // dispatch(
            //     { type: PersonalDetailsAction.SET_ERROR, payload: error.response.data.message },
            // )
            // console.log(error, "adwfwerge")
        }
        )
    }
}
const createEmployeeReset = (data) => {
    return {
        type: PersonalDetailsAction.CREATE_EMPLOYEE_RESET,
    }
}


const getSingleEmployee = (id) => {
    if (Auth?.boolUserType() !== 5) {
        return async (dispatch) => {
            personalDetails.get(`${personalDetails.endpoint.single_employee_detail}${id}`, (success) => {
                dispatch(
                    { type: PersonalDetailsAction.GET_SINGLE_EMPLOYEE, payload: success.data },
                )
            }, (error) => {
                console.log(error)
            }
            )
        }
    } else if (Auth?.boolUserType() === 5) {
        return async (dispatch) => {
            personalDetails.get(`${personalDetails.endpoint.get_employee_Login}`, (success) => {
                dispatch(
                    { type: PersonalDetailsAction.GET_SINGLE_EMPLOYEE, payload: success.data },
                )
            }, (error) => {
                console.log(error)
            }
            )
        }
    }
}
const resetSingleEmployee = () => {
    return { type: PersonalDetailsAction.SET_SINGLE_EMPLOYEE_RESET }
}

const updateEmployee = (id, data) => {
    return async (dispatch) => {
        personalDetails.put(`${personalDetails.endpoint.edit_employee}${id}`, data, (success) => {
            dispatch(
                { type: PersonalDetailsAction.UPDATE_SINGLE_EMPLOYEE },
            )
            dispatch(showSuccessToast('Employee update successfully!'))
            dispatch(hideError())

        }, (error) => {
            if (error?.response?.status == 409) {
                dispatch({ type: PersonalDetailsAction.SET_ERROR, payload: error.response.data.message })
            }
        }
        )
    }
}
const deleteEmployee = (id) => {
    return async (dispatch) => {
        personalDetails.delete(`${personalDetails.endpoint.delete_employee}${id}`, (success) => {
            dispatch({ type: PersonalDetailsAction.DELETE_EMPLOYEE_SUCCESS })
            dispatch(showSuccessToast(success?.data?.message))
        }
        )
    }
}

const hideError = () => {
    return ({
        type: PersonalDetailsAction.HIDE_ERROR
    })
}
const updateSingleEmployeeReset = () => {
    return ({
        type: PersonalDetailsAction.UPDATE_SINGLE_EMPLOYEE_RESET
    })
}

const resetDelete = () => {
    return ({
        type: PersonalDetailsAction.RESET_DELETE
    })
}
const setActiveTab = (data) => {
    return ({
        type: PersonalDetailsAction.SET_ACTIVE_TAB,
        payload: data
    })
}

export {
    createEmployee, hideError, getSingleEmployee, updateEmployee, updateSingleEmployeeReset
    , deleteEmployee, resetDelete, createEmployeeReset, resetSingleEmployee, setActiveTab
}