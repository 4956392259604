import React from 'react'
import './dataNotFound.scss'
const DataNotFound = ({ text, children, className, textCenter = false }) => {
    return (
        <div className={`data-not-found-wrapper ${className}`} >
            <p className={`text-xs w-500 ${textCenter && 'text-center'}`}>{text ? text : "No record found!"}</p>
        </div>
    )
}

export default DataNotFound