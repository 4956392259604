import React, { useEffect, useState } from 'react'
import '../DynamicdocumentFields.scss'
import Dropdown from '../../../../../CommanComponents/Dropdown/Dropdown'
import useDocumentDetails from '../../../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
import FormError from '../../../../../CommanComponents/Form/FormError'
import { farFutureDate, use18YearsAgoDate, useTodayDate, useTomorrowDate, } from '../../../../../CustomHooks/useTodayDate'
import { dateFormat } from '../../../../../CustomHooks/dateFormat'
import RegexValid from '../../../../../Classes/RegexValid'
import InputDatePicker from '../../../../../CommanComponents/Form/InputDatePicker'
import moment from 'moment'
const PassportFieldsFrontSide = ({ DynamicInputValues, setDynamicInputValues, handleInputChange, ondynamicDropdownChange, showError, handleDateInput }) => {
    const todayDate = useTodayDate()
    const tomorrowDate = useTomorrowDate()
    const [regexError, setRegexError] = useState();
    const ValidReg = new RegexValid()
    const Years18AgoDate = use18YearsAgoDate()
    console.log(farFutureDate, "sdavsdfbvfarFutureDate")
    const handleRegexValidation = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName === 'Given_name' || inputName === 'Place_of_Birth' || inputName === 'Place_of_issue') {
            if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                e.preventDefault();
                setRegexError(inputName)
            } else {
                setRegexError('')
            }
        } else if (inputName === 'Passport_No') {
            if (inputValue.length === 12 && e.key !== "Backspace") {
                // e.preventDefault();
                setRegexError(inputName)
            } else {
                setRegexError('')
            }
        } else if (inputName === 'type_of_document' || inputName === 'Country_Code' || inputName === 'Nationality') {
            if (ValidReg.acceptAlphaWithSpace(e.key)) {
                setRegexError('')
            } else {
                e.preventDefault();
                setRegexError(inputName)
            }
        }
        else {
            setRegexError('')
        }
    }

    console.log(DynamicInputValues?.Date_of_Birth, 'DACSGVSFED')

    return (
        <div className="DynamicdocumentFields-main-wrapper document-dyanmic-fields">
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Type*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.type_of_document?.value}
                    type='text'
                    name='type_of_document'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    maxLength={1}
                    tabIndex={1}
                />
                {/* <FormError show={!DynamicInputValues?.type_of_document?.isValid && showError} error={'Please enter type'} /> */}
                <FormError show={!DynamicInputValues?.type_of_document?.isValid && showError || regexError === 'type_of_document'} error={regexError === 'type_of_document' ? "Please enter only alphabetical character." : 'Please enter type'} />
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Country Code*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Country_Code?.value}
                    type='text'
                    name='Country_Code'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={2}
                />
                <FormError show={!DynamicInputValues?.Country_Code?.isValid && showError || regexError === 'Country_Code'} error={regexError === 'Country_Code' ? "Please enter only alphabetical character." : 'Please enter country code.'} />
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Passport No.*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Passport_No?.value}
                    type='text'
                    name='Passport_No'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    maxLength={12}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={3}
                />
                <FormError show={!DynamicInputValues?.Passport_No?.isValid && showError} error={'Please enter passport no'} />
                {regexError === 'Passport_No' && <FormError show={true} error={'Not accept more then 12 characters.'} />}

            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Nationality*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Nationality?.value}
                    type='text'
                    name='Nationality'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={4}
                />
                <FormError show={!DynamicInputValues?.Nationality?.isValid && showError} error={'Please enter nationality.'} />
                {regexError === 'Nationality' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field dob-main-wrappper ">
                <label htmlFor="" className={`mb-3 d-block text-xxs `}> Gender *</label>
                <div className={`dob-gender-wrap`}>
                    <Dropdown
                        key={DynamicInputValues?.Gender?.value}
                        ClassName={`gender-dropdown  `}
                        btnClassName={'details-InputClassName'}
                        padding={'12.5px 16px'}
                        borderRadius={'8px'}
                        textAlign={'left'}
                        data={['Male', 'Female']}
                        defaultValue={DynamicInputValues?.Gender?.value}
                        clickHandle={(e) => ondynamicDropdownChange('Gender', e, setDynamicInputValues)}
                        tabIndex={5}
                    />
                </div>
                <FormError show={!DynamicInputValues?.Gender?.isValid && showError} error={'Please select gender.'} />
            </div>
            <div className={`form-field ${'details-InputClassName'}`}>
                <div>
                    <label htmlFor="" className={`mb-3 d-block text-xxs`}  >Date of Birth*</label>
                    {/* <div className={`dob-wrap d-flex align-center ${'details-InputClassName'}`}>
                        <div className={`dob_para text-xs w-400 secondary d-flex align-center ${'details-InputClassName'} ${DynamicInputValues?.Date_of_Birth?.value ? 'active' : ""}`}>{DynamicInputValues?.Date_of_Birth?.value ? dateFormat(DynamicInputValues?.Date_of_Birth?.value) : "DD-MM-YYYY"}</div>
                        <input
                            className={'details-InputClassName'}
                            type="date"
                            max={todayDate}
                            value={DynamicInputValues?.Date_of_Birth?.value}
                            name='Date_of_Birth'
                            onChange={handleInputChange}
                            onKeyUp={handleInputChange}
                        />
                    </div> */}
                    <div className="datePickerWrap">
                        <InputDatePicker
                            name="dob"
                            type="date"
                            maxDate={Years18AgoDate}
                            dateFormat="dd-MM-yyyy"
                            onSelect={(e) => {
                                handleDateInput(e, "Date_of_Birth", DynamicInputValues, setDynamicInputValues)
                            }}
                            placeholder="DD-MM-YYYY"
                            value={DynamicInputValues?.Date_of_Birth?.value}
                            className={'date-picker-auth'}
                            tabIndex={6}
                        />
                    </div>
                    <FormError show={!DynamicInputValues?.Date_of_Birth?.isValid && showError} error={'Please enter date of birth'} />
                </div>
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Given Name*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Given_name?.value}
                    type='text'
                    name='Given_name'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={7}
                />
                <FormError show={!DynamicInputValues?.Given_name?.isValid && showError} error={'Please enter given name.'} />
                {regexError === 'Given_name' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Place of Birth*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Place_of_Birth?.value}
                    type='text'
                    name='Place_of_Birth'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={8}
                />
                <FormError show={!DynamicInputValues?.Place_of_Birth?.isValid && showError} error={'Please enter place of birth.'} />
                {regexError === 'Place_of_Birth' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}>Place of Issue*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Place_of_issue?.value}
                    type='text'
                    name='Place_of_issue'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={9}
                />
                <FormError show={!DynamicInputValues?.Place_of_issue?.isValid && showError} error={'Please enter place of issue.'} />
                {regexError === 'Place_of_issue' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className={`form-field ${'details-InputClassName'}`}>
                <div>
                    <label htmlFor="" className={`mb-3 d-block text-xxs`}> Date of Issue*</label>
                    {/* <div className={`dob-wrap d-flex align-center ${'details-InputClassName'}`}>
                        <div className={`dob_para text-xs w-400 secondary d-flex align-center ${'details-InputClassName'} ${DynamicInputValues?.Date_of_issue?.value ? 'active' : ""}`}>{DynamicInputValues?.Date_of_issue?.value ? dateFormat(DynamicInputValues?.Date_of_issue?.value) : "DD-MM-YYYY"}</div>
                        <input
                            className={'details-InputClassName'}
                            type="date"
                            max={todayDate}
                            value={DynamicInputValues?.Date_of_issue?.value}
                            name='Date_of_issue'
                            onChange={handleInputChange}
                            onKeyUp={handleInputChange}
                        />
                    </div> */}
                    <div className="datePickerWrap">
                        <InputDatePicker
                            name="dob"
                            type="date"
                            maxDate={moment().toDate()}
                            dateFormat="dd-MM-yyyy"
                            onSelect={(e) => {
                                handleDateInput(e, "Date_of_issue", DynamicInputValues, setDynamicInputValues)
                            }}
                            placeholder="DD-MM-YYYY"
                            value={DynamicInputValues?.Date_of_issue?.value}
                            className={'date-picker-auth'}
                            tabIndex={10}
                        />
                    </div>
                    <FormError show={!DynamicInputValues?.Date_of_issue?.isValid && showError} error={'Please enter date of issue.'} />
                </div>
            </div>
            <div className={`form-field ${'details-InputClassName'}`}>
                <div>
                    <label htmlFor="" className={`mb-3 d-block text-xxs`}> Date of Expiry*</label>
                    <div className="datePickerWrap">
                        <InputDatePicker
                            name="dob"
                            type="date"
                            minDate={tomorrowDate}
                            // maxDate={farFutureDate}
                            dateFormat="dd-MM-yyyy"
                            onSelect={(e) => {
                                handleDateInput(e, "Date_of_Expiry", DynamicInputValues, setDynamicInputValues)
                            }}
                            placeholder="DD-MM-YYYY"
                            value={DynamicInputValues?.Date_of_Expiry?.value}
                            className={'date-picker-auth'}
                            tabIndex={11}
                        />
                    </div>
                    <FormError show={!DynamicInputValues?.Date_of_Expiry?.isValid && showError} error={'Please enter date of expiry.'} />
                </div>
            </div>
        </div>
    )
}

export default PassportFieldsFrontSide