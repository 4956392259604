// import { State, } from "country-state-city";
// import Select from "react-select";
// import '../Csc2.scss'
// import { useDispatch, useSelector } from "react-redux";
// import { setCity, setNativeCity, setNativeState, setState } from "../../../store/Actions/CountryStateCity";
// import { useEffect } from "react";

// const NativeStateSelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
//     const dispatch = useDispatch()
//     const countryStateCity = useSelector((state) => { return state?.countryStateCity })
//     const onChangeHandle = (item) => {
//         dispatch(setNativeState(item))
//         dispatch(setNativeCity(''))
//     }
//     useEffect(() => {
//         // { Defaultvalue !== false && Defaultvalue !== "undefined" && dispatch(setNativeState({ "name": Defaultvalue })); }
//         { Defaultvalue !== false && Defaultvalue !== "undefined" && dispatch(setNativeState(State?.getStatesOfCountry(countryStateCity?.native_address?.n_country?.isoCode).filter(state => { return state.name === Defaultvalue })[0])); }

//     }, [Defaultvalue, countryStateCity?.native_address?.n_states])
//     return (
//         <Select
//             options={State?.getStatesOfCountry(countryStateCity?.native_address?.n_country?.isoCode)}
//             getOptionLabel={(options) => {
//                 return options["name"];
//             }}
//             getOptionValue={(options) => {
//                 return options["name"];
//             }}
//             value={countryStateCity?.native_address?.n_states}
//             onChange={(item) => {
//                 onChangeHandle(item)
//             }}
//             placeholder={placeholder ? placeholder : 'State '}
//             className={ClassName}
//         />

//     )
// }

// export default NativeStateSelector



import { State } from "country-state-city";
import Select from "react-select";
import '../Csc2.scss';
import { useDispatch, useSelector } from "react-redux";
import { setCity, setNativeCity, setNativeState, setState } from "../../../store/Actions/CountryStateCity";
import { useEffect, useState } from "react";

const NativeStateSelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
    const dispatch = useDispatch();
    const [modifiedStates, setModifiedStates] = useState([]);
    const countryStateCity = useSelector((state) => state?.countryStateCity);

    const onChangeHandle = (item) => {
        dispatch(setNativeState(modifyStateName(item)));
        dispatch(setNativeCity(''));
    };

    const modifyStateName = (state) => {
        if (!state) return state;
        switch (state.name) {
            case 'Abu Dhabi Emirate':
                return { ...state, name: 'Abu Dhabi' };
            case 'Ajman Emirate':
                return { ...state, name: 'Ajman' };
            case 'Sharjah Emirate':
                return { ...state, name: 'Sharjah' };
            default:
                return state;
        }
    };

    useEffect(() => {
        if (countryStateCity?.native_address?.n_country?.isoCode) {
            let fetchedStates = State.getStatesOfCountry(countryStateCity.native_address.n_country.isoCode);
            fetchedStates = fetchedStates.map(modifyStateName);
            setModifiedStates(fetchedStates);
        }
    }, [countryStateCity?.native_address?.n_country?.isoCode]);

    useEffect(() => {
        if (Defaultvalue !== false && countryStateCity?.native_address?.n_country?.isoCode) {
            const state = State.getStatesOfCountry(countryStateCity.native_address.n_country.isoCode).find(state => {
                return modifyStateName(state).name === Defaultvalue;
            });
            if (state) {
                dispatch(setNativeState(state));
            }
        }
    }, [Defaultvalue, countryStateCity?.native_address?.n_country?.isoCode, dispatch]);

    return (
        <Select
            options={modifiedStates}
            getOptionLabel={(options) => options.name}
            getOptionValue={(options) => options.isoCode}
            value={countryStateCity?.native_address?.n_states ? modifyStateName(countryStateCity.native_address.n_states) : null}
            onChange={onChangeHandle}
            placeholder={placeholder ? placeholder : 'State '}
            className={ClassName}
        />
    );
};

export default NativeStateSelector;
