import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientSearch, getClientsList, getUsersClientsList, resetClientList, resettSingleClient, setFetchType } from '../../store/Actions/Clients'
import Auth from '../../Classes/Auth'
import { useLocation } from 'react-router-dom'

const useClients = () => {
    const clients = useSelector((e) => e.clients)
    const dispatch = useDispatch()
    const userId = Auth?.user()?.user_id // this is for added by user
    const userId_user = Auth?.user()?.user_id_user
    const location = useLocation();
    useEffect(() => {
        if (clients?.fetch_type?.length == 0) {
            if (Auth?.boolUserType() == 4) {
                dispatch(getUsersClientsList(userId_user, userId))
            } else {
                dispatch(getClientsList(userId))
            }

        } else {
            dispatch(clientSearch(userId, clients?.fetch_type))
        }
        dispatch(resettSingleClient())
    }, [])

    useEffect(() => {
        if (location?.pathname?.includes("/clients-management")) {
            dispatch(setFetchType(''))
            dispatch(resetClientList())
        }
    }, [])

    // useLayoutEffect(() => {
    //     if (location?.pathname.includes('folders')) {
    //         dispatch(resetFolderList())
    //     }
    // }, [])

    return {}
}

export default useClients