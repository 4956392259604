import topButtonAction from "../Actions/TopActionButtons/actionType";
const initState = {
    docType: "Select document type *"
}
const topActionButtons = (state = initState, action) => {
    switch (action.type) {
        case topButtonAction.SELECT_DOCUMENT_TYPE:
            return {
                ...state,
                docType: action.payload
            }
        default: return state
    }
}

export default topActionButtons