import Request from "../../../Classes/Requests";

class remindersReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            list_of_reminder: super.url("/list_of_reminder"),
            reminder_2: super.url("/reminder_2/__EMPLOYEE__ID?Document_type=__VALUE__"),
        }
    }
}
export default new remindersReq();
