import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
import { Autoplay, Parallax, Pagination, Navigation, Keyboard, Mousewheel, EffectCoverflow, Grid } from 'swiper/modules';

const SliderTest = () => {
    const swiperConfig = {
        className: "wfd",
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        speed: 1200,
        mousewheel: {
            enabled: true
        },
        keyboard: {
            enabled: true
        },
        coverflowEffect: {
            rotate: 50,
            slideShadows: true
        },
        pagination: {
            clickable: true,
        },
        observer: true,
        observeParents: true,
        parallax: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        modules: [Keyboard, Mousewheel, Pagination, Navigation, EffectCoverflow, Parallax, Grid],
    };
    return (
        <>

            <Swiper {...swiperConfig}>
                <SwiperSlide >
                    1
                </SwiperSlide>
                <SwiperSlide >
                    2
                </SwiperSlide>
                <SwiperSlide >
                    3
                </SwiperSlide>
            </Swiper>
            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
        </>
    )
}

export default SliderTest