import { Employee_Management_Actions } from "../Actions/EmployeeManagement/actionType"


const initState = {
    data: [],
    loading: false,
    employee_type: '',
    client: {
        business_name: "Select client*"
    },
    bulk_upload: {
        loading: false,
        success: false,
        error: false,
        data: []
    }
}

const employeeManagement = (state = initState, { type, payload }) => {
    switch (type) {
        case Employee_Management_Actions.SET_LOADING: return {
            ...state,
            loading: true
        }
        case Employee_Management_Actions.GET_ALL_EMPLOYEE: return {
            ...state,
            loading: false,
            data: payload
        }
        case Employee_Management_Actions.RESET_ALL_EMPLOYEE: return {
            ...state,
            data: []
        }
        case Employee_Management_Actions.SET_EMPLOYEE_TYPE: return {
            ...state,
            employee_type: payload
        }
        case Employee_Management_Actions.SET_SEARCH: return {
            ...state,
            data: payload
        }
        case Employee_Management_Actions.SET_CLIENT: return {
            ...state,
            client: payload
        }
        case Employee_Management_Actions.SET_BULK_UPLOAD_LOADING: return {
            ...state,
            bulk_upload: {
                ...state.bulk_upload,
                loading: true,
            }
        }
        case Employee_Management_Actions.SET_BULK_UPLOAD_SUCCESS: return {
            ...state,
            bulk_upload: {
                ...state.bulk_upload,
                loading: false,
                success: true,
                error: false
            }
        }
        case Employee_Management_Actions.RESET_BULK_UPLOAD: return {
            ...state,
            bulk_upload: {
                ...state.bulk_upload,
                success: false,
                error: false
            }
        }
        case Employee_Management_Actions.SHOW_BULK_UPLOAD_ERROR: return {
            ...state,
            bulk_upload: {
                ...state.bulk_upload,
                error: true,
                loading: false,
                success: false,
                data: payload

            }
        }
        case Employee_Management_Actions.RESET_BULK_UPLOAD_ERROR: return {
            ...state,
            bulk_upload: {
                ...state.bulk_upload,
                error: false,
                loading: false,
                success: false,
                data: []

            }
        }

        default: return state
    }
}

export default employeeManagement