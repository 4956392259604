
import Auth from '../../../Classes/Auth';
import { showSuccessToast } from '../SuccessMessagePopup';
import SupportAction from './actionType'
import supportReq from './supportReq';
const userId = Auth?.user()?.user_id // this is for added by user
const setCreateTicket = (data, userId) => {
    return async (dispatch) => {
        supportReq.post(supportReq.endpoint.tickets_create, data, (success) => {
            dispatch({ type: SupportAction.SET_CREATE_TICKET_SUCCESS })
            dispatch(showSuccessToast("Ticket created successfully"))
            dispatch(getSupportList(userId))
        },
            (error) => {

            }
        )
    }
}
const getSupportList = (id) => {
    return async (dispatch) => {
        supportReq.get(supportReq.endpoint.tickets_list.replace("__USER_ID__", id), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch({ type: SupportAction.SET_TICKET_LIST_SUCCESS, payload: payload })
        },
            (error) => {

            }
        )
    }
}

const viewTicket = (id) => {
    return async (dispatch) => {
        supportReq.get(supportReq.endpoint.tickets_view.replace("__ID__", id), (success) => {
            dispatch({ type: SupportAction.SET_TICKET_VIEW_SUCCESS, payload: success?.data })
        },
            (error) => {

            }
        )
    }
}

const updateTicket = (data, id) => {
    return async (dispatch) => {
        supportReq.patch(supportReq.endpoint.tickets_update.replace("__ID__", id), data, (success) => {
            // dispatch({ type: SupportAction.SET_TICKET_VIEW_SUCCESS, payload: success?.data })
            dispatch(getSupportList(userId))
            dispatch(showSuccessToast("Ticket updated successfully!"))
        },
            (error) => {

            }
        )
    }
}


const setTicketSearch = (value) => {
    return async (dispatch) => {
        supportReq.get(supportReq.endpoint.serach_tickets.replace('__VALUE__', value).replace('__USER_ID__', userId), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch({ type: SupportAction.SET_TICKET_LIST_SUCCESS, payload: payload })
        }, (error) => {

        }
        )
    }
}

const resetViewTicket = () => {
    return { type: SupportAction.RESET_TICKET_VIEW_SUCCESS }
}
const resetCreateTicket = () => {
    return { type: SupportAction.RESET_CREATE_TICKET_SUCCESS }
}

export {
    setCreateTicket, resetCreateTicket, getSupportList, viewTicket,
    resetViewTicket, updateTicket, setTicketSearch
}