import React, { useEffect, useState } from 'react'
import '../DynamicdocumentFields.scss'
import Dropdown from '../../../../../CommanComponents/Dropdown/Dropdown'
import useDocumentDetails from '../../../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
import FormError from '../../../../../CommanComponents/Form/FormError'
const PassportFieldsBackSide = ({ DynamicInputValues, handleInputChange, showError }) => {
    const [regexError, setRegexError] = useState();

    const handleRegexValidation = (e) => {
        let inputName = e.target.name;
        if (inputName === 'Name_of_father' || inputName === 'Name_of_mother' || inputName === "Name_of_Spouse") {
            if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                e.preventDefault();
                setRegexError(inputName)
            }
        } else {
            setRegexError('')
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setRegexError('')
        }, 1500);
    }, [regexError])
    return (
        <div className="DynamicdocumentFields-main-wrapper document-dyanmic-fields">
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Name of Father / Legal Guardian*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Name_of_father?.value}
                    type='text'
                    name='Name_of_father'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={12}
                />
                <FormError show={!DynamicInputValues?.Name_of_father?.isValid && showError} error={'Please enter name of father.'} />
                {regexError === 'Name_of_father' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Name of Mother*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Name_of_mother?.value}
                    type='text'
                    name='Name_of_mother'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={13}
                />
                <FormError show={!DynamicInputValues?.Name_of_mother?.isValid && showError} error={'Please enter name of mother'} />
                {regexError === 'Name_of_mother' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Name of Spouse</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Name_of_Spouse?.value}
                    type='text'
                    name='Name_of_Spouse'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    onKeyDown={(e) => handleRegexValidation(e)}
                    tabIndex={14}
                />
                {/* <FormError show={!DynamicInputValues?.Name_of_Spouse?.isValid && showError} error={'Please enter name of spouse.'} /> */}
                {regexError === 'Name_of_Spouse' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Address*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Address?.value}
                    type='text'
                    name='Address'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    tabIndex={15}
                />
                <FormError show={!DynamicInputValues?.Address?.isValid && showError} error={'Please enter address.'} />
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}> Old Passport No. with Date and Place of Issue*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.Old_Passport_No_with_Date_and_Place_of_Issue?.value}
                    type='text'
                    name='Old_Passport_No_with_Date_and_Place_of_Issue'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    tabIndex={16}
                />
                <FormError show={!DynamicInputValues?.Old_Passport_No_with_Date_and_Place_of_Issue?.isValid && showError} error={'Please enter old passport no with date and place of issue'} />
            </div>
            <div className="form-field" >
                <label htmlFor="" className={`mb-3 d-block text-xxs`}>File No.*</label>
                <input
                    className='details-InputClassName'
                    value={DynamicInputValues?.File_No?.value}
                    type='text'
                    name='File_No'
                    onChange={handleInputChange}
                    onKeyUp={handleInputChange}
                    tabIndex={17}
                />
                <FormError show={!DynamicInputValues?.File_No?.isValid && showError} error={'Please enter file no.'} />
            </div>

        </div>
    )
}

export default PassportFieldsBackSide