import Request from "../../../Classes/Requests";

class allEmployeesReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            dashboard_details: super.url("/dashboard_details?added_by_user_id=USER_ID"),
        }
    }
}
export default new allEmployeesReq();
