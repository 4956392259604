import React, { useLayoutEffect, useState } from 'react'
import RightSidebar from '../../RightSideBar/RightSidebar'
import '../forgotPaswword.scss'
import backIcon from '../../../../Assets/icons/back-icon.svg'
import { useNavigate } from 'react-router-dom'
import ValidationFile from '../../Login/ValidationFile'
import FormError from '../../../../CommanComponents/Form/FormError'
import { setNewPassword } from '../../../../store/Actions/ForgotPassword'
import { useDispatch, useSelector } from 'react-redux'
const NewPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const forgotPassword = useSelector((state) => state.forgotPassword)
    const [eyeBtn, setEyeBtn] = useState(true)
    const [eyeBtn1, setEyeBtn1] = useState(true)
    const [showError, setShowError] = useState(false);
    const [passCondition, setPassCondition] = useState(false);
    const [re_passCondition, setRe_PassCondition] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');

    const [newPasswordData, setNewPasswordData] = useState({
        new_password1: {
            value: "",
            isValid: false,
        },
        new_password2: {
            value: "",
            isValid: false,
        },
        validation: false,
    });

    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "new_password1":
                return ValidationFile.ValidPasswordHard(inputValue);

            case "new_password2":
                return ValidationFile.samePassword(newPasswordData.new_password1.value, inputValue);
            default:
                return false;
        }
    };
    const isFormValid = () => {
        return newPasswordData.new_password1.isValid && newPasswordData.new_password2.isValid ? true : false;
    };

    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...newPasswordData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setNewPasswordData(data);

        if (inputName === "new_password1") {
            newPasswordData.new_password2.isValid = ValidationFile.samePassword(inputValue, newPasswordData.new_password2.value);

        } else if (inputName === "new_password2") {
            newPasswordData.new_password2.isValid = ValidationFile.samePassword(newPasswordData.new_password1.value, inputValue);
            if (inputValue.length > 0) {
                setRe_PassCondition(true)
            } else {
                setRe_PassCondition(false)
            }

        }

        if (inputName === "new_password1") {
            if (newPasswordData.new_password1.isValid) {
                setPassCondition(false)
            } else {
                setPassCondition(true)
            }
        }


    }

    const clickHandle = () => {
        const data = {
            email: emailParam,
            password: newPasswordData.new_password1.value
        }
        if (newPasswordData.validation) {
            dispatch(setNewPassword(data))
        } else {
            setShowError(true);
        }
    }

    useLayoutEffect(() => {
        if (forgotPassword.new_password) {
            navigate('/login')
        }
    }, [forgotPassword])
    return (
        <div className="forgot-wrapper">
            <RightSidebar>
                <div className="form-container">
                    <button className="back-wrap mb-40 transparent" onClick={() => navigate(-1)}>
                        <img src={backIcon} alt="" />
                    </button>
                    <div className="form-wrap">
                        <div className="form">
                            <h3 className="heading text-md w-500 primary text-center">Welcome</h3>
                            <p className="sub-heading w-400 text-center secondary">Change your paasword here</p>
                            <div className="form-control mt-40">
                                <div className="form-field ">
                                    <input type={eyeBtn ? 'password' : 'text'} name="new_password1" placeholder='New password *' className='confirm-pass-input'
                                        value={newPasswordData.new_password1.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                    />
                                    <button className="eye-btn" type='button' onClick={() => setEyeBtn((e) => !e)}>
                                        {/* <img src={eyeBtn ? EyeClose : EyeOpen} alt="" /> */}
                                        <i className={`${eyeBtn ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                    </button>
                                    {/* <FormError show={!newPasswordData.new_password1.isValid && showError} error={'Please enter new password'} /> */}
                                    {
                                        (passCondition || !newPasswordData.new_password1.isValid && showError) &&
                                        <div className='secondary text-xxs mt-10'>
                                            <p className={`danger mb-3`}> Password Condition :</p>
                                            <p> At least 8 characters long</p>
                                            <p> Include both lowercase and uppercase character</p>
                                            <p> One number or symbol</p>
                                        </div>
                                    }
                                </div>
                                <div className="form-field ">
                                    <input type={eyeBtn1 ? 'password' : 'text'} name="new_password2" placeholder='Confirm new password *' className='confirm-pass-input'
                                        value={newPasswordData.new_password2.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}

                                    />
                                    <button className="eye-btn" type='button' onClick={() => setEyeBtn1((e) => !e)}>
                                        <i className={`${eyeBtn1 ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                    </button>
                                    {/* <FormError show={!newPasswordData.new_password2.isValid && showError} error={'Please enter new password'} /> */}
                                    <FormError show={!newPasswordData.new_password2.isValid && showError} error={re_passCondition ? "Password not matched." : 'Please re-enter password'} />
                                </div>
                            </div>
                            <button type='button' className="login-button pT-10 pb-10 mt-30 w-400 text-s transparent white" onClick={() => clickHandle()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </RightSidebar>
        </div>
    )
}

export default NewPassword