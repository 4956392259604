import React, { useEffect, useRef, useState } from 'react'
import './SignupStyle.scss'
import EyeOpen from '../../../Assets/icons/lock-icon.svg'
import backIcon from '../../../Assets/icons/back-icon.svg'
import { Link, useNavigate } from "react-router-dom";
import CompleteProfile from './CompleteProfile/CompleteProfile'
import RightSidebar from '../RightSideBar/RightSidebar'
// import MobileInput from '../../../CommanComponents/MobileInput/MobileInput'
import Auth from '../../../Classes/Auth'
import ValidationFile from '../Login/ValidationFile';
import FormError from '../../../CommanComponents/Form/FormError';
import PhoneInput from 'react-phone-input-2';
import StepperBar from '../../../CommanComponents/StepperBar/StepperBar';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserExists, resetErrorMessage, resetSendEmailVerify, signupTypingCenter } from '../../../store/Actions/Auth';
import Storage from '../../../Classes/Storage';
import { user_offer_type, userType } from '../../../Constant/auth';
import { hideSuccessToast } from '../../../store/Actions/SuccessMessagePopup';
import { useTodayDate } from '../../../CustomHooks/useTodayDate';
import Validation from '../../../Classes/Validation';
import EmailSent from './EmailSent';
import RegexValid from '../../../Classes/RegexValid';
const Signup = () => {
    const { signUp, message, emailVerify } = useSelector((state) => {
        return ({
            signUp: state.auth.signUp,
            message: state.auth.message,
            emailVerify: state.auth.emailVerify
        })
    })

    const auth = useSelector((e) => e.auth)

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [eyeBtn, setEyeBtn] = useState(true)
    const [eyeBtn1, setEyeBtn1] = useState(true)
    const [completeProfile, setCompleteProfile] = useState(false)
    const [showLoginError, setShowLoginError] = useState(false);
    const [regexError, setRegexError] = useState();
    const [showMobileInput, setShowMobileInput] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [passCondition, setPassCondition] = useState(false);
    const [re_passCondition, setRe_PassCondition] = useState(false);
    const todayDate = useTodayDate()
    const validRegex = new Validation()
    const validReg = new RegexValid()
    const phoneInputRef = useRef(null);
    // frist page data state
    const [registerTC, setRegisterTC] = useState({
        _business_name: {
            value: "",
            isValid: false,
        },
        contact_person_name: {
            value: "",
            isValid: false,
        },
        first_name: {
            value: "",
            isValid: false,
        },
        last_name: {
            value: "",
            isValid: false,
        },
        _email: {
            value: "",
            isValid: false,
        },
        _phone: {
            value: "",
            country_code: "+971",
            isValid: false,
        },
        password: {
            value: "",
            isValid: false,
        },
        re_password: {
            value: "",
            isValid: false,
        },
        validation: false,
    });


    // check input vafildation
    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "_business_name":
                return ValidationFile.validEmpty(inputValue);

            case "contact_person_name":
                return ValidationFile.validEmpty(inputValue);

            case "first_name":
                return ValidationFile.validEmpty(inputValue);

            case "last_name":
                return ValidationFile.validEmpty(inputValue);

            case "_email":
                return ValidationFile.validEmail(inputValue);

            case "_phone":
                return ValidationFile.validPhoneNumber(inputValue);
            // var pattern = new RegExp(/[0-9]{9,}/)
            // if (pattern.test(inputValue)) {
            //     return true
            // }
            // else {
            //     return false
            // }

            case "password":
                return ValidationFile.ValidPasswordHard(inputValue);

            case "re_password":
                return ValidationFile.samePassword(registerTC.password.value, inputValue);

            default:
                return false;
        }
    };

    // check form is validation 
    const isFormValid = () => {
        if (Auth?.boolUserType() != 3) {
            return (
                registerTC._business_name.isValid &&
                    registerTC.contact_person_name.isValid &&
                    registerTC._email.isValid &&
                    registerTC._phone.isValid &&
                    registerTC.password.isValid &&
                    registerTC.re_password.isValid
                    ? true : false
            );
        } else if (Auth?.boolUserType() == 3) {
            return (
                registerTC.first_name.isValid &&
                    registerTC.last_name.isValid &&
                    registerTC._email.isValid &&
                    registerTC._phone.isValid &&
                    registerTC.password.isValid &&
                    registerTC.re_password.isValid
                    ? true : false
            );
        }
    };

    // handle input fileds
    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName == "first_name" || inputName == "last_name" || inputName == "contact_person_name") {
            // Capitalize the first letter of the input value
            inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        }
        let registrationData = {
            ...registerTC,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        // setRegisterTC(registrationData);
        switch (inputName) {
            case "first_name":
            case "last_name":
            case "_business_name":
            case "contact_person_name":
                if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
                    return setRegisterTC(registrationData), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            default: setRegisterTC(registrationData);
        }

        if (inputName === "_email") {
            dispatch(resetErrorMessage())
        }
        if (inputName === "password") {
            registrationData.re_password.isValid = ValidationFile.samePassword(inputValue, registerTC.re_password.value);
            // if (inputValue > 0) {
            //     setRe_PassCondition(true)
            // } else {
            //     setRe_PassCondition(false)
            // }
        } else if (inputName === "re_password") {
            registrationData.re_password.isValid = ValidationFile.samePassword(registerTC.password.value, inputValue);
            if (inputValue.length > 0) {
                setRe_PassCondition(true)
            } else {
                setRe_PassCondition(false)
            }

        }

        if (inputName === "password") {
            if (registrationData.password.isValid) {
                setPassCondition(false)
            } else {
                setPassCondition(true)
            }
        }

    };

    // console.log(re_passCondition, "sdcdfed")
    // handle phone input 
    const handlePhoneInput = (inputValue, countryDetail, nameValue) => {
        let dialCode = countryDetail.dialCode;
        let mobileNumber = inputValue.replace(dialCode, "");
        let mobileData = {
            ...registerTC,
            "_phone": {
                value: mobileNumber,
                country_code: dialCode,
                isValid: validationConfirm(mobileNumber, "_phone"),
            }
        }
        setRegisterTC(mobileData)
    }

    // first page data 
    let setUserType;
    if (Auth.userType() == "Business Owner") {
        setUserType = 1
    } else if (Auth.userType() == "Typing Center") {
        setUserType = 2
    } else if (Auth.userType() == "Individual") {
        setUserType = 3
    }
    const singleData = {
        business_name: registerTC._business_name.value,
        contact_person_name: registerTC.contact_person_name.value,
        email: registerTC._email.value,
        phone_no: `${registerTC._phone.country_code} ${registerTC._phone.value}`,
        password: registerTC.password.value,
        user_type: setUserType,
        first_name: registerTC.first_name.value,
        last_name: registerTC.last_name.value,
        subscription_type: Storage.getString(user_offer_type)
    }
    // handle first register page button
    const handleRegister = (e) => {
        e.preventDefault()
        if (isFormValid()) {
            if (Auth.userType() === "Typing Center") {
                dispatch(signupTypingCenter(singleData))
            } else {
                const data = {
                    email: registerTC._email.value,
                }
                dispatch(checkUserExists(data))
                // setCompleteProfile(true)
                // dispatch({ type: "stepperInc" })
            }
        } else {
            setShowLoginError(true);
        }
    }

    useEffect(() => {
        // if (signUp.success) {
        //     navigate('/login')
        // }
        dispatch(resetErrorMessage())
    }, [signUp.success])


    const handleRegexValidation = (e) => {
        let inputName = e.target.name;
        if (inputName === '_business_name') {
            if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                e.preventDefault();
                setRegexError(inputName)
            }
        }
        else {
            setRegexError('')
        }
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         setRegexError('')
    //     }, 1500);
    // }, [regexError])

    useEffect(() => {
        if (emailVerify?.success) {
            setEmailSent(true)
            dispatch(resetSendEmailVerify())
        }

    }, [emailVerify?.success])


    const focusMobile = () => {
        setShowMobileInput(true)
        if (phoneInputRef?.current) {
            phoneInputRef?.current?.numberInputRef?.focus();
        }
    }

    // useEffect(() => {
    //     if (phoneInputRef?.current) {
    //         phoneInputRef?.current?.numberInputRef?.focus();
    //     }
    // }, [showMobileInput, focusMobile])

    useEffect(() => {
        if (auth?.exist_user?.success) {
            setCompleteProfile(true)
            dispatch({ type: "stepperInc" })
        }
    }, [auth?.exist_user])

    console.log(auth, "Sxcxvc")

    if (Storage.alive(userType)) {
        return (
            <div className="signup-wrapper">
                {!completeProfile ?
                    <RightSidebar >
                        {Auth.userType() !== "Typing Center" && <div className="stepper-wrapper mb-10 mt-3">
                            <StepperBar StepsAmount={2} />
                        </div>}
                        <div className="form-container">
                            <button className="back-wrap mb-40 transparent" onClick={() => navigate('/login')}>
                                <img src={backIcon} alt="" tabIndex={12} />
                            </button>
                            <div className="form-wrap">
                                {!emailSent ?
                                    <form className="form" onSubmit={(e) => handleRegister(e)}>
                                        <h3 className="heading text-md w-500 primary text-center">Register as {Auth.userType() === "Individual" ? "Individual" : Auth.userType() === "Typing Center" ? "Typing / Business Center" : "Business Owner"} </h3>
                                        <p className="sub-heading w-400 text-center secondary">  Please enter your detail</p>
                                        <div className="form-control mt-40">
                                            {
                                                Auth.boolUserType() !== 3 ?
                                                    <>
                                                        <div className="form-field ">
                                                            <input className='signup-input-filed' type="text" name="_business_name" placeholder='Business Name*'
                                                                onChange={handleInput}
                                                                // onKeyUp={handleInput}
                                                                // onKeyDown={e => handleRegexValidation(e)}
                                                                value={registerTC._business_name.value}
                                                                tabIndex={1}
                                                            />
                                                            <FormError show={!registerTC._business_name.isValid && showLoginError} error={'Please enter business name'} />
                                                            {regexError === '_business_name' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
                                                        </div>
                                                        <div className="form-field ">
                                                            <input className='signup-input-filed' type="text" name="contact_person_name" placeholder='Contact Person Name*'
                                                                onChange={handleInput}
                                                                // onKeyUp={handleInput}
                                                                // onKeyDown={e => handleRegexValidation(e)}
                                                                value={registerTC.contact_person_name.value}
                                                                tabIndex={2}
                                                            />
                                                            <FormError show={!registerTC.contact_person_name.isValid && showLoginError} error={'Please enter contact person name'} />
                                                            {regexError === 'contact_person_name' && <FormError show={true} error={'Please enter only alphabetical character.'} />}
                                                        </div>

                                                    </>
                                                    :
                                                    <>
                                                        <div className="form-field ">
                                                            <input type="text" name="first_name" id="" placeholder='First Name*' className='signup-input-filed'
                                                                value={registerTC.first_name.value}
                                                                onChange={handleInput}
                                                                tabIndex={1}
                                                            />
                                                            <FormError show={!registerTC.first_name.isValid && showLoginError || regexError === "first_name"} error={regexError === "first_name" ? 'Please enter only alphabetical character.' : 'Please enter first name'} />

                                                        </div>
                                                        <div className="form-field ">
                                                            <input type="text" name="last_name" id="" placeholder='Last Name*' className='signup-input-filed'
                                                                value={registerTC.last_name.value}
                                                                onChange={handleInput}
                                                                tabIndex={2}
                                                            />
                                                            <FormError show={!registerTC.last_name.isValid && showLoginError || regexError === "last_name"} error={regexError === "last_name" ? 'Please enter only alphabetical character.' : 'Please enter last name'} />
                                                        </div>
                                                    </>
                                            }
                                            <div className="form-field ">
                                                <input className='signup-input-filed' type="text" name="_email" placeholder='Email*'
                                                    onChange={handleInput}
                                                    onKeyUp={handleInput}
                                                    value={registerTC._email.value}
                                                    // tabIndex={Auth?.boolUserType() == 3 ? '3' : ""}
                                                    tabIndex={3}
                                                />
                                                {message?.length ?
                                                    <FormError show={true} error={message} />
                                                    : <FormError show={!registerTC._email.isValid && showLoginError} error={'Please enter valid email'} />
                                                }
                                            </div>

                                            <div className="form-field" tabIndex={4} onFocus={() => focusMobile()}>

                                                {/* <MobileInput /> */}
                                                {!showMobileInput &&
                                                    <>
                                                        <input className={'signup-input-filed'} type="text" placeholder='Mobile Number*'

                                                        // tabIndex={4}
                                                        />
                                                        <FormError show={!registerTC._phone.isValid && showLoginError} error={'Please enter phone number.'} />
                                                    </>
                                                }
                                                {true && < div className={`auth-phpne-wrapper ${showMobileInput && 'active'}`}>

                                                    <PhoneInput
                                                        ref={phoneInputRef}
                                                        countryCodeEditable={false}
                                                        containerClass="custom-form-container"
                                                        inputClass="custom-form-input"
                                                        specialLabel
                                                        value={`${registerTC._phone.country_code} ${registerTC._phone.value}`}
                                                        onChange={(value, formattedValue, nameValue) => {
                                                            handlePhoneInput(value, formattedValue, nameValue);
                                                        }}
                                                        onKeyUp={(value, formattedValue, nameValue) => {
                                                            handlePhoneInput(value, formattedValue, nameValue);
                                                        }}
                                                        onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                                        preserveOrder={['onlyCountries', 'preferredCountries']}
                                                        inputProps={{
                                                            name: "_phone",
                                                            autoFocus: false,
                                                            placeholder: "Phone Number *",
                                                            // ref: phoneInputRef,
                                                            // tabIndex: Auth?.boolUserType() == 3 ? '3' : ""
                                                            tabIndex: 4

                                                        }}
                                                        enableSearch
                                                        name="mobileNo"
                                                        searchPlaceholder="Search Country"
                                                        disableSearchIcon
                                                    />
                                                    <FormError show={!registerTC._phone.isValid && showLoginError}
                                                        error={
                                                            registerTC._phone.value.length < 9 && registerTC._phone.value.length > 0
                                                                ? 'Please enter correct phone number.'
                                                                : 'Please enter phone number.'
                                                        } />
                                                </ div>}
                                            </div>
                                            <div className="form-field ">
                                                <div className='relative'>

                                                    <input className='signup-input-filed pass-input' type={eyeBtn ? 'password' : 'text'} name="password" placeholder='Password*'
                                                        onChange={handleInput}
                                                        onKeyUp={handleInput}
                                                        value={registerTC.password.value}
                                                        onKeyDown={e => handleRegexValidation(e)}
                                                        onFocus={() => !registerTC.password.isValid && setPassCondition(true)}
                                                        // onBlur={() => setPassCondition(false)}
                                                        maxLength={16}
                                                        tabIndex={5}
                                                    />
                                                    <button className="eye-btn" type='button' onClick={() => setEyeBtn((e) => !e)}>
                                                        <i className={`${eyeBtn ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                                    </button>
                                                </div>
                                                {/* <FormError
                                                    show={!registerTC.password.isValid && showLoginError}
                                                    error={"Password should be 6-16 chars, 1 digit, 1 special char required."}
                                                /> */}
                                                {
                                                    (passCondition || !registerTC.password.isValid && showLoginError) &&
                                                    <div className='secondary text-xxs mt-10'>
                                                        <p className={`danger mb-3`}> Password Condition :</p>
                                                        <p> At least 8 characters long</p>
                                                        <p> Include both lowercase and uppercase character</p>
                                                        <p> One number or symbol</p>
                                                    </div>
                                                }

                                            </div>
                                            <div className="form-field ">
                                                <div className='relative'>
                                                    <input className='signup-input-filed pass-input' type={eyeBtn1 ? 'password' : 'text'} name="re_password" placeholder='Re-Enter Password*'
                                                        onChange={handleInput}
                                                        onKeyUp={handleInput}
                                                        value={registerTC.re_password.value}
                                                        tabIndex={6}
                                                    />
                                                    <button className="eye-btn" type='button' onClick={() => setEyeBtn1((e) => !e)}>
                                                        <i className={`${eyeBtn1 ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                                    </button>
                                                </div>
                                                <FormError show={!registerTC.re_password.isValid && showLoginError} error={re_passCondition ? "Password not matched." : 'Please re-enter password'} />
                                            </div>

                                        </div>
                                        <button type='submit' className="login-button pT-10 pb-10 mt-30 w-400 text-s transparent white"
                                            // onClick={() => handleRegister()}
                                            tabIndex={7}
                                        >
                                            {emailVerify?.loading ? "Sending email..." : "Register"}
                                        </button>
                                        <p className='signup-btn text-center mt-30 text-xs w-400'>Have an Account? <Link className='pointer w-600' to={'/login'}> Login</Link></p>
                                    </form>
                                    : <EmailSent />
                                }
                            </div>
                        </div>
                    </RightSidebar>

                    : <CompleteProfile
                        setCompleteProfile={setCompleteProfile}
                        singleData={singleData}
                        emailSent={emailSent}
                        setEmailSent={setEmailSent}
                    />}
            </div>
        )
    } else {
        window.location.replace('login')
    }
}

export default Signup