import React, { useRef, useState } from 'react'
import './users.scss'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import usePageListData from '../../CustomHooks/usePageListData'
import SearchControl from '../../CommanComponents/SearchControl/SearchControl'
import AddUserPop from './AddUserPop/AddUserPop'
import useUsers from '../../CustomHooks/Users/useUsers'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
const Users = () => {
    const user = useSelector((e) => e.user)
    const { addUserData, handleInputChange, showError, handlePhoneInput, addUserSaveBtn, handleSelectDropdown,
        addUserRef, AddUserClose, AddUserClickHanlder, editBtnHanlder, deleteUserHandler, updateID, regexError } = useUsers()
    const ref = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [activeManage, setActiveManage] = useDetectOutsideClick(ref, false)
    const [searchVal, setSearchVal] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }
    const handleResetSearch = () => {
        setSearchVal('')
    }
    const handleManageBtn = (e, val) => {
        e.stopPropagation()
        setActiveManage(activeManage === val ? -1 : val)
    }
    const EmployeeClickHandle = (id, name) => {
        navigate(`user-details?users_id=${id}&user_name=${name}`)
    }
    const dataForCurrentPage = usePageListData(user?.data, pageNumber)

    const deleteClickHandler = (id) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }

    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }

    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeletePop(false);
        deleteUserHandler(deleteId)
    }
    console.log(user, "Afdsfgf")
    return (
        < >
            <div className="employees-management-wrapper user-main-wrapper">
                <div className="employees-management-wrapper">
                    <div className="heading-search-item justify-between align-center">
                        <SectionHeading>
                            User Details
                        </SectionHeading>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                        </div>

                        <div className="actions-wrappper">
                            <CommanButton ClassName={"action-btn"} onClick={AddUserClickHanlder} >
                                <p className='w-700'><span className='text-xs'>  + </span>Add User</p>
                            </CommanButton>
                        </div>
                        <PaginationSimple
                            className={"document-pagenation"}
                            data={user?.data}
                            shows={user?.data?.length < 20 ? user?.data?.length : "20"}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />
                    </div>
                    <div className="employees-management-table-wrapper mt-10">
                        {dataForCurrentPage?.length ?
                            <div className="table-wrap">
                                <table className="table-item">
                                    <thead className='table-head'>
                                        <tr className="table-row">
                                            <th className=''>ID </th>
                                            <th className=''>Name</th>
                                            <th className=''>Email</th>
                                            <th className=''>Mobile</th>
                                            <th className=''>Type</th>
                                            <th className=''>Assign Clients</th>
                                            <th className=''>Action</th>
                                        </tr>
                                    </thead>


                                    <tbody className='table-body'>
                                        {dataForCurrentPage?.map((val, key) => {
                                            return (
                                                <tr className="table-row" key={key} onClick={() => EmployeeClickHandle(val.id, val?.full_name)}>
                                                    <td  > {val?.user_id}  </td>
                                                    <td> {val?.full_name} </td>
                                                    <td > {val?.email} </td>
                                                    <td> {val?.phone_no} </td>
                                                    <td> {val?.type}</td>
                                                    <td> {val?.count_assigned_client} </td>
                                                    <td onClick={(e) => e.stopPropagation()}>
                                                        <div className='dots-wrap d-flex justify-center align-center relative' onClick={(e) => { handleManageBtn(e, key) }} >
                                                            <i className='dots-icon' > </i>
                                                        </div>
                                                        {<div className={`dropdown-wrap absolute ${activeManage === key && 'active'}`} ref={ref}>
                                                            {/* <div className="item d-flex align-center mb-10 pointer "  >
                                                                <i className='icons mr-5 add '></i>
                                                                <p className="text-2xs w-500 secondary">Add Document</p>
                                                            </div> */}
                                                            <div className="item d-flex align-center mb-10 pointer"
                                                                onClick={() => deleteClickHandler(val?.id)}
                                                            >
                                                                <i className='icons mr-5 delete'></i>
                                                                <p className="text-2xs w-500 secondary"

                                                                >Delete</p>
                                                            </div>
                                                            <div className="item d-flex align-center pointer" onClick={() => editBtnHanlder(val?.id)} >
                                                                <i className='icons mr-5 edit'></i>
                                                                <p className="text-2xs w-500 secondary" >Edit</p>
                                                            </div>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                            :
                            <DataNotFound />
                        }
                    </div>
                </div>
            </div>
            <AddUserPop
                ref={addUserRef}
                onClose={AddUserClose}
                addUserData={addUserData}
                handleInputChange={handleInputChange}
                showError={showError}
                handlePhoneInput={handlePhoneInput}
                addUserSaveBtn={addUserSaveBtn}
                handleSelectDropdown={handleSelectDropdown}
                updateID={updateID}
                regexError={regexError}
            />
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handleDeleteBtn()}
            />}
        </>
    )
}

export default Users