import Request from "../../../Classes/Requests";

class AdminRegisterUserReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            get_all_user_list: super.url("/get_all_user_list"),
        }
    }
}
export default new AdminRegisterUserReq();
