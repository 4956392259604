import { City } from "country-state-city";
import Select from "react-select";
import '../Csc2.scss'
import { useDispatch, useSelector } from "react-redux";
import { setCity, setNativeCity } from "../../../store/Actions/CountryStateCity";
import { useEffect } from "react";
const NativeCitySelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
    const dispatch = useDispatch();
    const countryStateCity = useSelector((state) => { return state?.countryStateCity })
    useEffect(() => {
        { Defaultvalue !== false && Defaultvalue !== "undefined" && dispatch(setNativeCity({ "name": Defaultvalue })); }
    }, [Defaultvalue])

    console.log(countryStateCity, "muk fh")
    return (
        <Select
            options={City.getCitiesOfState(
                countryStateCity?.native_address?.n_states?.countryCode,
                countryStateCity?.native_address?.n_states?.isoCode
            )}
            getOptionLabel={(options) => {
                return options["name"];
            }}
            getOptionValue={(options) => {
                return options["name"];
            }}
            value={countryStateCity?.native_address?.n_city}
            onChange={(item) => {
                dispatch(setNativeCity(item));
            }}
            placeholder={placeholder ? placeholder : 'City '}
            className={ClassName}
        />
    )
}

export default NativeCitySelector