import React, { useState } from 'react'
import './otherSettings.scss'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton'
import useOtherSettings from '../../../CustomHooks/Settings/useOtherSettings'
import FormError from '../../../CommanComponents/Form/FormError'
const OtherSettings = () => {

    const { newPasswordData, handleInput, clickHandle, eyeBtn, eyeBtn1,
        showError, passCondition, re_passCondition, forgotPass, setForgotPass, setEyeBtn1, setEyeBtn } = useOtherSettings();

    return (
        <div className="other-settings-main-wrapper">
            <div className="other-setting-wrap">
                <div className="forgot-password-wrap">
                    <h3 className="heading-para text-ss w-600 mb-3 d-flex align-center justify-between">
                        Change Password
                        {!forgotPass && <button className='transparent' onClick={() => setForgotPass(e => !e)}> <i className='cross-icon'></i> </button>}
                    </h3>

                    {forgotPass ?
                        <div className="forgotPass-before-collapse d-flex justify-between wrap">
                            <p className="text-xxs w-400 description-para">Do you want to change your account password?</p>
                            <button className='transparent primary w-600 text-xs action-btn' onClick={() => setForgotPass(e => !e)}>Change Password</button>
                        </div>
                        :
                        <form className="forgotPass-after-collapse mt-20 mb-10 d-flex" onSubmit={clickHandle}>
                            <div className="forgot-fields-wrap d-flex align-start">
                                <div className="form-field ">
                                    <div className="relative input-wrap">
                                        <input
                                            type={eyeBtn ? 'password' : 'text'}
                                            className='pass-input'
                                            placeholder='New Password *'
                                            name="new_password1"
                                            value={newPasswordData.new_password1.value}
                                            onChange={handleInput}
                                            onKeyUp={handleInput}
                                        />
                                        <button className="eye-btn" type='button' onClick={() => setEyeBtn((e) => !e)}>
                                            <i className={`${eyeBtn ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                        </button>
                                    </div>
                                    {
                                        (passCondition || !newPasswordData.new_password1.isValid && showError) &&
                                        <div className='secondary text-xxs mt-10'>
                                            <p className={`danger mb-3`}> Password Condition :</p>
                                            <p> At least 8 characters long</p>
                                            <p> Include both lowercase and uppercase character</p>
                                            <p> One number or symbol</p>
                                        </div>
                                    }
                                </div>
                                <div className="form-field ">
                                    <div className='relative input-wrap'>
                                        <input
                                            type={eyeBtn1 ? 'password' : 'text'}
                                            className='pass-input'
                                            placeholder='Confirm New Password *'
                                            name="new_password2"
                                            value={newPasswordData.new_password2.value}
                                            onChange={handleInput}
                                            onKeyUp={handleInput}

                                        />
                                        <button className="eye-btn" type='button' onClick={() => setEyeBtn1((e) => !e)}>
                                            <i className={`${eyeBtn1 ? "EyeClose" : "EyeOpen"} eye-icon`}></i>
                                        </button>
                                    </div>
                                    <FormError show={!newPasswordData.new_password2.isValid && showError} error={re_passCondition ? "Password not matched." : 'Please re-enter password'} />
                                </div>
                            </div>
                            <div className="update-pass-btn-wrap d-flex ">
                                <CommanButton Text={'Save'} ClassName={'save-btn'} type={"submit"} />
                                <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={() => setForgotPass(e => !e)} />
                            </div>
                        </form>
                    }
                </div>
            </div>

        </div>
    )
}

export default OtherSettings