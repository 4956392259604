import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReminderList, setRemider2 } from '../../store/Actions/Reminders'
import { useParams } from 'react-router-dom'
import { useTodayDate } from '../useTodayDate'

const useReminders = () => {
    const reminders = useSelector((state) => state.reminders)
    const dispatch = useDispatch()
    const { employee_ID } = useParams()
    const get_employee_id = employee_ID.includes("id=") && employee_ID.replace("id=", "")
    const [date, setDate] = useState(null)
    const todayDate = useTodayDate()

    const handleInputChange = (e, docType) => {
        const inputValue = e.target.value;
        // setDate(inputValue);
        const data = {
            "Reminder_2": inputValue
        }
        let param;
        if (docType === "Emirates ID") {
            param = 'Emirates_ID'
        } else if (docType === "Passport") {
            param = 'Passport'
        }
        dispatch(setRemider2(get_employee_id, param, data))
    }

    useEffect(() => {
        const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
        dispatch(getReminderList(data))
    }, [])
    return { reminders, handleInputChange, date, todayDate }
}

export default useReminders