import React, { useCallback, useEffect, useRef, useState } from 'react'
import './notifications.scss'
import data from './data.json'

import SearchControl from '../../CommanComponents/SearchControl/SearchControl'

import Dashboard from '../DashBoard'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import { useOutsideClick } from 'rooks'
import Auth from '../../Classes/Auth'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import TopActionButtons from '../DashBoard/RightBarPannel/TopActionButtons/TopActionButtons'
import useNotifications from '../../CustomHooks/Notifications/useNotifications'
import { dateFormat } from '../../CustomHooks/dateFormat'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import usePageListData from '../../CustomHooks/usePageListData'
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple'
import { setNotificationSearch } from '../../store/Actions/Notifications'
import { useDispatch } from 'react-redux'
const Notifications = () => {
    const { notifications, onMsgClickHandler, companyDocuments } = useNotifications()

    const ref = useRef(null)
    const [searchVal, setSearchVal] = useState('')
    const [activeManage, setActiveManage] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const userId = Auth?.user()?.user_id // this is for added by user
    // Debounced search function
    const debouncedSearch = useRef(null);
    const dispatch = useDispatch()

    useEffect(() => {
        debouncedSearch.current = debounce((value) => {
            dispatch(setNotificationSearch(value, userId));
        }, 400);
    }, [dispatch]);

    const handleSearch = useCallback((e) => {
        const { value } = e.target;
        setSearchVal(value);
        debouncedSearch.current(value); // Call the debounced function
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    // const handleSearch = (e) => { 
    //     dispatch(employeeSearch(e.target.value))
    // }
    const handleResetSearch = () => {
        setSearchVal('')
    }
    const handleManageBtn = (val) => {
        setActiveManage(activeManage === val ? -1 : val)
    }

    useOutsideClick(ref, () => {
        setActiveManage(-1)
    });

    // const newNotyOBJ = [...notifications?.data, ...companyDocuments?.notification?.data]
    const newNotyOBJ = [
        ...(notifications?.data || []),
        ...(companyDocuments?.notification?.data || [])
    ];
    const dataForCurrentPage = usePageListData(newNotyOBJ, pageNumber)
    return (
        < >
            <div className="notification-wrapper">
                <div className="notification-wrap">
                    <div className="heading-search-item justify-between align-center">
                        <SectionHeading ClassName={" "}>
                            Notification
                        </SectionHeading>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                        </div>
                        {/* <TopActionButtons showAccess={true} /> */}
                        {/* <div className="pagination-wrapper d-flex align-center justify-right">
                            <div className="pagination-item d-flex align-center">
                                <p className="text-xxs w-400 secondary mr-10 text-nowrap">
                                    {`1-${data?.length} of ${data?.length}`}
                                </p>
                                <div className="arrow-btn-wrap d-flex align-center">
                                    <button
                                        className={`arrow left transparent mr-10`}
                                    >
                                        <i className='icon'></i>
                                    </button>
                                    <button
                                        className={`arrow right transparent`}
                                    >   <i ></i></button>
                                </div>
                            </div>
                        </div> */}
                        <PaginationSimple
                            className={"document-pagenation"}
                            data={newNotyOBJ}
                            shows={newNotyOBJ?.length < 20 ? newNotyOBJ?.length : "20"}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />
                    </div>

                    {/* <div className="notification-table-wrapper "> */}
                    <div className="invitaion-item-wrap ">
                        <div className="invitation-item">

                            {dataForCurrentPage?.map((val, key) => {
                                return (
                                    <div className="message-wrap pointer" key={key} onClick={() => { return (val?.document_type !== "File" && val?.document_type !== "Folder") ? onMsgClickHandler(val?.id, val?.is_seen, val?.employee_id, val?.client_id, val?.document_type) : null }}>
                                        <p className={`w-400 text-xxs text-left secondary noti-para ${!val?.is_seen && "w-600"}`}>
                                            {/* Hi {val?.client_name} , Your Employee {val?.first_name + " " + val?.last_name} document  {val?.document_type} is about to expire on {dateFormat(val.date_of_expiry)}. */}
                                            {/* {val?.reminder_message} */}
                                            {
                                                val?.reminder_message.replace("CompaniesFolder", "Company Folder").replace(/object \(\d+\)/, '').replace("CompaniesFile", "Company File")
                                            }
                                        </p>
                                    </div>
                                )
                            })}
                            {
                                // companyDocuments?.notification?.data?.map((val, key) => {
                                //     return (
                                //         <div className="message-wrap pointer" key={key}  >
                                //             <p className={`w-400 text-xxs text-left secondary noti-para ${!val?.is_seen && "w-600"}`}>
                                //                 {/* Hi {val?.client_name} , Your Employee {val?.first_name + " " + val?.last_name} document  {val?.document_type} is about to expire on {dateFormat(val.date_of_expiry)}. */}
                                //                 {
                                //                     val?.reminder_message.replace("CompaniesFolder", "Company Folder").replace(/object \(\d+\)/, '').replace("CompaniesFile", "Company File")
                                //                 }
                                //             </p>
                                //         </div>
                                //     )
                                // })
                            }

                            {dataForCurrentPage?.length === 0 && companyDocuments?.notification?.data?.length === 0 && <DataNotFound />}
                        </div>
                    </div>
                    {/* <div className="table-wrap">
                            <table className="table-item">
                                <thead className='table-head'>
                                    <tr className="table-row">
                                        <th className='name'>Purpose</th>
                                        <th className='email'>UserName</th>
                                        <th className='level'>Expiry Date</th>
                                        <th className='level'>Action 1</th>
                                        <th className='level'>Action 2</th>
                                    </tr>
                                </thead>

                                <tbody className='table-body'>
                                    {data?.map((val, key) => {
                                        return (

                                            <tr className="table-row" key={key}>
                                                <td className='name'>{val.purpose}</td>
                                                <td>{val.user_name}</td>
                                                <td>{val.expiry_date}</td>
                                                <td>
                                                    <button style={{ backgroundColor: 'blue', height: '30px', width: '80px', color: 'white', borderColor: 'lightBlue', borderRadius: 3, marginLeft: 10 }}>Renew</button>
                                                </td>
                                                <td>
                                                    <button style={{
                                                        backgroundColor: 'blue', height: '30px', width: '140px', color: 'white', borderColor: 'lightBlue',
                                                        // position: fixed, 

                                                        borderRadius: 3
                                                    }}>Remind Me Later</button>
                                                </td>
                                            </tr>

                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>

        </ >
    )
}

export default Notifications