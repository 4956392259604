import SettingsAction from "../Actions/Settings/actionType"


const initState = {
    personal: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    data: [],
}

const settings = (state = initState, { type, payload }) => {
    switch (type) {
        case SettingsAction.SET_PROFILE_LOADING: return {
            ...state,
            personal: {
                ...state.personal,
                loading: true
            }
        }
        case SettingsAction.SET_PROFILE_SUCCESS: return {
            ...state,
            personal: {
                ...state.personal,
                loading: false,
                data: payload
            }
        }
        default: return state
    }
}

export default settings