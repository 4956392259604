import React, { useState } from 'react'
import Modals from '../../CommanComponents/Modals'
import ModalsHeader from '../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../CommanComponents/Modals/ModalsBody'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton';
import ModalsFooter from '../../CommanComponents/Modals/ModalsFooter'
import './reminderPopup.scss'
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from '../../CustomHooks/dateFormat';
import { useTodayDate } from '../../CustomHooks/useTodayDate';
import FormError from '../Form/FormError';
const ReminderPopup = ({ reminderDate, setReminderDate, expiryDate, setExpiryDate, reference, onClose, onSubmitFunc, error, resetError }) => {
    const todayDate = useTodayDate()
    const dispatch = useDispatch()
    return (
        <Modals
            ref={reference}
            ClosePopUp={onClose}
            Position="center"
            ModalsSize={'modal-s'}
            ClassName={'ReminderPopup-upload-wrapper'}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={onClose}></i>
                    <p className='text-center m-auto'>Set reminders</p>
                </h4>
            </ModalsHeader>
            <ModalBody className={'ReminderPopup-modal-body'}>
                <div className="ReminderPopup-body-wrapper">
                    <div className="reminder-wrapper d-flex">
                        <div className="reminder-item">
                            <p className="text-xxs w-600 secondary description-para"> Set reminder*</p>
                            <button className='transparent set-reminder-btn mt-5'>
                                <div className={`dob-wrap d-flex align-center details-InputClassName`}>
                                    <p className={`dob_para d-flex align-center secondary ${reminderDate ? 'active' : ""}`}>{reminderDate ? dateFormat(reminderDate) : reminderDate ? dateFormat(reminderDate) : "DD-MM-YYYY"}</p>
                                    <input
                                        className={'details-InputClassName'}
                                        type="date"
                                        min={todayDate}
                                        value={reminderDate}
                                        // max={val?.Date_of_Expiry}
                                        // value={val?.Reminder_2 ? val?.Reminder_2 : reminderDate}
                                        // name='Issuing_Date'
                                        onChange={(e) => { return setReminderDate(e.target.value), dispatch(resetError()) }}
                                        onKeyUp={(e) => { return setReminderDate(e.target.value), dispatch(resetError()) }}
                                    />
                                </div>
                            </button>
                        </div>
                        <div className="reminder-item">
                            <p className="text-xxs w-600 secondary description-para"> Set expiry </p>
                            <button className='transparent set-reminder-btn mt-5'>

                                <div className={`dob-wrap d-flex align-center details-InputClassName`}>
                                    <p className={`dob_para d-flex align-center secondary ${expiryDate ? 'active' : ""}`}>{expiryDate ? dateFormat(expiryDate) : expiryDate ? dateFormat(expiryDate) : "DD-MM-YYYY"}</p>
                                    <input
                                        className={'details-InputClassName'}
                                        type="date"
                                        min={todayDate}
                                        // max={val?.Date_of_Expiry}
                                        value={expiryDate}
                                        // name='Issuing_Date'
                                        onChange={(e) => setExpiryDate(e.target.value)}
                                        onKeyUp={(e) => setExpiryDate(e.target.value)}
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <FormError show={error?.length > 0} error={error} />
                <div className="save-btn-wrap d-flex ">
                    <CommanButton
                        Text={'Set reminder'}
                        ClassName={'save-btn'}
                        onClick={onSubmitFunc}
                    />
                    <CommanButton
                        Text={'Cancel'}
                        cancel={true} type={'button'}
                        onClick={() => onClose()}
                    />
                </div>
            </ModalBody >

        </Modals>
    )
}

export default ReminderPopup