import React from 'react'
import './notificationShow.scss'
const NotificationShow = ({ ...props }) => {
    const { value } = props
    return (
        <p className="notification-show-wrap absolute w-400 white d-flex align-center justify-center">
            {value}
        </p>
    )
}

export default NotificationShow