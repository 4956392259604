import foldersReq from './folderReq'
import FoldersAction from './actionType'
import { showSuccessToast } from '../SuccessMessagePopup'
import { setCommonError } from '../CommonError'
const createNewFolder = (data) => {
    return async (dispatch) => {
        foldersReq.post(foldersReq.endpoint.Create_Folder, data, (success) => {
            dispatch({ type: FoldersAction.CREATE_FOLDER_SUCCESS },)
            dispatch(showSuccessToast("Folder created successfully!"))
        },
            (error) => {
                dispatch({ type: FoldersAction.CREATE_FOLDER_ERROR, payload: "Folder name already exists." },)
            }
        )
    }
}

const getFoldersList = (userId) => {
    return async (dispatch) => {
        dispatch({ type: FoldersAction.FOLDER_LIST_LOADING })
        foldersReq.get(foldersReq.endpoint.List_folders.replace("USER_ID", userId), (success) => {
            dispatch({ type: FoldersAction.FOLDER_LIST_LOADED, payload: success?.data })
        },
            (error) => {
                dispatch(setCommonError(error?.message))
            }
        )
    }
}
const deleteFolder = (id, userId, folderId) => {
    return async (dispatch) => {
        foldersReq.delete(`${foldersReq.endpoint.Delete_folders}/${id}`, (success) => {
            dispatch({ type: FoldersAction.DELETE_FOLDER_SUCCESS })
            dispatch(showSuccessToast("File deleted successfully!"))
            if (folderId !== null) {
                dispatch(getFolderDetail(folderId))
            } else {
                dispatch(getFoldersList(userId))
            }
        },
            (error) => {

            }
        )
    }
}
const deleteFiles = (id, userId, folderId) => {
    return async (dispatch) => {
        foldersReq.delete(`${foldersReq.endpoint.Delete_file}/${id}`, (success) => {
            dispatch({ type: FoldersAction.DELETE_FILES_SUCCESS })
            dispatch(showSuccessToast("File deleted successfully!"))
            if (folderId !== null) {
                dispatch(getFolderDetail(folderId))
            } else {
                dispatch(getFoldersList(userId))
            }
        },
            (error) => {

            }
        )
    }
}


const renameFolder = (id, data, folderId) => {
    return async (dispatch) => {
        foldersReq.put(`${foldersReq.endpoint.Rename_folders}/${id}`, data, (success) => {
            dispatch({ type: FoldersAction.RENAME_FOLDER_SUCCESS })
            dispatch(showSuccessToast("Folder rename successfully!"))
            if (folderId !== null) {
                dispatch(getFolderDetail(folderId))
            } else {
                // dispatch(getFoldersList(userId))
                dispatch(getFoldersList(data?.added_by_user_id))
            }
        },
            (error) => {

            }
        )
    }
}

const serachFolder = (value, userId, folderId) => {
    // Correcting the URL to be well-formed
    const url = window.location.href;
    const correctedUrl = url.replace('?', '&amp;').replace('&amp;', '?');

    // Parsing the corrected URL
    const urlObj = new URL(correctedUrl);

    // Extracting the search parameters
    const params = new URLSearchParams(urlObj.search);

    // Getting the folderId
    const folderId2 = params.get('folderId');
    return async (dispatch) => {
        if (value?.length > 0) {
            foldersReq.get(foldersReq.endpoint.Search_folders.replace('__VALUE__', value).replace("USER_ID", userId), (success) => {
                // let payload = success.data?.map((item, index, array) => {
                //     return array[array.length - 1 - index];
                // });
                dispatch({ type: FoldersAction.FOLDER_SEARCH_SUCCESS, payload: success.data },)
            },
                (error) => {

                }
            )
        } else {
            console.log(folderId2, "folderId11111 serachFolder")
            dispatch(searchReset(userId, folderId2))
        }
    }
}
const getFolderDetail = (id) => {
    return async (dispatch) => {
        foldersReq.get(`${foldersReq.endpoint.Folders_detail}/${id}`, (success) => {
            dispatch({ type: FoldersAction.FOLDER_LIST_LOADED, payload: success?.data })
        },
        )
    }
}
const uploadFile = (data, id) => {
    return async (dispatch) => {
        foldersReq.post(foldersReq.endpoint.upload_file_in_folder, data, (success) => {
            // dispatch({ type: FoldersAction.FOLDER_DETAIL_SET, payload: payload },)
            dispatch(getFolderDetail(id))
            dispatch(showSuccessToast("File Uploaded successfully!"))
        },
            (error) => {

            }
        )
    }
}



const setBreadcrumb = (payload) => {
    return { type: FoldersAction.SET_BREADCRUMB, payload: payload }
}
const createFolderReset = () => {
    return { type: FoldersAction.CREATE_FOLDER_RESET }
}
const ErrorReset = () => {
    return { type: FoldersAction.CREATE_FOLDER_ERROR_RESET }
}
const renameReset = () => {
    return { type: FoldersAction.RENAME_FOLDER_RESET }
}
const searchReset = (userId, folderId) => {
    return (dispatch) => {
        console.log(folderId, "folderId11111 searchReset 111")
        if (folderId !== null) {
            dispatch(getFolderDetail(folderId))
        } else {
            dispatch(getFoldersList(userId))
        }
    }
}
const resetFolderDetail = () => {
    return { type: FoldersAction.FOLDER_DETAIL_RESET }
}
const resetFolderList = () => {
    return { type: FoldersAction.FOLDER_LIST_RESET }
}


export {
    createNewFolder, getFoldersList, createFolderReset, ErrorReset, deleteFolder,
    renameFolder, renameReset, serachFolder, searchReset, getFolderDetail,
    uploadFile, resetFolderDetail, resetFolderList, setBreadcrumb, deleteFiles

}