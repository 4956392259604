import { type } from "@testing-library/user-event/dist/type"
import { showErrorToast, showSuccessToast } from "../SuccessMessagePopup"
import { forgotPasswordAction } from "./actionType"
import forgotRequest from "./forgotRequest"

const verifyValidUser = (data) => {
    return async (dispatch) => {
        dispatch({ type: forgotPasswordAction.SET_LOADING })
        forgotRequest.post(forgotRequest.endpoint.verify_user, data, (success) => {
            dispatch({ type: forgotPasswordAction.SET_VALID_USER },
                // { type: forgotPasswordAction.SET_MESSAGE, payload: "Otp sent successfully!" }
            )
            dispatch(showSuccessToast("Otp sent successfully!"))
        }, (error) => {
            dispatch({ type: forgotPasswordAction.SET_NOT_VALID_USER }, { type: forgotPasswordAction.SET_MESSAGE, payload: error?.response?.data })
        }, 'no-auth'
        )
    }
}
const resendOTP = (data) => {
    return async (dispatch) => {
        dispatch({ type: forgotPasswordAction.SET_LOADING })
        forgotRequest.post(forgotRequest.endpoint.verify_user, data, () => {
            dispatch(showSuccessToast("Otp sent successfully!"))
        }, (error) => {
            // dispatch({ type: forgotPasswordAction.SET_NOT_VALID_USER }, { type: forgotPasswordAction.SET_MESSAGE, payload: error?.response?.data })
        }, 'no-auth'
        )
    }
}

const VerifyOTP = (data) => {
    return async (dispatch) => {

        forgotRequest.post(forgotRequest.endpoint.verify_user_with_otp, data, (success) => {
            dispatch({ type: forgotPasswordAction.VERIFY_OTP_SUCCESS })
        }, (error) => {
            dispatch({ type: forgotPasswordAction.VERIFY_OTP_FAIL },)
            // dispatch({ type: forgotPasswordAction.SET_MESSAGE, payload: "Invalid Otp." })
        }, 'no-auth'
        )
    }
}
const setNewPassword = (data) => {
    return async (dispatch) => {
        forgotRequest.post(forgotRequest.endpoint.user_changes_password, data, (success) => {
            dispatch({ type: forgotPasswordAction.SET_NEWPASSWORD_SUCCESS })
            dispatch(showSuccessToast("New password set successfully!"))
        }, (error) => {
            dispatch({ type: forgotPasswordAction.SET_MESSAGE, payload: "" })
        }, 'no-auth'
        )
    }
}

const hideErrorMsg = () => {
    return ({ type: forgotPasswordAction.HIDE_VALID_USER })
}

const resetNewPass = () => {
    return ({ type: forgotPasswordAction.RESET_NEWPASSWORD_SUCCESS })
}
const resetVerifyOtp = () => {
    return ({ type: forgotPasswordAction.RESET_VERIFY_OTP_FAIL })
}


export { verifyValidUser, VerifyOTP, setNewPassword, hideErrorMsg, resetNewPass, resetVerifyOtp, resendOTP }