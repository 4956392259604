import Request from "../../../Classes/Requests";

class supportReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            tickets_create: super.url("/tickets_create"),
            tickets_list: super.url("/tickets_list?added_by_user_id=__USER_ID__"),
            tickets_view: super.url("/tickets_view?ticket_no=__ID__"),
            tickets_update: super.url("/tickets_update?ticket_no=__ID__"),
            serach_tickets: super.url("/serach_tickets?query=__VALUE__&added_by_user_id=__USER_ID__"),
        }
    }
}
export default new supportReq();
