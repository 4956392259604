import closeForceChangePassActions from "../Actions/ForceToChangePass/actionType"


const initState = {
    loading: false,
    success: false,
    error: false,
    data: []

}

const openForceChangePass = (state = initState, { type, payload }) => {
    switch (type) {
        case closeForceChangePassActions.OPEN_FORCE_CHANGE_PASS: return {
            ...state,
            success: true,
        }
        default: return state
    }
}

export default openForceChangePass