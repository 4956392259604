import React, { useState } from 'react'
import Modals from '../../../../CommanComponents/Modals'
import ModalsHeader from '../../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../../CommanComponents/Modals/ModalsBody'
import './docPreview.scss';
import CommanButton from '../../../../CommanComponents/CommanButton/CommanButton';
import ImageViewer from '../../../../CommanComponents/ImageViewer/ImageViewer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import { ALLOW_IMAGE_EXTENSIONS } from '../../../../config';
import getFileExtension from '../../../../HelperFunction/getFileExtension';
const DocPreview = ({ onClose, data, reffrence, setImageToShow }) => {
    const previewClose = () => {
        onClose();
        setActiveSlideIndex(0);
    };

    const [activeSlideIndex, setActiveSlideIndex] = useState(0); // State to hold the active slide index

    // Function to handle slide change
    const handleSlideChange = (swiper) => {
        setActiveSlideIndex(swiper.activeIndex);
    };

    const swiperConfig = {
        className: "swiper-wrapper",
        spaceBetween: 0,
        centeredSlides: true,
        loop: false,
        speed: 1200,
        navigation: true,
        modules: [Navigation],
        onSlideChange: handleSlideChange // Attach the slide change handler

    };

    const frontDocument = data?.front_document;
    const backDocument = data?.back_document;

    const frontExtension = getFileExtension(frontDocument ?? '');
    const backExtension = getFileExtension(backDocument ?? '');

    const isFrontAllowed = frontDocument && ALLOW_IMAGE_EXTENSIONS.includes(frontExtension);
    const isBackAllowed = backDocument && ALLOW_IMAGE_EXTENSIONS.includes(backExtension);
    return (
        <Modals
            ref={reffrence}
            ClosePopUp={previewClose}
            // slide={"zoom"}
            Position="center"
            ModalsSize={'modal-l'}
            ClassName={'folder-modal-wrapper'}
        // croseShow={true}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={previewClose}></i>
                    <p className='text-center m-auto'>
                        Preview -
                        {data?.front_document != null && data?.back_document != null ? activeSlideIndex == 0 ? ' Frontside' : " Backside" :
                            data?.front_document != null ? ' Frontside' : 'Backside '
                        }

                    </p>
                </h4>
            </ModalsHeader>
            <ModalBody >
                <Swiper {...swiperConfig}>
                    {data?.front_document !== null && isFrontAllowed && <SwiperSlide>
                        <div className="prev-img-wrap">
                            <ImageViewer object={data?.front_document} alt="uploaded-document" />
                        </div>
                    </SwiperSlide>}
                    {data?.back_document !== null && isBackAllowed && <SwiperSlide>
                        <div className="prev-img-wrap">
                            <ImageViewer object={data?.back_document} alt="uploaded-document" />
                        </div>
                    </SwiperSlide>}
                </Swiper>


                {/* <div className="save-btn-wrap d-flex mt-40 ">
                    <CommanButton Text={'Save'} ClassName={'save-btn'} onClick={saveBtnHandle} />
                    <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={() => onClose()} />
                </div> */}
            </ModalBody >
        </Modals>
    )
}

export default DocPreview