const DocumentUploadAction = {
    // upload document fields
    DOC_UPLOAD_SUCCESS: "DOC_UPLOAD_SUCCESS",
    DOC_SUCCESS_RESET: "DOC_SUCCESS_RESET",

    // upload document img
    DOC_IMAGE_SUCCESS: "DOC_IMAGE_SUCCESS",
    DOC_IMAGE_SUCCESS_RESET: "DOC_IMAGE_SUCCESS_RESET",

    // upload uploaded docs fields
    GET_UPLOADED_DOCS_SUCCESS: "GET_UPLOADED_DOCS_SUCCESS",

    UPLOADED_DOCS_RESET: "UPLOADED_DOCS_RESET",

    // upload uploaded docs fields
    DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",

    // delete uploaded docs 

    // uppdate input fileds 
    SET_UPDATE_DOCS: "SET_UPDATE_DOCS",
    RESET_UPDATE_DOCS: "RESET_UPDATE_DOCS",


    DOC_SET_LOADING: "SET_LOADING",
    DOC_HIDE_LOADING: "HIDE_LOADING",

    DOC_SET_ERROR: "SET_ERROR",
    DOC_HIDE_ERROR: "HIDE_ERROR",

    DELETE_PASSPORT_SET: "DELETE_PASSPORT_SET",

    GET_SINGLE_DOC: "GET_SINGLE_DOC",
    RESET_SINGLE_DOC: "RESET_SINGLE_DOC",

    SET_DOC_TYPE: "SET_DOC_TYPE",

    GET_AI_FRONTSIDE: "GET_AI_FRONTSIDE",
    RESET_AI_FRONTSIDE: "RESET_AI_FRONTSIDE",

    GET_AI_BACKSIDE: "GET_AI_BACKSIDE",
    RESET_AI_BACKSIDE: "RESET_AI_BACKSIDE",
}

export default DocumentUploadAction