import AddUserAction from "../Actions/User/actionType"



const initState = {
    loading: false,
    error: false,
    data: [],
    success: false,
    update_user: [],
    user_client_list: {
        loading: false,
        error: false,
        data: [],
        success: false,
    }
}

const user = (state = initState, { type, payload }) => {
    switch (type) {
        case AddUserAction.SET_ADD_USER_LOADING: return {
            ...state,
            loading: true,
            error: false,
        }
        case AddUserAction.SET_ADD_USER_SUCCESS: return {
            ...state,
            loading: false,
            success: true,
            error: false,
        }
        case AddUserAction.SET_ADD_USER_ERROR: return {
            ...state,
            loading: false,
            error: true
        }
        case AddUserAction.SET_ADD_USER_ERROR_RESET: return {
            ...state,
            loading: false,
            error: false
        }
        case AddUserAction.SET_ADD_USER_SUCCESS_RESET: return {
            ...state,
            loading: false,
            success: false
        }
        case AddUserAction.SET_USER_LIST_SUCCESS: return {
            ...state,
            data: payload
        }
        case AddUserAction.SET_USER_SINGLE_USER_VIEW: return {
            ...state,
            update_user: payload
        }
        case AddUserAction.SET_USER_UPDATE_RESET: return {
            ...state,
            update_user: []
        }
        case AddUserAction.SET_USER_CLIENT_LIST_SUCCESS: return {
            ...state,
            user_client_list: {
                ...state.user_client_list,
                data: payload
            }
        }
        case AddUserAction.SET_USER_CLIENT_LIST_RESET: return {
            ...state,
            user_client_list: {
                ...state.user_client_list,
                data: []
            }
        }

        default: return state
    }
}

export default user