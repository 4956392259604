import Request from "../../../Classes/Requests";

class addUserReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            add_user: super.url("/add_user"),
            list_users: super.url("/list_users?added_by_user_id=__USER_ID__"),
            view_user: super.url("/view_user"),
            delete_user: super.url("/delete_user"),
            update_user: super.url("/update_user"),
            list_client_by_user_id: super.url("/list_client_by_user_id?user_id=__ID__&added_by_user_id=__USER_ID__"),
        }
    }
}
export default new addUserReq();
