import React, { useRef, useState } from 'react';
import './createTicket.scss'
import Modals from '../../CommanComponents/Modals'
import ModalsHeader from '../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../CommanComponents/Modals/ModalsBody'
import './createTicket.scss';
import CommanButton from '../../CommanComponents/CommanButton/CommanButton';
import ImageViewer from '../../CommanComponents/ImageViewer/ImageViewer';
import FormError from '../../CommanComponents/Form/FormError';
import { useSelector } from 'react-redux';


const CreateTicket = ({ reference, onClose, ticketData, handleInputChange, inputFileRef, CreateTicketBtnHandle, showError, actionType }) => {
    const support = useSelector(e => e.support)

    const HandleDownload = (imageRef, fileName) => {
        const file = inputFileRef.current.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';  // Security best practice
            a.download = file.name; // Use the actual file name as download attribute
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };
    return (
        <Modals
            ref={reference}
            ClosePopUp={onClose}
            slide={"zoom"}
            Position="center"
            ModalsSize={'modal-m'}
            ClassName={'folder-modal-wrapper'}
            croseShow={true}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={onClose}></i>
                    <p className='text-center m-auto'>
                        {actionType == "update" ? 'Edit Ticket' : 'Create New Ticket'}
                    </p>
                </h4>
            </ModalsHeader>
            <ModalBody >
                <p className='secondary mb-15'>Error Name *</p>
                {/* <br /> */}
                <form className="folder-modal-content-wrap support-ticket-wrap" onSubmit={CreateTicketBtnHandle}>
                    <div className="form-wrapper">
                        <div className="form-field ">
                            <input
                                type="text"
                                className='folder-input base'
                                placeholder='Type Your Error *'
                                value={ticketData?.error_name?.value}
                                name={'error_name'}
                                onChange={handleInputChange}
                                onKeyUp={handleInputChange}
                            />

                            <FormError show={!ticketData?.error_name.isValid && showError} error={'Please enter error name.'} />
                        </div>
                    </div>
                    <br />
                    <p className='secondary mb-15'>Attachment *</p>
                    {/* <br /> */}
                    <div className='d-flex justify-between'>

                        <input id="myInput"
                            type="file"
                            ref={inputFileRef}
                            name={'attachment'}
                            onChange={(e) => handleInputChange(e)}
                            style={{ border: 'none', height: "auto", }}
                            className='attachement-input'
                        />

                        <button
                            className="icons-wrap transparent d-flex align-center justify-center"
                            type='button'
                            onClick={() => HandleDownload(inputFileRef)}
                            disabled={inputFileRef?.current?.files[0] || ticketData.attachment.value ? false : true}
                        >
                            <i className="icons download-icon"></i>
                        </button>
                    </div>
                    <FormError show={!ticketData?.attachment.isValid && showError} error={'Please add attachment.'} />
                    {/* <br />
                    <br /> */}
                    <p className='secondary mt-15 mb-15'>Description *</p>

                    <textarea
                        style={{ width: '100%', height: 100, verticalAlign: 'top', padding: "16px" }}
                        placeholder="Enter Description *"
                        value={ticketData?.description?.value}
                        name={'description'}
                        onChange={handleInputChange}
                        onKeyUp={handleInputChange}
                    ></textarea>
                    <FormError show={!ticketData?.description.isValid && showError} error={'Please enter description.'} />
                    <CommanButton
                        Text={'Create'}
                        ClassName={'folder-btn mt-50 mb-40'}

                    />
                </form>
            </ModalBody >
        </Modals >


    );
};


export default CreateTicket;
