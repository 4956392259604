import { SUCCESS_MSG_POPUP } from "../Actions/SuccessMessagePopup/actionType"

const INITIAL_ERROR_STATES = {
    message: "",
    toastType: "",
    showToast: false,
}

const successMessagePopup = (state = INITIAL_ERROR_STATES, { type, payload }) => {

    switch (type) {
        case SUCCESS_MSG_POPUP.SUCCESS_TOAST:
            return {
                message: payload,
                toastType: "success",
                showToast: true
            }
        case SUCCESS_MSG_POPUP.WARNING_TOAST:
            return {
                message: payload,
                toastType: "warning",
                showToast: true
            }
        case SUCCESS_MSG_POPUP.ERROR_TOAST:
            return {
                message: payload,
                toastType: "error",
                showToast: true
            }
        case SUCCESS_MSG_POPUP.HIDE_TOAST:
            return {
                message: payload,
                toastType: "",
                showToast: false
            }
        default:
            return state;
    }
}

export default successMessagePopup;