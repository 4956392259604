import NotificationsActions from "../Actions/Notifications/actionType"
const initState = {
    data: [],
    trigger: false
}

const notifications = (state = initState, { type, payload }) => {
    switch (type) {
        case NotificationsActions.NOTIFICATION_TRIGGER_SET: return {
            ...state,
            trigger: true
        }
        case NotificationsActions.NOTIFICATION_SUCCESS: return {
            ...state,
            data: payload
        }
        // case NotificationsActions.NOTIFICATION_LOGIN_SET: return {
        //     ...state,
        //     data: payload
        // }
        default: return state
    }
}

export default notifications