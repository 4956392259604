import React, { useEffect, useRef, useState } from 'react'
import { DropBtnWrapper, Button, ItemList, List, Icon } from './DropdownStyle.js'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick.js'
import './dropdown.scss'
const Dropdown = ({ ClassName, btnClassName, data, defaultValue, clickHandle, name, key, disableList = [], tabIndex = false, ...props }) => {

    const { padding, borderRadius, borderWidth, background, fontSize, color, width, textAlign } = props
    const btnStyle = {
        padding: padding,
        borderRadius: borderRadius,
        borderWidth: borderWidth,
        background: background,
        fontSize: fontSize,
        color: color,
        width: width,
        textAlign: textAlign,
    }
    const ref = useRef(null);
    const [activeDrop, setActiveDrop] = useDetectOutsideClick(ref, false)
    const [value, setValue] = useState(defaultValue ? defaultValue : 'Select *')
    console.log(defaultValue, "SAC")
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const listHandle = (val) => {
        setValue(val)
        setActiveDrop((e) => !e)
        clickHandle && clickHandle(val)
    }
    const handleKeyDown = (e) => {
        if (!activeDrop) return;
        switch (e.key) {
            case 'ArrowDown':
                setHighlightedIndex((prevIndex) =>
                    (prevIndex + 1) % data.length
                );
                break;
            case 'ArrowUp':
                setHighlightedIndex((prevIndex) =>
                    (prevIndex - 1 + data.length) % data.length
                );
                break;
            case 'Enter':
                if (highlightedIndex >= 0 && highlightedIndex < data.length) {
                    listHandle(data[highlightedIndex]);
                    setActiveDrop(false)
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (activeDrop) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeDrop, highlightedIndex]);

    return (
        <>
            <DropBtnWrapper className={`comman-dropdown-wrapper ${ClassName} `} ref={ref} item={btnStyle} key={key}>
                <Button
                    item={btnStyle}
                    name={name}
                    type='button'
                    tabIndex={tabIndex}
                    className={`drop-btn ${data.includes(defaultValue) ? 'base' : 'secondary'} ${btnClassName}`}
                    onClick={() => setActiveDrop(!activeDrop)}
                // onFocus={() => setActiveDrop(!activeDrop)} // Add onFocus event to set activeDrop to true

                >
                    {value}
                    <Icon className={`${activeDrop ? 'active-icon' : ''} icon-arrow`}></Icon>
                </Button>
                {activeDrop && <ItemList >
                    {
                        data ? data.map((val, key) => {
                            return (
                                <List
                                    key={key}
                                    className={`${disableList.includes(val) && 'disabled'} ${value == val && "active"}  ${key == highlightedIndex && "makeHover"} `}
                                    item={btnStyle}
                                    onClick={() => { listHandle(val); }}
                                    onMouseEnter={() => setHighlightedIndex(key)}
                                >
                                    {val}
                                </List>
                            )
                        }) : ''
                    }
                </ItemList>}
            </DropBtnWrapper>
        </>
    )
}

export default Dropdown