import { Country } from "country-state-city";
import Select from "react-select";
import '../Csc2.scss'
import { useDispatch, useSelector } from "react-redux";
import { resetNativeCsc, setNativeCity, setNativeCountry, setNativeState } from "../../../store/Actions/CountryStateCity";
import { useEffect } from "react";
const NativeCountrySelector = ({ placeholder, ClassName, Defaultvalue = false }) => {
    const dispatch = useDispatch()
    const countryStateCity = useSelector((state) => { return state?.countryStateCity })
    const onChangeHandle = (item) => {
        dispatch(setNativeCountry(item));
        dispatch(setNativeState(''))
        dispatch(setNativeCity(''))
    }
    useEffect(() => {
        dispatch(resetNativeCsc());
    }, [])

    useEffect(() => {
        // { Defaultvalue !== false && Defaultvalue !== "undefined" && dispatch(setNativeCountry({ "name": Defaultvalue })); }
        { Defaultvalue !== false && Defaultvalue !== "undefined" && dispatch(setNativeCountry(Country.getAllCountries().filter(country => { return country.name === Defaultvalue })[0])); }
    }, [Defaultvalue])
    console.log(countryStateCity, "countryStateCity234")
    return (
        <Select
            options={Country.getAllCountries()}
            getOptionLabel={(options) => {
                return options["name"];
            }}
            getOptionValue={(options) => {
                return options["name"];
            }}
            value={countryStateCity?.native_address?.n_country}
            // value={{
            //     "name": "guddu", 
            // }}
            onChange={(item) => {
                onChangeHandle(item)

            }}
            placeholder={placeholder ? placeholder : 'Country'}
            className={ClassName}
        />
    )
}

export default NativeCountrySelector