
import axios from "axios";
import DocumentUploadAction from "./actionType";
import documentUpload from './documentUploadReq'
import Api from "../../../Classes/Api";
import { showSuccessToast } from "../SuccessMessagePopup";
import Auth from "../../../Classes/Auth";
import { hideLoadingFetch, showLoadingFetch } from "../LoaderFetching";
const API = new Api();
const uploadPassport = (data) => {
    return async (dispatch) => {
        documentUpload.post(documentUpload.endpoint.passport_document, data, (success) => {
            dispatch(
                { type: DocumentUploadAction.DOC_UPLOAD_SUCCESS },
            )
            dispatch(showSuccessToast("Added successfully!"))
            // dispatch(resetUpdateDocs())
        },
            (error) => {

            }
        )
    }
}
const updateUploadPassport = (id, data) => {
    return async (dispatch) => {
        documentUpload.put(`${documentUpload.endpoint.edit_employee_passport_document}/${id}`, data, (success) => {
            dispatch(
                { type: DocumentUploadAction.DOC_UPLOAD_SUCCESS },
            )
            dispatch(showSuccessToast("Update successfully!"))
        },
            (error) => {

            }
        )
    }
}
const deleteUploadedPassport = (id) => {
    return async (dispatch) => {
        documentUpload.delete(`${documentUpload.endpoint.passport_fields_delete}/${id}`, (success) => {
            dispatch(
                { type: DocumentUploadAction.DELETE_DOCUMENT_SUCCESS },
            )
            dispatch(showSuccessToast("Delete successfully!"))
            dispatch(getListOfDocument(id))
        },
            (error) => {

            }
        )
    }
}


const uploadDocImage = (formData) => {
    return async (dispatch) => {
        axios.post(
            API.url("upload_employee_document"),
            formData,
            {
                headers: {
                    Authorization: `Bearer ${Auth.token()}`,
                }
            }
        )
        // documentUpload.post(documentUpload.endpoint.upload_employee_document, doc, (success) => {
        //     dispatch(
        //         { type: DocumentUploadAction.DOC_UPLOAD_SUCCESS },
        //     )
        // },
        //     (error) => {
        //         console.log(error)
        //     }
        // )
    }
}
const viewPassport = (id) => {
    return async (dispatch) => {
        documentUpload.get(`${documentUpload.endpoint.passport_document_view}/${id}`, (success) => {
            let payload;
            if (typeof success.data === 'object') {
                payload = success.data;
            } else {
                payload = []
            }
            dispatch(
                { type: DocumentUploadAction.GET_SINGLE_DOC, payload: success?.data },
            )
        },
            (error) => {

            }
        )
    }
}
const resetUplodedDocs = (id) => {
    return async (dispatch) => {
        dispatch(
            { type: DocumentUploadAction.UPLOADED_DOCS_RESET }
        )
    }
}

const deleteUploadedDocument = (id) => {
    return async (dispatch) => {
        documentUpload.delete(`${documentUpload.endpoint.uploaded_document_delete}/${id}`, (success) => {
            dispatch(
                { type: DocumentUploadAction.DELETE_DOCUEMNT_SUCCESS },
            )
        },
            (error) => {
                console.log(error)
            }
        )
    }
}

const uploadReset = () => {
    return (dispatch) => {
        dispatch(
            { type: DocumentUploadAction.DOC_SUCCESS_RESET },
        )
    }
}

const setUpdateDocs = () => {
    return {
        type: DocumentUploadAction.SET_UPDATE_DOCS,
    }
}
const resetUpdateDocs = () => {
    return {
        type: DocumentUploadAction.RESET_UPDATE_DOCS
    }
}
const DeletePassportImg = (employee_id, document_type) => {
    return async (dispatch) => {
        documentUpload.delete(`${documentUpload.endpoint.delete_employee_passport_document}/${employee_id}/${document_type}`, (success) => {
            dispatch(
                { type: DocumentUploadAction.DELETE_PASSPORT_SET },
            )
            dispatch(showSuccessToast("Deleted successfully!"))
            dispatch(viewPassport(employee_id))
        },
            (error) => {
            }
        )
    }
}

// Emirates Id
const uploadEmiratesID = (data) => {
    return async (dispatch) => {
        documentUpload.post(documentUpload.endpoint.emiratesID_document, data, (success) => {
            dispatch(
                { type: DocumentUploadAction.DOC_UPLOAD_SUCCESS },
            )
            dispatch(showSuccessToast("Added successfully!"))
        },
            (error) => {

            }
        )
    }
}


const viewEmiratesID = (id) => {
    return async (dispatch) => {
        documentUpload.get(`${documentUpload.endpoint.EmiratesID_view}/${id}`, (success) => {
            let payload;
            if (typeof success.data === 'object') {
                payload = success.data;
            } else {
                payload = []
            }
            dispatch(
                { type: DocumentUploadAction.GET_SINGLE_DOC, payload: success?.data },
            )
        },
            (error) => {

            }
        )
    }
}
const deleteEmiratesID = (id) => {
    return async (dispatch) => {
        documentUpload.delete(`${documentUpload.endpoint.EmiratesID_fields_delete}/${id}`, (success) => {
            dispatch(
                { type: DocumentUploadAction.DELETE_DOCUMENT_SUCCESS },
            )
            dispatch(showSuccessToast("Delete successfully!"))
            dispatch(getListOfDocument(id))
        },
            (error) => {

            }
        )
    }
}
const updateEmiratesID = (id, data) => {
    return async (dispatch) => {
        documentUpload.put(`${documentUpload.endpoint.edit_employee_emiratesID_document}/${id}`, data, (success) => {
            dispatch(
                { type: DocumentUploadAction.DOC_UPLOAD_SUCCESS },
            )
            dispatch(showSuccessToast("Update successfully!"))
        },
            (error) => {

            }
        )
    }
}

const DeleteEmiratesImg = (employee_id, document_type) => {
    return async (dispatch) => {
        documentUpload.delete(`${documentUpload.endpoint.delete_employee_EmiratesID_document}/${employee_id}/${document_type}`, (success) => {
            dispatch(
                { type: DocumentUploadAction.DELETE_PASSPORT_SET },
            )
            dispatch(showSuccessToast("Deleted successfully!"))
            dispatch(viewEmiratesID(employee_id))
        },
            (error) => {
            }
        )
    }
}

// get List of all document
const getListOfDocument = (id) => {
    return async (dispatch) => {
        documentUpload.get(`${documentUpload.endpoint.list_of_all_documents}/${id}`, (success) => {
            dispatch(
                { type: DocumentUploadAction.GET_UPLOADED_DOCS_SUCCESS, payload: success?.data },
            )
            // dispatch(showSuccessToast("Added successfully!"))
        },
            (error) => {

            }
        )
    }
}

const setDocType = (payload) => {
    return (
        { type: DocumentUploadAction.SET_DOC_TYPE, payload: payload }
    )
}


const setPassportAnalyzer = (data, docSide) => {
    return async (dispatch) => {
        dispatch(showLoadingFetch("Fetching data..."))
        axios.post(process.env.REACT_APP_PASSPORT_AI_URL_V3, data)
            .then(response => {
                if (docSide === 0) {
                    dispatch({ type: DocumentUploadAction.GET_AI_FRONTSIDE, payload: response?.data },)
                } else {
                    const data = {
                        "data": {
                            "Name of Father/Legal Guardian": "LIYAKATH ARAKKAKKATTIL",
                            "old Passport No. with date and Place of Issue": "K3066901 06/08/2012 MALAPPURAM",
                            "File No": "UE2074738424322",
                            "Name of Mother": "SAFIA LIYAKATH",
                            "Name of Spouse": "FAHISA PALLIMANLJALIL MELETHIL",
                            "Address": "VELAKKERI HOUSE \" PERUMPADAPPA PO,MALAPPURAM \" PIN:679580,KERALA,INDIA"
                        }
                    }
                    dispatch({ type: DocumentUploadAction.GET_AI_BACKSIDE, payload: response?.data },)
                }
                dispatch(hideLoadingFetch())

            })
            .catch(error => {
                // Handle any errors
                if (error?.response?.status == 404) {
                    // if (docSide === 0) {
                    //     dispatch(resetPassportAnalyzerFrontSide())
                    // } else {
                    //     dispatch(resetPassportAnalyzerBackSide())
                    // }
                }
                dispatch(hideLoadingFetch())
            })
        // .finally(() => {
        //     dispatch(resetPassportAnalyzerFrontSide())
        // });
    }
}

const resetPassportAnalyzerFrontSide = (payload) => {
    return (
        { type: DocumentUploadAction.RESET_AI_FRONTSIDE }
    )
}
const resetPassportAnalyzerBackSide = (payload) => {
    return (
        { type: DocumentUploadAction.RESET_AI_BACKSIDE }
    )
}

export {
    uploadPassport, uploadReset, uploadDocImage, viewPassport,
    deleteUploadedDocument, setUpdateDocs, resetUpdateDocs, updateUploadPassport, deleteUploadedPassport
    , resetUplodedDocs, DeletePassportImg, uploadEmiratesID, getListOfDocument, viewEmiratesID,
    deleteEmiratesID, updateEmiratesID, DeleteEmiratesImg, setDocType, setPassportAnalyzer, resetPassportAnalyzerFrontSide,
    resetPassportAnalyzerBackSide
} 