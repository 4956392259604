export default class RegexValid {
    isControlKey(key) {
        return key === "Control" || key === "Shift" || key === "Alt" || key === "ArrowLeft" || key === "ArrowRight" || key === "ArrowUp" || key === "ArrowDown" || key === "Backspace";
    }

    acceptNum(value) {
        if (/^[0-9\b]+$/.test(value)) {
            return true
        } else return false
    }
    acceptAlphaWithSpace(value) {
        if (/^[a-zA-Z\s]*$/.test(value)) {
            return true
        } else return false
    }
    notAcceptSpecialChars(value) {
        return /^[a-zA-Z0-9]+$/.test(value);
    }

    acceptNums(value) {
        if (/^[0-9]+$/.test(value)) {
            return true
        } else return false
    }
    acceptNumsForCard(value) {
        if (/^[0-9-]+$/.test(value)) {
            return true
        } else return false
    }
}