import settingsReq from './settingsReq'
import SettingsAction from './actionType'
import { showSuccessToast } from '../SuccessMessagePopup'
import Auth from '../../../Classes/Auth'

const getMyProfile = (id) => {
    return async (dispatch) => {
        dispatch({ type: SettingsAction.SET_PROFILE_LOADING })
        settingsReq.get(`${settingsReq.endpoint.get_user_profile.replace("__USER_ID__", id)}`, (success) => {
            dispatch({ type: SettingsAction.SET_PROFILE_SUCCESS, payload: success?.data })
            const profileData = success?.data;
            // Loop through each key-value pair in the success.data object
            Auth.updateUserDetail("business_name", profileData?.business_name);
            Auth.updateUserDetail("email", profileData?.email);
        },
            (error) => {

            }
        )
    }
}

const updateMyProfile = (id, data) => {
    return async (dispatch) => {
        // dispatch({ type: SettingsAction.SET_PROFILE_LOADING })
        settingsReq.put(`${settingsReq.endpoint.update_user_profile.replace("__USER_ID__", id)}`, data, (success) => {
            // dispatch({ type: SettingsAction.SET_PROFILE_SUCCESS, payload: success?.data })
            dispatch(showSuccessToast("Profile updated successfully!"))
            dispatch(getMyProfile(id))
        },
            (error) => {

            }
        )
    }
}

const resetReminderList = () => {
    return { type: SettingsAction.REMINDER_LIST_RESET }
}

export { getMyProfile, updateMyProfile }