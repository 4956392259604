import notificationReq from './notificationReq'
import NotificationsActions from './actionType'

const notificationTrigger = (user_id) => {
    return async (dispatch) => {
        notificationReq.get(`${notificationReq.endpoint.notificationTrigger}/${user_id}`, (success) => {
            dispatch({ type: NotificationsActions.NOTIFICATION_TRIGGER_SET, payload: success.data })
        },
            (error) => {

            }
        )
    }
}
const setNotifications = (user_id) => {
    return async (dispatch) => {
        notificationReq.get(`${notificationReq.endpoint.notifications}/${user_id}`, (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });

            dispatch({ type: NotificationsActions.NOTIFICATION_SUCCESS, payload: success.data })
        },
            (error) => {

            }
        )
    }
}
const setInviteNotification = (user_id) => {
    return async (dispatch) => {
        notificationReq.get(`${notificationReq.endpoint.access_invite_notifications.replace("__ID__", user_id)}`, (success) => {
            // let payload = success?.data?.notifications.map((item, index, array) => {
            //     return array[array.length - 1 - index];
            // });

            dispatch({ type: NotificationsActions.NOTIFICATION_SUCCESS, payload: success?.data })
        },
            (error) => {

            }
        )
    }
}


const setSeenNoty = (user_id, data) => {
    return async (dispatch) => {
        notificationReq.post(`${notificationReq.endpoint.notifications}/${user_id}`, data, (success) => {
            dispatch(setNotifications(user_id))
        },
            (error) => {

            }
        )
    }
}
const setNotificationSearch = (data, user_id) => {
    return async (dispatch) => {
        notificationReq.get(`${notificationReq.endpoint.combinednotificationsearch.replace("__USER_ID__", user_id).replace("__VALUE__", data)}`, (success) => {
            dispatch({ type: NotificationsActions.NOTIFICATION_SUCCESS, payload: success.data })
        },
            (error) => {

            }
        )
    }
}



export { notificationTrigger, setNotifications, setSeenNoty, setInviteNotification, setNotificationSearch }