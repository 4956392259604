import styled from "styled-components";
import Arrow from '../../Assets/icons/left-arrow.svg';
export const DropBtnWrapper = styled.div`
    position: relative;
    width:${({ item }) => item?.width ? item?.width : '100%'}; 
    border-radius: ${({ item }) => item?.borderRadius ? item?.borderRadius : '8px'};
`
export const Button = styled.button`
    padding: ${({ item }) => item?.padding ? item?.padding : '12px 16px'};
    border-radius: ${({ item }) => item?.borderRadius ? item?.borderRadius : '8px'};
    border-width:${({ item }) => item?.borderWidth ? item?.borderWidth : '0px'}; 
    background:${({ item }) => item?.background ? item?.background : 'transparent'}; 
    font-size:${({ item }) => item?.fontSize ? item?.fontSize : '16px'}; 
    /* color:${({ item }) => item?.color ? item?.color : '#666666'};  */
    width:${({ item }) => item?.width ? item?.width : '100%'}; 
    text-align:${({ item }) => item?.textAlign ? item?.textAlign : 'center'}; 
    line-height:150%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`
export const ItemList = styled.ul`
        position: absolute;
        z-index: 2;
        width: 100%;
        background: #fff;
        box-shadow: 0px 0px 2px 0px rgba(102,102,102,1);
        border-radius: 8px;
        top: 120%;
        `
export const List = styled.li`
        color:${({ item }) => item.color ? item?.color : '#666666'}; 
        font-size:${({ item }) => item.fontSize ? item?.fontSize : '16px'}; 
        padding: 10px 16px;
        cursor: pointer;
        transition: all .3s;
        &:first-child{
            border-radius:10px 10px 0px  0px  ;
        }
        &:last-child{
            border-radius:0 0px 10px 10px ;
        }
        &:hover{
            /* background-color: #2C4364;
            color: #fff; */
        }
`
export const Icon = styled.i`
    -webkit-mask-image: url(${Arrow});
    mask-image: url(${Arrow}); 
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: 11px;
    width: 11px;
    background-color: #666666;
    line-height: 0;
    rotate: -90deg;
    /* transform-origin: 59% 35%; */
    transition: all .3s;
    mask-size: contain;
    /* transform-origin: center; */
    /* transform: translate(-50%,-50%); */
        &.active-icon{
            rotate: 90deg;
        }
`