import { useEffect, useRef, useState } from "react"
import Auth from "../../Classes/Auth"
import { useDispatch, useSelector } from "react-redux"
import { resetNewPass } from "../../store/Actions/ForgotPassword"
import { useLocation } from "react-router-dom"

const useForceToChangePass = () => {
    const forgotPassword = useSelector((state) => state.forgotPassword)
    const forceToChangePopRef = useRef()
    const [active, setActive] = useState(true)
    const dispatch = useDispatch()
    const location = useLocation();


    const openForceChangePass = () => {
        forceToChangePopRef?.current?.open();
    }
    const closeForceChangePass = () => {
        forceToChangePopRef?.current?.close();
    }

    console.log(Auth?.user()?.fist_login, "Auth?.user()?.fisaaat_login ")

    const logoutBtnHandler = () => {
        closeForceChangePass()
        Auth.logout()
        window.location.href = "/login"
    }


    useEffect(() => {
        Auth?.user()?.fist_login && openForceChangePass()
    }, [])

    useEffect(() => {
        if (Auth?.isLogin()) {
            if (forgotPassword?.new_password) {
                Auth.updateUserDetail("fist_login", false)
                closeForceChangePass()
                dispatch(resetNewPass())
                !location.pathname.includes("/settings") && window.location.reload();
            }
        }
    }, [forgotPassword])

    return { forceToChangePopRef, openForceChangePass, closeForceChangePass, logoutBtnHandler }
}

export default useForceToChangePass
