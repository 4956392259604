import AdminRegisterUserReq from "./AdminRegisterUserReq";
import AdminRegisterUserAction from "./actionType";


const getAllRegisteredUserList = (id) => {
    return async (dispatch) => {
        dispatch({ type: AdminRegisterUserAction.SET_ADMIN_REGISTER_USER_LOADING })
        AdminRegisterUserReq.get(`${AdminRegisterUserReq.endpoint.get_all_user_list}`, (success) => {
            dispatch({ type: AdminRegisterUserAction.SET_ADMIN_REGISTER_USER_SUCCESS, payload: success.data })
        },
            (error) => {

            }
        )
    }
}

// const setRemider2 = (id, docType, data) => {
//     return async (dispatch) => {
//         remindersReq.post(remindersReq.endpoint.reminder_2.replace('__EMPLOYEE__ID', id)
//             .replace('__VALUE__', docType), data, (success) => {
//                 dispatch(showSuccessToast('Update successfully!'))
//                 dispatch(getReminderList(id))
//             }, (error) => {

//             }
//         )
//     }
// }


export { getAllRegisteredUserList }