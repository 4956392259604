import React, { useEffect, useState } from 'react'
import Auth from '../../Classes/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat, formatDateDatePicker } from '../dateFormat';
import ValidationFile from '../../Classes/ValidationFile';
import Validation from '../../Classes/Validation';
import RegexValid from '../../Classes/RegexValid';
import { getMyProfile, updateMyProfile } from '../../store/Actions/Settings';
const usePersonalSettings = () => {
    const userDetails = Auth.user();
    const dispatch = useDispatch()
    const userId = Auth?.user()?.user_id // this is for added by user
    const countryStateCity = useSelector((state) => state.countryStateCity)
    const settings = useSelector(e => e.settings)
    const validation = new Validation()
    const validReg = new RegexValid()
    const [showError, setShowError] = useState()
    const profileData = settings?.personal
    const [regexError, setRegexError] = useState();
    console.log(profileData, "ZXvcscvc")
    const [BusOwnerData, setBusOwnerData] = useState({
        business_name: {
            value: "",
            isValid: false
        },
        contact_person_name: {
            value: "",
            isValid: false,
        },
        business_licence_no: {
            value: "",
            isValid: false
        },

        email: {
            value: "",
            isValid: false
        },
        _phone: {
            value: "",
            country_code: "+971",
            isValid: false
        },
        owner_phone_no: {
            value: "",
            country_code: "+971",
            isValid: false
        },
        Emirates_ID: {
            value: "",
            isValid: false
        },
        address: {
            value: "",
            isValid: false
        },
        country: {
            value: "",
            isValid: false
        },
        state: {
            value: "",
            isValid: false
        },
        city: {
            value: "",
            isValid: false
        },
        postal_code: {
            value: "",
            isValid: false
        },
        validation: false,
    });

    const [typingData, setTypingData] = useState({
        business_name: {
            value: "",
            isValid: false,
        },
        email: {
            value: "",
            isValid: false,
        },
        contact_person_name: {
            value: "",
            isValid: false,
        },
        _phone: {
            value: "",
            country_code: "+971",
            isValid: false,
        },
        validation: false,
    });

    const [individualData, setIndividualData] = useState({
        first_name: {
            value: "",
            isValid: false,
        },
        last_name: {
            value: "",
            isValid: false,
        },
        email: {
            value: "",
            isValid: false
        },
        _phone: {
            value: "",
            country_code: "+971",
            isValid: false
        },
        dob: {
            value: "",
            isValid: false,
        },

        gender: {
            value: "Gender *",
            isValid: false,
        },
        address: {
            value: "",
            isValid: false,
        },
        country: {
            value: "",
            isValid: false,
        },
        state: {
            value: "",
            isValid: false,
        },
        city: {
            value: "",
            isValid: false,
        },
        zip_code: {
            value: "",
            isValid: false,
        },
        validation: false,
    });

    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "first_name": return ValidationFile.validEmpty(inputValue);
            case "last_name": return ValidationFile.validEmpty(inputValue);
            case "business_name": return ValidationFile.validEmpty(inputValue);
            case "contact_person_name": return ValidationFile.validEmpty(inputValue);
            case "business_licence_no": return ValidationFile.validEmpty(inputValue);
            case "Emirates_ID": return ValidationFile.validEmpty(inputValue);
            case "dob": return ValidationFile.validEmpty(inputValue);
            case "gender": return inputValue !== "Gender *"
            case "date_of_joining": return ValidationFile.validEmpty(inputValue);
            case "email": return ValidationFile.validEmail(inputValue);
            case "_phone":
                return ValidationFile.validPhoneNumber(inputValue);
            //  var pattern = new RegExp(/[0-9]{10,}/)
            // if (pattern.test(inputValue)) {
            //     return true
            // }
            // else {
            //     return false
            // }

            case "address": return ValidationFile.validEmpty(inputValue);
            case "country": return ValidationFile.validEmpty(inputValue);
            case "state": return ValidationFile.validEmpty(inputValue);
            case "city": return ValidationFile.validEmpty(inputValue);
            // case "current_zip_code": return ValidationFile.validEmpty(inputValue);
            case "zip_code": return validation.minLength(inputValue, 5)

            default:
                return false;
        }
    };

    // check form is validation 
    const isFormValid = () => {
        if (Auth?.boolUserType() == 3) {
            return (
                individualData?.first_name?.isValid &&
                    individualData?.last_name?.isValid &&
                    individualData?.dob?.isValid &&
                    individualData?.gender?.isValid &&
                    // individualData?.date_of_joining?.isValid &&
                    individualData?.email?.isValid &&
                    individualData?._phone?.isValid &&
                    individualData?.address?.isValid &&
                    individualData?.country?.isValid &&
                    individualData?.state?.isValid &&
                    individualData?.city?.isValid &&
                    individualData?.zip_code?.isValid ? true : false
            )
        } else if (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) {
            return (
                typingData?.business_name?.isValid &&
                    typingData?.email?.isValid &&
                    typingData?.contact_person_name?.isValid &&
                    typingData?._phone?.isValid ? true : false
            )
        } else if (Auth?.boolUserType() == 1) {
            return (
                BusOwnerData?.business_name?.isValid &&
                    BusOwnerData?.contact_person_name?.isValid &&
                    BusOwnerData?.email?.isValid &&
                    BusOwnerData?._phone?.isValid &&
                    BusOwnerData.business_licence_no.isValid &&
                    BusOwnerData.Emirates_ID.isValid &&
                    BusOwnerData.address.isValid &&
                    BusOwnerData.zip_code.isValid &&
                    BusOwnerData?.country?.isValid &&
                    BusOwnerData?.state?.isValid &&
                    BusOwnerData?.city?.isValid ? true : false
            )
        }
    };


    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName == "first_name" || inputName == "last_name") {
            // Capitalize the first letter of the input value
            inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        }

        let data = {
            ...Auth?.boolUserType() == 3 ? individualData : Auth?.boolUserType() == 1 ? BusOwnerData : typingData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        // setIndividualData(data);
        // Auth?.boolUserType() == 3 ? setIndividualData(data) : Auth?.boolUserType() == 1 ? setBusOwnerData(data) : setTypingData(data);
        switch (inputName) {
            case "first_name":
            case "last_name":
            case "business_name":
            case "contact_person_name":
                if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
                    return Auth?.boolUserType() == 3 ? setIndividualData(data) : Auth?.boolUserType() == 1 ? setBusOwnerData(data) : setTypingData(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            case "Emirates_ID":
                if (validReg.acceptNumsForCard(inputValue) || inputValue === '') {
                    return Auth?.boolUserType() == 3 ? setIndividualData(data) : Auth?.boolUserType() == 1 ? setBusOwnerData(data) : setTypingData(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            case "postal_code":
            case "zip_code":
                if (validReg.acceptNums(inputValue) || inputValue === '') {
                    return Auth?.boolUserType() == 3 ? setIndividualData(data) : Auth?.boolUserType() == 1 ? setBusOwnerData(data) : setTypingData(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            default:
                return Auth?.boolUserType() == 3 ? setIndividualData(data) : Auth?.boolUserType() == 1 ? setBusOwnerData(data) : setTypingData(data);
        }

    }

    const handleDateInput = (date, inputName) => {
        let DateInfo = {
            ...individualData,
            [inputName]: {
                value: date,
                isValid: date ? true : false,
            },
            "validation": isFormValid(),
        };
        setIndividualData(DateInfo);
    }


    // gender change handler
    const handleGenderChange = (val) => {
        let setGender = {
            ...individualData,
            "gender": {
                value: val,
                isValid: validationConfirm(val, "gender"),
            },
            validation: isFormValid(),
        }
        setIndividualData(setGender)
    }

    // handle phone input
    const handlePhoneInput = (inputValue, countryDetail, nameValue) => {
        let dialCode = countryDetail.dialCode;
        let mobileNumber = inputValue.replace(dialCode, "");

        let mobileData = {
            ...Auth?.boolUserType() == 3 ? individualData : Auth?.boolUserType() == 1 ? BusOwnerData : typingData,
            "_phone": {
                value: mobileNumber,
                country_code: dialCode,
                isValid: validationConfirm(mobileNumber, "_phone"),
                // isValid: true,
            },
            validation: isFormValid(),
        }
        console.log(mobileData, "fdsedgsdfg56")
        // setIndividualData(mobileData)
        Auth?.boolUserType() == 3 ? setIndividualData(mobileData) : Auth?.boolUserType() == 1 ? setBusOwnerData(mobileData) : setTypingData(mobileData);
    }



    // effect for get the value of countryStateCity  
    useEffect(() => {
        let setAddress = {
            // ...individualData,
            ...Auth?.boolUserType() == 3 ? individualData : Auth?.boolUserType() == 1 ? BusOwnerData : typingData,
            "country": {
                value: countryStateCity?.country?.name,
                isValid: validationConfirm(countryStateCity?.country?.name, "country")
            },
            "state": {
                value: countryStateCity?.states?.name,
                isValid: validationConfirm(countryStateCity?.states?.name, "state")
            },
            "city": {
                value: countryStateCity?.city?.name,
                isValid: validationConfirm(countryStateCity?.city?.name, "city")
            },
            "validation": isFormValid(),
        }
        // setIndividualData(setAddress)
        Auth?.boolUserType() == 3 ? setIndividualData(setAddress) : Auth?.boolUserType() == 1 ? setBusOwnerData(setAddress) : setTypingData(setAddress);
    }, [countryStateCity])



    const handleCancelBtn = () => {

    }

    useEffect(() => {
        // set business owener
        setBusOwnerData({
            business_name: {
                value: profileData?.data?.business_name,
                isValid: profileData?.data?.business_name?.length ? true : false,
            },
            contact_person_name: {
                value: profileData?.data?.contact_person_name,
                isValid: profileData?.data?.contact_person_name?.length ? true : false,
            },
            business_licence_no: {
                value: profileData?.data?.complete_profile?.business_licence_no,
                isValid: profileData?.data?.complete_profile?.business_licence_no?.length ? true : false,
            },
            email: {
                value: profileData?.data?.email,
                isValid: profileData?.data?.email?.length ? true : false,
            },
            _phone: {
                value: profileData?.data?.phone_no?.split(" ")[1],
                country_code: profileData?.data?.phone_no?.split(" ")[0],
                isValid: profileData?.data?.phone_no?.length ? true : false,
            },

            Emirates_ID: {
                value: profileData?.data?.complete_profile?.Emirates_ID,
                isValid: profileData?.data?.complete_profile?.Emirates_ID?.length ? true : false,
            },
            address: {
                value: profileData?.data?.complete_profile?.address,
                isValid: profileData?.data?.complete_profile?.address?.length ? true : false,
            },
            country: {
                value: profileData?.data?.complete_profile?.country,
                isValid: profileData?.data?.complete_profile?.country?.length ? true : false,
            },
            state: {
                value: profileData?.data?.complete_profile?.state,
                isValid: profileData?.data?.complete_profile?.state?.length ? true : false,
            },
            city: {
                value: profileData?.data?.complete_profile?.city,
                isValid: profileData?.data?.complete_profile?.city?.length ? true : false,
            },
            zip_code: {
                value: profileData?.data?.complete_profile?.postal_code,
                isValid: profileData?.data?.complete_profile?.postal_code?.length ? true : false,
            },
            validation: isFormValid(),
        })
        //  set typing center
        setTypingData({
            business_name: {
                value: profileData?.data?.business_name,
                isValid: profileData?.data?.business_name?.length ? true : false,
            },
            email: {
                value: profileData?.data?.email,
                isValid: profileData?.data?.email?.length ? true : false,
            },
            contact_person_name: {
                value: profileData?.data?.contact_person_name,
                isValid: profileData?.data?.contact_person_name?.length ? true : false,
            },
            _phone: {
                value: profileData?.data?.phone_no?.split(" ")[1],
                country_code: profileData?.data?.phone_no?.split(" ")[0],
                isValid: profileData?.data?.phone_no?.length ? true : false,
            },
            validation: isFormValid(),
        })

        // set individuals 
        setIndividualData({
            email: {
                value: profileData?.data?.email,
                isValid: true,
            },
            _phone: {
                value: profileData?.data?.phone_no?.split(" ")[1],
                country_code: profileData?.data?.phone_no?.split(" ")[0],
                isValid: profileData?.data?.phone_no?.length ? true : false
            },
            first_name: {
                value: profileData?.data?.complete_profile?.first_name,
                isValid: profileData?.data?.complete_profile?.first_name?.length > 0 ? true : false,
            },
            last_name: {
                value: profileData?.data?.complete_profile?.last_name,
                isValid: profileData?.data?.complete_profile?.last_name?.length > 0 ? true : false
            },

            dob: {
                value: profileData?.data?.complete_profile?.dob,
                isValid: profileData?.data?.complete_profile?.dob?.length > 0 ? true : false,
            },

            gender: {
                value: profileData?.data?.complete_profile?.gender,
                isValid: profileData?.data?.complete_profile?.gender?.length > 0 ? true : false,
            },
            address: {
                value: profileData?.data?.complete_profile?.address,
                isValid: profileData?.data?.complete_profile?.address?.length > 0 ? true : false,
            },
            country: {
                value: profileData?.data?.complete_profile?.country,
                isValid: profileData?.data?.complete_profile?.country?.length > 0 ? true : false,
            },
            state: {
                value: profileData?.data?.complete_profile?.state,
                isValid: profileData?.data?.complete_profile?.state?.length > 0 ? true : false,
            },
            city: {
                value: profileData?.data?.complete_profile?.city,
                isValid: profileData?.data?.complete_profile?.city?.length > 0 ? true : false,
            },
            zip_code: {
                value: profileData?.data?.complete_profile?.postal_code,
                isValid: profileData?.data?.complete_profile?.postal_code?.length > 0 ? true : false,
            },
            validation: isFormValid(),
        })

    }, [profileData, dispatch])

    console.log(individualData, "sdfsff")
    const handleSaveBtn = (e) => {
        e.preventDefault()
        if (Auth?.boolUserType() == 3) {
            if (individualData?.validation) {
                const data = {
                    first_name: individualData?.first_name?.value,
                    last_name: individualData?.last_name?.value,
                    email: individualData?.email?.value,
                    phone_no: `${individualData?._phone?.country_code} ${individualData?._phone?.value}`,
                    dob: formatDateDatePicker(individualData?.dob?.value),
                    gender: individualData?.gender?.value,
                    address: individualData?.address?.value,
                    country: countryStateCity?.country?.name,
                    state: countryStateCity?.states?.name,
                    city: countryStateCity?.city?.name,
                    postal_code: individualData?.zip_code?.value
                }
                dispatch(updateMyProfile(userId, data))

            } else {
                setShowError(true);
            }
        } else if (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) {
            if (isFormValid()) {
                const data = {
                    business_name: typingData?.business_name?.value,
                    contact_person_name: typingData?.contact_person_name?.value,
                    email: typingData?.email?.value,
                    phone_no: `${typingData?._phone?.country_code} ${typingData?._phone?.value}`,
                }
                dispatch(updateMyProfile(userId, data))

            } else {
                setShowError(true);
            }
        } else if (Auth?.boolUserType() == 1) {
            if (isFormValid()) {
                const data = {
                    business_name: BusOwnerData?.business_name?.value,
                    contact_person_name: BusOwnerData?.contact_person_name?.value,
                    email: BusOwnerData?.email?.value,
                    phone_no: `${BusOwnerData?._phone?.country_code} ${BusOwnerData?._phone?.value}`,
                    business_licence_no: BusOwnerData.business_licence_no.value,
                    Emirates_ID: BusOwnerData.Emirates_ID.value,
                    address: BusOwnerData.address.value,
                    postal_code: BusOwnerData.zip_code.value,
                    country: countryStateCity?.country?.name,
                    state: countryStateCity?.states?.name,
                    city: countryStateCity?.city?.name,
                }
                dispatch(updateMyProfile(userId, data))

            } else {
                setShowError(true);
            }
        }
    }
    console.log(isFormValid(), individualData?.validation, individualData, "BusOwnerData22222")

    useEffect(() => {
        dispatch(getMyProfile(userId))
    }, [])


    return {
        BusOwnerData, typingData, individualData, handleInput, handleGenderChange,
        handlePhoneInput, handleDateInput, handleSaveBtn, handleCancelBtn, showError,
        regexError, setRegexError
    }
}

export default usePersonalSettings