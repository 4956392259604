import React, { forwardRef } from "react";
import { getBaseUrl } from "../../config";

const ImageViewer = forwardRef(({ object, defaultImage, ...props }, ref) => {
    const baseUrl = getBaseUrl()
    return (
        <React.Fragment>
            <img
                ref={ref}
                src={object ? baseUrl + object : defaultImage}
                {...props}
            />
            {/* {object?.key ? (
                <img ref={ref}
                    src={process.env.REACT_APP_S3_BUCKET_URL + object?.key ? encodeURI(process.env.REACT_APP_S3_BUCKET_URL + object?.key) : defaultImage}
                    alt={object?.alt}
                    {...props} />
            ) : (

                <img
                    src={object?.src ? encodeURI(object.src) : object ? object : defaultImage}
                    alt={object?.alt}
                    {...props} />
            )} */}
        </React.Fragment>
    );
});
export default ImageViewer