import Request from "../../../Classes/Requests";

class notificationReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            notificationTrigger: super.url("/api/trigger-notifications"),
            notifications: super.url("/notifications"),
            access_invite_notifications: super.url("/access_invite_notifications?typing_centre_id=__ID__"),
            combinednotificationsearch: super.url("/combinednotificationsearch?user_id=__USER_ID__&query=__VALUE__")
        }
    }
}
export default new notificationReq();
