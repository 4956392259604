import React from 'react'
import './pricingItem.scss';
import { setUserType } from '../../../store/Actions/Auth';
import { useDispatch } from 'react-redux';
const PricingItem = ({ type, basicOffer, proOffer, premOffer }) => {
    const dispatch = useDispatch()
    const clickHandle = (val, offer_type) => {
        dispatch(setUserType(val, offer_type))
        window.location.href = "/create-account"
        // navigate('/create-account')
    }
    return (
        <div className="pricing-item-wrapper">
            <div className="plan-heading-wrap">
                <h2 className='heading-para text-center text-s mb-0 white'>{type}</h2>
            </div>
            <div className="basic-offer-wrap mt-15">
                <h4 className="heading text-xs white"> Basic Offer </h4>
                <div className="offer-detail d-flex justify-between mt-8">
                    <p className="offer-head white mb-0 text-s">{basicOffer?.price}</p>
                    <div className="offer-space white  mb-0 text-s">{basicOffer?.value}</div>
                </div>
                <button className='buy-now'
                    onClick={() => clickHandle(type === "Individual" ? "3" : type === "Typing Centers" ? "2" : type === "Business Owners" ? "1" : '', "Basic Offer")}
                >
                    Buy now
                </button>
            </div>
            <div className="basic-offer-wrap mt-15">
                <h4 className="heading text-xs white"> Pro Offer </h4>
                <div className="offer-detail d-flex justify-between mt-8">
                    <p className="offer-head white mb-0 text-s">{proOffer?.price}</p>
                    <div className="offer-space white  mb-0 text-s">{proOffer?.value}</div>
                </div>
                <button className='buy-now'
                    onClick={() => clickHandle(type === "Individual" ? "3" : type === "Typing Centers" ? "2" : type === "Business Owners" ? "1" : '', "Pro Offer")}
                >
                    Buy now
                </button>
            </div>
            <div className="basic-offer-wrap mt-15">
                <h4 className="heading text-xs white "> Premium Offer </h4>
                <div className="offer-detail d-flex justify-between mt-8 mb-10">
                    <div className="offer-head white mb-0 ">
                        <p className='mb-0 text-s' >{premOffer?.price}</p>
                        <p className='mb-0 text-xxs'>For the next </p>
                    </div>
                    <div className="offer-space white mb-0 text-s">{premOffer?.value}</div>
                </div>
                <button className='buy-now'
                    onClick={() => clickHandle(type === "Individual" ? "3" : type === "Typing Centers" ? "2" : type === "Business Owners" ? "1" : '', "Premium Offer")}
                >
                    Buy now
                </button>
            </div>
        </div>
    )
}

export default PricingItem