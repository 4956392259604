import React from 'react'

const LoadingData = ({ text, children, className, textCenter = false }) => {
    return (
        <div className={`data-not-found-wrapper ${className}`} >
            <p className={`text-xs w-500 ${textCenter && 'text-center'}`}>{text ? text : "Loading..."}</p>
        </div>
    )
}

export default LoadingData