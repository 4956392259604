export const Country_State_city = {
    SET_COUNTRY: "SET_COUNTRY",
    SET_STATE: "SET_STATE",
    SET_CITY: "SET_CITY",
    RESET_CSC: "RESET_CSC",

    SET_NATIVE_COUNTRY: "SET_NATIVE_COUNTRY",
    SET_NATIVE_STATE: "SET_NATIVE_STATE",
    SET_NATIVE_CITY: "SET_NATIVE_CITY",
    RESET_NATIVE_CSC: "RESET_NATIVE_CSC",
};