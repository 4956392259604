export const forgotPasswordAction = {
    SET_MESSAGE: "SET_MESSAGE",
    SET_LOADING: "SET_LOADING",

    SET_VALID_USER: "SET_VALID_USER",
    HIDE_VALID_USER: "HIDE_VALID_USER",

    SET_NOT_VALID_USER: "SET_NOT_VALID_USER",


    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAIL: "VERIFY_OTP_FAIL",
    RESET_VERIFY_OTP_FAIL: "RESET_VERIFY_OTP_FAIL",

    SET_NEWPASSWORD_SUCCESS: "SET_NEWPASSWORD_SUCCESS",
    RESET_NEWPASSWORD_SUCCESS: "RESET_NEWPASSWORD_SUCCESS",
}