const AddUserAction = {
    SET_ADD_USER_LOADING: "SET_ADD_USER_LOADING",
    SET_ADD_USER_SUCCESS: "SET_ADD_USER_SUCCESS",
    SET_ADD_USER_ERROR: "SET_ADD_USER_ERROR",
    SET_ADD_USER_ERROR_RESET: "SET_ADD_USER_ERROR_RESET",
    SET_ADD_USER_SUCCESS_RESET: "SET_ADD_USER_SUCCESS_RESET",

    SET_USER_LIST_SUCCESS: "SET_USER_LIST_SUCCESS",

    SET_USER_SINGLE_USER_VIEW: "SET_USER_SINGLE_USER_VIEW",

    SET_USER_UPDATE_RESET: "SET_USER_UPDATE_RESET",

    SET_USER_CLIENT_LIST_LOADING: "SET_USER_CLIENT_LIST_LOADING",
    SET_USER_CLIENT_LIST_SUCCESS: "SET_USER_CLIENT_LIST_SUCCESS",
    SET_USER_CLIENT_LIST_RESET: "SET_USER_CLIENT_LIST_RESET",



}

export default AddUserAction