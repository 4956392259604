import React, { useEffect, useState } from 'react'
import ValidationFile from '../../Components/Auth/Login/ValidationFile';
import { setNewPassword } from '../../store/Actions/ForgotPassword';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../Classes/Auth';

const useOtherSettings = () => {
    const forgotPassword = useSelector((state) => state.forgotPassword)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [forgotPass, setForgotPass] = useState(true)
    const [eyeBtn, setEyeBtn] = useState(true)
    const [eyeBtn1, setEyeBtn1] = useState(true)
    const [showError, setShowError] = useState(false);
    const [passCondition, setPassCondition] = useState(false);
    const [re_passCondition, setRe_PassCondition] = useState(false);
    const emailParam = Auth?.user()?.email;
    const [newPasswordData, setNewPasswordData] = useState({
        new_password1: {
            value: "",
            isValid: false,
        },
        new_password2: {
            value: "",
            isValid: false,
        },
        validation: false,
    });

    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "new_password1":
                return ValidationFile.ValidPasswordHard(inputValue);

            case "new_password2":
                return ValidationFile.samePassword(newPasswordData.new_password1.value, inputValue);
            default:
                return false;
        }
    };
    const isFormValid = () => {
        return newPasswordData.new_password1.isValid && newPasswordData.new_password2.isValid ? true : false;
    };

    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...newPasswordData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setNewPasswordData(data);

        if (inputName === "new_password1") {
            newPasswordData.new_password2.isValid = ValidationFile.samePassword(inputValue, newPasswordData.new_password2.value);

        } else if (inputName === "new_password2") {
            newPasswordData.new_password2.isValid = ValidationFile.samePassword(newPasswordData.new_password1.value, inputValue);
            if (inputValue.length > 0) {
                setRe_PassCondition(true)
            } else {
                setRe_PassCondition(false)
            }

        }

        if (inputName === "new_password1") {
            if (newPasswordData.new_password1.isValid) {
                setPassCondition(false)
            } else {
                setPassCondition(true)
            }
        }


    }

    const clickHandle = (e) => {
        e.preventDefault();
        const data = {
            email: emailParam,
            password: newPasswordData.new_password1.value
        }
        if (newPasswordData.validation) {
            dispatch(setNewPassword(data))
        } else {
            setShowError(true);
        }
    }

    const resetnewPasswordData = () => {
        setNewPasswordData({
            new_password1: {
                value: "",
                isValid: false,
            },
            new_password2: {
                value: "",
                isValid: false,
            },
            validation: false,
        })
    }
    useEffect(() => {
        if (forgotPassword.new_password) {
            resetnewPasswordData()
            setShowError(false)
            setPassCondition(false)
            setForgotPass(true)
        }
    }, [forgotPassword])

    return {
        newPasswordData, handleInput, clickHandle, eyeBtn, setEyeBtn,
        eyeBtn1, setEyeBtn1,
        showError,
        passCondition,
        re_passCondition, forgotPass, setForgotPass
    }
}

export default useOtherSettings