import React, { createContext, useEffect, useRef, useState } from 'react'
import './dashboard.scss'
import LeftBarPannel from './LeftBarPannel/LeftBarPannel'
import RightBarPannel from './RightBarPannel/RightBarPannel'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import SessionStorage from '../../Classes/SessionStorage'
import Cookies from '../../Classes/Cookies'
import TopRibbon from './TopRibbon/TopRibbon'
import useForceToChangePass from '../../CustomHooks/ForceToChangePass/useForceToChangePass'
import Auth from '../../Classes/Auth'
export const ActiveTabContext = createContext()
const Dashboard = ({ children }) => {
    const leftSideRef = useRef(null)
    const toggelVal = SessionStorage.getBool("toggelBtn")
    const [toggelBtn, setToggelBtn] = useState(toggelVal !== null ? JSON.parse(toggelVal) : false)
    const [burgerBtn, setBurgerBtn] = useDetectOutsideClick(leftSideRef, false)

    useEffect(() => {
        SessionStorage.setBool("toggelBtn", JSON.stringify(toggelBtn))
    }, [toggelBtn])


    // const { forceToChangePopRef } = useForceToChangePass()
    // useEffect(() => {
    return (
        <ActiveTabContext.Provider value={{ toggelBtn, setToggelBtn, setBurgerBtn }}>
            <div className={`dashboard-main-wrapper`} aria-disabled={Auth?.user()?.fist_login ? Auth?.user()?.fist_login : false}>
                <TopRibbon />
                <div className="left-sidebar-wrapper" >
                    <LeftBarPannel
                        toggelBtn={toggelBtn}
                        setToggelBtn={setToggelBtn}
                        burgerBtn={burgerBtn}
                        setBurgerBtn={setBurgerBtn}
                        leftSideRef={leftSideRef}
                    />
                </div>
                <div className="right-bar-content-wrapper">
                    <RightBarPannel
                        toggelBtn={toggelBtn}
                        burgerBtn={burgerBtn}
                        setBurgerBtn={setBurgerBtn}
                        children={children}
                    />
                </div>
            </div>
        </ActiveTabContext.Provider>
    )
}

export default Dashboard