import Request from "../../../Classes/Requests";

class clientsReq extends Request {
    constructor() {
        super()
        this.endpoint = {
            typing_client_users: super.url("/typing_client_users"),
            client_view: super.url("/client_view"),
            add_typing_client: super.url("/add_typing_client"),
            update_typing_client: super.url("/update_typing_client"),
            delete_typing_client: super.url("/delete_typing_client"),
            search_clients: super.url("/search_clients?typing_center_id=__TC_ID__&query=__VALUE__"),

            employee_search: super.url("/employee_search?added_by_user_id=__USER_ID__&query=__VALUE__"),
            //  /employee_search?added_by_user_id=305&query=guddu

        }
    }
}
export default new clientsReq();
