import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { addClient, getSingleClient, resetAddClient, resetError, setClientType, updateClient } from '../../store/Actions/Clients'
import ValidationFile from '../../Classes/ValidationFile'
import Validation from '../../Classes/Validation'
import { useTodayDate } from '../useTodayDate'
import RegexValid from '../../Classes/RegexValid'
import Auth from '../../Classes/Auth'

const useClientPersonalData = (setActiveEdit) => {
    const clients = useSelector((e) => e.clients)
    const countryStateCity = useSelector((state) => state.countryStateCity)
    const dispatch = useDispatch()
    const { client_id } = useParams()
    const navigate = useNavigate()
    const [type, setType] = useState('new')
    const validReg = new RegexValid()
    const imageRef = useRef();
    const inputFile = useRef(null)
    const singleClient = clients.single_client;
    const userId = Auth?.user()?.user_id // this is for added by user
    const [currentID, setCurrentID] = useState(null)
    const [arrowBtn, setArrowBtn] = useState({
        prev: false,
        next: false
    })
    const [clientData, setClientData] = useState({
        business_name: {
            value: "",
            isValid: false,
        },
        business_licence_no: {
            value: "",
            isValid: false,
        },

        email: {
            value: "",
            isValid: false,
        },
        _phone: {
            value: "",
            country_code: "+971",
            isValid: false,
        },
        Emirates_ID: {
            value: "",
            isValid: false,
        },
        Nationality: {
            value: "",
            isValid: false,
        },
        text_id: {
            value: "",
            isValid: true,
        },

        address: {
            value: "",
            isValid: false,
        },
        country: {
            value: "",
            isValid: false,
        },
        state: {
            value: "",
            isValid: false,
        },
        city: {
            value: "",
            isValid: false,
        },
        postal_code: {
            value: "",
            isValid: false,
        },
        validation: false,
    });

    const validation = new Validation()
    const [regexError, setRegexError] = useState();
    const [showCPError, setShowCPError] = useState(false);
    const todayDate = useTodayDate()
    useEffect(() => {
        if (client_id.includes("id=") || clients.client_type == 'old') {
            const id = client_id?.includes("id=") && client_id?.replace("id=", "")
            setType("old")
            dispatch(setClientType("old"))
            dispatch(getSingleClient(userId, id))
            setCurrentID(id)
        } else {
            setType("new")
            dispatch(setClientType("new"))
            setActiveEdit && setActiveEdit(true)
        }
    }, [client_id])

    function onProflieChange(e, ref) {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                ref.current.src = e.target.result
                setActiveEdit && setActiveEdit(true)
            };

            reader.readAsDataURL(file);
        }
    }


    // check input vafildation

    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "business_name": return ValidationFile.validEmpty(inputValue);
            case "business_licence_no": return ValidationFile.validEmpty(inputValue);
            case "email": return ValidationFile.validEmail(inputValue);
            case "_phone":
                return ValidationFile.validPhoneNumber(inputValue);
            //  var pattern = new RegExp(/[0-9]{10,}/)
            // if (pattern.test(inputValue)) {
            //     return true
            // }
            // else {
            //     return false
            // }
            case "Emirates_ID": return ValidationFile.validEmpty(inputValue);
            case "Nationality": return ValidationFile.validEmpty(inputValue);
            case "text_id": return ValidationFile.validEmpty(inputValue);
            case "address": return ValidationFile.validEmpty(inputValue);
            case "country": return ValidationFile.validEmpty(inputValue);
            case "state": return ValidationFile.validEmpty(inputValue);
            case "city": return ValidationFile.validEmpty(inputValue);
            case "postal_code": return validation.minLength(inputValue, 5)

            default:
                return false;
        }
    };

    // check form is validation 
    const isFormValid = () => {
        return (
            clientData?.business_name?.isValid &&
                clientData?.business_licence_no?.isValid &&
                clientData?.email?.isValid &&
                clientData?._phone?.isValid &&
                clientData?.Emirates_ID?.isValid &&
                clientData?.Nationality?.isValid &&
                clientData?.text_id?.isValid &&
                clientData?.address?.isValid &&
                clientData?.country?.isValid &&
                clientData?.state?.isValid &&
                clientData?.city?.isValid &&
                clientData?.postal_code?.isValid ? true : false
        )
    };
    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (inputName == "business_name") {
            inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        }
        let data = {
            ...clientData,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        // setClientData(data);

        if (inputName == 'email') {
            dispatch(resetError())
        }


        switch (inputName) {
            case "Nationality":
            case "business_name":
                if (validReg.acceptAlphaWithSpace(inputValue) || inputValue === '') {
                    return setClientData(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            case "postal_code":
                if (validReg.acceptNums(inputValue) || inputValue === '') {
                    return setClientData(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            case "Emirates_ID":
                if (validReg.acceptNumsForCard(inputValue) || inputValue === '') {
                    return setClientData(data), setRegexError('');
                } else {
                    return setRegexError(inputName);
                }
            default:
                return setClientData(data);
        }


    }

    // handle phone input
    const handlePhoneInput = (inputValue, countryDetail, nameValue) => {
        let dialCode = countryDetail.dialCode;
        let mobileNumber = inputValue.replace(dialCode, "");
        let mobileData = {
            ...clientData,
            "_phone": {
                value: mobileNumber,
                country_code: dialCode,
                isValid: validationConfirm(mobileNumber, "_phone"),
            },
            validation: isFormValid(),
        }
        setClientData(mobileData)
    }
    // effect for get the value of countryStateCity  
    useEffect(() => {
        let setAddress = {
            ...clientData,
            "country": {
                value: countryStateCity?.country?.name,
                isValid: validationConfirm(countryStateCity?.country?.name, "country"),
            },
            "state": {
                value: countryStateCity?.states?.name,
                isValid: validationConfirm(countryStateCity?.states?.name, "state"),
            },
            "city": {
                value: countryStateCity?.city?.name,
                isValid: validationConfirm(countryStateCity?.city?.name, "city"),
            },
        }
        setClientData(setAddress)
    }, [countryStateCity])

    // Save Btn handler
    const handlePersonalClientSaveBtn = (e, imgData) => {
        const data = {
            "business_name": clientData?.business_name?.value,
            "email": clientData?.email?.value,
            "phone_no": `${clientData._phone.country_code} ${clientData._phone.value}`,
            "added_by_user_id": userId,
            "additional_information": {
                "business_licence_no": clientData?.business_licence_no?.value,
                "Emirates_ID": clientData?.Emirates_ID?.value,
                "Nationality": clientData?.Nationality?.value,
                "text_id": clientData?.text_id?.value,
                "address": clientData?.address?.value,
                "country": clientData?.country?.value,
                "state": clientData?.state?.value,
                "city": clientData?.city?.value,
                "postal_code": clientData?.postal_code?.value,
            }
        }

        if (isFormValid()) {
            if (type == 'new') {
                dispatch(addClient(data))
            } else {
                dispatch(updateClient(singleClient?.data?.id, data, userId))
            }
        } else {
            setShowCPError(true);
            console.log(clientData?.email?.value.length, "clientData?.email?.value.length")
            if (clientData?.email?.value.length == 0) {
                setRegexError('email');
            } else if (clientData?.email?.value.length >= 0) {
                setRegexError('');
            }
        }
    }

    useEffect(() => {
        if (clients?.success) {
            navigate('/clients-management')
            dispatch(resetAddClient())
        }
    }, [clients?.success])



    //Effect for fetch existing client details in input form
    useEffect(() => {
        if (client_id.includes("id=")) {
            const mobileNo = singleClient?.data?.phone_no?.split(" ")
            setClientData(
                {
                    business_name: {
                        value: singleClient?.data?.business_name,
                        isValid: true,
                    },
                    business_licence_no: {
                        value: singleClient?.data?.business_licence_no,
                        isValid: true,
                    },

                    email: {
                        value: singleClient?.data?.email,
                        isValid: true,
                    },
                    _phone: {
                        value: mobileNo ? mobileNo[1] : '',
                        country_code: mobileNo ? mobileNo[0] : '',
                        isValid: true,
                    },
                    Emirates_ID: {
                        value: singleClient?.data?.Emirates_ID,
                        isValid: true,
                    },
                    Nationality: {
                        value: singleClient?.data?.Nationality,
                        isValid: true,
                    },
                    text_id: {
                        value: singleClient?.data?.text_id,
                        isValid: true,
                    },

                    address: {
                        value: singleClient?.data?.address,
                        isValid: true,
                    },
                    country: {
                        value: singleClient?.data?.country,
                        isValid: true,
                    },
                    state: {
                        value: singleClient?.data?.state,
                        isValid: true,
                    },
                    city: {
                        value: singleClient?.data?.city,
                        isValid: true,
                    },
                    postal_code: {
                        value: singleClient?.data?.postal_code,
                        isValid: true,
                    },
                    validation: true,
                }
            )
        }
    }, [singleClient])


    // Preview AND NEXT  Btn handler
    const currentClient = clients?.data?.find(client => client?.id == currentID);
    const ClientIndex = clients.data.findIndex(client => client?.id === currentClient?.id);

    const arrowBtnHandler = (direction) => {
        if (currentClient) {
            console.log("Desired Employee:", currentClient);

            // Find the ClientIndex of the currentClient
            console.log(ClientIndex, "fgdg")
            if (direction === "prev") {
                if (ClientIndex > 0) {
                    const previousEmployee = clients.data[ClientIndex - 1].id;
                    navigate(`/clients-management/id=${previousEmployee}`)
                }
            }
            else
                if (direction === "next") {
                    if (ClientIndex < clients.data.length - 1) {
                        const nextEmployee = clients.data[ClientIndex + 1].id;
                        navigate(`/clients-management/id=${nextEmployee}`)
                    }
                }
        }
    }
    useEffect(() => {
        if (ClientIndex == 0) {
            setArrowBtn({ next: false, prev: true })
        }
        else if (ClientIndex < clients.data.length - 1) {
            setArrowBtn({ prev: false, next: false })
        }
        else if (ClientIndex == clients.data.length - 1) {
            setArrowBtn({ prev: false, next: true })
        }
    }, [currentClient])


    const handleRegexValidation = (e) => {
        // let inputName = e.target.name;
        // // || !validReg.acceptAlphaWithSpace(e.target.value)
        // const inputValue = e?.clipboardData?.getData('text');
        // if (inputName === "Nationality") {
        //     if (!validReg.acceptAlphaWithSpace(e.key) || !validReg.acceptAlphaWithSpace(inputValue)) {
        //         e.preventDefault();
        //         setRegexError(inputName)
        //         // resetRegexError(); // Call the reset function
        //     } else {
        //         setRegexError('')
        //     }
        // } else if (inputName === 'postal_code') {
        //     if ((e.key !== "Backspace" && !/^[0-9\b]+$/.test(e.key))) {
        //         e.preventDefault();
        //         setRegexError(inputName)
        //     } else {
        //         setRegexError('')
        //     }
        // } else if (inputName === 'Emirates_ID') {
        //     if (
        //         validReg.acceptNum(e.key) ||
        //         e.key == '-' ||
        //         e.key == "Backspace" ||
        //         e.ctrlKey ||
        //         validReg.acceptNum(inputValue)
        //     ) {
        //         setRegexError('')
        //     } else {
        //         e.preventDefault();
        //         setRegexError(inputName)
        //     }
        // }

        // else {
        //     setRegexError('')
        // }
    }

    // const handlePaste = (e) => {
    //     const inputValue = e.clipboardData.getData('text');
    //     if (!validReg.acceptAlphaWithSpace(inputValue)) {
    //         e.preventDefault();
    //         setRegexError('Nationality');
    //     } else {
    //         setRegexError('');
    //     }
    // };

    return {
        type, clients, imageRef, inputFile, onProflieChange, singleClient, clientData,
        handleInput, handleRegexValidation, showCPError, regexError,
        todayDate, handlePhoneInput, handlePersonalClientSaveBtn, arrowBtnHandler, arrowBtn,

    }
}

export default useClientPersonalData