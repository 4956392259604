import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Validation from '../../Classes/Validation';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../Classes/Auth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { compressFile } from '../../HelperFunction/FileCompressor';
import { FILE_ACCEPT, IMG_ACCEPT } from '../../Constant/Constants';
import { ALLOW_IMAGE_EXTENSIONS, noticationTiggerTime } from '../../config';
import { companydocsCreateNewFolder, getComapanyDocumentsFoldersList, renameCompanyDocsFolder, uploadComapanyDocsFile, getCompanyDocsSubFolder, setReminderFolder, setReminderFile, setCompanyDocumentsNotifications, triggerCompanyDocumentsNotification } from '../../store/Actions/CompanyDocuments';
import { ErrorReset, createFolderReset, deleteFiles, deleteFolder, renameReset, resetFolderDetail, resetFolderList, setBreadcrumb } from '../../store/Actions/CompanyDocuments';
const useCompanyDocuments = (folderPopPopRef) => {
    const companyDocuments = useSelector((e) => e.companyDocuments)
    const userId = Auth?.user()?.user_id // this is for added by user 
    const valid = new Validation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showError, setShowError] = useState(false)
    const [editable, setEditabel] = useState(false)
    const [uploadType, setUploadType] = useState('create')
    const [renameId, setRenameId] = useState('')
    // const [breadcrumb, setBreadcrumb] = useState('')
    const [folderId, setFolderId] = useState(null)
    const [searchVal, setSearchVal] = useState('')
    const prevRef = useRef(null)
    const [prevData, setPrevData] = useState(null)
    // const { folderId } = useParams()
    const location = useLocation()
    const searchParamsString = location.search.replace(/\?/g, '&');
    const params = new URLSearchParams(searchParamsString);
    const [reminderDate, setReminderDate] = useState(null)
    const [expiryDate, setExpiryDate] = useState(null)
    const [reminderFolderID, setReminderID] = useState(null)
    const [reminderFileType, setReminderFileType] = useState(null)

    useEffect(() => {
        setFolderId(params.get('folderId'));
        dispatch(setBreadcrumb(params.get('folderName')))
    }, [location, searchVal])

    console.log(folderId, "folderIdfolderId")

    const [folderName, setFolderName] = useState({
        name: {
            value: "",
            isValid: false,
        },
        validation: false,
    })
    const validationConfirm = (inputValue, inputName) => {
        switch (inputName) {
            case "name":
                return valid.isNotEmpty(inputValue);

            default:
                return false;
        }
    };

    const isFormValid = () => {
        return folderName.name.isValid ? true : false;
    };
    const handleInput = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        let data = {
            ...folderName,
            [inputName]: {
                value: inputValue,
                isValid: validationConfirm(inputValue, inputName),
            },
            validation: isFormValid(),
        };
        setFolderName(data);
        dispatch(ErrorReset())
    };

    // SAVE BTN HANDLE
    const saveBtnHandle = () => {
        if (isFormValid()) {
            const data = {
                "name": folderName?.name?.value,
                "added_by_user_id": userId,
                "parent": folderId
            }
            if (uploadType == 'create') {
                dispatch(companydocsCreateNewFolder(data))
            } else if (uploadType == 'rename') {
                // dispatch(renameFolder(renameId, data))

                // Modify the data object before dispatching
                const renamedData = {
                    "new_name": data.name, // Replace 'name' with 'new_name'
                };
                dispatch(renameCompanyDocsFolder(renameId, renamedData, folderId, userId));

            }
        } else {
            setShowError(true)
        }
    }

    // DELETE BTN HANDLER
    const deleteHandlerBtn = (id) => {
        dispatch(deleteFolder(id, userId, folderId))
    }


    // GET FOLDER LIST
    // useEffect(() => {
    //     dispatch(getFoldersList())
    // }, [dispatch, companyDocuments?.created])


    // RENAME FOLDER NAME
    function binarySearch(arr, id) {
        let low = 0;
        let high = arr.length - 1;

        while (low <= high) {
            let mid = Math.floor((low + high) / 2);
            let midId = arr[mid]?.id;

            if (midId === id) {
                return arr[mid]; // Found the item
            } else if (midId < id) {
                low = mid + 1; // Continue searching in the right half
            } else {
                high = mid - 1; // Continue searching in the left half
            }
        }

        return null; // Item not found
    }
    const renameHandlerBtn = (e, id) => {
        e.stopPropagation()
        setUploadType('rename')
        setRenameId(id)
        folderPopPopRef.current.open();
        const foundItem = companyDocuments?.data?.folders.find(item => item?.id == id);
        // let foundItem = binarySearch(companyDocuments?.data?.folders, id);

        if (foundItem) {
            setFolderName({
                name: {
                    value: foundItem?.name,
                    isValid: true,
                },
                validation: true,
            });
        }
    }


    // FOLDER DETAIL HANDLER
    const FolderDetailsHandler = (id, name) => {
        // setBreadcrumb(name)
        dispatch(setBreadcrumb(name))
        navigate(`/company-documents?folderId=${id}&folderName=${name}`)
        dispatch(getCompanyDocsSubFolder(id))
    }

    // UPLOAD FILE INTEGRATION 
    function onUploadFileChange(e) {
        const file = e.target.files[0];
        console.log(file?.type, FILE_ACCEPT.includes(file?.type), "ascsafsadf")
        const maxSize = 500 * 1024 * 1024; // 2 MB in bytes 
        if (IMG_ACCEPT.includes(file?.type) || FILE_ACCEPT.includes(file?.type)) {
            if (file && file.size <= maxSize) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    // setImage(e.target.result);
                };

                reader.readAsDataURL(file);

                const formData = new FormData();

                const data = {
                    folder_id: folderId,
                    added_by_user_id: userId
                }

                Object.entries(data).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                // formData.append("file", file)
                // dispatch(uploadComapanyDocsFile(formData, folderId));
                compressFile(file)
                    .then((compressedFile) => {
                        formData.append("file", compressedFile, file.name); // Append the compressed file 
                        dispatch(uploadComapanyDocsFile(formData, userId, folderId));
                    })
                    .catch((err) => {
                        console.error("File compression error:", err);
                        // Handle the error appropriately, maybe show an error message to the user
                    });


                // compressFile(file)
                //     .then((compressedFile) => {
                //         const formData = new FormData();
                //         formData.append("folder_id", folderId);
                //         formData.append("added_by_user_id", userId);
                //         formData.append("file", compressedFile, file.name); // Append the compressed file 

                //         dispatch(uploadComapanyDocsFile(formData, folderId));
                //     })
                //     .catch((err) => {
                //         console.error("File compression error:", err);
                //         // Handle the error appropriately, maybe show an error message to the user
                //     });

                // Clear the file input
                e.target.value = null;
            } else {
                alert('File size exceeds the limit of 500 MB');
            }

        } else {
            alert('Invalid Format! Accept only images, .doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .txt');
        }
    }


    // useEffect(() => {
    //     if (folder_ID == 'all') {
    //         dispatch(getFoldersList())
    //         dispatch(resetFolderDetail())
    //         setBreadcrumb('')
    //     }
    // }, [folder_ID])


    useEffect(() => {
        if (folderId == null && params.get('folderId') == null) {
            dispatch(getComapanyDocumentsFoldersList(userId))
            dispatch(resetFolderDetail())
            // setBreadcrumb('')
            dispatch(setBreadcrumb(''))
        } else { }
        if (folderId == null && companyDocuments?.created) {
            dispatch(getComapanyDocumentsFoldersList(userId))
        }
        if (folderId !== null) {
            dispatch(getCompanyDocsSubFolder(folderId))
        }
    }, [folderId, companyDocuments?.created, companyDocuments?.reminder])

    useEffect(() => {
        const folder_id_param = params.get('folderId')
        if (folder_id_param !== null) {
            dispatch(getCompanyDocsSubFolder(folder_id_param))
        }
        // setBreadcrumb(companyDocuments?.data[0]?.name)
    }, [])
    useEffect(() => {
        if (params.get('folderId') !== null) {
            // setBreadcrumb(companyDocuments?.data[0]?.name)
        } else {
            // setBreadcrumb('')
        }
    }, [companyDocuments])


    // RESET FOLDER NAME 
    useEffect(() => {
        if (companyDocuments?.created || companyDocuments?.rename) {
            dispatch(createFolderReset())
            dispatch(renameReset())
            setFolderName({
                name: {
                    value: "",
                    isValid: false,
                },
                validation: false,
            })
        }
    }, [companyDocuments])


    const resetFolderName = () => {
        dispatch(ErrorReset())
        setFolderName({
            name: {
                value: "",
                isValid: false,
            },
            validation: false,
        });
    };
    useLayoutEffect(() => {
        if (location?.pathname.includes('companyDocuments')) {
            dispatch(resetFolderList())
        }
    }, [])

    const filePrevHandler = (data) => {
        prevRef.current.open();
        setPrevData(data)
        console.log(data, "sfsadvfc")
    }
    const filePrevHandlerClose = () => {
        prevRef.current.close();
    }

    const deleteFileHanlder = (id) => {
        dispatch(deleteFiles(id, userId, folderId))
    }


    console.log(reminderFolderID, "SDfdsfgf")
    const setReminderHandler = (e) => {
        e.preventDefault()
        const data = {
            "folder_id": reminderFolderID?.id,
            "reminder_date": reminderDate,
            ...(expiryDate && { "expiry_date": expiryDate })
        }
        // if (reminderDate !== null) {
        if (reminderFileType === "folder") {
            dispatch(setReminderFolder(data))
        } else if (reminderFileType === "file") {
            dispatch(setReminderFile(data))
        }
        // }
    }

    // Trigger company documents noty
    useEffect(() => {
        dispatch(triggerCompanyDocumentsNotification(userId))
        dispatch(setCompanyDocumentsNotifications(userId))
    }, []);

    return {
        folderName, setFolderName, handleInput, saveBtnHandle,
        showError, deleteHandlerBtn, resetFolderName, renameHandlerBtn, editable,
        uploadType, setUploadType, setShowError, FolderDetailsHandler, onUploadFileChange,
        setBreadcrumb, folderId, setFolderId, searchVal, setSearchVal, filePrevHandler, prevRef, filePrevHandlerClose,
        prevData, deleteFileHanlder, reminderDate, setReminderDate, expiryDate, setExpiryDate, setReminderHandler, reminderFolderID, setReminderID,
        setReminderFileType
    }
}

export default useCompanyDocuments