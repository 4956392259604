import Compressor from 'compressorjs';
import { gzipSync } from 'fflate';
import { PDFDocument } from 'pdf-lib';

const compressPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    // Apply any compression or optimization techniques here if needed

    const compressedArrayBuffer = await pdfDoc.save();
    return new Blob([compressedArrayBuffer], { type: 'application/pdf' });
};

const compressNonImageFile = (file) => {
    return new Promise((resolve, reject) => {
        if (file.type === 'application/pdf') {
            // compressPDF(file)
            //     .then((compressedPDF) => resolve(compressedPDF))
            //     .catch((err) => reject(err));
            resolve(file);
        } else {
            // If the file is not an image or a PDF, return the file as is
            resolve(file);
            // const reader = new FileReader();
            // reader.readAsArrayBuffer(file);
            // reader.onload = () => {
            //     const arrayBuffer = reader.result;
            //     try {
            //         const compressedBuffer = gzipSync(new Uint8Array(arrayBuffer));
            //         const compressedBlob = new Blob([compressedBuffer], { type: file.type });
            //         resolve(compressedBlob);
            //     } catch (err) {
            //         reject(err);
            //     }
            // };
            // reader.onerror = (error) => {
            //     reject(error);
            // };
        }
    });
};

const compressFile = (file) => {
    return new Promise((resolve, reject) => {
        // Check if file size is less than 500 KB (500 * 1024 bytes)
        if (file.size <= 500 * 1024) {
            // If file size is >= 500 KB, resolve with the original file
            resolve(file);
        } else {
            if (file.type.startsWith('image/')) {
                new Compressor(file, {
                    quality: 1,
                    maxWidth: 800,
                    maxHeight: 800,
                    success: (compressedResult) => {
                        resolve(compressedResult);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
            } else {
                compressNonImageFile(file)
                    .then((compressedResult) => {
                        resolve(compressedResult);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        }
    });
};

// const compressFile = (file) => {
//     return new Promise((resolve, reject) => {
//         if (file.type.startsWith('image/')) {
//             new Compressor(file, {
//                 quality: 0.6,
//                 maxWidth: 800,
//                 maxHeight: 800,
//                 success: (compressedResult) => {
//                     resolve(compressedResult);
//                 },
//                 error: (err) => {
//                     reject(err);
//                 }
//             });
//         } else {
//             compressNonImageFile(file)
//                 .then((compressedResult) => {
//                     resolve(compressedResult);
//                 })
//                 .catch((err) => {
//                     reject(err);
//                 });
//         }
//     });
// };


export { compressFile };
