import React, { useEffect, useRef, useState } from 'react'
import Modals from '../../../CommanComponents/Modals'
import ModalsHeader from '../../../CommanComponents/Modals/ModalsHeader'
import ModalBody from '../../../CommanComponents/Modals/ModalsBody'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton';
import './bulkEmployeePop.scss'
import ModalsFooter from '../../../CommanComponents/Modals/ModalsFooter'
import FormError from '../../../CommanComponents/Form/FormError';
import { useSelector } from 'react-redux';
import imddd from '../../../Assets/icons/cross.svg'
import { fileDownloaderHelperFunc } from '../../../HelperFunction/fileDownloaderHelperFunc';
import { bulkEmployeeFormatFile } from '../../../Constant/ConstantsFile';
const BulkEmployeePop = ({ reference, onClose, handleBulkInput,
    inputBulkRef,
    bulkFile, handleBulkUpload, crossBtnHandler
}) => {
    const { bulk_upload } = useSelector((state) => state.employeeManagement)

    // const handleDrop = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();

    //     if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
    //         inputBulkRef.current.files = event.dataTransfer.files;
    //         handleBulkInput({ target: inputBulkRef.current });
    //         event.dataTransfer.clearData();
    //     }
    // };

    // const handleDragOver = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    // };
    console.log(bulk_upload?.data, "SAdvdfgbvf")
    return (
        <Modals
            ref={reference}
            ClosePopUp={onClose}
            Position="center"
            ModalsSize={'modal-s'}
            ClassName={'employee-bulk-upload-wrapper'}
        >
            <ModalsHeader ClassName={'heading'} >
                <h4 className='w-500 text-s d-flex align-center '  >
                    <i className="back-btn pointer mr-10" onClick={onClose}></i>
                    <p className='text-center m-auto'>Bulk Upload</p>
                </h4>
            </ModalsHeader>
            <ModalBody className={'employee-bulk-modal-body'}>
                <div className="uploaded-doc-img-wrapper">
                    <div className="download-format-wrapper d-flex mb-25">
                        <p className="text-xs w-500 secondary mr-10" >Download File Format -</p>
                        {/* <a href={imddd}>sdfdfg</a> */}
                        <button
                            className="icons-wrap transparent d-flex align-center justify-center"
                            onClick={() => fileDownloaderHelperFunc(bulkEmployeeFormatFile)}
                        > <i className="icons download-icon"></i></button>
                    </div>
                    <div className="img-wrapper">
                        <div className={`img-wrap ${bulkFile ? "excel-show" : 'default-show'} `}>
                            <div className={`overlay d-flex justify-center   ${bulkFile && "delete-show"}`} >
                                <button className="icons-wrap transparent cursor"
                                    onClick={() => inputBulkRef.current.click()}
                                // onDrop={handleDrop}
                                // onDragOver={handleDragOver}

                                >
                                    <i className={`icons ${bulkFile ? "excel-icon" : 'upload-icon'}`}></i>
                                </button>
                                {bulkFile && <p className='text-xs w-500 secondary file-name'> {bulkFile.name}</p>}
                                {bulkFile && <i className='cross-icon' onClick={() => crossBtnHandler()}> </i>}
                                <FormError
                                    show={bulk_upload?.data.hasOwnProperty("error") || bulk_upload?.data.hasOwnProperty("message") || bulk_upload?.data.hasOwnProperty("e_mail")}
                                    error={bulk_upload?.data?.message || bulk_upload?.data?.error} />

                                {
                                    bulk_upload?.data.hasOwnProperty("duplicate_emails") &&
                                    <p className='text-xxs secondary'>
                                        {
                                            bulk_upload?.data?.duplicate_emails.map((e, key) => {
                                                return `${e}, `
                                            })
                                        }
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <input id="myInput"
                    type="file"
                    ref={inputBulkRef}
                    style={{ display: 'none' }}
                    onChange={handleBulkInput}
                    accept=".xls,.xlsx"
                />
            </ModalBody >
            <ModalsFooter>
                {/* <div className="d-flex">
                    <CommanButton
                        Text={'Upload'}
                        ClassName={'save-btn'}
                        disabled={!file}
                    />
                </div> */}
                <div className="save-btn-wrap d-flex ">
                    <CommanButton
                        Text={'Add Employee'}
                        ClassName={'save-btn'}
                        onClick={handleBulkUpload}
                        disabled={!bulkFile}
                    />
                    <CommanButton
                        Text={'Cancel'}
                        cancel={true} type={'button'}
                        onClick={() => onClose()}
                    />
                </div>
            </ModalsFooter>
        </Modals>
    )
}

export default BulkEmployeePop