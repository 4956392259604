import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useFolders from '../../CustomHooks/Folders/useFolders'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import { ALLOW_IMAGE_EXTENSIONS, getBaseUrl } from '../../config'
import getFileExtension from '../../HelperFunction/getFileExtension'
import { fileDownloaderHelperFunc } from '../../HelperFunction/fileDownloaderHelperFunc'
import LoadingData from '../../CommanComponents/LoadingData/LoadingData'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'

const FolderListView = ({ deleteHandlerBtn, renameHandlerBtn, FolderDetailsHandler, filePrevHandler, deleteFileHanlder, folderId, openFolderPopup }) => {
    const folders = useSelector((e) => e.folders) // FOLDERS SELECTOR 
    const ref = useRef(null)
    const ref2 = useRef(null)
    const [activeOptionsDrop, setActiveOptionsDrop] = useDetectOutsideClick(ref, false)
    const [activeOptionsDrop2, setActiveOptionsDrop2] = useDetectOutsideClick(ref2, false)
    const [deleteId, setDeleteId] = useState("");
    const [deletePop, setDeletePop] = useState(false)
    const [deleteType, setDeleteType] = useState(null);

    const handleDotsBtn = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop(prev => val === prev ? -1 : val)

    }
    const handleDotsBtn2 = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop2(prev => val === prev ? -1 : val)

    }
    const handleDltBtn = (e, id, type) => {
        e.stopPropagation()
        setDeletePop(true);
        setDeleteId(id)
        setDeleteType(type)
    }

    const CancleDelete = () => {
        setDeletePop(false);
        setDeleteType(null)
    }
    const handlePopDeleteBtn = () => {
        setDeletePop(false);
        setDeleteType(null)
        if (deleteType == "folder") {
            deleteHandlerBtn(deleteId)
        } else if (deleteType == "file") {
            deleteFileHanlder(deleteId)
        }
    }

    return (
        <div className="list-folder-item-wrap ">
            <div className="folder-table-wrapper ">
                <div className="table-wrap">
                    {folders?.loading ? <LoadingData /> : folders?.data?.folders?.length || folders?.data?.files?.length ?
                        <table className="table-item">
                            <thead className='table-head'>
                                <tr className="table-row">
                                    <th > Name  </th>
                                    <th > Action  </th>
                                </tr>
                            </thead>

                            <tbody className='table-body'>
                                {folders?.data?.folders?.map((val, key) => {
                                    return (
                                        // !val.hasOwnProperty("files") ?
                                        <tr className="table-row relative" key={key} onClick={() => FolderDetailsHandler(val?.id, val?.name)}>
                                            <td  >
                                                <div className="folder-name-wrap d-flex align-center">
                                                    <i className="folder-icon"></i>
                                                    {val?.name}
                                                </div>
                                            </td>
                                            <td onClick={(e) => e.stopPropagation()} className='relative new-action-wrap'>
                                                <div className='new-action-wrap-dots-wrap d-flex justify-left align-center'>
                                                    {

                                                        <div className={`item d-flex align-center justify-center pointer`}
                                                            onClick={(e) => renameHandlerBtn(e, val?.id)} >
                                                            <i className='icons sT-icon secondary i-xxs edit-icon'></i>
                                                        </div>
                                                    }
                                                    <div className="item d-flex align-center justify-center pointer " onClick={(e) => handleDltBtn(e, val?.id, 'folder')}>
                                                        <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td >
                                                <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn(e, key)}>
                                                    <i className='dots-icon'> </i>
                                                </div>
                                                {activeOptionsDrop === key && <div className={`dropdown-wrap absolute ${true && "active"}`} ref={ref}>
                                                    <p className="text-2xs w-500 secondary mb-10 pointer" onClick={(e) => renameHandlerBtn(e, val?.id)}>Rename</p>
                                                    <p className="text-2xs w-500 secondary  pointer" onClick={(e) => handleDltBtn(e, val?.id, 'folder')}>Delete</p>
                                                </div>}
                                            </td> */}
                                        </tr>
                                    )
                                })}
                                {folders?.data?.files?.map((val, key) => {
                                    return (
                                        <tr className="table-row relative" key={key} onDoubleClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}>
                                            <td  >
                                                <div className="folder-name-wrap d-flex align-center">
                                                    <i className="folder-icon gallery-icon"></i>
                                                    {val.file.split('/')[val.file.split('/').length - 1].split('.')[0]}
                                                </div>
                                            </td>
                                            <td onClick={(e) => e.stopPropagation()} className='relative new-action-wrap'>
                                                <div className='new-action-wrap-dots-wrap d-flex justify-left align-center'>
                                                    {

                                                        <div className={`item d-flex align-center justify-center pointer`}
                                                            onClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }} >
                                                            <i className='icons sT-icon secondary i-xxs eye'></i>
                                                        </div>
                                                    }
                                                    <div className="item d-flex align-center justify-center pointer " onClick={(e) => handleDltBtn(e, val?.id, 'file')}>
                                                        <i className='icons sT-icon secondary i-xxs delete-icon'></i>
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td >
                                                <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn2(e, key)}>
                                                    <i className='dots-icon'> </i>
                                                </div>
                                                {activeOptionsDrop2 === key && <div className={`dropdown-wrap absolute active`} ref={ref2}>
                                                    <p className="text-2xs w-500 secondary mb-10 pointer" 
                                                        onClick={() => { ALLOW_IMAGE_EXTENSIONS.includes(getFileExtension(val.file)) ? filePrevHandler(val) : fileDownloaderHelperFunc(getBaseUrl() + val.file) }}
                                                    >
                                                        Preview
                                                    </p>
                                                    <p className="text-2xs w-500 secondary pointer" onClick={(e) => handleDltBtn(e, val?.id, 'file')}  >Delete</p>
                                                   
                                                </div>}
                                            </td> */}
                                        </tr>

                                    )
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='d-flex add-folder-btn'>
                            <DataNotFound />
                            {folderId === null && (folders?.data?.breadcrumbs?.length < 4 || folders?.data?.breadcrumbs == undefined) && <CommanButton onClick={(e) => { return openFolderPopup() }} ClassName={"action-btn"} >
                                <p className='w-700'> <span className='text-xs'>  + </span> Folder</p>
                            </CommanButton>}
                        </div>
                    }
                </div>
            </div>
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handlePopDeleteBtn()}
            />}
        </div>
    )
}

export default FolderListView