import axios from "axios";
import Auth from "../../../Classes/Auth";
import Cookies from "../../../Classes/Cookies";
import Storage from "../../../Classes/Storage";
import { user_offer_type, userType } from "../../../Constant/auth";
import { showErrorToast, showSuccessToast } from "../SuccessMessagePopup";
import { User_Details } from "./actionType"
import authRequest from "./authRequest";
import Api from "../../../Classes/Api";
const api = new Api()
const setUserType = (val, offer_type = "") => {
    Storage.setBool(userType, val)
    const user_type = Storage.getBool(userType)

    Storage.set(user_offer_type, offer_type)
    const user_Subscription_type = Storage.getString(user_offer_type) ? Storage.getString(user_offer_type) : "rgfd"

    const payload = { user_type, user_Subscription_type }
    // let user_type;
    // if (bool_user_type == "1") {
    //     user_type = "Business Owner"
    // } else if (bool_user_type == "2") {
    //     user_type = "Typing Center"
    // } else if (bool_user_type == "3") {
    //     user_type = "Typing Center"
    // }
    // console.log(user_type, bool_user_type, "user_typeuser_typeuser_typeuser_type")
    return {
        type: User_Details.SET_USER_TYPE,
        payload: payload
    }
}


const setlogin = (data) => {
    return async (dispatch) => {
        // authRequest.post(authRequest.endpoint.user_login, data, (success) => {
        //     dispatch({ type: User_Details.LOGIN_SUCCESS })
        //     dispatch(showSuccessToast("Login successfull"))
        //     Auth.setUserLogin(success.data)
        // }, (error) => {
        //     dispatch({ type: User_Details.SET_MESSAGE, payload: error?.response?.data?.error })
        // }, 'noAuth'
        // )
        axios.post(`${api.url()}user_login`, data)
            .then(function (success) {
                dispatch({ type: User_Details.LOGIN_SUCCESS })
                dispatch(showSuccessToast("Login successfull"))
                Auth.setUserLogin(success.data?.signup_data)
                Auth.updateUserDetail("token", success.data?.token?.access)
                // console.log(success.data?.token, "Axcsc")
            })
            .catch(function (error) {
                dispatch({ type: User_Details.SET_MESSAGE, payload: error?.response?.data?.error })
            });
    }
}
const resetLogin = (data) => {
    return {
        type: User_Details.LOGIN_RESET
    }
}

const signupTypingCenter = (data) => {
    return async (dispatch) => {
        authRequest.post(authRequest.endpoint.signup_typing_center, data, (success) => {
            dispatch({ type: User_Details.SET_SIGNUP_SUCCESS },)
            dispatch(showSuccessToast(success?.data?.msg))
            dispatch(sendEmailVerify({ email: data?.email }))
        }, (error) => {
            // dispatch(
            // showErrorToast(error?.response?.data?.email[0])
            if (error?.response?.status == 400) {
                dispatch({ type: User_Details.SET_MESSAGE, payload: "User registration with this email already exists." })
            }
            // )
        }, 'noAuth'
        )

        // axios.post(`${api.url()}typing_center_register`, data)
        //     .then(function (success) {
        //         dispatch({ type: User_Details.SET_SIGNUP_SUCCESS },)
        //         dispatch(showSuccessToast(success?.data?.msg))
        //     })
        //     .catch(function (error) {
        //         dispatch({ type: User_Details.SET_MESSAGE, payload: error?.response?.data?.email[0] })
        //     });
    }
}

const signupBusinessOwner = (data) => {
    return async (dispatch) => {
        authRequest.post(authRequest.endpoint.business_user_register, data, (success) => {
            dispatch({ type: User_Details.SET_SIGNUP_SUCCESS })
            dispatch(showSuccessToast(success?.data?.msg))
            dispatch(sendEmailVerify({ email: data?.email }))
        }, (error) => {
            if (error?.response?.status == 400) {
                dispatch({ type: User_Details.SET_MESSAGE, payload: "User registration with this email already exists." })
            }
        }, 'noAuth'
        )

        // axios.post(`${api.url()}business_user_register`, data)
        //     .then(function (success) {
        //         dispatch({ type: User_Details.SET_SIGNUP_SUCCESS })
        //         dispatch(showSuccessToast(success?.data?.msg))
        //     })
        //     .catch(function (error) {
        //         dispatch({ type: User_Details.SET_MESSAGE, payload: error?.response?.data?.email[0] })
        //     });

    }
}
const signupIndividual = (data) => {
    return async (dispatch) => {
        authRequest.post(authRequest.endpoint.individual_user_register, data, (success) => {
            dispatch({ type: User_Details.SET_SIGNUP_SUCCESS })
            dispatch(showSuccessToast(success?.data?.msg))
            dispatch(sendEmailVerify({ email: data?.email }))
        }, (error) => {
            if (error?.response?.status == 400) {
                dispatch({ type: User_Details.SET_MESSAGE, payload: "User registration with this email already exists." })
            }
        }, 'noAuth'
        )
    }
}
const sendEmailVerify = (email) => {
    return async (dispatch) => {
        dispatch({ type: User_Details.SET_SEND_EMAIL_LOADING })
        authRequest.post(authRequest.endpoint.sendEmailVerification, email, (success) => {
            dispatch({ type: User_Details.SET_SEND_EMAIL_SUCCESS })
            // dispatch(showSuccessToast(success?.data?.msg))
            // console.log(success.data)
        }, (error) => {

        }, 'noAuth'
        )
    }
}

const verifyEmail = (uidb64, token) => {
    return async (dispatch) => {
        authRequest.get(authRequest.endpoint.verify_email.replace('__U_IDB__', uidb64).replace('__Token__', token), (success) => {
            dispatch({ type: User_Details.SET_VERIFY_EMAIL_SUCCESS, payload: success?.data?.message })
            console.log(success, "Sddsfg")
        }, (error) => {
            dispatch({ type: User_Details.SET_VERIFY_EMAIL_ERROR, payload: error?.response?.data?.error })
            console.log(error, "Sddsfg 11111111")
        }, 'noAuth'
        )
    }
}


const checkUserExists = (data) => {
    return async (dispatch) => {
        dispatch({ type: User_Details.SET_CHECK_EXIST_LOADING })
        authRequest.post(authRequest.endpoint.check_user_exists, data, (success) => {
            dispatch({ type: User_Details.SET_CHECK_EXIST_SUCCESS })
            // dispatch(showSuccessToast(success?.data?.msg))
            // console.log(success.data)
        }, (error) => {
            if (error?.response?.status == 409) {
                dispatch({ type: User_Details.SET_CHECK_EXIST_ERROR, payload: "User registration with this email already exists." })
            }
        }, 'noAuth'
        )
    }
}


const resetCheckUserExists = () => {
    return {
        type: User_Details.RESET_SET_CHECK_EXIST,
    }
}
const resetErrorMessage = () => {
    return {
        type: User_Details.SET_MESSAGE_HIDE,
    }
}
const resetSendEmailVerify = () => {
    return {
        type: User_Details.RESET_SEND_EMAIL_SUCCESS,
    }
}

export {
    setUserType, setlogin, resetLogin, signupTypingCenter,
    signupBusinessOwner, signupIndividual, resetErrorMessage, sendEmailVerify, verifyEmail,
    resetSendEmailVerify, checkUserExists, resetCheckUserExists
};