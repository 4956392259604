import './breadcurmb.scss';

function Breadcrumb({ children }) {
  return (
    <div className="common-breadcrumb-wrapper">
      <ul className="BreadCrumb">{children}</ul>
    </div>
  );
}

export default Breadcrumb;
