import { forgotPasswordAction } from "../Actions/ForgotPassword/actionType";

const initState = {
    loading: false,
    isValid: false,
    message: "",
    // otp_varify: false,
    new_password: false,
    verifyOtp: {
        loading: false,
        success: false,
        error: false,
        data: []
    }
}

const forgotPassword = (state = initState, action) => {
    switch (action.type) {
        case forgotPasswordAction.SET_LOADING: return {
            ...state,
            loading: true
        }
        case forgotPasswordAction.SET_VALID_USER: return {
            ...state,
            isValid: true,
            loading: false
        }
        case forgotPasswordAction.HIDE_VALID_USER: return {
            ...state,
            isValid: false,
            loading: false,
            message: ''
        }
        case forgotPasswordAction.SET_NOT_VALID_USER: return {
            ...state,
            isValid: false,
            loading: false,
            message: "Not a valid user."
        }
        case forgotPasswordAction.HIDE_VALID_USER: return {
            ...state,
            message: ""
        }
        case forgotPasswordAction.SET_MESSAGE: return {
            ...state,
            message: action.payload
        }
        case forgotPasswordAction.VERIFY_OTP_SUCCESS: return {
            ...state,
            // otp_varify: true
            verifyOtp: {
                ...state?.verifyOtp,
                success: true,
            }
        }
        case forgotPasswordAction.VERIFY_OTP_FAIL: return {
            ...state,
            verifyOtp: {
                ...state?.verifyOtp,
                success: false,
                error: true,
            }
        }
        case forgotPasswordAction.RESET_VERIFY_OTP_FAIL: return {
            ...state,
            verifyOtp: {
                ...state?.verifyOtp,
                success: false,
                error: false,
            }
        }
        case forgotPasswordAction.SET_NEWPASSWORD_SUCCESS: return {
            ...state,
            new_password: true
        }
        case forgotPasswordAction.RESET_NEWPASSWORD_SUCCESS: return {
            ...state,
            new_password: false
        }

        default: return state
    }
}

export default forgotPassword;